import "./ShareModal.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import _ from "lodash"
import modalDeprecated from "../../decorators/modalDeprecated"
import strings from "../../../locale/strings"
import Actions from "../../../actions/index"
import Button from "../../elements/Button/Button"
import Typeahead from "../../elements/Typeahead/Typeahead"
import TypeaheadRowWithAvatar from "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar"
import TextEditor from "../../elements/TextEditor"
import { UserByEmailType } from "../../prop-types/userObjectTypes"

class ShareModal extends React.Component {
  static propTypes = {
    header: PropTypes.node.isRequired,
    message: PropTypes.node,
    initialRecipient: UserByEmailType,
    initialRecipients: PropTypes.arrayOf(UserByEmailType),
    allowNotes: PropTypes.bool,
    cancelButton: PropTypes.bool,
    primaryButtonText: PropTypes.node,
    notePlaceholder: PropTypes.string,
    preview: PropTypes.node,
    onShare: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    hasPseudoheader: PropTypes.bool
  }

  static defaultProps = {
    allowNotes: true,
    cancelButton: false,
    hasPseudoheader: true,
    primaryButtonText: <FormattedMessage {...strings.sharingModal.share} />
  }

  constructor(props) {
    super(props)

    // This initialization of recipients relies indirectly on modal's mounting behaviour.
    this.state = {
      recipients:
        props.initialRecipients ||
        (props.initialRecipient && [props.initialRecipient]) ||
        []
    }
  }

  handleRecipientsChange = recipients => {
    this.setState({ recipients })
  }

  handleShare = () => {
    const { recipients } = this.state
    this.props.onShare({
      recipients,
      notes: this.noteEditor && this.noteEditor.getMarkdown()
    })
  }

  loadUserOptions(query) {
    return Actions.User.list({ q: query })
  }

  render() {
    const {
      header,
      message,
      allowNotes,
      notePlaceholder,
      preview,
      primaryButtonText,
      cancelButton,
      hasPseudoheader,
      intl: { formatMessage }
    } = this.props
    const { recipients } = this.state

    return (
      <div className="ShareModal layout vertical center-center">
        <h2>{header}</h2>

        {message && <p className="ShareModal--message">{message}</p>}

        {hasPseudoheader && (
          <p className="ShareModal--pseudoheader center-align">
            <FormattedMessage {...strings.sharingModal.shareWith} />
          </p>
        )}

        <Typeahead
          className="ShareModal--recipients-select"
          zugataStyle={true}
          value={recipients}
          onChange={this.handleRecipientsChange}
          placeholder={
            <FormattedMessage {...strings.sharingModal.shareWithPlaceholder} />
          }
          clearable={false}
          allowCreate={false}
          searchable={true}
          multi={true}
          loadOptions={this.loadUserOptions}
          loadOptionsField="users"
          autoload={false}
          labelKey="full_name"
          valueKey="email"
          valueComponent={config => {
            const { value, onRemove } = config
            return (
              <TypeaheadRowWithAvatar
                user={value}
                showEmail={false}
                clearable={true}
                onClear={() => onRemove(value)}
              />
            )
          }}
          optionRenderer={user => <TypeaheadRowWithAvatar user={user} />}
        />

        {allowNotes && [
          <p key="header" className="ShareModal--pseudoheader">
            <FormattedMessage {...strings.sharingModal.addANote} />
          </p>,
          <TextEditor
            key="editor"
            forwardedRef={noteEditor => (this.noteEditor = noteEditor)}
            placeholder={
              notePlaceholder ||
              formatMessage(strings.sharingModal.addANoteGenericPlaceholder)
            }
            className="ShareModal--note-box"
          />
        ]}

        {preview && (
          <div className="ShareModal--preview-container">{preview}</div>
        )}

        <div className="ShareModal--button-container">
          {cancelButton && (
            <Button
              className="ShareModal--cancel-button"
              actionType="secondary"
              onClick={() => this.props.onClose()}
            >
              <FormattedMessage {...strings.sharingModal.cancel} />
            </Button>
          )}
          <Button
            actionType="primary"
            onClick={this.handleShare.bind(this)}
            disabled={!recipients || recipients.length === 0}
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    )
  }
}

export default _.compose(
  modalDeprecated({
    className: "ShareModal"
  }),
  injectIntl
)(ShareModal)

export { ShareModal as RawShareModal }
