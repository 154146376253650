/**
 * TODO: Confirm how we want to track pages ie just a string with the event name?
 * Names should be a string with lower case letters numbers or _ correct?
 */
import { trackPageview } from "./treasureData"
import * as events from "./events"

// this is used to set "Event Properties" attributed in event documentation
// for pageviews
// TODO - Tracking: Is this used?
export const setPageProperty = (
  analyticKey: string,
  value: Record<string, string>
) =>
  // @ts-ignore note: this should be updated
  window.treasureDataSettings.pageProperties.push({
    [analyticKey]: value
  })

// helper functions for tracking page views
const isCreateTeamPath = (pathname: string): boolean =>
  /teams\/new\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g.test(
    pathname
  )

const isSpecificTeamPath = (pathname: string): boolean =>
  /teams\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g.test(
    pathname
  )

const isSpecificArchivedTeamPath = (pathname: string): boolean =>
  /teams\/archived\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g.test(
    pathname
  )

type Path = {
  pathname: string
}
/**
 * Handles the page changes that occur in the browserRouter + initial loading.
 * @see Entrypoint src/index.jsx line 154
 *
 * This approach has been taking with the upgrade of React Router v3 -> v5 losing
 * the option to run an onEnter hook. Alternatives can be looked at post-upgrade.
 *
 * Other design weak points include those where we wish to track properties that cannot
 * be passed through location. Those may need a delegate object or explicit calling
 * within the component (be wary of multiple renders overcalling).
 *
 * @param {Path} { pathname } A location object that structures the pathname to compare
 */
export const handlePageview = ({ pathname }: Path) => {
  try {
    // call to track general td.trackPageview
    trackPageview()

    // TODO - Tracking: is this needed?
    switch (true) {
      case pathname === "/teams":
        events.trackTeamsSectionViewed()
        break
      case pathname === "/teams/archived":
        events.trackArchivedTeamsSectionViewed()
        break
      case pathname === "/new_goals/personal":
        events.trackIndividualGoalsSectionViewed()
        break
      case pathname === "/new_goals/personal/new":
        events.trackCreateIndividualGoalPageViewed()
        break
      case pathname === "/new_goals/department":
        events.trackDepartmentGoalsSectionViewed()
        break
      case pathname === "/new_goals/department/new":
        events.trackCreateDepartmentGoalPageViewed()
        break
      case pathname === "/new_goals/team":
        events.trackTeamGoalsSectionViewed()
        break
      case pathname === "/new_goals/team/new":
        events.trackCreateTeamGoalPageViewed()
        break
      case pathname === "/new_goals/company":
        events.trackCompanyGoalsSectionViewed()
        break
      case pathname === "/new_goals/company/new":
        events.trackCreateCompanyPageViewed()
        break
      case isCreateTeamPath(pathname):
        events.trackCreateTeamPageViewed()
        break
      case isSpecificTeamPath(pathname):
        events.trackSpecificTeamsPageViewed()
        break
      case isSpecificArchivedTeamPath(pathname):
        events.trackSpecificArchivedTeamsPageViewed()
        break
      // Does this route actually exist to be tracked? Is is the parent to all `new_goals`?
      // The might need to be a separate call for this.
      case pathname === "/new_goals":
        events.trackGoalSectionViewed()
        break
      default:
        break
    }
  } catch (err) {
    // TODO - Tracking: Add Sentry helper
    console.error(err)
  }
}
