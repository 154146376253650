/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from "lodash"
import { diff as deepDiff } from "deep-diff"

/**
 * Returns a new object, that includes only the items properties that
 * have changed.
 */
export const getObjectDiff = (objBefore: any, objAfter: any) => {
  const diff = deepDiff(objBefore, objAfter)

  if (diff) {
    const lhs = {}
    const rhs = {}
    diff.forEach(elem => {
      // I'm not sure the situation where `path` will be undefined, but
      // the type definition says that it's possible
      if (elem.kind === "N" && elem.path) {
        // New
        _.set(rhs, elem.path, elem.rhs)
      } else if (elem.kind === "E" && elem.path) {
        // Edited
        _.set(rhs, elem.path, elem.rhs)
        _.set(lhs, elem.path, elem.lhs)
      } else if (elem.kind === "D" && elem.path) {
        // Deleted
        _.set(lhs, elem.path, elem.lhs)
      } else if (elem.kind === "A" && elem.path) {
        // In an array - just show the entire array for a quick implementation.
        // Maybe later this can be enhanced we can return just the specific
        // array updates
        _.set(lhs, elem.path, _.get(objBefore, elem.path))
        _.set(rhs, elem.path, _.get(objAfter, elem.path))
      }
    })

    return { lhs, rhs }
  } else {
    return null
  }
}

const mapArrayOrObject = (arrOrObj: any[] | object, fn: (val: any) => any) => {
  if (_.isArray(arrOrObj)) {
    return arrOrObj.map(fn)
  } else {
    return _.mapValues(arrOrObj, fn)
  }
}

// Takes a nested object, and converts any ImmutableJS object into regular
// js objects
// No need to pass the `objectReferencePath` parameter, that's for internal
// recursive purposes.
export const simplifyImmutableJsObject = (
  obj: any,
  circularReferenceReplacement: any = null,
  objectReferencePath: object[] = []
): any => {
  if (!obj) return obj
  if (obj.toJS) return obj.toJS()
  if (typeof obj !== "object") return obj

  // If the object has a cyclic object reference, this recursive function will
  // run to infinity.
  if (objectReferencePath.includes(obj)) return circularReferenceReplacement

  return mapArrayOrObject(obj, val => {
    if (val && val.toJS) {
      return val.toJS()
    } else if (typeof val === "object") {
      return simplifyImmutableJsObject(val, circularReferenceReplacement, [
        ...objectReferencePath,
        obj
      ])
    }
    return val
  })
}
