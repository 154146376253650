import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import _ from "lodash"
import { List } from "immutable"
import editIcon from "@kaizen/component-library/icons/edit.icon.svg"
import deleteIcon from "@kaizen/component-library/icons/trash.icon.svg"
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"
import ZugataFormattedRelativeDate from "../../elements/ZugataFormattedDate/ZugataFormattedRelativeDate"
import strings from "../../../locale/strings"
import Select from "../../elements/Select/Select"
import ProfilePic from "../ProfilePic/ProfilePic"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import Loader from "../Loader/Loader"
import Goal from "../../../models/Goal"
import User from "../../../models/User"
import ErrorBoundary from "../../util/ErrorBoundary"
import GoalProgressSlider from "./GoalProgressSlider"
import GoalProgressBar from "./GoalProgressBar"
import CreateOrEditGoalFields from "./CreateOrEditGoalFields"
import CollapsibleSection from "../../elements/CollapsibleSection/CollapsibleSection"
import GoalKeyResultList from "./GoalKeyResultList"
import GoalVisibilityIndicator from "./GoalVisibilityIndicator"
import GoalAlignmentList from "./GoalAlignmentList"
import GoalsTable from "./GoalsTable"
import GoalStatus from "./GoalStatus"
import GoalConversationSection from "./GoalConversationSection"
import STATUS_OPTIONS from "./util/goalStatusOptions"
import { chunkBy } from "../../../util/sequences"
import { ActionItems } from "../../widgets/ActionItems/ActionItems"
import ConfirmationModal from "../../widgets/modals/ConfirmationModal"
import BreadcrumbAndMenuHeader from "../../widgets/BreadcrumbAndMenuHeader/BreadcrumbAndMenuHeader"
import "./BaseViewGoalModal.less"

export const BASE_VIEW_GOAL_MODAL_PUBLIC_PROPS = {
  readOnly: PropTypes.bool,
  allowDeletion: PropTypes.bool
}

export default class BaseViewGoalModal extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    goal: PropTypes.object,
    handleTeamGoal: PropTypes.func,
    NestedGoalModalComponent: PropTypes.func.isRequired,
    ConversationComponent: PropTypes.func,
    comments: PropTypes.array,
    createAction: PropTypes.func,
    updateAction: PropTypes.func,
    deleteAction: PropTypes.func,
    markAsSeenAction: PropTypes.func,

    ...BASE_VIEW_GOAL_MODAL_PUBLIC_PROPS
  }

  static defaultProps = {
    allowDeletion: true
  }

  constructor(props) {
    super(props)

    this.state = {
      inEditingMode: false,
      isDeleting: false,
      completion: null,
      alignedGoalIdToShow: null,
      keyResults: null
    }
  }

  componentDidMount() {
    this.markAsSeen()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.goal !== this.props.goal) {
      this.markAsSeen()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { goal: nextGoal } = nextProps
    const { goal } = this.props

    if (nextGoal && nextGoal !== goal) {
      this.handleGoal(nextGoal)
    }
  }

  handleGoal = goal => {
    // Track completion in state, don't override with new value from SF
    this.setState(state => ({
      completion:
        state.completion !== null ? state.completion : goal.completion,
      keyResults: List(goal.key_results).map(newKeyResult => {
        if (!state.keyResults) {
          return newKeyResult
        }

        const previousKeyResult = state.keyResults.find(
          keyResult => keyResult.id === newKeyResult.id
        )

        return previousKeyResult
          ? { ...newKeyResult, completion: previousKeyResult.completion }
          : newKeyResult
      })
    }))
  }

  handleDoneEditing = () => {
    this.setState({ inEditingMode: false })
  }

  markAsSeen() {
    const { markAsSeenAction, goal } = this.props

    if (
      markAsSeenAction &&
      goal &&
      !goal.manager_seen_at &&
      User.isManagerOf(this.context.user, goal.user)
    ) {
      markAsSeenAction(goal.id)
    }
  }

  updateGoalProperty(name, value) {
    const { updateAction, goal } = this.props

    if (goal[name] === value) {
      return
    }

    updateAction({
      originalGoal: goal,
      updatedGoal: { ...goal, [name]: value }
    })
  }

  handleStatusChange = option => {
    this.updateGoalProperty("status", option.value)
  }

  handleCompletionChange = completion => {
    this.setState({ completion })
  }

  handleAfterCompletionChange = completion => {
    this.updateGoalProperty("completion", completion)
  }

  handleKeyResultCompletionChange = (index, completion) => {
    this.setState(state => ({
      keyResults: state.keyResults.update(index, kr => ({ ...kr, completion }))
    }))
  }

  handleAfterKeyResultCompletionChange = () => {
    this.updateGoalProperty("key_results", this.state.keyResults.toArray())
  }

  handleClickAlignment = alignedGoal => {
    if (alignedGoal.goal_type === "team_goal") {
      this.props.handleTeamGoal(alignedGoal)
    } else {
      this.setState({
        alignedGoalIdToShow: alignedGoal.id
      })
    }
  }

  handleCloseNestedGoalModal = () => {
    this.setState({
      alignedGoalIdToShow: null
    })
  }

  handleConfirmDelete = () => {
    const { goal, deleteAction, onDelete } = this.props

    deleteAction(goal).then(onDelete)
  }

  getSourceName() {
    const { goal } = this.props
    switch (goal.goal_type) {
      case "my_goal":
        return goal.user.best_name
      case "department_goal":
        return goal.department.title
      case "team goal":
        return "Team goal"
      default:
        return this.context.user.company.name
    }
  }

  renderGoalContent() {
    const { goal, readOnly } = this.props
    const { completion, keyResults } = this.state
    const viewer = this.context.user

    const {
      priority,
      priority_name: priorityName,
      name,
      description,
      status,
      visibility,
      outgoing_aligned_goals: outgoingAlignedGoals,
      access_permissions: accessPermissions
    } = goal

    const editable = !readOnly && Goal.isEditableByUser(goal, viewer)
    const completionEditable =
      !readOnly && goal && viewer && Goal.completionEditableByUser(goal, viewer)
    const hasKeyResults = !!keyResults && keyResults.size > 0

    return (
      <div className="BaseViewGoalModal--goal-content">
        {editable ? (
          <Select
            className="BaseViewGoalModal--status-select"
            value={status}
            options={STATUS_OPTIONS}
            onChange={this.handleStatusChange}
          />
        ) : (
          <GoalStatus status={status} />
        )}

        <GoalVisibilityIndicator
          key={`${visibility}-${accessPermissions}`}
          visibility={visibility}
          individuals={visibility === "individuals" && accessPermissions}
        />

        {priority > 0 && (
          <div
            className={`BaseViewGoalModal--priority BaseViewGoalModal--priority-${priority}`}
          >
            {priorityName}
          </div>
        )}

        <div className="BaseViewGoalModal--name-and-description">
          <div className="BaseViewGoalModal--goal-header">
            <div className="BaseViewGoalModal--name left-align flex three">
              {name}
            </div>

            {!hasKeyResults &&
              completion !== null &&
              (completionEditable ? (
                <div className="BaseViewGoalModal--overall-container">
                  <GoalProgressSlider
                    className="BaseViewGoalModal--overall-completion-slider flex one"
                    value={completion}
                    onChange={this.handleCompletionChange}
                    onAfterChange={this.handleAfterCompletionChange}
                  />
                </div>
              ) : (
                <GoalProgressBar
                  className="BaseViewGoalModal--overall-completion-bar flex one"
                  value={completion}
                />
              ))}
          </div>

          {description && (
            <MarkdownText
              className="BaseViewGoalModal--description-text"
              text={description}
            />
          )}
        </div>

        {hasKeyResults && (
          <GoalKeyResultList
            keyResults={keyResults}
            readOnly={!completionEditable}
            onCompletionChange={this.handleKeyResultCompletionChange}
            onAfterCompletionChange={this.handleAfterKeyResultCompletionChange}
          />
        )}

        {_.get(outgoingAlignedGoals, "length") > 0 && (
          <GoalAlignmentList
            alignments={outgoingAlignedGoals}
            onClickAlignment={this.handleClickAlignment}
          />
        )}
      </div>
    )
  }

  renderActivityContent() {
    const { goal } = this.props
    const modifyingUsersById = _.indexBy(goal.modifying_users, "id")

    const chunkedAudits = chunkBy(goal.audits, audit =>
      audit.audited_changes.incoming_alignment_goal_id
        ? `alignments-${audit.user_id}`
        : String(audit.id)
    ).map(chunk => chunk.values[0])

    return (
      <ErrorBoundary>
        <div className="BaseViewGoalModal--activity-container">
          <CollapsibleSection
            key="BaseViewGoalModal--activity"
            className="BaseViewGoalModal--activity"
            title={
              <strong>
                <FormattedMessage {...strings.general.auditActivity} />
              </strong>
            }
            numberOfItems={_.get(chunkedAudits, "length")}
          >
            <div className="BaseViewGoalModal--audit-wrapper">
              {chunkedAudits &&
                chunkedAudits.map(
                  ({
                    id,
                    user_id: userId,
                    created_at: createdAt,
                    audited_changes: auditedChanges
                  }) => {
                    const changes = _(auditedChanges)
                      .map((value, key) => this.renderAuditChange(value, key))
                      .compact()
                      .value()

                    const user = modifyingUsersById[userId]

                    if (!changes.length || !user) {
                      return null
                    } else {
                      return this.renderAuditItem({
                        key: id,
                        user,
                        date: createdAt,
                        content: [
                          <div
                            key="user-line"
                            className="BaseViewGoalModal--audit-user-line"
                          >
                            <strong>{user.full_name}</strong>
                          </div>,
                          changes
                        ]
                      })
                    }
                  }
                )}
              {// "<user> created this goal on <date>" item
              this.renderAuditItem({
                user: goal.user,
                date: goal.created_at,
                content: (
                  <div className="BaseViewGoalModal--audit-change">
                    <FormattedMessage
                      {...strings.goals.audit.userCreatedOn}
                      values={{
                        name: <strong>{goal.user.full_name}</strong>,
                        date: (
                          <strong>
                            <ZugataFormattedDate value={goal.created_at} />
                          </strong>
                        )
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </CollapsibleSection>
        </div>
      </ErrorBoundary>
    )
  }

  renderAuditItem({ user, date, content, key = null }) {
    return (
      <div
        key={key}
        className="BaseViewGoalModal--audit-item layout horizontal"
      >
        <ProfilePic
          className="BaseViewGoalModal--audit-avatar flex none"
          user={user}
          size="thumb"
        />

        <div className="flex">
          <div className="BaseViewGoalModal--audit-dateline">
            <ZugataFormattedRelativeDate value={date} />
          </div>
          {content}
        </div>
      </div>
    )
  }

  renderAuditChange(auditVal, auditKey) {
    const getAuditField = key =>
      key === "description"
        ? // "description" cannot be used as a string resource
          strings.goals.audit.fields["_description"]
        : strings.goals.audit.fields[key]

    // The parameter auditVal is an array representing the pre and post change values
    const field = getAuditField(auditKey)

    if (!field) {
      return null
    }

    let mappedValues = []
    if (field.values) {
      // The 'values' field is present on the string resource, map to the values using it
      mappedValues = auditVal.map((key, index) => {
        const valueMapped = field.values[key]
        if (!valueMapped) {
          return null
        }

        return <FormattedMessage key={index} {...valueMapped} />
      })

      if (!mappedValues[0] || !mappedValues[1]) {
        return null
      }
    } else {
      // No mapping is required, the key is already the value that needs to be displayed
      if (_.isArray(auditVal)) {
        mappedValues = auditVal.map(key => {
          if (auditKey === "completion") {
            return <FormattedNumber value={key} style="percent" />
          } else if (auditKey === "due_at") {
            return <ZugataFormattedDate value={key} />
          } else {
            return key
          }
        })
      }
    }

    return (
      <div className="BaseViewGoalModal--audit-change" key={auditKey}>
        <FormattedMessage
          {...field.template}
          values={{
            field: (
              <strong>
                <FormattedMessage {...field.name} />
              </strong>
            ),
            pre: mappedValues[0],
            post: mappedValues[1]
          }}
        />
      </div>
    )
  }

  renderConversations() {
    const { comments, goal } = this.props
    const lastSavedReplyId =
      comments && comments.length
        ? _.get(comments[comments.length - 1], "id")
        : undefined

    const { user } = this.context
    const isManagerVisibility = goal?.visibility === "manager"
    const currentUserIsManager = goal?.user?.manager_id === user.id
    const currentUserIsSelf = goal?.user?.id === user.id

    const canComment = !(
      isManagerVisibility &&
      !currentUserIsManager &&
      !currentUserIsSelf
    )

    return (
      comments &&
      canComment && (
        <div className="BaseViewGoalModal--conversations-wrapper">
          <GoalConversationSection
            sourceObj={goal}
            lastSavedReplyId={lastSavedReplyId}
            comments={comments}
          />
        </div>
      )
    )
  }

  renderIncomingAlignedGoals() {
    const { goal } = this.props

    const buildAlignmentSection = type => ({
      incomingAlignmentType: type,
      incomingGoals: goal.incoming_aligned_goals?.filter(
        goal => goal.goal_type === type
      )
    })

    const alignmentSections = {
      my_goal: [], // Individual Goals don't have any incoming goal sections
      department_goal: [buildAlignmentSection("my_goal")],
      company_goal: [
        buildAlignmentSection("department_goal"),
        buildAlignmentSection("my_goal")
      ]
    }

    return alignmentSections[goal.goal_type]
      .filter(({ incomingGoals }) => incomingGoals?.length > 0)
      .map(({ incomingGoals, incomingAlignmentType }) => (
        <div key={`BaseViewGoalModal--incoming-${incomingAlignmentType}`}>
          <CollapsibleSection
            className="BaseViewGoalModal--incoming-goals"
            title={
              <strong>
                <FormattedMessage
                  {...strings.goals.alignments.incoming[incomingAlignmentType]}
                />
              </strong>
            }
            numberOfItems={incomingGoals.length}
          >
            <GoalsTable
              className="BaseViewGoalModal--incoming-goals-table"
              searchResults={incomingGoals}
              onSelectGoal={this.handleClickAlignment}
              tableOnly={true}
              hideHeaderRow={true}
            />
          </CollapsibleSection>
        </div>
      ))
  }

  renderNestedGoalModal() {
    const { goal, NestedGoalModalComponent } = this.props
    const { alignedGoalIdToShow } = this.state

    const goalToShow =
      alignedGoalIdToShow && goal.incoming_aligned_goals
        ? [...goal.outgoing_aligned_goals, ...goal.incoming_aligned_goals].find(
            ({ id }) => id === alignedGoalIdToShow
          )
        : goal.outgoing_aligned_goals.find(
            ({ id }) => id === alignedGoalIdToShow
          )

    if (!goalToShow) {
      // Because this is a recursive render, we can only render this modal if it's shown
      return null
    }

    return (
      <NestedGoalModalComponent
        {...this.props}
        goal={goalToShow}
        allowDeletion={false}
        onClose={this.handleCloseNestedGoalModal}
      />
    )
  }

  render() {
    const { inEditingMode } = this.state
    const {
      goal,
      allowDeletion,
      onDirtinessChange,
      onClose,
      createAction,
      updateAction,
      deleteAction,
      readOnly
    } = this.props
    const viewer = this.context.user
    const editable = goal && !readOnly && Goal.isEditableByUser(goal, viewer)

    if (!goal) {
      return <Loader />
    }

    const menuItems = _.compact([
      editable &&
        !inEditingMode && {
          icon: editIcon,
          text: strings.general.edit,
          onClick: () =>
            this.setState(state => ({
              inEditingMode: !state.inEditingMode
            })),
          priorityAction: true
        },
      editable &&
        goal.id &&
        allowDeletion &&
        !inEditingMode && {
          icon: deleteIcon,
          text: strings.general.delete,
          onClick: () => this.setState({ isDeleting: true }),
          priorityAction: true,
          destructive: true
        }
    ])

    return (
      <>
        <div className="BaseViewGoalModal">
          <BreadcrumbAndMenuHeader
            headerText={
              <FormattedMessage
                {...strings.goals[Goal.getTitleString(goal, viewer)]}
                values={{ name: Goal.getTitleValue(goal) }}
              />
            }
            subheaderText={
              <FormattedMessage
                {...strings.general.due}
                values={{ time: <ZugataFormattedDate value={goal.due_at} /> }}
              />
            }
            hasMenu={menuItems.length > 0}
            leftAligned={true}
          >
            <ActionItems menuItems={menuItems} />
          </BreadcrumbAndMenuHeader>

          {!inEditingMode ? (
            this.renderGoalContent()
          ) : (
            <CreateOrEditGoalFields
              goal={goal}
              sourceName={this.getSourceName()}
              allowDeletion={allowDeletion}
              onSave={this.handleDoneEditing}
              onCancel={this.handleDoneEditing}
              onDelete={onClose}
              onDirtinessChange={onDirtinessChange}
              createAction={createAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
            />
          )}
          <div className="BaseViewGoalModal--list-container">
            {this.renderConversations()}

            {this.renderIncomingAlignedGoals()}

            {this.renderActivityContent()}

            {this.renderNestedGoalModal()}
          </div>
        </div>

        <ConfirmationModal
          opened={this.state.isDeleting}
          headerContent={
            <FormattedMessage
              {...strings.goals.deleteGoal.confirmationHeader}
            />
          }
          messageContent={
            <p>
              <FormattedMessage
                {...strings.goals.deleteGoal.confirmationMessage}
              />
            </p>
          }
          noButtonText={
            <FormattedMessage {...strings.general.cancelConfirmation} />
          }
          yesButtonText={
            <FormattedMessage {...strings.general.confirmDelete} />
          }
          onClose={() => this.setState({ isDeleting: false })}
          onConfirmClick={this.handleConfirmDelete}
        />
      </>
    )
  }
}
