import Reflux from "reflux-core"
import _ from "lodash"
import { addLocaleData } from "react-intl"
import CurrentUserStore from "./CurrentUserStore"
import LocaleActions from "../actions/LocaleActions"
import LocalStorage from "./lib/LocalStorage"

// Remember the user's previous locale
const db = new LocalStorage({ key: "LocaleData" })

/*
 * This store contains the canonical locale setting (which is saved to LocalStorage),
 * And loaded module data/messages (which are not)
 */
export const createLocaleStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        // Load last-used locale, default to english
        locale: _.get(db.loadData(), "locale") || "en",
        messages: null, // Message descriptors used by react-intl
        flatpickr: null // Flatpickr l10n module
      }

      this.listenTo(CurrentUserStore, this.onCurrentUserChanged)
      this.listenTo(
        LocaleActions.loadLocaleData.completed,
        this.onLoadedLocaleData
      )
    },

    getInitialState() {
      return this.data
    },

    onCurrentUserChanged(currentUser) {
      if (currentUser) {
        this.onLocaleChanged(currentUser.locale || "en")
      }
    },

    onLocaleChanged(locale) {
      this.data = {
        ...this.data,
        locale
      }
      // Update last-used locale
      db.updateData({ locale })

      this.trigger(this.data)
    },

    onLoadedLocaleData({ messages, reactIntl, flatpickr }) {
      if (reactIntl) {
        addLocaleData(reactIntl)
      }

      this.data = {
        ...this.data,
        messages,
        flatpickr
      }
      this.trigger(this.data)
    }
  })

export default createLocaleStore()
