import React from "react"
import Icon from "../Icon/Icon"
import "./InputClearButton.less"

type Props = {
  icon?: string
  className: string
  automationId?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default class InputClearButton extends React.Component<Props> {
  static defaultProps = {
    className: ""
  }

  render() {
    const { className, automationId, ...rest } = this.props

    return (
      <button
        {...rest}
        type="button"
        tabIndex={-1}
        className={`InputClearButton btn-flat ${className}`}
        data-automation-id={automationId}
      >
        <div className="layout vertical center-center">
          <Icon iconName="close" />
        </div>
      </button>
    )
  }
}
