import Reflux from "reflux-core"
import * as AdminActions from "../actions"
import BaseIncompleteSurveyResponseStore from "../../stores/BaseIncompleteSurveyResponseStore"

const AdminIncompleteSurveyResponseStore = Reflux.createStore({
  ...BaseIncompleteSurveyResponseStore,

  init() {
    BaseIncompleteSurveyResponseStore.init.call(this, {
      actions: AdminActions.IncompleteSurveyResponse
    })
  }
})

export default AdminIncompleteSurveyResponseStore
