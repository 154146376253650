import * as React from "react"
import { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { InjectedRouter } from "react-router"
import { Text } from "@kaizen/component-library"
import useSurveyResponses from "../../../hooks/useSurveyResponses"
import useSurveyResponseDetail from "../../../hooks/useSurveyResponseDetail"
import useCompleteSurveyResponses from "../../../hooks/useCompleteSurveyResponses"
import useDismissSurveyResponse from "../../../hooks/useDismissSurveyResponses"
import useDebounce from "../../../hooks/useDebounce"
import useNotifications from "../../../hooks/useNotifications"
import { encodeSurveyItem } from "../../../state/SurveyState"
import useUpdateSurveyItems from "../../../hooks/useUpdateSurveyItems"
import strings from "../../../locale/strings"
import Loading from "../../elements/Loading/Loading"
import UserSelector from "../../elements/UserSelector/UserSelector"
import styles from "./IncompleteTBFPage.scss"
import Avatar from "../../Avatar/Avatar"
import DismissRequestFeedbackButton from "../../widgets/DismissRequestFeedbackButton/DismissRequestFeedbackButton"
//@ts-ignore
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"
//@ts-ignore
import ProfilePanelWrapper from "../../widgets/ProfilePanelWrapper/ProfilePanelWrapper"
//@ts-ignore
import SurveyForm from "../../widgets/SurveyForm/SurveyForm"

export const DISMISS_REQUEST_OPTIONS = [
  {
    text: strings.review.skipPerson.noFeedbackNow,
    value: "no_feedback"
  },
  {
    text: strings.review.skipPerson.notWorkWith,
    value: "have_not_worked_with_recently"
  }
]

/**
 * This page is available when completing Team based feedback.
 * Could be opened from in-app notifications or email.
 *
 * TODO: Load survey from invitationToken logic.
 *
 * TODO: Update kind of survey responses fetched to "TBF"
 *
 * @param {IncompleteTBFPageProps}
 *
 * @returns {IncompleteTBFPage} Page component
 */

interface IncompleteTBFPageProps {
  router: Pick<InjectedRouter, "push">
}

const IncompleteTBFPage = ({ router }: IncompleteTBFPageProps) => {
  const { showNotification } = useNotifications()
  const { surveyResponses: tbfSurveyResponses, loading } = useSurveyResponses({
    kind: "TBF",
    mine: true,
    completed: false
  })

  const [activeReviewId, setActiveReviewId] = useState<number | undefined>(
    undefined
  )

  const { surveyResponse: tbfSurvey } = useSurveyResponseDetail(activeReviewId)

  const [updatedAnswerObject, setUpdatedAnswerObject] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any | undefined
  >(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeToDebounce = (answerObject: any) => {
    // Debounce false is when SurveyForm is calling onFocus, so we don't need to debounce.
    // We only debounce open-ended questions
    return !answerObject
      ? 0
      : answerObject.debounce
      ? answerObject.answer.question_type === "open-ended"
        ? 300
        : 0
      : 0
  }

  const debouncedUpdatedAnswerObject = useDebounce(
    updatedAnswerObject,
    timeToDebounce(updatedAnswerObject)
  )

  const { updateSurveyItem } = useUpdateSurveyItems(
    tbfSurvey && tbfSurvey.id,
    debouncedUpdatedAnswerObject &&
      debouncedUpdatedAnswerObject.answer &&
      debouncedUpdatedAnswerObject.answer.id
  )

  // Updates the survey item remotely calling the API
  useEffect(() => {
    if (debouncedUpdatedAnswerObject) {
      const isMultipleChoiceQuestion =
        debouncedUpdatedAnswerObject.answer.question_type ===
          "multiple-choice" ||
        debouncedUpdatedAnswerObject.answer.question_type === "rating"
      const answerObject = isMultipleChoiceQuestion
        ? { choice: debouncedUpdatedAnswerObject.content }
        : { answer: debouncedUpdatedAnswerObject.content }

      const surveyItem = encodeSurveyItem(debouncedUpdatedAnswerObject.answer)
      const surveyItemUpdated = { ...surveyItem, ...answerObject }

      updateSurveyItem(surveyItemUpdated, answerObject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdatedAnswerObject])

  useEffect(() => {
    // If there is no activeReviewId return the first one from the list
    // If thre is an activeReviewId but is not on the list, return the first one from list
    if (
      tbfSurveyResponses.length > 0 &&
      (!activeReviewId ||
        !tbfSurveyResponses.find(tbf => tbf.id === activeReviewId))
    ) {
      setActiveReviewId(tbfSurveyResponses[0].id)
    } else if (tbfSurveyResponses.length === 0 && activeReviewId) {
      router.push("/notifications")
    }
  }, [activeReviewId, router, tbfSurveyResponses])

  const [shareWithReviewee, setShareWithReviewee] = useState(false)

  const { dismissSurveyResponse } = useDismissSurveyResponse(
    tbfSurvey && tbfSurvey.id
  )

  const {
    data: completeAPIData,
    error: completeAPIError,
    completeSurveyResponse
  } = useCompleteSurveyResponses(tbfSurvey && tbfSurvey.id)

  useEffect(() => {
    if (completeAPIData) {
      setShareWithReviewee(false)
      showNotification({
        type: "affirmative",
        title: "Feedback submitted!",
        message: ""
      })
    }
  }, [completeAPIData, showNotification])

  useEffect(() => {
    if (completeAPIError) {
      showNotification({
        type: "negative",
        title: "Something went wrong submitting your review, please try again!",
        message: ""
      })
    }
  }, [completeAPIError, showNotification])

  return (
    <div className={styles.page}>
      {tbfSurvey && (
        <ProfilePanelWrapper user={tbfSurvey.subject}>
          <div className={styles.surveyContainer}>
            <div className={styles.userInfo}>
              <Avatar
                key={tbfSurvey.subject.id}
                imageURL={tbfSurvey.subject.profileImage}
                size="large"
              />
              <Text tag="h2" style="zen-heading-2">
                {tbfSurvey.subject.name}
              </Text>
            </div>
            <div className={styles.requestInfo}>
              <FormattedMessage
                {...strings.review["requestedOnByTeamLead"]}
                values={{
                  requester: tbfSurvey.creator && tbfSurvey.creator.name,
                  reviewee: tbfSurvey.subject.name,
                  formattedDate: (
                    <ZugataFormattedDate
                      value={tbfSurvey.createdAt}
                      month="short"
                    />
                  )
                }}
              />
            </div>
            <div className={styles.dismissButton}>
              <DismissRequestFeedbackButton
                skipOptions={DISMISS_REQUEST_OPTIONS}
                onSkipOptionSelect={(reason: string) => {
                  dismissSurveyResponse(reason)
                }}
              />
            </div>
            <div className={styles.form}>
              {tbfSurvey.surveyItems && (
                <SurveyForm
                  data-testid={"survey-form"}
                  surveyId={tbfSurvey.subject.id}
                  reviewee={{
                    best_name: tbfSurvey.subject.name
                  }}
                  allowShareWithReviewee={true}
                  answers={tbfSurvey.surveyItems.map(surveyItem => {
                    return {
                      question_title: surveyItem.questionTitle,
                      question_description: surveyItem.questionDescription,
                      question_type: surveyItem.questionType,
                      answer: surveyItem.answer,
                      required: surveyItem.required,
                      choices: surveyItem.choices,
                      choice: surveyItem.choice,
                      id: surveyItem.id,
                      idx: surveyItem.idx,
                      state: surveyItem.state,
                      saveState: surveyItem.saveState
                    }
                  })}
                  submitButtonText={
                    <FormattedMessage
                      {...strings.teamLeadFeedback.sendToXXXTeamLead}
                      values={{ name: tbfSurvey.subject.name }}
                    />
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onAnswerChange={(answerInfo: any) => {
                    setUpdatedAnswerObject(answerInfo)
                  }}
                  onShareWithRevieweeChange={() =>
                    setShareWithReviewee(!shareWithReviewee)
                  }
                  shareWithReviewee={shareWithReviewee}
                  onSubmit={() => completeSurveyResponse(shareWithReviewee)}
                />
              )}
              <div className={styles.visibilityFooter}>
                {!shareWithReviewee && (
                  <FormattedMessage
                    {...strings.peerReview.visibility.message}
                    values={{
                      not: (
                        <span className={styles.visibilityText}>
                          <FormattedMessage
                            {...strings.peerReview.visibility.not}
                          />
                        </span>
                      ),
                      name: tbfSurvey.subject.name
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </ProfilePanelWrapper>
      )}
      <div className={styles.userSelector}>
        {tbfSurvey && tbfSurveyResponses && (
          <UserSelector
            data-testid={"user-selector"}
            title={
              <FormattedMessage {...strings.peerReview.giveFeedbackAbout} />
            }
            users={tbfSurveyResponses.map(tbf => ({
              id: tbf.id.toString(),
              name: tbf.subject.name,
              avatar: tbf.subject.avatar
            }))}
            onSelectUser={tbfId => setActiveReviewId(Number(tbfId))}
            selectedUser={tbfSurvey && tbfSurvey.id.toString()}
            positionRelative={true}
          />
        )}
      </div>
      {loading && <Loading />}
    </div>
  )
}

export default IncompleteTBFPage
