import React, { createContext, useContext, useReducer } from "react"
import { User } from "../types/User"
import { DraftsProvider } from "./Drafts"

export interface Comment {
  id: number
  dateSent: Date
  body: string
  author: User
  replies?: Comment[]
}

interface State {
  commentsBySourceId: Record<number, Comment[]>
}

type Action =
  | {
      type: "FETCH_COMMENTS"
      payload: { sourceId: number; comments: Comment[] }
    }
  | {
      type: "ADD_NEW_COMMENT"
      payload: { sourceId: number; comment: Comment }
    }
  | {
      type: "ADD_NEW_REPLY"
      payload: { sourceId: number; reply: Comment; parentCommentId: number }
    }

export const commentsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FETCH_COMMENTS": {
      const { sourceId, comments } = action.payload
      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: comments
        }
      }
    }
    case "ADD_NEW_REPLY": {
      const { sourceId, reply, parentCommentId } = action.payload
      const comments = state.commentsBySourceId[sourceId]

      comments.map(comment => {
        if (comment.id === parentCommentId) {
          if (comment.replies) {
            comment.replies = [...comment.replies, reply]
          } else {
            comment.replies = [reply]
          }
        }
        return comment
      })

      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: comments
        }
      }
    }
    case "ADD_NEW_COMMENT": {
      const { sourceId, comment } = action.payload
      const comments = state.commentsBySourceId[sourceId]

      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: [...comments, comment]
        }
      }
    }
  }

  return state
}

export const CommentsContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: { commentsBySourceId: {} },
  dispatch: state => state
})

export const CommentsProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const context = useContext(CommentsContext)
  const [state, dispatch] = useReducer(commentsReducer, context.state)

  return (
    <CommentsContext.Provider value={{ state, dispatch }}>
      <DraftsProvider>{children}</DraftsProvider>
    </CommentsContext.Provider>
  )
}
