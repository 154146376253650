import React from "react"
import PropTypes from "prop-types"
import Tooltip from "rc-tooltip"
import { injectIntl } from "react-intl"
import cx from "classnames"
import _ from "lodash"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import ProgressBar from "../../elements/ProgressBar/ProgressBar"

import "./GoalProgressBar.less"

class GoalProgressBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    status: PropTypes.string,
    goalProgressBarClass: PropTypes.string
  }

  static defaultProps = {
    className: "",
    goalProgressBarClass: "GoalProgressBar"
  }

  render() {
    const {
      className,
      value,
      status,
      goalProgressBarClass,
      intl: { formatMessage }
    } = this.props

    return (
      <div className={cx(`${goalProgressBarClass}`, className)}>
        <div className="ProgressBar--container layout horizontal">
          <ProgressBar
            completionClassName={cx({
              [`${goalProgressBarClass}--${status}`]: status
            })}
            value={value}
            {...this.props}
          />
          <Tooltip
            placement="top"
            mouseEnterDelay={0.1}
            overlay={
              <div className={`${goalProgressBarClass}--tooltip`}>
                {formatMessage(strings.goals.status.blocked)}
              </div>
            }
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <div
              className={cx(`${goalProgressBarClass}--blocked-icon`, {
                [`${goalProgressBarClass}--blocked-icon-inverted`]: value === 1
              })}
            >
              {status === "blocked" && (
                <Icon iconName="exclamation" style={{ width: 20 }} />
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default _.compose(injectIntl)(GoalProgressBar)

export { GoalProgressBar as RawGoalProgressBar }
