import { useContext, useEffect } from "react"
import { getGoalDetailsResponseDecoder } from "../decoders/GetGoalDetailsResponseDecoder/getGoalDetailsResponseDecoder"
import { encodeGoalToUpdate } from "../encoders/goals"
import { GoalsContext } from "../state/GoalsState"
import { UpdatingGoal } from "../types/Goals"
import useDataAPI from "./useDataAPI"
import useTeamSummaries from "./useTeamSummaries"

const useUpdateGoal = (goalId?: number) => {
  const { dispatch } = useContext(GoalsContext)
  const updateAPI = useDataAPI(`/goals/${goalId}`, "PUT")
  const { refetch: fetchTeamSummaries } = useTeamSummaries()

  const updateGoal = (goal: UpdatingGoal) => {
    if (goalId) {
      const goalData = encodeGoalToUpdate(goal)
      updateAPI.fire(goalData)
    }
  }

  useEffect(() => {
    if (updateAPI.data) {
      getGoalDetailsResponseDecoder
        .decodePromise(updateAPI.data)
        .then(goal => {
          dispatch({ type: "FETCH_GOAL_DETAILS", payload: goal })

          if (goal.type === "team") {
            fetchTeamSummaries()
          }
        })
        .catch(error => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAPI.data])

  return {
    updateGoal,
    error: updateAPI.error,
    success: !updateAPI.error && !!updateAPI.data,
    loading: updateAPI.loading
  }
}

export default useUpdateGoal
