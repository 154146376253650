import "./LockedFeedbackWrapper.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import strings from "../../../locale/strings"
import Loader from "../Loader/Loader"
import User from "../../../models/User"
import { debounce } from "../../../decorators/timers"
import Button from "../../elements/Button/Button"

const ANIMATION_DURATION = 700

export default class LockedFeedbackWrapper extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    review: PropTypes.object.isRequired,
    onOpen: PropTypes.func,
    feedbackContent: PropTypes.object,
    buyFailed: PropTypes.bool,
    userIsReviewee: PropTypes.bool,
    className: PropTypes.string
  }

  static defaultProps = {
    className: "",
    userIsReviewee: true
  }

  constructor(props) {
    super(props)
    this.state = {
      animating: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.animating && this.state.animating) {
      this.revealContent()
    }
  }

  @debounce(ANIMATION_DURATION)
  revealContent() {
    this.setState({ animating: false })
  }

  handleUnsealClick = () => {
    this.setState({ animating: true })
    this.props.onOpen()
  }

  renderBuyFailedContent() {
    return [
      <h4 key="header">
        <FormattedMessage {...strings.lockedFeedbackWrapper.header} />
      </h4>,
      <p key="message">
        <FormattedMessage {...strings.lockedFeedbackWrapper.message} />
      </p>,
      <Button
        key="button"
        actionType="primary"
        to="review"
        query={{ pathOnFinished: "myfeedback" }}
      >
        <FormattedMessage {...strings.lockedFeedbackWrapper.buttonCTA} />
      </Button>
    ]
  }

  renderLocked() {
    const {
      review: {
        reviewer,
        named_reviewer: namedReviewer,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        completed_at: completedAt
      }
    } = this.props
    const { animating } = this.state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = this.context

    return (
      <div
        className="LockedFeedbackWrapper--panel-container"
        onClick={this.handleUnsealClick}
      >
        <div className="LockedFeedbackWrapper--panel-wrapper">
          <div
            className={cx("LockedFeedbackWrapper--left-panel", {
              "LockedFeedbackWrapper--left-panel-animation": animating
            })}
          />

          <div
            className={cx("LockedFeedbackWrapper--right-panel", {
              "LockedFeedbackWrapper--right-panel-animation": animating
            })}
          />
        </div>

        <div className="LockedFeedbackWrapper--panel-wrapper-sticker">
          <div
            className={cx("LockedFeedbackWrapper--sticker", {
              "LockedFeedbackWrapper--sticker-animation": animating
            })}
          >
            <div className="LockedFeedbackWrapper--sticker-text">
              <FormattedMessage {...strings.feedbackRequests.labels.fromWord} />
              {do {
                if (reviewer) {
                  ;<strong>
                    {namedReviewer ? (
                      User.getFirstName(reviewer)
                    ) : (
                      <FormattedMessage
                        {...strings.myFeedback.envelopeAnonymousName}
                      />
                    )}
                  </strong>
                } else {
                  ;<i className="LockedFeedbackWrapper--author">
                    <FormattedMessage {...strings.user.deleted} />
                  </i>
                }
              }}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { review, feedbackContent, className, userIsReviewee } = this.props
    const { animating } = this.state

    const contentReady = review.rating_summary && !animating
    const buyFailed =
      !review.paid_for && review.buyAttempted && !animating && userIsReviewee
    const shouldLock =
      ((!review.paid_for && !review.free) || animating) && userIsReviewee

    const lockedFeedbackClasses = cx(
      {
        LockedFeedbackWrapper: true,
        "LockedFeedbackWrapper--buy-failed-content layout vertical center-center": buyFailed,
        "LockedFeedbackWrapper--locked": shouldLock || !contentReady,
        "LockedFeedbackWrapper--finished-animating":
          buyFailed || !(shouldLock || contentReady)
      },
      className
    )

    return (
      <div className={lockedFeedbackClasses} id={`review-${review.id}`}>
        {buyFailed ? (
          this.renderBuyFailedContent()
        ) : shouldLock ? (
          this.renderLocked()
        ) : contentReady ? (
          feedbackContent
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}
