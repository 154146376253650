import "./AddQuestion.less"
import React from "react"
import cx from "classnames"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import Tooltip from "../../elements/Tooltip/Tooltip"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import Aid from "../../../constants/automationId"

export default class AddQuestion<ItemToAdd> extends React.Component<
  {
    onAddItem: (itemToAdd: ItemToAdd) => void
    getDefaultItem: () => ItemToAdd
    menuOptions: {
      icon?: React.ReactNode
      text: string
      getItem: () => ItemToAdd
    }[]
  },
  {
    showingMenu: boolean
  }
> {
  state = {
    showingMenu: false
  }

  setMenuVisibility = (showingMenu: boolean) => {
    this.setState({ showingMenu })
  }

  toggleMenuVisibility = () => {
    this.setState(({ showingMenu }) => ({ showingMenu: !showingMenu }))
  }

  handleOptionClick(itemToAdd: ItemToAdd) {
    this.setState({ showingMenu: false })
    this.props.onAddItem(itemToAdd)
  }

  handleDefaultClick = () => {
    const { onAddItem, getDefaultItem } = this.props
    onAddItem(getDefaultItem())
  }

  renderMenuOption = (
    {
      icon,
      text,
      getItem
    }: {
      icon: React.ReactNode
      text: string
      getItem: () => ItemToAdd
    },
    index: number
  ) => {
    return (
      <button
        type="button"
        key={`option-${index}`}
        className="AddQuestion--menu-option layout horizontal"
        onClick={() => this.handleOptionClick(getItem())}
      >
        {icon && <i className="AddQuestion--icon material-icons">{icon}</i>}
        <div>{text}</div>
      </button>
    )
  }

  render() {
    const { showingMenu } = this.state
    const { menuOptions } = this.props

    return (
      <div
        className="AddQuestion layout vertical center"
        data-automation-id={Aid.addQuestionButton}
        onMouseOver={
          menuOptions ? () => this.setMenuVisibility(true) : undefined
        }
        onMouseOut={
          menuOptions ? () => this.setMenuVisibility(false) : undefined
        }
        onClick={
          menuOptions ? this.toggleMenuVisibility : this.handleDefaultClick
        }
      >
        <div className="AddQuestion--add-label ">
          <Icon iconName="add" />
          <FormattedMessage
            {...strings.customizedQuestions.addQuestion.label}
          />
        </div>
        {menuOptions && (
          <Tooltip
            appearAbove={true}
            showPointerCutout={true}
            className={cx("AddQuestion--menu", {
              "AddQuestion--menu-visible layout vertical center": showingMenu
            })}
          >
            {_.flatten([
              /* This div provides a little extra mouseover area to cover the gap
               * between the actual tooltip and the "Add Question" button.
               */
              <div
                key="extraSpace"
                className="AddQuestion--extra-hover-area"
              />,

              // @ts-ignore: ignored because of time, please fix if you can
              menuOptions.map(this.renderMenuOption)
            ])}
          </Tooltip>
        )}
      </div>
    )
  }
}
