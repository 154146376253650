import React from "react"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import strings from "../../../locale/strings"
import Button from "../../elements/Button/Button"
import "./AddAnswerChoiceButton.less"
import { useIntl } from "../../../hooks/useIntl"

type Props = {
  onClick: (e: React.MouseEvent) => void
}

const AddAnswerChoiceButton = ({ onClick }: Props) => {
  const { formatMessage } = useIntl()
  return (
    <Button
      actionType="secondary"
      icon={addIcon}
      className="AddAnswerChoiceButton"
      // @ts-ignore: I don't understand this error
      onClick={onClick}
    >
      {/*
      // @ts-ignore: this should be a string, but it still works... */}
      <div className="layout horizontal center-center">
        {formatMessage(strings.customizedQuestions.addChoice)}
      </div>
    </Button>
  )
}

export default AddAnswerChoiceButton
