import { IconButton } from "@kaizen/component-library"
import closeIcon from "@kaizen/component-library/icons/close.icon.svg"
import * as React from "react"
import { hot } from "react-hot-loader"
import { withRouter, WithRouterProps } from "react-router"
import Loading from "../../elements/Loading/Loading"
import useGoalDetails from "../../../hooks/useGoalDetails"
import useGoalDossier from "../../../hooks/useGoalDossier"
import GoalDetails from "../GoalDetails/GoalDetails"
import styles from "./GoalDossier.scss"

interface GoalDossierProps extends WithRouterProps {
  goalId: string
}

const GoalDossier = ({ goalId, ...routerProps }: GoalDossierProps) => {
  const { goal } = useGoalDetails(Number(goalId))
  const { close: closeDossier } = useGoalDossier(
    routerProps.router,
    routerProps.location
  )

  return (
    <div className={styles.container}>
      <div className={styles.closeButton}>
        <IconButton label="" icon={closeIcon} onClick={closeDossier} />
      </div>
      {!goal ? <Loading /> : <GoalDetails goal={goal} {...routerProps} />}
    </div>
  )
}

export default hot(module)(withRouter(GoalDossier))
