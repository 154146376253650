/* eslint-disable import/default */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router"
import { FormattedMessage } from "react-intl"
import qs from "query-string"
import _ from "lodash"
import uniqueId from "lodash/utility/uniqueId"
import Tooltip from "rc-tooltip"
import cx from "classnames"
import messageDescriptorType from "../../prop-types/messageDescriptorType"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import Table from "../../elements/Table/Table"
import SortableCell from "../../elements/SortableCell/SortableCell"
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll"
import "./DataTable.less"

export default class DataTable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.number,
        className: PropTypes.string,
        title: PropTypes.oneOfType([messageDescriptorType, PropTypes.string]),
        tooltip: PropTypes.oneOfType([messageDescriptorType, PropTypes.string]),
        sortBy: PropTypes.string,
        renderCell: PropTypes.func.isRequired
      })
    ).isRequired,
    sortBy: PropTypes.string,
    sortId: PropTypes.number,
    sortOrder: PropTypes.oneOf(["asc", "desc"]),
    sortable: PropTypes.bool,
    hasMore: PropTypes.bool,
    responsive: PropTypes.bool,
    getLinkLocationForItem: PropTypes.func,
    isItemSelectable: PropTypes.func,
    onSort: PropTypes.func,
    onSelectItem: PropTypes.func,
    onNextPageNeeded: PropTypes.func,
    highlightedItemId: PropTypes.number
  }

  uniqueClass = uniqueId("DataTable-")

  static defaultProps = {
    className: "",
    sortable: true,
    hasMore: false,
    responsive: false,
    getLinkLocationForItem: _item => null,
    isItemSelectable: _item => true,
    highlightedItemId: null
  }

  componentDidMount() {
    this.updateFixedColumnStyles()
  }

  componentDidUpdate() {
    this.updateFixedColumnStyles()
  }

  updateFixedColumnStyles() {
    const { columns } = this.props
    const { styleNode, headerRowNode } = this

    if (!(styleNode && headerRowNode)) {
      return
    }

    const numFixedColumns = _.takeWhile(columns, "fixed").length

    const [css] = _.range(1, numFixedColumns).reduce(
      ([prevCss, prevLeft], colIndex) => {
        const childNum = colIndex + 1 // CSS is 1-based
        const precedingCell = headerRowNode.children[colIndex - 1]
        const left = prevLeft + precedingCell.offsetWidth

        const css =
          prevCss +
          `.${this.uniqueClass} .Table--cell:nth-child(${childNum}) { left: ${left}px; }\n`

        return [css, left]
      },
      ["", 0]
    )

    styleNode.innerText = css
  }

  handleSortColumn({ sortBy, id }) {
    const {
      sortBy: prevSortBy,
      sortId: prevSortId,
      sortOrder: prevSortOrder,
      onSort
    } = this.props

    const sameColumn = prevSortBy === sortBy && prevSortId === id
    const sortOrder = sameColumn && prevSortOrder === "asc" ? "desc" : "asc"

    onSort({ sortBy, sortId: id, sortOrder })
  }

  renderHeaderRow() {
    const { sortBy, sortId, sortOrder, columns, sortable } = this.props
    return (
      <div
        className="DataTable--header-row"
        key="headerRow"
        ref={ref => (this.headerRowNode = ref)}
      >
        {columns.map(column => {
          if (column.headerCell) {
            return React.cloneElement(column.headerCell, {
              key: `${column.type}-${column.id || ""}`,
              className: cx(
                column.headerCell.props.className,
                column.className,
                {
                  "DataTable--fixed-column": column.fixed
                }
              )
            })
          }
          const sorted = sortBy === column.sortBy && sortId === column.id
          const renderText = text => {
            if (typeof text === "object") {
              return <FormattedMessage {...text} />
            } else {
              // REVIEW: assumes strings passed as title/tooltip are markdown
              return <MarkdownText text={text} inline={true} />
            }
          }
          return (
            <SortableCell
              key={`${column.type}-${column.id || ""}`}
              className={cx(column.className, {
                "DataTable--fixed-column": column.fixed
              })}
              disabled={!(sortable && column.sortBy)}
              sortState={sorted ? sortOrder : null}
              onSort={() => this.handleSortColumn(column)}
            >
              {do {
                if (column.tooltip) {
                  ;<Tooltip
                    placement="bottom"
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                    overlay={
                      <div className="DataTable--header-tooltip">
                        {renderText(column.tooltip)}
                      </div>
                    }
                  >
                    <div className="DataTable--header-text truncate">
                      {renderText(column.title)}
                    </div>
                  </Tooltip>
                } else {
                  renderText(column.title)
                }
              }}
            </SortableCell>
          )
        })}

        <div>
          {/* Additional empty header needed for the cta arrow column */}
        </div>
      </div>
    )
  }

  renderRow(item) {
    const {
      columns,
      getLinkLocationForItem,
      isItemSelectable,
      onSelectItem,
      highlightedItemId
    } = this.props
    const linkLocation = getLinkLocationForItem(item)
    const selectable = isItemSelectable(item)

    const rowContent = columns.map(column => {
      const cellElem = column.renderCell(item, column, selectable)
      return React.cloneElement(cellElem, {
        key: `${column.type}-${column.id || ""}`,
        className: cx(column.className, cellElem.props.className, {
          "DataTable--fixed-column": column.fixed
        })
      })
    })

    const className =
      highlightedItemId && item.id === highlightedItemId
        ? "DataTable--active-row"
        : ""

    if (linkLocation && selectable) {
      return (
        <Link
          className={className}
          key={item.id}
          to={{
            pathname: linkLocation.pathname,
            // eslint-disable-next-line import/no-named-as-default-member
            search: `?${qs.stringify(linkLocation.query)}`
          }}
        >
          {rowContent}
        </Link>
      )
    } else {
      return (
        <div
          key={item.id}
          onClick={selectable ? onSelectItem.bind(null, item) : null}
          className={className}
        >
          {rowContent}
        </div>
      )
    }
  }

  render() {
    const {
      className,
      items,
      hasMore,
      responsive,
      columns,
      onNextPageNeeded
    } = this.props

    const rootClass = cx("DataTable", this.uniqueClass, className)

    const styleElem = columns.some(c => c.fixed) ? (
      <style ref={ref => (this.styleNode = ref)} />
    ) : null

    const table = (
      <Table
        className="DataTable--table"
        responsive={responsive}
        headerRows={[this.renderHeaderRow()]}
        rows={items.map(this.renderRow, this)}
      />
    )

    if (onNextPageNeeded) {
      return (
        <InfiniteScroll
          className={rootClass}
          items={items}
          hasMore={hasMore}
          onNextPageNeeded={onNextPageNeeded}
        >
          {styleElem}
          {table}
        </InfiniteScroll>
      )
    } else {
      return (
        <div className={rootClass}>
          {styleElem}
          {table}
        </div>
      )
    }
  }
}
