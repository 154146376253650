import Reflux from "reflux-core"
import observeSearchActions from "../../stores/lib/observeSearchActions"
import * as AdminActions from "../actions"

export const createAdminManagerFeedbackStatsStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        feedbackStats: null,
        invitationStats: null,
        individualStats: null,
        pagination: null,
        departmentId: null,
        dateRangeId: null,
        managerId: null,
        managerChartStats: null,
        invitesById: {},
        invitesByToken: {},
        invitesByUserId: {}
      }

      this.listenTo(
        AdminActions.ManagerFeedbackStats.getManagerFeedbackStats.completed,
        ({ feedbackStats, departmentId, dateRangeId }) => {
          this.data = { ...this.data, feedbackStats, departmentId, dateRangeId }
          this.trigger(this.data)
        }
      )

      observeSearchActions(this, {
        getResults: () => this.data.invitationStats,
        searchAction: AdminActions.ManagerFeedbackStats.getInvitationStats,
        pageAction: AdminActions.ManagerFeedbackStats.pageInvitationStats,
        onChange: (
          invitationStats,
          { pagination, departmentId, dateRangeId }
        ) => {
          this.data = {
            ...this.data,
            invitationStats,
            pagination,
            departmentId,
            dateRangeId
          }
          this.trigger(this.data)
        }
      })

      observeSearchActions(this, {
        getResults: () => this.data.individualStats,
        searchAction: AdminActions.ManagerFeedbackStats.getIndividualStats,
        pageAction: AdminActions.ManagerFeedbackStats.pageIndividualStats,
        onChange: (
          individualStats,
          { stats, pagination, managerId, dateRangeId }
        ) => {
          this.data = {
            ...this.data,
            individualStats,
            pagination: pagination,
            managerId: managerId,
            dateRangeId: dateRangeId,
            managerChartStats: stats
          }
          this.trigger(this.data)
        }
      })

      this.listenTo(
        AdminActions.ManagerFeedbackStats.getInvitation.completed,
        invite => {
          this.data = {
            ...this.data,
            invitesById: {
              ...this.data.invitesById,
              [invite.id]: invite
            },
            invitesByToken: {
              ...this.data.invitesByToken,
              [invite.token]: invite
            }
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminActions.ManagerFeedbackStats.getInvitationsForUser.completed,
        ({ userId, invites }) => {
          this.data = {
            ...this.data,
            invitesByUserId: {
              ...this.data.invitesByUserId,
              [userId]: invites
            }
          }
          this.trigger(this.data)
        }
      )
    },

    getInitialState() {
      return this.data
    }
  })

export default createAdminManagerFeedbackStatsStore()
