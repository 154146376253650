import Reflux from "../../actions/ActionsInitializer"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import strings from "../../locale/strings"
import endpoints from "../../api/endpoints.json"
import { loadingTypes } from "../../actions/UIActions"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"

const {
  ADMIN_USERS_URLS: {
    ADMIN_USER_IMPORT_CSV,
    ADMIN_USERS_EXPORT,
    ADMIN_USER_IMPORT_RESULTS_EXPORT_URL
  }
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const AdminUserActions = Reflux.createActions({
  importUserCsv: { asyncResult: true },
  usersExport: { asyncResult: true },
  cancelImportUserCsv: { asyncResult: true },
  continueImportUserCsv: { asyncResult: true },
  downloadImportErrorsAndWarnings: { asyncResult: true },
  listImportRequests: { asyncResult: true }
})

AdminUserActions.usersExport.listenAndPromise(() =>
  addLoadingState(
    true,
    addToasts(
      {
        defaultError: strings.adminDirectory.uploadUsersPage.exportFailed,
        success: strings.adminDirectory.uploadUsersPage.exportDataSuccess
      },
      agent.post(ADMIN_USERS_EXPORT)
    )
  )
)

AdminUserActions.importUserCsv.listenAndPromise(({ csv }) =>
  addLoadingState(
    [true, { type: loadingTypes.USER_IMPORT }],
    addToasts(
      { defaultError: strings.cycle.userUpload.defaultUploadError },
      extractResponseKey(
        "user_import_request",
        agent.post(ADMIN_USER_IMPORT_CSV).attach({
          name: "csv_file",
          file: csv
        })
      )
    )
  )
)

const cancelOrContinueImport = ({ id, ignoreWarnings, stringType }) =>
  addLoadingState(
    [true, { type: loadingTypes.USER_IMPORT }],
    addToasts(
      {
        success: strings.adminDirectory.uploadUsersPage[stringType].success,
        defaultError: strings.adminDirectory.uploadUsersPage[stringType].error
      },
      extractResponseKey(
        "user_import_request",
        agent.put(`${ADMIN_USER_IMPORT_CSV}/${id}`).send({
          user_import_request: {
            ignore_warnings: ignoreWarnings
          }
        })
      )
    )
  )

AdminUserActions.cancelImportUserCsv.listenAndPromise(({ id }) =>
  cancelOrContinueImport({ id, ignoreWarnings: false, stringType: "cancel" })
)

AdminUserActions.continueImportUserCsv.listenAndPromise(({ id }) =>
  cancelOrContinueImport({ id, ignoreWarnings: true, stringType: "continue" })
)

AdminUserActions.downloadImportErrorsAndWarnings.listenAndPromise(
  importRequestId => {
    const toasts =
      strings.adminDirectory.uploadUsersPage.downloadErrorsAndWarnings
    return addLoadingState(
      [true, { light: true }],
      addToasts(
        { success: toasts.success, defaultError: toasts.error },
        agent.post(ADMIN_USER_IMPORT_RESULTS_EXPORT_URL).send({
          user_import_results_export_request: {
            user_import_request_id: importRequestId
          }
        })
      )
    )
  }
)

AdminUserActions.listImportRequests.listenAndPromise(
  ({ loadingState = true, page = 1 }) =>
    addLoadingState(
      loadingState,
      extractResponseBody(
        agent.get(ADMIN_USER_IMPORT_CSV).query({
          page,
          per_page: 20
        })
      )
    ).then(body => ({
      userUploads: body.user_import_requests,
      meta: body.meta
    }))
)

export default AdminUserActions
