import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions"
import strings from "../locale/strings"

const pointsExplanationByEventType = {
  first_time_import: "for importing your contacts"
}

const ZugiPointsStore = Reflux.createStore({
  init() {
    this.data = {
      zugiPoints: null,
      recentZugiPointsActivities: [],
      disablePoints: false
    }

    this.listenTo(Actions.User.loadCurrent.completed, this.onUserChanged)
    this.listenTo(Actions.User.reloadCurrent.completed, this.onUserChanged)
    this.listenTo(Actions.User.update.completed, this.onUserChanged)

    this.listenTo(
      Actions.ZugiPoints.updatePointsInfo,
      ({ totalPoints, recentZugiPointsActivities }) => {
        if (this.data.zugiPoints === totalPoints) {
          return
        }

        this.notifyUserAboutPointChange({
          oldPointTotal: this.data.zugiPoints,
          newPointTotal: totalPoints,
          recentZugiPointsActivities,
          disablePoints: this.data.disablePoints
        })

        this.data = {
          ...this.data,
          zugiPoints: totalPoints,
          recentZugiPointsActivities
        }
        this.trigger(this.data)
      }
    )
  },

  getInitialState() {
    return this.data
  },

  onUserChanged(user) {
    this.data = {
      ...this.data,
      zugiPoints: user.zugi_points,
      disablePoints: user.company.disable_points
    }
    this.trigger(this.data)
  },

  notifyUserAboutPointChange({
    oldPointTotal = null,
    newPointTotal,
    recentZugiPointsActivities,
    disablePoints
  }) {
    if (disablePoints) return false
    if (oldPointTotal === null || newPointTotal <= oldPointTotal) {
      return
    }

    const lastPointGainingActivity = _.find(
      recentZugiPointsActivities,
      ({ points }) => points > 0
    )

    if (!lastPointGainingActivity) {
      return
    }

    const explanation =
      pointsExplanationByEventType[lastPointGainingActivity.event_type]

    if (!explanation) {
      return
    }

    Actions.UI.success(strings.general.leaderboard.zugiPoints.youveJustEarned, {
      points: lastPointGainingActivity.points,
      explanation
    })
  }
})

export default ZugiPointsStore
