import PropTypes from "prop-types"
import React from "react"

export default class ConversationModalWrapper extends React.Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired
  }

  render() {
    const { title, children } = this.props

    return (
      <div className="ConversationModalWrapper layout vertical flex">
        <h2 className="ConversationModalWrapper--header center-align">
          {title}
        </h2>
        {children}
      </div>
    )
  }
}
