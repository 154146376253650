import useDataAPI from "./useDataAPI"
import { TeamGoalFields } from "../components/widgets/TeamGoalForm/TeamGoalForm"
import { User } from "../types/User"

const useCreateTeamGoal = () => {
  const fetchAPI = useDataAPI("/goals", "POST")
  const createTeamGoal = (fields: TeamGoalFields) => {
    const goaldata = {
      type: "team_goal",
      team_id: fields.teamId,
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map((owner: User) => owner.aggregateId),
      key_results: fields.keyResults.map(({ title }) => ({
        title,
        completion: 0
      })),
      outgoing_alignment_associations_attributes: fields.alignedGoalIds.map(
        (id: number) => ({
          incoming_alignment_goal_id: id
        })
      )
    }
    fetchAPI.fire({ goal: goaldata })
  }
  return {
    createTeamGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading
  }
}

export default useCreateTeamGoal
