import { Icon, Text } from "@kaizen/component-library"
import chevronRightIcon from "@kaizen/component-library/icons/chevron-right.icon.svg"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { OutgoingAlignedGoal } from "../../../types/Goals"
import strings from "../../../locale/strings"
import styles from "./AlignedGoalsSection.scss"

type AlignedGoalsSection = {
  alignedGoals: OutgoingAlignedGoal[]
  goalType: "team" | "department" | "company"
  onGoalClick: (goalId: number) => void
  intl: InjectedIntl
}

const AlignedGoalsSection = ({
  alignedGoals,
  goalType,
  onGoalClick,
  intl
}: AlignedGoalsSection) => {
  const { formatMessage } = intl

  return (
    <div className={styles.outgoingGoalAlignments}>
      <Text tag="p" style="small-bold" inheritBaseline>
        {formatMessage(strings.goalsPage.goalsDossier.goalAlignments[goalType])}
      </Text>
      {alignedGoals.map(goal => (
        <div
          className={styles.alignedGoal}
          key={goal.id}
          onClick={() => onGoalClick(goal.id)}
        >
          <div>
            <Text tag="div" style="small" inheritBaseline>
              <strong>
                {goalType === "team"
                  ? goal.teamName.concat(": ")
                  : goalType === "department"
                  ? goal.departmentName.concat(": ")
                  : ""}
              </strong>
              {goal.name}
            </Text>
          </div>
          <Icon icon={chevronRightIcon} role="presentation" />
        </div>
      ))}
    </div>
  )
}

export default injectIntl(AlignedGoalsSection)
