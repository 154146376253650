import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import moment from "moment"
import { FormattedMessage, injectIntl } from "react-intl"
import { List } from "immutable"
import Settings from "../../../settings"
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"
import IntlFlatPickr from "../IntlFlatPickr/IntlFlatPickr"
import strings from "../../../locale/strings"
import QuestionFields from "../../elements/QuestionFields/QuestionFields"
import Select from "../../elements/Select/Select"
import Button from "../../elements/Button/Button"
import ConfirmationModal from "../modals/ConfirmationModal"
import { allFilled } from "../../../util/forms"
import TextEditor from "../../elements/TextEditor"
import ListEditor from "../ListEditor/ListEditor"
import GoalAlignmentSelector from "./GoalAlignmentSelector"
import InputField from "../../elements/InputField/InputField"
import Typeahead from "../../elements/Typeahead/Typeahead"
import TypeaheadRowWithAvatar from "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar"
import Actions from "../../../actions"
import Icon from "../../elements/Icon/Icon"
import "./CreateOrEditGoalFields.less"

const {
  GOALS: { DUE_AT_HOUR }
} = Settings

const VISIBILITY_OPTIONS = [
  { value: "everyone", text: strings.goals.visibility.everyone },
  { value: "manager", text: strings.goals.visibility.manager },
  { value: "individuals", text: strings.goals.visibility.individuals },
  { value: "owner", text: strings.goals.visibility.owner }
]

const PRIORITY_OPTIONS = [
  { value: 0, text: strings.general.priority.low },
  { value: 1, text: strings.general.priority.medium },
  { value: 2, text: strings.general.priority.high }
]

const DEFAULT_PRIORITY_VALUE = 1

class CreateOrEditGoalFields extends React.Component {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    sourceName: PropTypes.string,

    allowVisibilityChange: PropTypes.bool,

    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onDirtinessChange: PropTypes.func,

    createAction: PropTypes.func.isRequired,
    updateAction: PropTypes.func.isRequired,

    targetRange: PropTypes.array
  }

  static defaultProps = {
    onDirtinessChange: _.noop,
    allowVisibilityChange: true
  }

  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    const { goal } = props
    this.state = {
      editedGoal: {
        ...goal,
        individuals: goal.access_permissions
          ? _.map(goal.access_permissions, "user")
          : []
      },
      editedKeyResults: List(goal.key_results),
      keyResultsDirty: false,
      changingVisibility: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onDirtinessChange } = this.props

    if (this.isDirty() && !this.isDirty(prevState)) {
      onDirtinessChange(true)
    } else if (!this.isDirty() && this.isDirty(prevState)) {
      onDirtinessChange(false)
    }
  }

  isDirty(state = this.state) {
    const { goal } = this.props
    const { editedGoal, keyResultsDirty } = state

    // Add in individuals attribute for comparison, as we added it to editedGoal in the constructor
    goal["individuals"] = goal.access_permissions
      ? _.map(goal.access_permissions, "user")
      : []

    return !_.isEqual(goal, editedGoal) || keyResultsDirty
  }

  getGoalFields() {
    const {
      allowVisibilityChange,
      intl: { formatMessage }
    } = this.props
    const { editedGoal, editedKeyResults } = this.state
    const goalType = editedGoal.goal_type
    const goalPriority =
      editedGoal.priority || editedGoal.priority === 0
        ? editedGoal.priority
        : DEFAULT_PRIORITY_VALUE
    return _.compact([
      _.compact([
        {
          label: formatMessage(strings.general.dueDate),
          value: editedGoal.due_at,
          field: (
            <IntlFlatPickr
              options={{
                altInput: true
              }}
              value={editedGoal.due_at}
              onChange={e =>
                this.handleChangeGoalProperty(
                  "due_at",
                  moment(e[0])
                    .hour(DUE_AT_HOUR)
                    .format()
                )
              }
              placeholder={formatMessage(strings.general.selectDate)}
            />
          )
        },
        {
          label: formatMessage(strings.general.priority.priority),
          value: goalPriority,
          field: (
            <Select
              value={goalPriority}
              options={PRIORITY_OPTIONS}
              onChange={option =>
                this.handleChangeGoalProperty("priority", option.value)
              }
            />
          )
        },
        goalType === "my_goal" &&
          (allowVisibilityChange || !editedGoal.id) && {
            label: formatMessage(strings.general.visibility),
            value: editedGoal.visibility || VISIBILITY_OPTIONS[0].value,
            field: (
              <Select
                value={editedGoal.visibility || VISIBILITY_OPTIONS[0].value}
                options={VISIBILITY_OPTIONS}
                onChange={option =>
                  this.handleChangeGoalProperty("visibility", option.value)
                }
              />
            )
          }
      ]),
      editedGoal &&
        editedGoal.visibility === "individuals" && {
          value: editedGoal.individuals ? editedGoal.individuals : [],
          field: (
            <Typeahead
              className="CreateOrEditGoalFields--individuals-select flex"
              zugataStyle={true}
              value={editedGoal.individuals ? editedGoal.individuals : []}
              valueRenderer={user => (
                <TypeaheadRowWithAvatar user={user} showEmail={false} />
              )}
              placeholder={formatMessage(strings.goals.placeholder.searchUsers)}
              optionRenderer={user => <TypeaheadRowWithAvatar user={user} />}
              loadOptions={query => this.handleLoadSpecifyUsersOptions(query)}
              loadOptionsField="users"
              refreshOptionsOnFocus={true}
              clearable={false}
              multi={true}
              onChange={query =>
                this.handleChangeGoalProperty("individuals", query)
              }
              labelKey="full_name"
              valueKey="email"
            />
          )
        },
      {
        label: formatMessage(strings.general.goal),
        value: editedGoal.name,
        field: (
          <InputField
            borderedStyle={true}
            multiline={true}
            value={editedGoal.name}
            onChange={e =>
              this.handleChangeGoalProperty("name", e.target.value)
            }
            placeholder={formatMessage(strings.goals.placeholder.goal)}
          />
        )
      },
      {
        label: formatMessage(strings.general._description),
        value: editedGoal.description,
        optional: true,
        field: (
          <TextEditor
            initialValue={editedGoal.description}
            onChange={d => this.handleChangeGoalProperty("description", d)}
            placeholder={formatMessage(strings.goals.placeholder.desc)}
          />
        )
      },
      {
        label: formatMessage(strings.goals.keyResults),
        value: editedKeyResults,
        optional: true,
        field: (
          <ListEditor
            className="CreateOrEditGoalFields--key-results"
            initialItems={editedKeyResults}
            customItemAdder={this.renderKeyResultItemAdder}
            renderItems={this.renderKeyResultItems}
            onChange={this.handleKeyResultsChanged}
          />
        )
      },
      goalType !== "company_goal" &&
        editedGoal.status !== "accomplished" && {
          label: formatMessage(
            strings.goals.alignments.selector.header[goalType]
          ),
          value: editedGoal.outgoing_aligned_goals,
          optional: true,
          field: (
            <GoalAlignmentSelector
              goalType={goalType}
              initialSelectedGoals={editedGoal.outgoing_aligned_goals}
              onSelectionChange={alignedGoals =>
                this.handleChangeGoalProperty(
                  "outgoing_aligned_goals",
                  alignedGoals
                )
              }
            />
          )
        }
    ])
  }

  updateGoalProperty(name, transformer) {
    this.setState(state => ({
      editedGoal: {
        ...state.editedGoal,
        [name]: transformer(state.editedGoal[name])
      }
    }))
  }

  handleChangeGoalProperty(name, value) {
    if (name === "visibility" && this.state.editedGoal.id) {
      // Changing the visibility of an existing goal
      this.setState({ changingVisibility: true, newVisibility: value })
    } else {
      this.updateGoalProperty(name, () => value)
    }
  }

  handleLoadSpecifyUsersOptions(query) {
    const { user } = this.context
    return Actions.User.list({
      q: query
    }).then(({ users }) => {
      return {
        users: _.filter(users, u => u.id !== user.id)
      }
    })
  }

  handleKeyResultsChanged = editedKeyResults => {
    this.setState({ editedKeyResults, keyResultsDirty: true })
  }

  handleSave = () => {
    const {
      goal: originalGoal,
      createAction,
      updateAction,
      onSave,
      onDirtinessChange,
      targetRange
    } = this.props
    const { editedGoal, editedKeyResults } = this.state

    const originalOutgoingAlignedIds = _.map(
      originalGoal.outgoing_aligned_goals,
      "id"
    )
    const editedOutgoingAlignedIds = _.map(
      editedGoal.outgoing_aligned_goals,
      "id"
    )

    const alignedGoalIdsToBeAdded = _.difference(
      editedOutgoingAlignedIds,
      originalOutgoingAlignedIds
    )
    const alignedGoalIdsToBeRemoved = _.difference(
      originalOutgoingAlignedIds,
      editedOutgoingAlignedIds
    )
    const goal = {
      ...editedGoal,
      // eslint-disable-line camelcase
      key_results: editedKeyResults
        .map(
          // Hackish convert from ListEditor-style deleted_at to _destroy
          ({ deleted_at: deletedAt, completion, title, ...params }) => ({
            title,
            completion: completion || 0,
            _destroy: deletedAt || title.trim().length === 0 ? true : undefined,
            ...params
          })
        )
        .filter(keyResult => !(keyResult._destroy && keyResult.clientId))
        .toJS(),
      visibility: editedGoal.visibility || VISIBILITY_OPTIONS[0].value,
      priority:
        editedGoal.priority === 0 || editedGoal.priority > 0
          ? editedGoal.priority
          : DEFAULT_PRIORITY_VALUE,
      // eslint-disable-line camelcase
      outgoing_alignment_associations_attributes: alignedGoalIdsToBeAdded
        .map(id => ({
          incoming_alignment_goal_id: id // eslint-disable-line camelcase
        }))
        .concat(
          _(alignedGoalIdsToBeRemoved)
            .map(id => {
              const alignment = originalGoal.outgoing_alignment_associations.find(
                assoc => assoc.incoming_alignment_goal_id === id
              )
              return alignment && { id: alignment.id, _destroy: true }
            })
            .compact()
            .value()
        )
    }

    const savePromise = editedGoal.id
      ? updateAction({
          originalGoal,
          updatedGoal: goal
        })
      : createAction({ goal, targetRange })

    savePromise.then(() => {
      onDirtinessChange(false)
      onSave()
    })
  }

  handleCancel = () => {
    const { onCancel, onDirtinessChange } = this.props
    onDirtinessChange(false)
    onCancel()
  }

  handleConfirmChangeVisibility = () => {
    const { newVisibility } = this.state

    this.updateGoalProperty("visibility", () => newVisibility)
  }

  renderKeyResultItems = ({ items, onChangeItem, onRemoveItem }) => {
    const {
      intl: { formatMessage }
    } = this.props

    return items.map((keyResult, i) => (
      <EditableKeyResult
        key={i}
        keyResult={keyResult}
        onChangeItem={changes => onChangeItem(i, changes)}
        onRemoveItem={() => onRemoveItem(i)}
        placeholder={formatMessage(strings.goals.placeholder.keyResult)}
      />
    ))
  }

  renderKeyResultItemAdder = ({ onAddItem }) => {
    return (
      <div
        className="CreateOrEditGoalFields--key-results-item-adder"
        onClick={() => onAddItem({ title: "", completion: 0 })}
      >
        <Icon iconName="add" />
        <FormattedMessage {...strings.goals.keyResult} />
      </div>
    )
  }

  render() {
    const {
      props: { onCancel },
      state: { editedGoal, changingVisibility }
    } = this

    const dirty = this.isDirty()
    const fields = this.getGoalFields()

    const submittable = editedGoal.id
      ? dirty && allFilled(fields)
      : allFilled(fields)

    return (
      <div className="CreateOrEditGoalFields relative center-align">
        <p className="CreateOrEditGoalFields--subheading">
          {editedGoal.id ? (
            <FormattedMessage
              {...strings.goals.editGoalSubtitle}
              values={{
                date: (
                  <ZugataFormattedDate
                    value={editedGoal.created_at}
                    month="short"
                  />
                )
              }}
            />
          ) : _(["department_goal", "company_goal"]).includes(
              editedGoal.goal_type
            ) ? null : (
            <FormattedMessage {...strings.goals.createGoalSubtitle} />
          )}
        </p>

        <QuestionFields
          onSubmit={this.handleSave}
          jumpFieldOnEnter={false}
          items={fields}
        />

        <div className="CreateOrEditGoalFields--buttons">
          <div className="CreateOrEditGoalFields--buttons-container">
            {!!onCancel && (
              <Button actionType="secondary" onClick={this.handleCancel}>
                <FormattedMessage {...strings.general.cancel} />
              </Button>
            )}
            <Button
              id="saveButton"
              actionType="primary"
              disabled={!submittable}
              onClick={this.handleSave}
            >
              <FormattedMessage
                {...strings.general[editedGoal.id ? "save" : "publish"]}
              />
            </Button>
          </div>
        </div>

        <ConfirmationModal
          opened={changingVisibility}
          headerContent={
            <FormattedMessage {...strings.goals.visibilityChange.header} />
          }
          noButtonText={
            <FormattedMessage
              {...strings.goals.visibilityChange.noButtonText}
            />
          }
          yesButtonText={
            <FormattedMessage
              {...strings.goals.visibilityChange.yesButtonText}
            />
          }
          onClose={() =>
            this.setState({ changingVisibility: false, newVisibility: null })
          }
          onConfirmClick={this.handleConfirmChangeVisibility}
        />
      </div>
    )
  }
}

const KEY_RESULT_TOOLBAR_BUTTONS = ["bold", "link"]

export class EditableKeyResult extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    keyResult: PropTypes.object,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    onRemoveItem: PropTypes.func,
    onChangeItem: PropTypes.func
  }

  static defaultProps = {
    className: ""
  }

  handleTitleChange = title => this.props.onChangeItem({ title })

  render() {
    const {
      className,
      keyResult: { title },
      placeholder,
      autoFocus,
      onRemoveItem
    } = this.props

    return (
      <TextEditor
        className={`CreateOrEditGoalFields--key-results-item ${className}`}
        clearable={true}
        placeholder={placeholder}
        initialValue={title}
        onChange={this.handleTitleChange}
        onClear={onRemoveItem}
        toolbarButtonsToUse={KEY_RESULT_TOOLBAR_BUTTONS}
        highlightedInitialValue={autoFocus ? placeholder : undefined}
      />
    )
  }
}

export default injectIntl(CreateOrEditGoalFields)
export { CreateOrEditGoalFields as RawCreateOrEditGoalFields }
