import "./FadeoutTruncated.less"
import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import { debounceAnimationFrame } from "../../../decorators/timers"

export default class FadeoutTruncated extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hideFadeOnUnderflow: PropTypes.bool
  }

  static defaultProps = {
    hideFadeOnUnderflow: true
  }

  constructor(props) {
    super(props)

    this.state = {
      underflowed: false
    }
  }

  componentDidMount() {
    if (this.props.hideFadeOnUnderflow) {
      this.initEventHandlers()
    }
    this.updateUnderflowed()
  }

  componentDidUpdate(prevProps) {
    if (this.props.hideFadeOnUnderflow) {
      if (!prevProps.hideFadeOnUnderflow) {
        this.initEventHandlers()
      }
      this.updateUnderflowed()
    } else if (prevProps.hideFadeOnUnderflow) {
      this.teardownEventHandlers()
    }
  }

  componentWillUnmount() {
    if (this.props.hideFadeOnUnderflow) {
      this.teardownEventHandlers()
    }
    this.updateUnderflowed.cancel()
  }

  initEventHandlers() {
    window.addEventListener("resize", this.updateUnderflowed)
    window.addEventListener("orientationchange", this.updateUnderflowed)
  }

  teardownEventHandlers() {
    window.removeEventListener("resize", this.updateUnderflowed)
    window.removeEventListener("orientationchange", this.updateUnderflowed)
  }

  @debounceAnimationFrame()
  updateUnderflowed() {
    if (!this.props.hideFadeOnUnderflow) {
      return
    }

    const { container } = this

    if (!container) {
      return
    }

    // To check if the element is overflowed, we compare its scroll height (That is, the size the content would have
    // if the container was allowed to scroll, or its "natural" height) to its client Height (its actual size on the
    // screen). In some rare cases, scrollHeight will be greater than clientHeight by 1px due to "interesting" rounding
    // choices on behalf of the rendering engine. So, we add 1px as "fudge factor" to account for this case
    const underflowed = container.scrollHeight <= container.clientHeight + 1

    if (underflowed !== this.state.underflowed) {
      this.setState({
        underflowed
      })
    }
  }

  render() {
    const { className, children, hideFadeOnUnderflow } = this.props
    const { underflowed } = this.state

    return (
      <div
        className={cx(className, "FadeoutTruncated", {
          "FadeoutTruncated--underflowed": hideFadeOnUnderflow && underflowed
        })}
        ref={container => (this.container = container)}
      >
        {children}
      </div>
    )
  }
}
