import "./PillList.less"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"

export default class PillList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        className: PropTypes.string,
        text: PropTypes.node
      })
    ).isRequired,
    valueMapper: PropTypes.func,
    onChange: PropTypes.func,
    enableClearAll: PropTypes.bool
  }

  static defaultProps = {
    className: ""
  }

  handleRemoveValue = id => {
    const { valueMapper, onChange } = this.props
    onChange(_.filter(this.props.values, value => valueMapper(value).id !== id))
  }

  renderPill({ id, text, className = "" }) {
    return (
      <div
        key={id}
        className={`PillList--pill layout horizontal center ${className}`}
      >
        <div className="truncate">{text}</div>
        <div
          className="PillList--pill-clear"
          onClick={() => this.handleRemoveValue(id)}
        >
          <Icon iconName="clear" className="PillList--pill-clear-icon" />
        </div>
      </div>
    )
  }

  renderClearAll() {
    return (
      <div
        className="PillList--clear-all layout horizontal center"
        onClick={() => this.props.onChange([])}
      >
        <FormattedMessage {...strings.general.clearAll} />
      </div>
    )
  }

  render() {
    const { className, values, enableClearAll, valueMapper } = this.props

    if (!values.length) {
      return null
    }

    return (
      <div
        className={`PillList layout horizontal center-items wrap ${className}`}
      >
        {values.map(value =>
          this.renderPill(valueMapper ? valueMapper(value) : value)
        )}
        {enableClearAll && this.renderClearAll()}
      </div>
    )
  }
}
