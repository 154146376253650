import "./GoalAlignmentsIndicator.less"
import React from "react"
import PropTypes from "prop-types"
import Icon from "../../elements/Icon/Icon"

const GoalAlignmentsIndicator = ({ count }) =>
  count > 0 && (
    <div className="GoalAlignmentsIndicator layout horizontal center">
      <div className="GoalAlignmentsIndicator--icon">
        <Icon iconName="factors" />
      </div>
      <span className="GoalAlignmentsIndicator--count">{count}</span>
    </div>
  )

GoalAlignmentsIndicator.propTypes = {
  count: PropTypes.number.isRequired
}

export default GoalAlignmentsIndicator
