import * as React from "react"
import { Location } from "history"
import { hot } from "react-hot-loader/root"
import { IndexRoute, Route, InjectedRouter } from "react-router"
// @ts-ignore
import Authenticate from "../../pages/lib/Authenticate"
import EditTeam from "../EditTeam/EditTeam"
import NewTeam from "../NewTeam/NewTeam"
import Team from "../Team/Team"
import TeamsList from "../TeamsList/TeamsList"
import ArchivedTeamsList from "../ArchivedTeamsList/ArchivedTeamsList"
import ArchivedTeam from "../../pages/ArchivedTeam/ArchivedTeam"
import styles from "./TeamsApp.scss"
import GoalDossier from "../GoalDossier/GoalDossier"
import useGoalDossier from "../../../hooks/useGoalDossier"

type TeamsApp = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
}>

const TeamsApp: TeamsApp = ({ children, location, router }) => {
  const { isOpen: dossierIsOpen, goalId } = useGoalDossier(router, location)
  return (
    <div className={styles.teams}>
      <div className={styles.content}>{children}</div>
      {dossierIsOpen && (
        <div className={styles.dossier}>
          {
            // @ts-ignore: Router props are being given by withRouter HOC
            <GoalDossier goalId={goalId} />
          }
        </div>
      )}
    </div>
  )
}
export const routes = (
  <Route
    path="teams"
    component={hot(TeamsApp)}
    onEnter={Authenticate({
      // @ts-ignore
      predicate: user => user.company.allow_new_goals_interface
    })}
  >
    <IndexRoute component={hot(TeamsList)} />
    <Route path="archived" component={hot(ArchivedTeamsList)} />
    <Route path="archived/:teamId" component={hot(ArchivedTeam)} />
    <Route path=":teamId" component={hot(Team)} />
    <Route path="new/:teamId" component={hot(NewTeam)} />
    <Route path="edit/:teamId" component={hot(EditTeam)} />
  </Route>
)
