import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import "./SearchField.less"

@injectIntl
export default class SearchField extends React.Component {
  static propTypes = {
    query: PropTypes.string,
    onQueryChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    query: ""
  }

  constructor(props) {
    super(props)

    this.state = {
      focused: false
    }
  }

  handleSearchClick(e) {
    if (e.target.tagName.toLowerCase() !== "input") {
      this.searchInput.focus()
    }
  }

  handleFocus = () => this.setState({ focused: true })
  handleBlur = () => this.setState({ focused: false })

  render() {
    const {
      intl: { formatMessage },
      query,
      onQueryChange,
      fullWidth,
      placeholder,
      className
    } = this.props
    const { focused } = this.state

    return (
      <div
        className={`SearchField layout horizontal center flex
                       ${className}
                       ${fullWidth ? "SearchField--full-width" : "one"}
                       ${focused ? "focused" : ""}`}
        onClick={this.handleSearchClick.bind(this)}
      >
        <span className="icon">
          <Icon iconName="search" />
        </span>

        <input
          ref={searchInput => (this.searchInput = searchInput)}
          type="search"
          className={`flex`}
          placeholder={
            placeholder
              ? placeholder
              : formatMessage(strings.teamSearch.searchName)
          }
          value={query || ""}
          onChange={e => onQueryChange(e.target.value)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />

        {query ? (
          <button className="btn-flat" onClick={() => onQueryChange("")}>
            <Icon iconName="clear" />
          </button>
        ) : null}
      </div>
    )
  }
}
