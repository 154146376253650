import * as React from "react"
import { useState } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { IconButton } from "@kaizen/component-library"
import closeIcon from "@kaizen/component-library/icons/close.icon.svg"
import { TextField } from "@kaizen/component-library/draft"
import styles from "./KeyResultListItem.scss"
import strings from "../../../locale/strings"

export type KeyResult = { id: string; title: string; isNew?: boolean }

type KeyResultListItem = React.FunctionComponent<{
  intl: InjectedIntl
  keyResult: KeyResult
  index: number
  updateKeyResult: (id: string, title: string) => void
  removeKeyResult: (id: string) => void
}>

// note: look to abstract keyResults and functions from file
const KeyResultListItem: KeyResultListItem = ({
  intl,
  index,
  keyResult,
  updateKeyResult,
  removeKeyResult
}) => {
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const { formatMessage } = intl
  return (
    <div className={styles.container}>
      <div className={styles.peripheralContainer}>
        <h4 className={styles.keyResultTitle}>{index + 1}</h4>
      </div>
      <div className={styles.textFieldContainer}>
        <TextField
          id={`key-result-${index}`}
          inputValue={keyResult.title}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            updateKeyResult(keyResult.id, value)
          }}
          inputType="text"
          placeholder={formatMessage(strings.keyResultsListItem.placeholder)}
          description={
            showDescription
              ? formatMessage(strings.keyResultsListItem.descriptionText)
              : undefined
          }
          onFocus={() => setShowDescription(true)}
          onBlur={() => setShowDescription(false)}
          labelText=""
        />
      </div>
      <div className={styles.peripheralContainer}>
        <IconButton
          onClick={() => removeKeyResult(keyResult.id)}
          icon={closeIcon}
          label="Label"
          automationId="demo-button"
        />
      </div>
    </div>
  )
}

export default injectIntl(KeyResultListItem)
