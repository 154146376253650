import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import Actions from "../../../actions"
import strings from "../../../locale/strings"
import modalDeprecated from "../../decorators/modalDeprecated"
import CreateOrEditGoalFields from "./CreateOrEditGoalFields"

@modalDeprecated({
  className: "CreateOrEditGoalModal",
  opened: props => !!props.goal,
  key: props => props.goal.id || "new"
})
export default class CreateOrEditGoalModal extends React.Component {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    sourceName: PropTypes.string,
    allowDeletion: PropTypes.bool,
    allowVisibilityChange: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onDirtinessChange: PropTypes.func.isRequired,
    targetRange: PropTypes.array
  }

  static defaultProps = {
    allowDeletion: true,
    allowVisibilityChange: true
  }

  handleClose = () => {
    const { onClose, onDirtinessChange } = this.props

    onDirtinessChange(false, onClose)
  }

  render() {
    const { goal, sourceName, onDirtinessChange, targetRange } = this.props

    return (
      <div>
        <h2 className="CreateOrEditGoalModal--with-subheading">
          <FormattedMessage
            {..._.get(
              strings.goals,
              goal.id
                ? goal.goal_type === "company_goal"
                  ? "company.editTitle"
                  : goal.goal_type === "department_goal"
                  ? "editDeptGoalTitle"
                  : "editGoalTitle"
                : goal.goal_type === "company_goal"
                ? "company.createNew.title"
                : goal.goal_type === "department_goal"
                ? "createNewDeptGoal"
                : "createNewPersonalGoal"
            )}
            values={{ name: sourceName }}
          />
        </h2>
        <CreateOrEditGoalFields
          {...this.props}
          createAction={Actions.Goal.create}
          updateAction={Actions.Goal.update}
          deleteAction={Actions.Goal.delete}
          onSave={this.handleClose}
          onCancel={this.handleClose}
          onDelete={this.handleClose}
          onDirtinessChange={onDirtinessChange}
          targetRange={targetRange}
        />
      </div>
    )
  }
}
