import { Icon, Text } from "@kaizen/component-library"
import { Tag } from "@kaizen/component-library/draft"
import {
  TableRow,
  TableRowCell
} from "@kaizen/component-library/draft/Kaizen/Table"
import chevronRightIcon from "@kaizen/component-library/icons/chevron-right.icon.svg"
import moment from "moment"
import React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
// @ts-ignore
import ProgressBar from "../ProgressBar/ProgressBar"
import useGetResponsiveValue from "../../../hooks/useGetResponsiveValue"
import GoalOwnersCell from "../GoalOwnersCell/GoalOwnersCell"
import styles from "./GoalRow.scss"
import { GoalPreview } from "../../../types/Goals"
import { User } from "../../../types/User"
import strings from "../../../locale/strings"

type GoalRow = React.FunctionComponent<{
  goal: GoalPreview
  owners?: User[]
  intl: InjectedIntl
}>

/*
 * I don't like this being written this way
 * but for the sake of time I am duplicating this
 * for mobile stylings
 */
const GoalRow: GoalRow = ({ goal, owners, intl }) => {
  const { formatMessage } = intl
  const getResponsiveValue = useGetResponsiveValue()
  const isMobile = getResponsiveValue({ 0: true, 768: false })
  const smallerCellWidth = owners ? 2 / 12 : 2 / 10
  const mediumCellWidth = owners ? 3 / 12 : 3 / 10
  const largerCellWidth = owners ? 5 / 12 : 5 / 10

  const desktop = () => (
    <TableRow>
      <TableRowCell width={largerCellWidth}>
        <div className={styles.goalInfo}>
          <div className={styles.priorityAndStatus}>
            <Text tag="div" style="label" inheritBaseline>
              {goal.priority}
            </Text>
            {goal.status === "blocked" && (
              <div className={styles.status}>
                <Tag variant="statusDraft">
                  {formatMessage(strings.goalsPage.goalRow.blocked)}
                </Tag>
              </div>
            )}
          </div>
          <Text tag="div" style="heading" inheritBaseline>
            {goal.name}
          </Text>
        </div>
      </TableRowCell>

      {owners && (
        <TableRowCell width={smallerCellWidth}>
          <GoalOwnersCell
            avatars={owners.map(owner => {
              return owner.profileImage
            })}
          />
        </TableRowCell>
      )}

      <TableRowCell width={smallerCellWidth}>
        <Text tag="p" style="body" inheritBaseline>
          {moment(goal.dueDate).format("MMM DD, YYYY")}
        </Text>
      </TableRowCell>
      <TableRowCell width={mediumCellWidth}>
        <div className={styles.complete}>
          <ProgressBar className={styles.progressBar} value={goal.completion} />
          <div className={styles.openIcon}>
            <Icon
              title={formatMessage(strings.goalsPage.goalRow.openGoal)}
              icon={chevronRightIcon}
            />
          </div>
        </div>
      </TableRowCell>
    </TableRow>
  )

  const mobile = () => (
    <div className={styles.mobileContainer}>
      <div className={styles.goalInfo}>
        <div className={styles.priorityAndStatus}>
          <Text tag="div" style="label" inheritBaseline>
            {goal.priority}
          </Text>
          {goal.status === "blocked" && (
            <div className={styles.status}>
              <Tag variant="statusDraft">
                {formatMessage(strings.goalsPage.goalRow.blocked)}
              </Tag>
            </div>
          )}
        </div>
        <Text tag="div" style="heading" inheritBaseline>
          {goal.name}
        </Text>
      </div>

      {owners && (
        <div className={styles.mobileMargins}>
          <GoalOwnersCell
            avatars={owners.map(owner => {
              return owner.profileImage
            })}
          />
        </div>
      )}

      <div className={styles.mobileMargins}>
        <Text tag="p" style="body" inheritBaseline>
          {moment(goal.dueDate).format("MMM DD, YYYY")}
        </Text>
      </div>
      <div className={styles.complete}>
        <ProgressBar className={styles.progressBar} value={goal.completion} />
        <div className={styles.openIcon}>
          <Icon
            title={formatMessage(strings.goalsPage.goalRow.openGoal)}
            icon={chevronRightIcon}
          />
        </div>
      </div>
    </div>
  )

  return isMobile ? mobile() : desktop()
}

export default injectIntl(GoalRow)
