import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import strings from "../../../locale/strings"

const typeOfGoal = (goal, companyName) => {
  if (goal.goal_type === "department_goal") {
    return goal.department.title
  } else if (goal.goal_type === "team_goal") {
    return goal.team_name
  } else {
    return companyName
  }
}

const AlignableGoalItemFormat = ({ goal, companyName }) => (
  // console.log("alignment list")
  <FormattedMessage
    {...strings.goals.alignments.itemFormat}
    values={{
      companyOrDept: typeOfGoal(goal, companyName),
      goalName: goal.name
    }}
  />
)

AlignableGoalItemFormat.propTypes = {
  goal: PropTypes.object.isRequired,
  companyName: PropTypes.string
}

export default AlignableGoalItemFormat
