import { Text } from "@kaizen/component-library"
import * as React from "react"
import Avatar from "../../Avatar/Avatar"
import styles from "./AvatarDetails.scss"

type AvatarDetailsProps = {
  bestName: string
  avatarURL: string
  jobTitle: string
}

const AvatarDetails = ({
  bestName,
  avatarURL,
  jobTitle
}: AvatarDetailsProps) => {
  return (
    <div className={styles.container}>
      <div>
        <Avatar size={"medium"} imageURL={avatarURL} />
      </div>
      <div className={styles.details}>
        <Text tag="div" style="small-bold" inheritBaseline>
          {bestName}
        </Text>
        <Text tag="div" style="small" inheritBaseline>
          {jobTitle}
        </Text>
      </div>
    </div>
  )
}

export default AvatarDetails
