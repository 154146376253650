/* eslint-disable react/no-string-refs */
import React from "react"
import _ from "lodash"
import cx from "classnames"
import "./ButtonMenu.less"
import Aid from "../../../constants/automationId"

type Props = {
  className: string
  items: React.ReactNode[]
  menuClass: string
  menuAlignment: "left" | "middle" | "right"
  showTip: boolean
  onMouseLeave?: boolean // Yes, this is actually supposed to be a boolean
  onToggleMenu?: (isOpen: boolean) => void
}

type State = {
  opened: boolean
}

export default class ButtonMenu extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
    menuClass: "",
    menuAlignment: "middle",
    showTip: true,
    onToggleMenu: _.noop
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      opened: false
    }
  }

  componentDidMount() {
    this.positionMenu()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { opened } = this.state

    if (opened !== prevState.opened) {
      if (opened) {
        document.addEventListener("click", this.handleDocumentClick, false)
      } else {
        document.removeEventListener("click", this.handleDocumentClick, false)
      }
      this.positionMenu()
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick, false)
  }

  positionMenu(state = this.state) {
    const { menu } = this.refs
    const { menuAlignment, showTip } = this.props

    if (menu) {
      let dx
      const offsetWidthPadding = 10
      const dy = state.opened && showTip ? 0 : -10

      switch (menuAlignment) {
        case "left":
          dx = -offsetWidthPadding
          break
        case "right":
          // @ts-ignore
          dx = -(menu.offsetWidth - offsetWidthPadding)
          break
        default:
          // @ts-ignore
          dx = -menu.offsetWidth / 2
      }

      // @ts-ignore
      menu.style.transform = menu.style.webkitTransform = `translate(${dx}px, ${dy}px)`
    }
  }

  handleToggleMenu(opened: boolean) {
    const { onToggleMenu } = this.props
    onToggleMenu && onToggleMenu(opened)
    this.setState({ opened })
  }

  handleClick = (e: React.MouseEvent) => {
    if (!this.state.opened) {
      this.handleToggleMenu(true)
    }
    // Since the button is added on Team SR/Eval tables we want to avoid later events
    e.preventDefault()
  }

  handleMouseLeave(e: React.MouseEvent) {
    if (this.state.opened) {
      this.handleToggleMenu(false)
    }
    // Since the button is added on Team SR/Eval tables we want to avoid later events
    e.preventDefault()
  }

  handleDocumentClick = () => {
    this.handleToggleMenu(false)
  }

  render() {
    const {
      className,
      menuClass,
      children,
      items,
      showTip,
      onMouseLeave
    } = this.props
    const { opened } = this.state

    return (
      <div
        className={cx("ButtonMenu", { "ButtonMenu--open": opened }, className)}
        onClick={this.handleClick}
        onMouseLeave={
          onMouseLeave ? this.handleMouseLeave.bind(this) : undefined
        }
        data-automation-id={Aid.moreMenu}
      >
        {children}

        <ul ref="menu" className={`ButtonMenu--menu ${menuClass}`}>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
          {showTip && (
            <li className="ButtonMenu--tip-holder">
              <span className="ButtonMenu--tip" />
            </li>
          )}
        </ul>
      </div>
    )
  }
}
