import { JsonDecoder } from "ts.data.json"
import { CompanyGoalPreview, CompanyGoal } from "../../types/Goals"
import {
  goalKeyResultDecoder,
  goalPriorityDecoder
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"

export const companyGoalDecoder: JsonDecoder.Decoder<CompanyGoal> = JsonDecoder.object<
  CompanyGoal
>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
    priority: goalPriorityDecoder,
    type: JsonDecoder.isExactly("company_goal").map((): "company" => "company"),
    owners: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalOwnerDecoder, "goalOwners")
    ),
    description: JsonDecoder.failover(undefined, JsonDecoder.string),
    status: JsonDecoder.string,
    keyResults: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalKeyResultDecoder, "keyResults")
    ),
    visibility: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    ),
    outgoingAlignmentAssociations: JsonDecoder.failover(
      undefined,
      JsonDecoder.array(
        JsonDecoder.object<{
          id: number
          alignedGoalId: number
        }>(
          {
            id: JsonDecoder.number,
            alignedGoalId: JsonDecoder.number
          },
          "outgoing alignment association",
          {
            alignedGoalId: "incoming_alignment_goal_id"
          }
        ),
        "outgoing alignment associations "
      )
    )
  },
  "companyGoal",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    owners: "goal_owners",
    keyResults: "key_results",
    outgoingAlignedGoals: "outgoing_aligned_goals",
    outgoingAlignmentAssociations: "outgoing_alignment_associations"
  }
)

export const companyGoalPreviewDecoder: JsonDecoder.Decoder<CompanyGoalPreview> = JsonDecoder.object<
  CompanyGoalPreview
>(
  {
    owners: JsonDecoder.array(goalOwnerDecoder, "owners"),
    type: JsonDecoder.isExactly("company_goal").map((): "company" => "company"),
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
    priority: goalPriorityDecoder,
    status: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    )
  },
  "companyGoalPreview",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    owners: "goal_owners"
  }
)

type ResponseType = {
  goals: CompanyGoalPreview[]
}

export const getCompanyGoalsResponseDecoder = JsonDecoder.object(
  {
    company_goals: JsonDecoder.array(
      JsonDecoder.object<ResponseType>(
        {
          goals: JsonDecoder.array(companyGoalPreviewDecoder, "companyGoals")
        },
        "companyGoal"
      ),
      "companyGoals"
    )
  },
  "getCompanyGoalsResponse"
).map(({ company_goals }) =>
  company_goals[0] ? company_goals[0] : { goals: [] }
)
