import useDataAPI from "./useDataAPI"

type Reviewer = {
  id: number
}

type Reviewee = {
  id?: number
  name: string
}

type Question = {
  type: string
  title: string
  description?: string
  required: boolean
}

const useRequestTeamBasedFeedback = (
  teamId: string,
  questions: Question[],
  reviewers: Reviewer[],
  reviewee?: Reviewee
) => {
  const { fire, loading } = useDataAPI("/survey_responses", "POST")

  const requestFeedback = () => {
    if (!reviewee) return Promise.reject()

    return fire({
      survey_response: {
        kind: "TBF",
        subject_id: reviewee.id,
        team_id: teamId,
        questions,
        author_ids: reviewers.map(r => r.id)
      }
    })
  }

  return {
    requestFeedback,
    loading
  }
}

export default useRequestTeamBasedFeedback
