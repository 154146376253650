import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import cx from "classnames"
import strings from "../../../locale/strings"
import MarkdownText from "../MarkdownText/MarkdownText"
import "./QuestionFields.less"

export default class QuestionFields extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.node,
          value: PropTypes.any,
          field: PropTypes.element,
          optional: PropTypes.bool
        }),
        PropTypes.array
      ])
    ).isRequired,
    onSubmit: PropTypes.func
  }

  static defaultProps = {
    className: "",
    onSubmit: _.noop
  }

  constructor(props) {
    super(props)
  }

  getOptionalFieldLabel(label) {
    return (
      <div className="layout horizontal center">
        {label}
        <div className="QuestionFields--label-optional">
          (<FormattedMessage {...strings.general.optional} />)
        </div>
      </div>
    )
  }

  renderRow({ item, i, inline = false }) {
    const { label, field, optional } = item

    const markdownLabel = <MarkdownText text={label} inline={true} />

    return (
      <div
        key={i}
        className={cx("QuestionFields--item", {
          "QuestionFields--item-without-field": !field,
          "flex one": field && inline
        })}
      >
        {label && (
          <div className="QuestionFields--label">
            {optional
              ? this.getOptionalFieldLabel(markdownLabel)
              : markdownLabel}
          </div>
        )}
        {field && React.cloneElement(field, { ref: `fields[${i}]` })}
      </div>
    )
  }

  render() {
    const {
      props: { items, className }
    } = this

    let i = 0

    return (
      <div className={`QuestionFields layout vertical ${className}`}>
        {items.map(item => {
          if (Array.isArray(item)) {
            return (
              <div key={i} className="QuestionFields--container">
                {item.map(subItem =>
                  this.renderRow({ item: subItem, i: i++, inline: true })
                )}
              </div>
            )
          } else {
            return (
              <div className="QuestionFields--row-container">
                {this.renderRow({ item, i: i++ })}
              </div>
            )
          }
        })}
      </div>
    )
  }
}
