import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import { Company } from "../../../state/CompanyState"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useNotifications from "../../../hooks/useNotifications"
import CompanyGoalForm, {
  CompanyGoalFields
} from "../CompanyGoalForm/CompanyGoalForm"
import useCreateCompanyGoal from "../../../hooks/useCreateCompanyGoal"
// import useCompanies from "../../hooks/useCompanies"
import strings from "../../../locale/strings"
import { User } from "../../../types/User"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"

type NewCompanyGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
}>

export const formDisabled = (loading: boolean, companies: Company[]) =>
  loading || companies.length === 0

const NewCompanyGoal: NewCompanyGoal = ({ intl, router }) => {
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const currentUser = useCurrentUser()
  // NOTE: Do we need to have a search term here?
  const { company } = currentUser
  const { id: companyId } = company

  // NOTE: company should never be null
  const companies = [
    { id: companyId ? companyId.toString() : "", name: company.name }
  ]
  const { createCompanyGoal, loading, data, error } = useCreateCompanyGoal()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.newCompanyGoal.newCompanyGoalSuccessTitle),
        message: formatMessage(
          strings.newCompanyGoal.newCompanyGoalSuccessNotification
        )
      })
      router.push({
        pathname: `/new_goals/company`,
        query: {
          // @ts-ignore
          goalId: data.goal.id
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.newCompanyGoal.newCompanyGoalErrorTitle),
        message: formatMessage(
          strings.newCompanyGoal.newCompanyGoalErrorNotification
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const initOwners: User[] = [currentUser]
  const newGoal: CompanyGoalFields = {
    name: "",
    companyId: companyId ? companyId.toString() : "",
    description: "",
    dueDate: currentUser.company.goalCycle.end,
    priority: 1,
    visibility: "everyone",
    owners: initOwners,
    keyResults: [newKeyResult()],
    alignedGoalIds: []
  }

  const initCompany = () =>
    company
      ? { value: companyId ? companyId.toString() : "", label: company.name }
      : undefined

  return (
    <CompanyGoalForm
      title={formatMessage(strings.newCompanyGoal.title)}
      submitLabel={formatMessage(strings.newCompanyGoal.submitLabel)}
      defaultFields={newGoal}
      companies={companies}
      initCompany={companyId ? initCompany() : undefined}
      onSubmit={createCompanyGoal}
      onLeavePage={() => router.push("/new_goals/company")}
      disabled={formDisabled(loading, companies)}
      initOwners={initOwners}
    />
  )
}

export default injectIntl(NewCompanyGoal)
