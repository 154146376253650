import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import Tooltip from "rc-tooltip"
import d3 from "d3"
import cx from "classnames"
import { FormattedMessage } from "react-intl"
import { rescaleDomain, scaleFromObject } from "../../../util/scales"
import Settings from "../../../settings"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import "./MultipleChoice.less"

export default class MultipleChoice extends React.Component {
  static propTypes = {
    question: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      canShare: PropTypes.bool,
      choices: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            description: PropTypes.string
          })
        ),
        PropTypes.arrayOf(PropTypes.string)
      ])
    }).isRequired,
    selectedAnswerValue: PropTypes.number,
    htmlId: PropTypes.string.isRequired,
    onSelectAnswer: PropTypes.func,
    answerOptionsMapper: PropTypes.func,
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    orientation: PropTypes.oneOf(["vertical", "horizontal"]),
    textAlign: PropTypes.oneOf(["left-align", "center-center"]),
    className: PropTypes.string,
    hideAsterisk: PropTypes.bool,
    disableColor: PropTypes.bool,
    hideQuestion: PropTypes.bool,
    automationId: PropTypes.string
  }

  static defaultProps = {
    answerOptionsMapper: question =>
      _(question.answer_titles_hash)
        .map((value, key) => ({ title: value, value: Number(key) }))
        .sortBy("value")
        .value(),
    orientation: "horizontal",
    textAlign: "left-align",
    onSelectAnswer: _.noop,
    className: "",
    hideQuestion: false
  }

  handleSelectAnswer(value) {
    const { selectedAnswerValue, onSelectAnswer } = this.props
    if (selectedAnswerValue === value) {
      onSelectAnswer(null)
    } else {
      onSelectAnswer(value)
    }
  }

  getButtonCSSContent(selected, buttonId, color, orientation) {
    const { readOnly } = this.props
    const isVertical = orientation === "vertical"
    return {
      __html: `
      ${
        !readOnly
          ? isVertical
            ? `#${buttonId}:hover {
              border-left: 4px solid #21A38B !important;
              background-color: #f8f8f9;
            }

            #${buttonId}:focus {
              border-left: 4px solid #21A38B !important;
              background-color: #f8f8f9;
            }`
            : `#${buttonId}:hover {
            background-color: ${color};
            color: white;
            }
            #${buttonId}:focus {
            background-color: ${color};
            color: white;
            }`
          : selected
          ? isVertical
            ? `#${buttonId} {
                padding-left: 20px !important;
              }`
            : ""
          : isVertical
          ? `#${buttonId} {
              padding-left: 24px !important;
              opacity: 0.7;
            }`
          : `#${buttonId} {
              opacity: 0.7;
            }`
      }
      ${
        selected
          ? isVertical
            ? `#${buttonId} {
              background-color: #f8f8f9;
              border-left: 4px solid #21A38B !important;
            }`
            : `#${buttonId} {
              background-color: ${color};
              color: white;
            }
            @media print {
              #${buttonId} {
                background-color: transparent;
                color: ${color};
                border: 5px solid ${color};
              }
            }`
          : ""
      }
      `
    }
  }

  getColorScale(choices) {
    const [answerMin, answerMax] = d3.extent(choices, opt => opt.value)
    return rescaleDomain(
      scaleFromObject("linear", Settings.RATING_COLOR_SCALE),
      [answerMin, answerMax]
    )
  }

  renderButton({
    buttonId,
    selected,
    buttonColor,
    text,
    readOnly,
    orientation,
    value,
    textAlign
  }) {
    return (
      <div
        key={buttonId}
        id={buttonId}
        disabled={readOnly}
        type="button"
        className={cx(
          "MultipleChoice--button flex one",
          `MultipleChoice--button-${orientation}`
        )}
        onClick={() => this.handleSelectAnswer(value)}
      >
        <style
          dangerouslySetInnerHTML={this.getButtonCSSContent(
            selected,
            buttonId,
            buttonColor,
            orientation
          )}
        />
        {orientation === "vertical" && !readOnly && (
          <div
            className={`MultipleChoice--button-icon ${
              selected ? "selected" : ""
            }`}
          >
            <div className="MultipleChoice--button-icon-dot">
              <Icon iconName="empty" className="empty" />
              <Icon iconName="full" className="full" />
            </div>
          </div>
        )}
        <div
          className={`MultipleChoice--button-content layout vertical ${textAlign}`}
        >
          {text}
        </div>
      </div>
    )
  }

  render() {
    const {
      className,
      question,
      orientation,
      htmlId,
      index,
      readOnly,
      hideAsterisk,
      disableColor,
      selectedAnswerValue,
      answerOptionsMapper,
      textAlign,
      hideQuestion,
      automationId
    } = this.props
    const { can_share: canShare, title, description } = question
    const choices = answerOptionsMapper(question)
    const colorScale = this.getColorScale(choices)

    const shareableIndicator = canShare && !hideAsterisk ? "*" : ""
    const requiredIndicator = do {
      if (!question.required && question.required !== undefined) {
        ;<FormattedMessage {...strings.customizedQuestions.optionalIndicator} />
      }
    }

    return (
      <div
        className={`MultipleChoice ${className}`}
        ref={ref => (this.container = ref)}
        data-automation-id={automationId}
      >
        <div className="MultipleChoice--question-container layout horizontal">
          {index && (
            <div className="MultipleChoice--question-number flex none">
              {index + ". "}
            </div>
          )}
          {!hideQuestion && (
            <div className="MultipleChoice--question layout vertical flex one">
              <div className="MultipleChoice--question-title">
                <MarkdownText text={title} inline={true} /> {shareableIndicator}
                <span className="MultipleChoice--question-optional">
                  {" "}
                  {requiredIndicator}
                </span>
              </div>
              {description && (
                <div>
                  <MarkdownText
                    className="MultipleChoice--question-description"
                    text={description}
                    inline={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className={`layout ${orientation}`}>
          {choices.map(({ title: text, description, value }) => {
            const buttonId = `${htmlId}-${value}`
            const selected = selectedAnswerValue === value
            const buttonColor = disableColor
              ? Settings.COLORS.TEXT_COLOR_SECONDARY
              : colorScale(value)
            const button = this.renderButton({
              buttonId,
              selected,
              buttonColor,
              text,
              readOnly,
              value,
              orientation,
              textAlign
            })
            if (description) {
              return (
                <Tooltip
                  className="MultipleChoice--tooltip"
                  key={`tooltip-${buttonId}`}
                  placement="top"
                  overlay={() => (
                    <div
                      className="MultipleChoice--description layout vertical
                        center-items"
                    >
                      {description}
                    </div>
                  )}
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                >
                  {button}
                </Tooltip>
              )
            } else {
              return button
            }
          })}
        </div>
      </div>
    )
  }
}
