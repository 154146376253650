import { Text, MenuItem, MenuList } from "@kaizen/component-library"
import { Location } from "history"
import React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import { Tooltip } from "@kaizen/component-library/draft"
import SplitButton from "../../elements/SplitButton/SplitButton"
import Tabs from "../Tabs/Tabs"
import styles from "./GoalsList.scss"
import strings from "../../../locale/strings"
import useCurrentUser from "../../../hooks/useCurrentUser"
import { hasConfigOption } from "../../../state/CurrentUser"
import { isUserHRBP } from "../../../util/users"
import useTeams from "../../../hooks/useTeams"
import ConfigurationOptions from "../../../constants/configurationOptions"

type GoalsList = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
  intl: InjectedIntl
}>

const GOAL_TYPES = {
  PERSONAL: {
    type: "personal",
    createLabel: strings.goalsPage.goalType.individual.createGoalButton,
    tabLabel: strings.goalsPage.goalType.individual.tabLabel,
    path: "/new_goals/personal",
    createGoalPath: "/new_goals/personal/new"
  },
  TEAM: {
    type: "team",
    createLabel: strings.goalsPage.goalType.team.createGoalButton,
    tabLabel: strings.goalsPage.goalType.team.tabLabel,
    path: "/new_goals/team",
    createGoalPath: "/new_goals/team/new"
  },
  DEPARTMENT: {
    type: "department",
    createLabel: strings.goalsPage.goalType.department.createGoalButton,
    tabLabel: strings.goalsPage.goalType.department.tabLabel,
    path: "/new_goals/department",
    createGoalPath: "/new_goals/department/new"
  },
  COMPANY: {
    type: "company",
    createLabel: strings.goalsPage.goalType.company.createGoalButton,
    tabLabel: strings.goalsPage.goalType.company.tabLabel,
    path: "/new_goals/company",
    createGoalPath: "/new_goals/company/new"
  }
}

const GoalsList: GoalsList = ({ children, router, location, intl }) => {
  const { formatMessage } = intl
  const currentUser = useCurrentUser()
  const { teams } = useTeams({ all_teams: false })
  const handleCreateButtonClick = (path: string, type?: string) => {
    router.push(path)
  }

  const teamGoalCreationEnabled = teams.length > 0

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>
          <Text tag="h2" style="zen-heading-2">
            {formatMessage(strings.goalsPage.header)}
          </Text>
          <SplitButton
            variant="primary"
            data-automation-id="goals-create-personal-goal"
            label={formatMessage(GOAL_TYPES.PERSONAL.createLabel)}
            dropdownContent={
              <MenuList>
                {hasConfigOption(
                  currentUser,
                  ConfigurationOptions.teamGoals
                ) && (
                  <MenuItem
                    action={() => {
                      teamGoalCreationEnabled &&
                        handleCreateButtonClick(
                          GOAL_TYPES.TEAM.createGoalPath,
                          GOAL_TYPES.TEAM.type
                        )
                    }}
                  >
                    {teamGoalCreationEnabled ? (
                      formatMessage(GOAL_TYPES.TEAM.createLabel)
                    ) : (
                      <Tooltip
                        text={formatMessage(strings.goalsPage.noTeamsCreated)}
                        position="below"
                      >
                        {formatMessage(GOAL_TYPES.TEAM.createLabel)}
                      </Tooltip>
                    )}
                  </MenuItem>
                )}
                {(currentUser.isAdmin ||
                  isUserHRBP(currentUser) ||
                  currentUser.managedDepartments.length > 0) && (
                  <MenuItem
                    action={() => {
                      handleCreateButtonClick(
                        GOAL_TYPES.DEPARTMENT.createGoalPath,
                        GOAL_TYPES.DEPARTMENT.type
                      )
                    }}
                  >
                    {formatMessage(GOAL_TYPES.DEPARTMENT.createLabel)}
                  </MenuItem>
                )}
                {currentUser.isAdmin && (
                  <MenuItem
                    action={() => {
                      handleCreateButtonClick(
                        GOAL_TYPES.COMPANY.createGoalPath,
                        GOAL_TYPES.COMPANY.type
                      )
                    }}
                  >
                    {formatMessage(GOAL_TYPES.COMPANY.createLabel)}
                  </MenuItem>
                )}
              </MenuList>
            }
            onClick={() => {
              handleCreateButtonClick(GOAL_TYPES.PERSONAL.createGoalPath)
            }}
          />
        </div>
        <Tabs
          tabs={[
            {
              label: formatMessage(GOAL_TYPES.PERSONAL.tabLabel),
              href: GOAL_TYPES.PERSONAL.path,
              active: location.pathname.match(/\/new_goals\/personal/) !== null
            },

            hasConfigOption(currentUser, ConfigurationOptions.teamGoals) && {
              label: formatMessage(GOAL_TYPES.TEAM.tabLabel),
              href: GOAL_TYPES.TEAM.path,
              active: location.pathname.match(/\/new_goals\/team/) !== null
            },
            {
              label: formatMessage(GOAL_TYPES.DEPARTMENT.tabLabel),
              href: "new_goals/department",
              active:
                location.pathname.match(/\/new_goals\/department/) !== null
            },
            {
              label: formatMessage(GOAL_TYPES.COMPANY.tabLabel),
              href: "new_goals/company",
              active: location.pathname.match(/\/new_goals\/company/) !== null
            }
          ]}
        />
      </div>
      {children}
    </div>
  )
}

export default injectIntl(GoalsList)
