import { JsonDecoder } from "ts.data.json"
import { SurveyItem, SurveyItemChoice } from "../../state/SurveyState"
const { object, failover, string, number, array, boolean } = JsonDecoder

const surveyItemChoiceDecoder = object<SurveyItemChoice>(
  {
    value: number,
    title: string,
    description: failover(undefined, string)
  },
  "surveyItemChoice decoder"
)

export const individualSurveyItemDecoder = object<SurveyItem>(
  {
    answer: failover(undefined, string),
    choice: failover(undefined, number),
    choices: failover(
      undefined,
      array(surveyItemChoiceDecoder, "surveyItemDecoder choices")
    ),
    comments: failover([], array(string, "surveyItemDecoder comments")),
    createdAt: failover(undefined, string),
    deletedAt: failover(undefined, string),
    discussionPrompt: failover(undefined, string),
    editedAt: failover(undefined, string),
    id: number,
    idx: number,
    questionDescription: failover(undefined, string),
    questionTitle: string,
    questionType: string,
    required: boolean,
    shareable: failover(undefined, boolean),
    state: string,
    stateChangedAt: failover(undefined, string),
    updatedAt: failover(undefined, string)
  },
  "individual_survey_item_decoder",
  {
    createdAt: "created_at",
    deletedAt: "deleted_at",
    discussionPrompt: "discussion_prompt",
    editedAt: "edited_at",
    questionDescription: "question_description",
    questionTitle: "question_title",
    questionType: "question_type",
    stateChangedAt: "state_changed_at",
    updatedAt: "updated_at"
  }
)

export const surveyItemDecoder = object(
  {
    survey_item: individualSurveyItemDecoder
  },
  "survey_item_decoder"
).map<SurveyItem>(res => res.survey_item)

export default surveyItemDecoder
