import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "react-intl"
import _ from "lodash"
import cx from "classnames"
import ExpandIndicator from "../../elements/ExpandIndicator/ExpandIndicator"
import Actions from "../../../actions"
import LeveledConversation from "../conversations/LeveledConversation"
import ProfilePic from "../ProfilePic/ProfilePic"
import SavingIndicator from "../SavingIndicator/SavingIndicator"
import Button from "../../elements/Button/Button"
import TextEditor from "../../elements/TextEditor"
import DraftStore, { DraftSource } from "../../../stores/DraftStore"
import DraftActions from "../../../actions/DraftActions"
import FormattedListSentence from "../../elements/FormattedListSentence/FormattedListSentence"
import strings from "../../../locale/strings"
import connect from "../../decorators/connect"
import { isNonBlank } from "../../../util/strings"
import { debounce } from "../../../decorators/timers"
import Goal from "../../../models/Goal"
import "./GoalConversationSection.less"

class GoalConversationSection extends React.Component {
  static propTypes = {
    sourceObj: PropTypes.object.isRequired,
    lastSavedReplyId: PropTypes.number,
    comments: PropTypes.array
  }

  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      inputMarkdown: "",
      inputVersion: 0
    }
  }

  componentDidMount() {
    const { sourceObj } = this.props
    this.loadReplies()
    DraftActions.get({ parentObjType: "goal", parentObjId: sourceObj.id }).then(
      () => {
        this.initInputFromDraft()
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputMarkdown !== this.state.inputMarkdown) {
      this.handleDraftEditing()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastSavedReplyId !== this.props.lastSavedReplyId) {
      this.setState(state => ({
        inputMarkdown: null,
        inputVersion: state.inputVersion + 1
      }))
    }

    const nextPropsDraft = this.getDraft({ props: nextProps })

    if (!this.getDraft(this) && nextPropsDraft) {
      this.initInputFromDraft({ props: nextProps })
    }
  }

  getDraft({ props } = this) {
    const {
      sourceObj,
      draftData: { draftsBySource }
    } = props
    return draftsBySource.get(
      DraftSource({ parentObjType: "goal", parentObjId: sourceObj.id })
    )
  }

  initInputFromDraft({ props } = this) {
    const body = _.get(this.getDraft({ props }), "body") || ""
    this.setState(state => ({
      inputMarkdown: body,
      inputVersion: state.inputVersion + 1
    }))
  }

  @debounce(2000)
  saveDraft() {
    const { sourceObj } = this.props
    const { inputMarkdown } = this.state
    DraftActions.save({
      parentObjType: "goal",
      parentObjId: sourceObj.id,
      body: inputMarkdown
    })
  }

  loadReplies = (sourceObj = this.props.sourceObj) => {
    Actions.Comments.list({
      sourceObjectId: sourceObj.id,
      type: "goal"
    })
  }

  handleReply = (body, parentComment = undefined) => {
    const { sourceObj } = this.props
    Actions.Comments.comment({
      sourceObject: sourceObj,
      type: "goal",
      isPrivate: false,
      parentId: parentComment ? parentComment.id : undefined,
      parentSourceObject: parentComment ? parentComment : undefined,
      parentType: parentComment ? "comments" : undefined,
      body
    })
  }

  loadUsersForMention = ({ query }) => {
    const { sourceObj } = this.props
    return Actions.Goal.loadUsersForMention({ goalId: sourceObj.id, query })
  }

  handleDraftEditing() {
    const { sourceObj } = this.props
    DraftActions.notifyEditing({
      parentObjType: "goal",
      parentObjId: sourceObj.id
    })
    this.saveDraft()
  }

  handleOnChange = value => {
    this.setState({ inputMarkdown: value })
  }

  handleSubmit = () => {
    const { inputMarkdown } = this.state
    if (isNonBlank(inputMarkdown)) {
      this.handleReply(inputMarkdown)
      this.setState({
        inputMarkdown: "",
        inputVersion: 0
      })
    }
  }

  handleSubmitNestedComment = (body, comment) => {
    this.handleReply(body, comment)
  }

  renderComment = comment => {
    const { sourceObj } = this.props
    const visibleByUser = Goal.isVisibleByUser(
      sourceObj,
      _.get(comment, "author_id")
    )
    const lastSavedReplyId =
      comment.child_comments && comment.child_comments.length
        ? _.get(comment.child_comments[comment.child_comments.length - 1], "id")
        : undefined
    return (
      <LeveledConversation
        canReply={visibleByUser}
        key={`conversation-${comment.id}`}
        nested={false}
        comment={comment}
        onSubmit={this.handleSubmitNestedComment}
        loadUsersForMention={this.loadUsersForMention}
        lastSavedReplyId={lastSavedReplyId}
      />
    )
  }

  renderCollapsableHeader() {
    const { sourceObj } = this.props
    const { expanded } = this.state
    return (
      <div
        className="GoalConversationSection--collapsable-header layout horizontal center"
        onClick={() => this.setState({ expanded: !this.state.expanded })}
      >
        <div className="layout horizontal">
          <div className="layout vertical">
            <div className="GoalConversationSection--collapsable-header-title">
              {sourceObj.visibility === "individuals" ? (
                <FormattedListSentence
                  base={
                    strings.goals.conversations.visibilityHeader[
                      sourceObj.visibility
                    ]
                  }
                  items={sourceObj.access_permissions}
                  options={{
                    itemRenderer: ({ user }) => user.full_name
                  }}
                />
              ) : (
                <FormattedMessage
                  {...strings.goals.conversations.visibilityHeader[
                    sourceObj.visibility
                  ]}
                  values={
                    sourceObj.visibility === "manager"
                      ? { managerName: sourceObj.user.manager_name }
                      : undefined
                  }
                />
              )}
            </div>
            <div className="GoalConversationSection--collapsable-header-subtitle">
              <FormattedMessage
                {...strings.goals.conversations.visibilitySubheader}
              />
            </div>
          </div>
        </div>
        <ExpandIndicator expanded={expanded} />
      </div>
    )
  }

  renderReplyForm() {
    const {
      intl: { formatMessage }
    } = this.props
    const { user } = this.context
    const { inputMarkdown, inputVersion } = this.state
    const { saveState } = this.getDraft() || {}

    const replyRowClasses = cx(
      {
        "GoalConversationSection--reply-action-row-active":
          isNonBlank(inputMarkdown) || saveState === "saving"
      },
      "GoalConversationSection--reply-action-row layout horizontal center end-justified"
    )

    return (
      <div className="GoalConversationSection--reply-form">
        <div className="layout horizontal">
          <ProfilePic className="flex none" user={user} />

          <div className="flex one">
            <TextEditor
              key={inputVersion}
              className="GoalConversationSection--reply-text-editor"
              placeholder={formatMessage(strings.general.commentPlaceholder)}
              initialValue={inputMarkdown}
              onChange={this.handleOnChange}
              hasEmojiPicker={true}
              loadUsersForMention={this.loadUsersForMention}
            />
          </div>
        </div>
        {
          <div className={replyRowClasses}>
            <SavingIndicator saveState={saveState} />
            <Button
              className="GoalConversationSection--send-button"
              actionType="secondary"
              onClick={this.handleSubmit}
            >
              <FormattedMessage {...strings.general.send} />
            </Button>
          </div>
        }
      </div>
    )
  }

  render() {
    const { expanded } = this.state
    const { comments } = this.props
    return (
      <div className="GoalConversationSection">
        {this.renderCollapsableHeader()}

        {expanded && _.map(comments, comment => this.renderComment(comment))}

        {expanded && this.renderReplyForm()}
      </div>
    )
  }
}

export default _.compose(
  connect(DraftStore, "draftData"),
  injectIntl
)(GoalConversationSection)

export { GoalConversationSection as RawGoalConversationSection }
