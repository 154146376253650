import React from "react"
import PropTypes from "prop-types"
import ToggleableProfileContent from "../ToggleableProfileContent/ToggleableProfileContent"
import "./ProfilePanelWrapper.less"

class ProfilePanelWrapper extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    children: PropTypes.node,
    selfReflectionToExcludeId: PropTypes.number,
    onToggle: PropTypes.func,
    savedViewId: PropTypes.number,
    isAdmin: PropTypes.bool
  }

  render() {
    const {
      className,
      children,
      user,
      savedViewId,
      selfReflectionToExcludeId,
      onToggle,
      isAdmin
    } = this.props
    return (
      <div className={`ProfilePanelWrapper layout horizontal ${className}`}>
        <div
          id="profile-content-scroll"
          className="ProfilePanelWrapper--content layout vertical flex"
        >
          {children}
        </div>
        {user && (
          <ToggleableProfileContent
            className="flex none"
            user={user}
            savedViewId={savedViewId}
            selfReflectionToExcludeId={selfReflectionToExcludeId}
            hideActionButtons={true}
            onToggle={onToggle}
            panelStyle={true}
            isAdmin={isAdmin}
          />
        )}
      </div>
    )
  }
}

export default ProfilePanelWrapper
