import { useCallback, useContext, useEffect } from "react"
import { departmentsDecoder } from "../decoders/DepartmentsDecoder/departmentsDecoder"
import { Department, DepartmentsContext } from "../state/DepartmentsState"
import useDataAPI from "./useDataAPI"

const useDepartments = () => {
  const { state, dispatch } = useContext(DepartmentsContext)
  const { fire, loading, data } = useDataAPI("/departments", "GET")

  /**
   * Sending a big number in per_page prop is not the ideal. We are doing this for get all departments.
   * We will work on a solution for search departments by name when creating a department goal
   */

  useEffect(() => {
    fire({ per_page: 1000000000 })
  }, [fire])

  useEffect(() => {
    if (data) {
      departmentsDecoder
        .decodePromise(data)
        .then(response => {
          dispatch({
            type: "FETCH_ALL_DEPARTMENTS",
            payload: response
          })
        })
        .catch(error => {
          throw error
        })
    }
  }, [data, dispatch])

  const filteredIds = state.allDepartments
  const fetchMore = useCallback(() => {
    const nextPage = filteredIds.nextPage
    if (nextPage) {
      fire({ page: nextPage })
    }
  }, [filteredIds.nextPage, fire])

  const departments: Department[] = Object.values(filteredIds.pages)
    .reduce((res, departments) => [...res, ...departments], [])
    .map(goalId => state.departmentsById[goalId] as Department)

  const hasMore = filteredIds.totalCount > departments.length

  return {
    hasMore,
    fetchMore,
    departments,
    loading: loading
  }
}

export default useDepartments
