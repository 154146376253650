import React from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import ProfileItemVisibilityIndicator from "../ProfileItemVisibilityIndicator/ProfileItemVisibilityIndicator"
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"
import strings from "../../../locale/strings"
import "./FeedbackItemTitle.less"

export default class FeedbackItemTitle extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static defaultProps = {
    showProfileItemVisibilityIndicator: true,
    showDate: true
  }

  static propTypes = {
    review: PropTypes.object.isRequired,
    reviewer: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    typeText: PropTypes.node,
    getTooltipContainer: PropTypes.func,
    showProfileItemVisibilityIndicator: PropTypes.bool,
    showDate: PropTypes.bool //This is a hack until we campificate skill's review feedback.
  }

  render() {
    const {
      review,
      reviewer,
      type,
      typeText,
      getTooltipContainer,
      showProfileItemVisibilityIndicator,
      showDate
    } = this.props
    const reviewee = review.reviewee || review.subject || review.user

    return (
      <div className="FeedbackItemTitle layout horizontal center">
        <div className="left-align">
          {showDate && (
            <div className="FeedbackItemTitle--date">
              <ZugataFormattedDate
                value={review.completed_at || review.created_at}
                month="short"
              />
            </div>
          )}
          {do {
            if (reviewer) {
              ;<div className="FeedbackItemTitle--reviewer-name">
                {reviewer.full_name}
              </div>
            } else {
              ;<i className="FeedbackItemTitle--reviewer-name">
                <FormattedMessage {...strings.user.deleted} />
              </i>
            }
          }}
        </div>
        {typeText && <div className="FeedbackItemTitle--type">{typeText}</div>}

        {showProfileItemVisibilityIndicator && (
          <ProfileItemVisibilityIndicator
            subjectUser={reviewee}
            itemType={type}
            getTooltipContainer={getTooltipContainer}
          />
        )}
      </div>
    )
  }
}
