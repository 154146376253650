import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/component-library/draft"
import * as React from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../../locale/strings"
import styles from "./SuccessConfirmationModal.scss"

type SuccessConfirmationModal = React.FunctionComponent<{
  onLeavePage: () => void
  onRequestTbf: () => void
  intl: InjectedIntl
}>

const SuccessConfirmationModal: SuccessConfirmationModal = ({
  onLeavePage,
  onRequestTbf,
  intl
}) => {
  const { formatMessage } = intl

  return (
    <ConfirmationModal
      isOpen
      type="positive"
      title={formatMessage(strings.teamLeadRequestFeedback.successModal.title)}
      confirmLabel={formatMessage(
        strings.teamLeadRequestFeedback.successModal.anotherRequest
      )}
      dismissLabel={formatMessage(
        strings.teamLeadRequestFeedback.successModal.back
      )}
      onDismiss={() => onLeavePage()}
      onConfirm={() => onRequestTbf()}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {formatMessage(strings.teamLeadRequestFeedback.successModal.content)}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(SuccessConfirmationModal)
