import React from "react"
import cx from "classnames"
// @ts-ignore
import InputField from "../../elements/InputField/InputField"
import InputClearButton from "../../elements/InputClearButton/InputClearButton"
import "./EditableAnswerChoice.less"
import Aid from "../../../constants/automationId"

type Props = {
  className?: string
  title?: string
  description?: string | null
  valueIndicator?: React.ReactNode
  placeholders?: {
    title?: string
    desc?: string
  }
  showRemove?: boolean
  showDescription?: boolean
  containerRef?: React.RefObject<HTMLDivElement>
  onChangeTitle: (title: string) => void
  onChangeDescription: (description: string) => void
  onRemove: () => void
}

const EditableAnswerChoice = ({
  className = "",
  title,
  description,
  valueIndicator,
  placeholders: { title: titlePlaceholder, desc: descriptionPlaceholder } = {},
  showDescription = false,
  showRemove = true,
  containerRef,
  onChangeTitle,
  onChangeDescription,
  onRemove
}: Props) => (
  <div
    ref={containerRef}
    className={cx("EditableAnswerChoice layout horizontal", className, {
      "EditableAnswerChoice--with-description": showDescription
    })}
    data-automation-id={Aid.editableAnswerChoice}
  >
    {valueIndicator &&
      // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
      React.cloneElement(valueIndicator, {
        className: cx(
          "EditableAnswerChoice--value-indicator flex none",
          // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
          valueIndicator.props.className
        )
      })}

    <div className="EditableAnswerChoice--content layout vertical flex one">
      <InputField
        className="EditableAnswerChoice--title-field"
        value={title || ""}
        placeholder={titlePlaceholder}
        borderlessStyle={true}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeTitle(e.target.value)
        }}
      />

      {showDescription && (
        <InputField
          className="EditableAnswerChoice--description-field"
          value={description || ""}
          placeholder={descriptionPlaceholder}
          borderlessStyle={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeDescription(e.target.value)
          }}
        />
      )}
    </div>
    {showRemove && (
      <InputClearButton
        className="flex none"
        onClick={onRemove}
        icon="remove_circle_outline"
      />
    )}
  </div>
)

export default EditableAnswerChoice
