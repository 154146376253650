import React, { useEffect, useRef } from "react"
import useScroll from "../../../hooks/useScroll"

type ScrollContainer = React.FunctionComponent<{
  onScrolledToBottom: () => void
}>

const ScrollContainer: ScrollContainer = ({ onScrolledToBottom, children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const scrolledToBottom = useScroll(ref.current)

  useEffect(() => {
    if (scrolledToBottom) onScrolledToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom])

  return <div ref={ref}>{children}</div>
}

export default ScrollContainer
