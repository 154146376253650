import { Button, Text } from "@kaizen/component-library"
import { TextField, AsyncSelect, Select } from "@kaizen/component-library/draft"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import cx from "classnames"
import * as React from "react"
import { useState, useEffect, useRef } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { debounce } from "lodash"
import strings from "../../../locale/strings"
import { User } from "../../../types/User"
import GoalUnsavedChangesModal from "../GoalUnsavedChangesModal/GoalUnsavedChangesModal"
import KeyResultList from "../KeyResultList/KeyResultList"
import GoalOwnerSelect from "../../elements/GoalOwnerSelect/GoalOwnerSelect"
import styles from "./TeamGoalForm.scss"
import { Team } from "../../../state/TeamsState"
import PrioritySelector from "../../elements/PrioritySelector/PrioritySelector"
import DatePicker from "../DatePicker/DatePicker"
import AlignableGoal from "../AlignableGoals/AlignableGoals"
import useTeams from "../../../hooks/useTeams"
import useTeamsAsync from "../../../hooks/useTeamsAsync"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"
import { KeyResult } from "../KeyResultList/KeyResultListItem"

import {
  trackTeamGoalNameAdded,
  trackTeamGoalDescriptionAdded,
  trackTeamGoalOwnersAdded,
  trackKeyResultsAdded,
  trackTeamGoalAligned
} from "../../../lib/analytics/events"

// we set this ourselves for UI sake - the real max is 65535
const SQL_TEXT_MAX_LENGTH = 1000
const { teamGoalForm } = strings
type Priority = 0 | 1 | 2

export type Visibility = "everyone" | "team_only"

export interface TeamGoalFields {
  name: string
  description: string
  teamId: string
  dueDate: Date
  priority: Priority
  visibility?: Visibility
  owners: User[]
  keyResults: KeyResult[]
  alignedGoalIds: number[]
  initialTeam?: Option
}

type TeamGoalForm = React.FunctionComponent<{
  title: React.ReactNode
  initialTeam?: Option
  disabled: boolean
  submitLabel: string
  goalId?: string
  defaultFields: TeamGoalFields
  onSubmit: (fields: TeamGoalFields) => void
  onLeavePage: () => void
  intl: InjectedIntl
  initOwners: User[]
}>

type Option = {
  value: string
  label: string
}

type TextFieldValidation = {
  status: string
  validationMessage?: string
}

const TeamGoalForm: TeamGoalForm = ({
  title,
  disabled,
  goalId,
  submitLabel,
  defaultFields,
  initialTeam,
  initOwners,
  onSubmit,
  onLeavePage,
  intl
}) => {
  const { formatMessage } = intl
  const visibilityLabels = {
    everyone: strings.teamGoals.visibility.everyone,
    team_only: strings.teamGoals.visibility.teamOnly
  }

  const { teams: initialTeams } = useTeams({ all_teams: false, q: "" })
  const { fetchTeams } = useTeamsAsync()

  const [fields, setFields] = useState<TeamGoalFields>(defaultFields)
  const [selectedTeam, setSelectedTeam] = useState<Option | undefined>(
    initialTeam
  )

  const [selectedVisibility, setSelectedVisibility] = useState<
    Option | undefined
  >(
    defaultFields.visibility
      ? {
          value: defaultFields.visibility,
          label: formatMessage(visibilityLabels[defaultFields.visibility])
        }
      : undefined
  )

  const [nameTextField, setNameTextField] = useState<TextFieldValidation>({
    status: "default",
    validationMessage: undefined
  })
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [descriptionTextField, setDescriptionTextField] = useState<
    TextFieldValidation
  >({
    status: "default",
    validationMessage: undefined
  })

  const { name, description, keyResults, alignedGoalIds, owners } = fields

  const keys = Object.keys(fields) as Array<keyof typeof fields>
  // isDiff returns true if there are unsaved changes on the form
  const isDiff = keys.some(
    key => JSON.stringify(fields[key]) !== JSON.stringify(defaultFields[key])
  )

  const teamOptions = (teams?: Team[]) =>
    teams
      ? teams.map(team => ({
          value: team.id,
          label: team.name
        }))
      : []

  // this is to be used to set a default value
  const updateSelect = (value: Option) => {
    setSelectedTeam(value)
    setFields(fields => ({
      ...fields,
      // @ts-ignore
      teamId: value.value,
      owners: initOwners
    }))
  }

  // Fire immediate use of useEffect for initTeam
  // but then push off any other effects for the next 5s.
  // This prevents the deletion of an owner being overriden
  // when initTeam fires again during inital component mounting.
  const throttleSelectedTeam = useRef(
    debounce(
      (initTeam: Option) => {
        updateSelect(initTeam)
      },
      5000,
      { leading: true, trailing: false }
    )
  )
  useEffect(() => {
    if (!disabled && initialTeam) {
      throttleSelectedTeam.current(initialTeam)
    }
  }, [initialTeam, disabled])

  const updateVisibility = (value: Option) => {
    setSelectedVisibility(value)
    setFields(fields => ({
      ...fields,
      visibility: value.value as Visibility
    }))
  }

  const handleUnsavedChanges = React.useCallback(() => {
    setShowUnsavedChangesModal(true)
  }, [])

  const addKeyResult = () => {
    setFields({
      ...fields,
      keyResults: [...fields.keyResults, newKeyResult()]
    })
    trackKeyResultsAdded()
  }

  const removeKeyResult = (id: string) => {
    const keyResults = fields.keyResults.filter(kr => kr.id !== id)
    setFields({
      ...fields,
      keyResults
    })
  }

  const updateKeyResult = (id: string, title: string) => {
    const keyResults = fields.keyResults.map(kr =>
      id === kr.id ? { ...kr, title } : kr
    )
    setFields({
      ...fields,
      keyResults
    })
  }

  // validations
  const hasInvalidNameStringLen = (value: string) =>
    !value || value.trim().length === 0 || value.length > SQL_TEXT_MAX_LENGTH
  const hasInvalidDescriptionStringLen = (value: string) =>
    value.length > SQL_TEXT_MAX_LENGTH
  const validateGoalInputLength = (
    isInvalid: boolean,
    validationMessage: string,
    setValidationField: (obj: TextFieldValidation) => void
  ) => {
    if (isInvalid) {
      setValidationField({
        status: "error",
        validationMessage: validationMessage
      })
    } else {
      setValidationField({
        status: "default",
        validationMessage: undefined
      })
    }
  }

  // check the required fields and ensure they store valid values
  const isFormDisabled =
    !selectedTeam ||
    !selectedVisibility ||
    hasInvalidNameStringLen(name) ||
    !fields.dueDate

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadOptions = (input: string, callback: any) => {
    fetchTeams({ q: input }).then(teams => callback(teamOptions(teams)))
  }

  const debouncedLoadOptions = debounce(loadOptions, 300)

  return (
    <div className={styles.container}>
      <Button
        secondary
        label={formatMessage(strings.general.back)}
        icon={leftIcon}
        onClick={onLeavePage}
      />
      <Text tag="h1" style="zen-heading-2">
        {title}
      </Text>
      {showUnsavedChangesModal && (
        <GoalUnsavedChangesModal
          onLeavePage={onLeavePage}
          onCancel={() => setShowUnsavedChangesModal(false)}
        />
      )}
      {/* including "search" in the id stops Lastpass icon from appearing in field */}
      <form id="search-form">
        <div className={cx(styles.labelText)}>
          <Text tag="h3" style="label">
            {formatMessage(strings.teamGoalForm.teamListLabel)}
          </Text>
        </div>
        <div className={styles.section}>
          <AsyncSelect
            value={selectedTeam}
            defaultOptions={teamOptions(initialTeams)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loadOptions={(inputValue: string, callback: any) =>
              debouncedLoadOptions(inputValue, callback)
            }
            // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
            onChange={updateSelect}
            placeholder={formatMessage(teamGoalForm.teamListPlaceholder)}
            isDisabled={initialTeam ? true : false}
            noOptionsMessage={() =>
              formatMessage(teamGoalForm.noOptionsMessage)
            }
          />
        </div>
        <TextField
          id="name"
          inputValue={name}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            const invalidNameLength = value.length > SQL_TEXT_MAX_LENGTH
            validateGoalInputLength(
              invalidNameLength,
              formatMessage(teamGoalForm.nameField.error.validationMessage),
              setNameTextField
            )
            setFields(fields => ({
              ...fields,
              name: (evt.target as HTMLInputElement).value
            }))
          }}
          onBlur={() => trackTeamGoalNameAdded()}
          type="text"
          labelText={formatMessage(teamGoalForm.nameField.labelText)}
          placeholder={formatMessage(teamGoalForm.nameField.placeholder)}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={nameTextField.status}
          validationMessage={nameTextField.validationMessage}
          required
          disabled={disabled}
        />
        <TextField
          id="description"
          inputValue={description}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            validateGoalInputLength(
              hasInvalidDescriptionStringLen(value),
              formatMessage(
                teamGoalForm.descriptionField.error.validationMessage
              ),
              setDescriptionTextField
            )
            setFields(fields => ({
              ...fields,
              description: (evt.target as HTMLInputElement).value
            }))
          }}
          onBlur={() =>
            trackTeamGoalDescriptionAdded({
              team_goal_description: fields.description
            })
          }
          type="text"
          labelText={formatMessage(teamGoalForm.descriptionField.labelText)}
          placeholder={formatMessage(teamGoalForm.descriptionField.placeholder)}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={descriptionTextField.status}
          validationMessage={descriptionTextField.validationMessage}
          required
          disabled={disabled}
        />

        <div className={styles.section}>
          <KeyResultList
            title={formatMessage(strings.teamGoalForm.keyResultsListTitle)}
            keyResults={fields.keyResults}
            addKeyResult={addKeyResult}
            removeKeyResult={removeKeyResult}
            updateKeyResult={updateKeyResult}
          />
        </div>

        <div className={styles.section}>
          <GoalOwnerSelect
            setFields={(owners: User[]) => {
              setFields({
                ...fields,
                owners
              })
            }}
            onUserAdded={(userAdded: User) => {
              trackTeamGoalOwnersAdded({
                added_user_id: userAdded.aggregateId
              })
            }}
            owners={owners}
            title={formatMessage(strings.teamGoalForm.goalOwnerTitle)}
            disabled={disabled ? true : !selectedTeam ? true : false}
            placeholder={formatMessage(
              strings.teamGoalForm.goalOwnerPlaceholder
            )}
            teamId={selectedTeam ? selectedTeam.value : ""}
          />
        </div>

        <div className={styles.row}>
          <div
            className={cx(styles.field, styles.small, styles.priorityContainer)}
          >
            <PrioritySelector
              onChange={evt => {
                evt.persist()
                setFields(fields => ({
                  ...fields,
                  // @ts-ignore
                  priority: parseInt(evt.target.value) as Priority
                }))
              }}
              selectedValue={fields.priority}
            />
          </div>
          <div className={cx(styles.field, styles.small)}>
            <div className={cx(styles.labelText)}>
              <Text tag="h3" style="label">
                {formatMessage(strings.teamGoalForm.dueDateLabel)}
              </Text>
            </div>
            <DatePicker
              id={"new-team-goal-date-picker"}
              initialDate={[fields.dueDate]}
              onChange={([dueDate]) => {
                setFields(fields => ({
                  ...fields,
                  dueDate
                }))
              }}
            />
          </div>
          <div
            className={cx(styles.field, styles.small)}
            data-testid="visibility"
          >
            <div className={cx(styles.labelText)}>
              <Text tag="h3" style="label">
                {formatMessage(strings.teamGoalForm.visibilityLabel)}
              </Text>
            </div>
            <Select
              id="visibility"
              key="visibility"
              value={selectedVisibility}
              placeholder={formatMessage(
                strings.teamGoals.visibility.placeholder
              )}
              options={[
                {
                  value: "everyone",
                  label: formatMessage(strings.teamGoals.visibility.everyone)
                },
                {
                  value: "team_only",
                  label: formatMessage(strings.teamGoals.visibility.teamOnly)
                }
              ]}
              // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
              onChange={updateVisibility}
              isSearchable={false}
              disabled={disabled}
            />
          </div>
        </div>
        <AlignableGoal
          currentGoalId={goalId ? Number(goalId) : undefined}
          alignedGoalIds={alignedGoalIds}
          onAdd={id => {
            setFields(fields => ({
              ...fields,
              alignedGoalIds: [...fields.alignedGoalIds, id]
            }))
            trackTeamGoalAligned({ goals_aligned_id: id })
          }}
          onRemove={id => {
            setFields(fields => ({
              ...fields,
              alignedGoalIds: fields.alignedGoalIds.filter(
                goalId => id !== goalId
              )
            }))
          }}
          disabled={disabled}
        />
        <div className={styles.actions}>
          <div className={styles.action}>
            <Button
              disabled={disabled}
              automationId="cancel-team-goal"
              secondary
              label={formatMessage(strings.teamGoalForm.cancelButtonLabel)}
              onClick={isDiff ? handleUnsavedChanges : onLeavePage}
            />
          </div>
          <div className={styles.action}>
            <Button
              automationId="create-team-goal"
              disabled={isFormDisabled}
              primary
              label={submitLabel}
              onClick={() => {
                onSubmit({
                  ...fields,
                  keyResults: keyResults.filter(({ title }) => title.length > 0)
                })
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(TeamGoalForm)
