import _ from "lodash"
import momentTimezone from "moment-timezone"
import BaseModel from "./BaseModel"
import User from "./User"
// @ts-ignore
import SurveyTemplate from "./SurveyTemplate"
// @ts-ignore
import lazy from "../decorators/lazy"
import { Audiences, Survey as RawSurvey, Template } from "../../types/Survey"
import { UserRaw } from "../../types/User"
import { SurveyKind } from "../constants/survey"

export default class Survey extends BaseModel implements RawSurvey {
  id: number
  kind: SurveyKind
  name: string | null
  description: string | null
  creator_id: number
  creator_name: string
  start_date: string | null
  end_date: string | null
  time_zone: string | null
  state: "ready" | "created" | "active" | "closed" | "draft" // There might be others
  state_changed_at: string // Date string, eg. 2019-10-30T05:06:53Z
  created_at: string // Date string
  updated_at: string // Date string
  deleted_at: null | string
  completed_at: null | string
  completion_progress: {
    completed_survey_responses_count: number
    users_count: number
  }
  updating_survey_responses_in_progress: boolean
  templates: Template[]
  // survey_audiences will appear in the `/surveys/:id` api response (eg. for the SR details page), but not the `/surveys` api response (eg. for the SR listing page).
  survey_audiences?: Audiences[]

  static get cycleTypeName() {
    return "Survey"
  }

  constructor(props: RawSurvey) {
    super(props)
    // For some reason, the _.assign in the BaseModel stopped working once this
    // file was converted to typescript.
    _.assign(this, props)

    if (this.start_date) {
      this.start_date = momentTimezone
        .tz(this.start_date, this.time_zone || "")
        .format()
    }
    if (this.end_date) {
      this.end_date = momentTimezone
        .tz(this.end_date, this.time_zone || "")
        .format()
    }

    // Wrap each of the templates using the SurveyTemplate model to convert them
    if (props && props.templates) {
      this.templates = props.templates.map(SurveyTemplate.of)
    }
  }

  @lazy
  get dateRange(): [Date | null, Date | null] {
    // @ts-ignore
    return [this.start_date, this.end_date].map(d => d && new Date(d))
  }

  userCanEditNameAndDate(user: UserRaw) {
    // Any Admin user can edit the survey's name, the date depends on the state, but that
    // is managed at the SF level, which disallows this operation for certain cases.
    return (
      // `created` is the old "draft" state value. We will be removing that soon.
      User.isAdmin(user) &&
      !(this.state === "draft" || this.state === "created")
    )
  }

  userCanEditQuestions(user: UserRaw) {
    return User.isAdmin(user) && ["ready"].includes(this.state)
  }

  userCanEditDraft(user: UserRaw) {
    return (
      // `created` is the old "draft" state value. We will be removing that soon.
      User.isAdmin(user) && (this.state === "draft" || this.state === "created")
    )
  }

  userCanManageAudience(user: UserRaw) {
    return User.isAdmin(user) && ["ready", "active"].includes(this.state)
  }

  userCanDuplicateSurvey(user: UserRaw) {
    return User.isAdmin(user)
  }

  userCanEndSurvey(user: UserRaw) {
    return User.isAdmin(user) && this.state === "active"
  }

  userCanReopen(user: UserRaw) {
    return User.isAdmin(user) && this.state === "closed"
  }

  canDownloadCsv() {
    // `created` is the old "draft" state value. We will be removing that soon.
    return !["created", "ready", "draft"].includes(this.state)
  }

  userCanDelete(user: UserRaw) {
    return User.isAdmin(user)
  }
}
