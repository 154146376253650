import "./GoalsOverview.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Map } from "immutable"
import moment from "moment"
import Settings from "../../../settings"
import strings from "../../../locale/strings"
import User from "../../../models/User"
import Company from "../../../models/Company"
import { GoalSource } from "../../../stores/GoalStore"
import GoalsGrid from "./GoalsGrid"
import CreateOrEditGoalModal from "./CreateOrEditGoalModal"
import ViewGoalModal from "./ViewGoalModal"
import GoalActions from "../../../actions/GoalActions"
import EmptyState from "../EmptyState/EmptyState"
import PersonalGoalsEmptyState from "../PersonalGoalsEmptyState/PersonalGoalsEmptyState"
import emptyTeamGoalsImg from "../../../static/images-2017/drawings/emptystates/goals.png"

const {
  GOALS: { DUE_AT_HOUR }
} = Settings

export default class GoalsOverview extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    createNewGoalText: PropTypes.node,
    className: PropTypes.string,
    goalType: PropTypes.oneOf(["my_goal", "department_goal", "company_goal"])
      .isRequired,
    sourceId: PropTypes.number,
    sourceName: PropTypes.string,
    sourceUser: PropTypes.object,
    goalsBySource: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool,
    statusSortType: PropTypes.oneOf(["dueDate", "priority"]),
    singleColumnMode: PropTypes.bool,
    hideAccomplishedGoals: PropTypes.bool,
    sortingOptions: PropTypes.array,
    onSortingChange: PropTypes.func,
    selectedSortingOption: PropTypes.string,
    targetRange: PropTypes.array,
    emptyState: PropTypes.node
  }

  static defaultProps = {
    className: "",
    statusSortType: "dueDate"
  }

  constructor(props) {
    super(props)

    this.state = {
      goalIdToView: null,
      goalToCreate: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { goalType, sourceId } = this.props
    const { goalIdToView } = this.state
    const { goalIdToView: prevGoalIdToView } = prevState
    if (
      goalType === "my_goal" &&
      sourceId === this.context.user.id &&
      goalIdToView === null &&
      prevGoalIdToView !== null
    ) {
      GoalActions.load({ id: prevGoalIdToView, shouldAddToasts: false })
    }
  }

  getGoals({ props = this.props, sourceId = props.sourceId } = {}) {
    const { goalType, goalsBySource, hideAccomplishedGoals } = props
    const goals = goalsBySource.get(GoalSource({ goalType, sourceId }))

    if (goals && hideAccomplishedGoals) {
      return goals.filter(goal => goal.status !== "accomplished")
    } else {
      return goals
    }
  }

  canCreate({ props = this.props, sourceId = props.sourceId } = {}) {
    const { goalType, readOnly } = props
    const { department_managers: departmentManagers } = this.context.user

    return (
      !readOnly &&
      // Admins can always create goals, including for company goals
      (User.isAdmin(this.context.user) ||
        // Department managers can edit their department's goals
        (goalType === "department_goal" &&
          departmentManagers.some(dm => dm.department_id === sourceId)) ||
        // A user can always create goals for themselves
        goalType === "my_goal")
    )
  }

  sortStatusGoals = goals => {
    const { statusSortType } = this.props

    return goals
      ? statusSortType === "dueDate"
        ? goals.sortBy(g => g.due_at)
        : statusSortType === "priority"
        ? goals.sortBy(g => -g.priority)
        : goals
      : goals
  }

  handleSelectGoal = goalToView => {
    return this.setState({ goalIdToView: goalToView.id })
  }

  handleStartCreate = () => {
    const { user } = this.context
    const { goalType, sourceId } = this.props

    const currentPeriodEnd = Company.getEnclosingGoalPeriod(user.company)[1]
    const dueAt = moment(currentPeriodEnd)
      .hour(DUE_AT_HOUR)
      .startOf("hour")
      .format()

    this.setState({
      goalToCreate: {
        goal_type: goalType, // eslint-disable-line camelcase
        department_id:
          goalType === "department_goal" // eslint-disable-line camelcase
            ? sourceId
            : undefined,
        due_at: dueAt // eslint-disable-line camelcase
      }
    })
  }

  render() {
    const {
      className,
      goalType,
      sourceName,
      sourceUser,
      createNewGoalText,
      readOnly,
      singleColumnMode,
      sortingOptions,
      onSortingChange,
      selectedSortingOption,
      targetRange,
      emptyState
    } = this.props
    const { goalIdToView, goalToCreate } = this.state
    const { user } = this.context
    const goals = this.getGoals()
    const canCreate = this.canCreate()

    if (!goals) {
      return null
    } else if (!goals.size && !canCreate) {
      if (goalType === "my_goal") {
        return (
          emptyState || (
            <PersonalGoalsEmptyState
              viewingOwnGoals={User.equals(user, sourceUser)}
              userName={sourceName}
              email={sourceUser.email}
            />
          )
        )
      } else {
        return (
          emptyState || (
            <EmptyState
              imgSrc={emptyTeamGoalsImg}
              header={
                <FormattedMessage
                  {...strings.goals.empty.collectiveGoalsMessages[goalType]}
                  values={{ name: sourceName }}
                />
              }
            />
          )
        )
      }
    }

    const goalToView = goals.find(goal => goal.id === goalIdToView)

    return (
      <div className={`GoalsOverview layout vertical ${className}`}>
        <GoalsGrid
          className="flex"
          createNewGoalText={createNewGoalText}
          readOnly={!canCreate}
          goals={this.getGoals()}
          sortStatusGoals={this.sortStatusGoals}
          goalType={goalType}
          onSelectGoal={this.handleSelectGoal}
          onStartCreate={this.handleStartCreate}
          singleColumnMode={singleColumnMode}
          sortingOptions={sortingOptions}
          onSortingChange={onSortingChange}
          selectedSortingOption={selectedSortingOption}
        />

        <CreateOrEditGoalModal
          goal={goalToCreate}
          sourceName={sourceName}
          onClose={() => this.setState({ goalToCreate: null })}
          targetRange={targetRange}
        />

        <ViewGoalModal
          goal={goalToView}
          readOnly={readOnly}
          onClose={() => this.setState({ goalIdToView: null })}
        />
      </div>
    )
  }
}
