import _ from "lodash"

export function valueIsFilledIn(value) {
  return (
    _.isDate(value) ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    !_.isEmpty(value)
  )
}

export function allFilled(fields) {
  return fields.every(field => {
    if (Array.isArray(field)) {
      return allFilled(field)
    } else {
      return field.optional || valueIsFilledIn(field.value)
    }
  })
}

export const LOADING_STYLE = {
  opacity: 0.5,
  cursor: "not-allowed",
  pointerEvents: "none"
}

export const FAILED_STYLE = {
  backgroundColor: "rgba(255,0,0,0.05)"
}
