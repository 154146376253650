import "./Select.less"
import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import _ from "lodash"
import cx from "classnames"
import Typeahead from "../Typeahead/Typeahead"
import messageDescriptorType from "../../prop-types/messageDescriptorType"

function getOptionText(option, formatMessage) {
  if (!option) {
    return undefined
  }
  const { text, value } = option

  return text === undefined ? value : text.id ? formatMessage(text) : text
}

const Select = ({
  automationId,
  centeredText,
  className,
  defaultText,
  defaultValue,
  disabled,
  intl,
  loadOptions,
  loadOptionsField,
  onBlur,
  onChange,
  onFocus,
  optionRenderer,
  options,
  placeholder,
  refreshOptionsOnFocus = false,
  value,
  valueRenderer
}) => {
  const computedDefaultValue =
    defaultValue || defaultValue === 0 ? defaultValue : ""

  const typeaheadOptions =
    options &&
    _.compact([
      defaultText ? { value: computedDefaultValue, label: defaultText } : null,
      ..._(options)
        .reject(option => defaultText && option.value === computedDefaultValue)
        .map(option => ({
          value: option.value,
          label: getOptionText(option, intl.formatMessage)
        }))
        .value()
    ])

  const selectClassName = cx(className, {
    "Select--disabled": disabled,
    "Select--centered": centeredText
  })

  const optionProps = {
    options: typeaheadOptions ? typeaheadOptions : undefined,
    loadOptions,
    optionRenderer,
    valueRenderer,
    loadOptionsField
  }

  return (
    <Typeahead
      className={selectClassName} // The 'Select' classname is added by the underlying Typeahead
      {...optionProps}
      value={!!defaultText && !value ? computedDefaultValue : value}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      zugataStyle={true}
      hideArrow={false}
      clearable={false}
      searchable={true}
      refreshOptionsOnFocus={refreshOptionsOnFocus}
      automationId={automationId}
    />
  )
}

Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  defaultText: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, messageDescriptorType])
    }).isRequired
  ),
  loadOptions: PropTypes.func,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  loadOptionsField: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  centeredText: PropTypes.bool,
  placeholder: PropTypes.string
}

Select.defaultProps = {
  className: "",
  disabled: false,
  centeredText: false,
  onChange: _.noop,
  onFocus: _.noop,
  onBlur: _.noop
}

export default injectIntl(Select)
export { Select as RawSelect }
