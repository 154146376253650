import React, { createContext, useContext, useEffect, useState } from "react"
import Loading from "../components/elements/Loading/Loading"
import currentUserDecoder from "../decoders/CurrentUserDecoder/currentUserDecoder"
import useDataAPI from "../hooks/useDataAPI"
import ZugiPointsStore from "../stores/ZugiPointsStore"
import { User } from "../types/User"
import { JobTitle } from "../types/JobTitle"
import ConfigurationOptions from "../constants/configurationOptions"

export interface CurrentUser extends User {
  company: {
    accountId: string
    goalCycle: {
      start: Date
      end: Date
    }
    allowReviews: boolean
    allowTeamBasedFeedback: boolean
    name: string
    id?: number
    allowEveryoneToSeeDepartmentGoas?: boolean
    subdomain: string
  }
  isAdmin: boolean
  customTerms: Record<string, string>
  department?: JobTitle
  jobTitle?: JobTitle
  hrbPartners: JobTitle[]
  managedDepartments: JobTitle[]
  configurationOptions?: ConfigurationOptions[]
}

export function hasConfigOption(
  user: CurrentUser,
  option: ConfigurationOptions
) {
  return Boolean(user.configurationOptions?.includes(option))
}

export const CurrentUserContext = createContext<CurrentUser | null>(null)

export const CurrentUserProvider: React.FunctionComponent = ({ children }) => {
  const currentUser = useContext(CurrentUserContext)
  const [currentUserState, setCurrentUserState] = useState(currentUser)
  const { fire, data } = useDataAPI("/users/me", "GET")
  const { localStorage } = window
  const userLocalStore = localStorage.getItem("UserStore")

  useEffect(() => {
    if (!localStorage.getItem("UserStore") || !ZugiPointsStore.data) {
      fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const userDataFromLocalStorage = () => ({
      user: JSON.parse(userLocalStore as string),
      meta: ZugiPointsStore.data
    })

    currentUserDecoder
      .decodePromise(data || userDataFromLocalStorage())
      .then(setCurrentUserState)
      .catch((error: Error) => {
        throw error
      })
  }, [data, userLocalStore])
  return currentUserState === null ? (
    <Loading />
  ) : (
    <CurrentUserContext.Provider value={currentUserState}>
      {children}
    </CurrentUserContext.Provider>
  )
}
