import React from "react"
import IconCA from "@kaizen/component-library/components/Icon/Icon"
import SVG from "react-inlinesvg"
import "./Icon.less"

import refresh from "@kaizen/component-library/icons/refresh.icon.svg"
import search from "@kaizen/component-library/icons/search.icon.svg"
import close from "@kaizen/component-library/icons/close.icon.svg"
import add from "@kaizen/component-library/icons/add.icon.svg"
import minus from "@kaizen/component-library/icons/minus.icon.svg"
import exportIcon from "@kaizen/component-library/icons/export.icon.svg"
import duplicate from "@kaizen/component-library/icons/duplicate.icon.svg"
import chevronLeft from "@kaizen/component-library/icons/chevron-left.icon.svg"
import chevronDown from "@kaizen/component-library/icons/chevron-down.icon.svg"
import chevronRight from "@kaizen/component-library/icons/chevron-right.icon.svg"
import chevronUp from "@kaizen/component-library/icons/chevron-up.icon.svg"
import user from "@kaizen/component-library/icons/user.icon.svg"
import leaderboard from "@kaizen/component-library/icons/leaderboard.icon.svg"
import support from "@kaizen/component-library/icons/support.icon.svg"
import logOut from "@kaizen/component-library/icons/log-out.icon.svg"
import comment from "@kaizen/component-library/icons/comment.icon.svg"
import edit from "@kaizen/component-library/icons/edit.icon.svg"
import sharing from "@kaizen/component-library/icons/report-sharing.icon.svg"
import skip from "@kaizen/component-library/icons/skip.icon.svg"
import favoriteOn from "@kaizen/component-library/icons/favorite-on.icon.svg"
import favoriteOff from "@kaizen/component-library/icons/favorite-off.icon.svg"
import starOn from "@kaizen/component-library/icons/star-on.icon.svg"
import starOff from "@kaizen/component-library/icons/star-off.icon.svg"
import listView from "@kaizen/component-library/icons/list-view.icon.svg"
import trash from "@kaizen/component-library/icons/trash.icon.svg"
import dateRange from "@kaizen/component-library/icons/date-range.icon.svg"
import question from "@kaizen/component-library/icons/question.icon.svg"
import users from "@kaizen/component-library/icons/users.icon.svg"
import clear from "@kaizen/component-library/icons/clear.icon.svg"
import redo from "@kaizen/component-library/icons/redo.icon.svg"
import check from "@kaizen/component-library/icons/check.icon.svg"
import flagOn from "@kaizen/component-library/icons/flag-on.icon.svg"
import flagOff from "@kaizen/component-library/icons/flag-off.icon.svg"
import visible from "@kaizen/component-library/icons/visible.icon.svg"
import information from "@kaizen/component-library/icons/information.icon.svg"
//import warningYuzu from '../../static/images-2017/icons/CA-Icons/warning-yuzu.icon.svg';
import faceVerySatisfied from "@kaizen/component-library/icons/face-very-satisfied.icon.svg"
//import factors from '../../static/images-2017/icons/CA-Icons/factors.icon.svg';
import closed from "@kaizen/component-library/icons/closed.icon.svg"
import draft from "@kaizen/component-library/icons/draft.icon.svg"
import exclamationOctagon from "@kaizen/component-library/icons/exclamation-octagon.icon.svg"
import exclamation from "@kaizen/component-library/icons/exclamation.icon.svg"
import empty from "@kaizen/component-library/icons/empty.icon.svg"
import full from "@kaizen/component-library/icons/full.icon.svg"
import arrowForward from "@kaizen/component-library/icons/arrow-forward.icon.svg"
import arrowBackward from "@kaizen/component-library/icons/arrow-backward.icon.svg"
import arrowUp from "@kaizen/component-library/icons/arrow-up.icon.svg"
import arrowDown from "@kaizen/component-library/icons/arrow-down.icon.svg"
import hamburger from "@kaizen/component-library/icons/hamburger.icon.svg"
import success from "@kaizen/component-library/icons/success.icon.svg"
import ellipsis from "@kaizen/component-library/icons/ellipsis.icon.svg"
import sortDescending from "@kaizen/component-library/icons/sort-descending.icon.svg"
import sortAscending from "@kaizen/component-library/icons/sort-ascending.icon.svg"
import spinner from "@kaizen/component-library/icons/spinner.icon.svg"
import feedbackReview from "@kaizen/component-library/icons/feedback-review.icon.svg"
import processManager from "@kaizen/component-library/icons/process-manager.icon.svg"
import camera from "@kaizen/component-library/icons/camera.icon.svg"
import communications from "../../../static/images-2017/icons/CA-Icons/communications.icon.svg"

const icons: { [key: string]: React.SVGAttributes<SVGSymbolElement> } = {
  refresh,
  search,
  close,
  add,
  minus,
  duplicate,
  chevronLeft,
  chevronDown,
  chevronRight,
  chevronUp,
  user,
  support,
  logOut,
  comment,
  edit,
  sharing,
  skip,
  listView,
  trash,
  dateRange,
  question,
  users,
  clear,
  redo,
  check,
  flagOff,
  flagOn,
  visible,
  information,
  faceVerySatisfied,
  closed,
  draft,
  exclamationOctagon,
  exclamation,
  empty,
  full,
  arrowForward,
  arrowBackward,
  arrowUp,
  arrowDown,
  success,
  hamburger,
  ellipsis,
  sortDescending,
  sortAscending,
  export: exportIcon,
  communications,
  favoriteOn,
  favoriteOff,
  starOn,
  starOff,
  leaderboard,
  spinner,
  feedbackReview,
  processManager,
  camera
}

// These are icons not included in kaizen
// and must be loaded differently.
// Icon component can't handle svg files not loaded from the sprite.
const otherIcons: { [key: string]: string } = {
  cultureAmp: "images-2017/CA-logo.svg"
  // favoriteOn,
  // favoriteOff,
  // starOnOcean,
  // starOff,
  // leaderboard,
  // warningYuzu,
  // factors
}

// TO USE JUST IMPORT Icon COMPONENT AND SET THE ICONNAME

// myComponent {
//   render() {
//     return (
//       <Icon iconName="myIconName" />
//     )
//   }
// }

type Props = {
  iconName: string
  style?: object
  className?: string
  inheritSize?: boolean
}

export default class Icon extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { iconName, style, className, inheritSize } = this.props
    let materialClassName = "material-icons"
    if (iconName.startsWith("mdi")) {
      materialClassName = iconName
    }
    if (!icons[iconName]) {
      if (otherIcons[iconName]) {
        return (
          <div className="Icon--container">
            <SVG style={style ? style : undefined} src={otherIcons[iconName]} />
          </div>
        )
      } else {
        return (
          <div className="Icon--container-no-image">
            <i
              className={materialClassName}
              style={{ ...style, fontSize: "20px" }}
            >
              {!iconName.startsWith("mdi") ? iconName : undefined}
            </i>
          </div>
        )
      }
    }
    return (
      <div
        className={`Icon--container ${className}`}
        style={style ? style : undefined}
      >
        <IconCA
          icon={icons[iconName]}
          role="presentation"
          inheritSize={inheritSize ? true : false}
        />
      </div>
    )
  }
}
