import Reflux from "reflux-core"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"
import SearchAgent from "../../actions/lib/SearchAgent"
import { adaptGoalsParams } from "../../actions/lib/goalsHelpers"
import { ADMIN_GOALS_URLS } from "../../api/endpoints.json"
import strings from "../../locale/strings"
import { showCsvConfirmation } from "../../actions/lib/csvExportation"

const {
  ADMIN_GOALS_URL,
  ADMIN_GOALS_STATS_EXPORT_REQUESTS_URL,
  ADMIN_GOAL_EXPORT_REQUEST_URL
} = ADMIN_GOALS_URLS

const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: ADMIN_GOALS_URL,
  resourceName: "goals",
  resourceNameSingle: "goal",
  pageSize: 25
})

const AdminGoalActions = Reflux.createActions({
  load: { asyncResult: true },
  searchGoals: { asyncResult: true },
  pageGoals: { asyncResult: true },
  loadStatsOverview: { asyncResult: true },
  loadDepartmentStats: { asyncResult: true },
  loadTeamStats: { asyncResult: true },
  exportStats: { asyncResult: true },
  exportGoals: { asyncResult: true }
})

AdminGoalActions.load.listenAndPromise(({ id }) =>
  searchAgent.get({ itemId: id })
)

/**
 * @param {string=} query
 * @param {string=} managerEmail
 * @param {number=} jobTitleId
 * @param {number=} departmentId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name | completion
 * @param {string=} sortOrder - asc | desc
 */
AdminGoalActions.searchGoals.listenAndPromise((searchParams = {}) =>
  addLoadingState(
    [true, { light: true }],
    searchAgent.search(adaptGoalsParams(searchParams))
  )
)

/**
 * @param {number} page
 * @param {string=} query
 * @param {string=} managerEmail
 * @param {number=} jobTitleId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name
 * @param {string=} sortOrder - asc | desc
 */
AdminGoalActions.pageGoals.listenAndPromise(searchParams =>
  searchAgent.search(adaptGoalsParams(searchParams))
)

/**
 * @param {Date} startDate - starting date for goals with due_at within that period
 * @param {Date} endDate - end date for goals with due_at within that period
 * @param {Number} departmentId - department id, if undefined, scope to all depts
 */
AdminGoalActions.loadStatsOverview.listenAndPromise(
  ({ startDate, endDate, departmentId }) =>
    addToasts(
      {},
      extractResponseKey(
        "stats",
        agent.get(`${ADMIN_GOALS_URL}/company_stats`).query({
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          parent_job_title_ids: departmentId ? [departmentId] : undefined
        })
      ).then(stats => ({ stats, departmentId }))
    )
)

AdminGoalActions.loadDepartmentStats.listenAndPromise(
  ({ startDate, endDate, sortBy, sortOrder }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {},
        extractResponseKey(
          "stats",
          agent.get(`${ADMIN_GOALS_URL}/department_stats`).query({
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            sort_by: sortBy ? sortBy : undefined,
            sort_order: sortOrder ? sortOrder : undefined
          })
        )
      )
    )
)

/**
 * @param {Date} startDate - starting date for goals with due_at within that period
 * @param {Date} endDate - end date for goals with due_at within that period
 * @param {Number} departmentId - department id, it is required
 */
AdminGoalActions.loadTeamStats.listenAndPromise(
  ({
    startDate,
    endDate,
    departmentId,
    departmentSortBy,
    departmentSortOrder
  }) =>
    addToasts(
      {},
      extractResponseKey(
        "stats",
        agent.get(`${ADMIN_GOALS_URL}/team_stats`).query({
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          parent_job_title_ids: departmentId,
          sort_by: departmentSortBy ? departmentSortBy : undefined,
          sort_order: departmentSortOrder ? departmentSortOrder : undefined
        })
      ).then(stats => ({ stats, departmentId }))
    )
)

AdminGoalActions.exportStats.listenAndPromise(
  ({ departmentId, startDate, endDate }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.adminGoals.stats.exportFailed },
        extractResponseKey(
          "export_request",
          agent.post(ADMIN_GOALS_STATS_EXPORT_REQUESTS_URL).send({
            goal_stat_export_request: {
              options: {
                min_due_at: startDate.toISOString(),
                max_due_at: endDate.toISOString(),
                department_ids: departmentId ? [departmentId] : undefined
              }
            }
          })
        ).then(showCsvConfirmation)
      )
    )
)

AdminGoalActions.exportGoals.listenAndPromise(({ startDate, endDate }) =>
  addLoadingState(
    true,
    addToasts(
      { defaultError: strings.adminGoals.stats.exportFailed },
      extractResponseKey(
        "export_request",
        agent.post(ADMIN_GOAL_EXPORT_REQUEST_URL).send({
          goal_export_request: {
            options: {
              min_due_at: startDate.toISOString(),
              max_due_at: endDate.toISOString()
            }
          }
        })
      ).then(showCsvConfirmation)
    )
  )
)

export default AdminGoalActions
