import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions/index"
import observeSearchActions from "./lib/observeSearchActions"

const INITIAL_DATA = {
  pagination: null,
  notificationSearchResults: null,
  notificationsById: {},
  unseenNotificationsCount: 0,
  searchParams: {},
  taskList: {}
}

const NotificationStore = Reflux.createStore({
  init() {
    this.initializeState()

    observeSearchActions(this, {
      getResults: () => this.data.notificationSearchResults,
      pageAction: Actions.Notification.pageNotifications,
      searchAction: Actions.Notification.searchNotifications,
      clearAction: Actions.Notification.localClear,
      onChange: (notificationSearchResults, notificationSearchState) => {
        if (!notificationSearchResults) {
          // REVIEW: this means the search was cleared with `Actions.Notification.localClear`, which
          // is handled explicitly below
          return
        }

        this.data = {
          ...this.data,
          notificationSearchResults,
          notificationsById: {
            ...this.data.notificationsById,
            ..._.indexBy(notificationSearchResults, "id")
          },
          pagination: notificationSearchState.pagination,
          searchParams: notificationSearchState.searchParams
        }
        this.trigger(this.data)
      }
    })

    this.listenTo(Actions.Notification.localClear, () => {
      const { searchParams } = this.data
      this.data = {
        ...INITIAL_DATA,
        searchParams
      }
      this.trigger(this.data)
    }),
      this.listenTo(
        Actions.Notification.getUnseenCount.completed,
        unseenNotificationsCount => {
          if (this.data.unseenNotificationsCount !== unseenNotificationsCount) {
            this.data = {
              ...this.data,
              unseenNotificationsCount
            }
            this.trigger(this.data)
          }
        }
      )

    this.listenTo(Actions.Notification.markAsSeen, () => {
      if (this.data.unseenNotificationsCount > 0) {
        this.data = {
          ...this.data,
          unseenNotificationsCount: 0
        }
        this.trigger(this.data)
      }
    })

    this.listenTo(Actions.Notification.markAsRead, id => {
      this.updateNotifications(notification =>
        notification.id === id
          ? {
              ...notification,
              read_at: new Date()
            }
          : notification
      )
      this.trigger(this.data)
    })

    this.listenTo(
      Actions.Notification.markAsRead.completed,
      updatedNotification => {
        this.updateNotifications(notification =>
          notification.id === updatedNotification.id
            ? updatedNotification
            : notification
        )

        this.trigger(this.data)
      }
    )

    this.listenTo(
      Actions.Notification.loadNotification.completed,
      notification => {
        const { notificationsById, notificationSearchResults } = this.data

        this.data = {
          ...this.data,
          notificationsById: {
            ...notificationsById,
            [notification.id]: notification
          },
          notificationSearchResults:
            notificationSearchResults &&
            notificationSearchResults.map(n =>
              n.id === notification.id ? notification : n
            )
        }

        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Notification.loadTasks.completed, tasks => {
      this.data = {
        ...this.data,
        taskList: {
          selfReflectionsTask: {
            date: tasks.soonest_date_to_do_self_reflection,
            count: tasks.to_do_self_reflections_count,
            names: tasks.all_to_do_self_reflection_cycle_names,
            cycleId: tasks.incomplete_self_reflection_ids
          },

          evaluationsTask: {
            count: tasks.to_do_performance_cycles_count,
            cycleId: tasks.soonest_to_do_performance_cycle_id,
            date: tasks.soonest_date_to_do_performance_reviews,
            names: tasks.all_to_do_performance_cycle_names
          },

          sharedCalibrationViewsTask: {
            count: tasks.to_do_saved_views_count,
            names: tasks.all_to_do_saved_views_names
          },

          feedbackCount: tasks.to_do_survey_manager_requested_feedback_count,
          teamBasedFeedbackCount: tasks.to_do_survey_team_based_feedback_count,
          blockedGoalsCount: tasks.to_do_blocked_goals_count,
          noGoalsPrompt: tasks.to_do_create_personal_goals
        }
      }

      this.trigger(this.data)
    })
  },

  initializeState() {
    this.data = INITIAL_DATA
  },

  getInitialState() {
    return this.data
  },

  updateNotifications(notificationTransformer) {
    const { notificationSearchResults, notificationsById } = this.data

    this.data = {
      ...this.data,
      notificationSearchResults:
        notificationSearchResults &&
        notificationSearchResults.map(notificationTransformer),
      notificationsById: _.mapValues(notificationsById, notificationTransformer)
    }
  }
})

export default NotificationStore
