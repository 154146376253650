import SessionActions from "../../actions/SessionActions"
import SessionStore from "../../stores/SessionStore"
import Settings from "../../settings"
import SupremeAgent from "../../lib/supremeagent/main"

class SteadyfootAgent {
  constructor() {
    return new SupremeAgent({
      base: Settings.STEADYFOOT_URL,
      refreshFunctor: SessionActions.refresh,

      sessionGetter() {
        const session = SessionStore.get()

        if (!session || session.access_token) {
          return session
        }

        const { pvnId = [] } = session

        // PVN ID is a pair of arrays of UTF-16 code points representing
        // access and refresh token.
        //
        const [accessToken, refreshToken] = pvnId.map(codePoints =>
          String.fromCharCode.apply(null, codePoints)
        )

        return { access_token: accessToken, refresh_token: refreshToken }
      },

      rateLimitInterval: 2000,
      maxRequestsPerRateLimitInterval: 10
    })
  }
}

// Ideally, this would be
//
//    static defaultInstance = new SteadyfootAgent();
//
// inside the class. But currently, the Babel plugin for compiling decorators changes class
// properties such that you can't reference the class being defined in a static class property.
// See https://github.com/loganfsmyth/babel-plugin-transform-decorators-legacy/issues/17.
//
SteadyfootAgent.defaultInstance = new SteadyfootAgent()

export default SteadyfootAgent
