import { Text } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { WithRouterProps } from "react-router"
import strings from "../../../locale/strings"
import styles from "./OutgoingAlignedGoals.scss"
import { Goal } from "../../../types/Goals"
import AlignedGoalsSection from "./AlignedGoalsSection"

interface OutgoingAlignedGoals extends WithRouterProps {
  goal: Goal
  intl: InjectedIntl
}

const OutgoingAlignedGoals = ({
  goal,
  router,
  location,
  intl
}: OutgoingAlignedGoals) => {
  const { formatMessage } = intl

  const getAlignedGoals = (goalType: string) =>
    goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.filter(
          alignedGoal => alignedGoal.goalType === goalType
        )
      : []

  const alignedTeamGoals = getAlignedGoals("team")
  const alignedDepartmentGoals = getAlignedGoals("department")
  const alignedCompanyGoals = getAlignedGoals("company")

  const onGoalClick = (goalId: number) => {
    router.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        goalId: goalId
      }
    })
  }

  return (
    <React.Fragment>
      {goal.type !== "company" && (
        <div
          data-automation-id="goal-alignments"
          className={styles.outgoingAlignmentsContainer}
        >
          <div className={styles.outgoingAlignmentsLabel}>
            <Text tag="h6">
              {formatMessage(
                strings.goalsPage.goalsDossier.goalAlignments.goalAlignmentLabel
              )}
            </Text>
          </div>
          {goal.outgoingAlignedGoals && goal.outgoingAlignedGoals.length > 0 ? (
            <div>
              {alignedTeamGoals.length > 0 && (
                <AlignedGoalsSection
                  alignedGoals={alignedTeamGoals}
                  goalType="team"
                  onGoalClick={onGoalClick}
                />
              )}
              {alignedDepartmentGoals.length > 0 && (
                <AlignedGoalsSection
                  alignedGoals={alignedDepartmentGoals}
                  goalType="department"
                  onGoalClick={onGoalClick}
                />
              )}
              {alignedCompanyGoals.length > 0 && (
                <AlignedGoalsSection
                  alignedGoals={alignedCompanyGoals}
                  goalType="company"
                  onGoalClick={onGoalClick}
                />
              )}
            </div>
          ) : (
            <Text tag="label" style="default-style">
              {formatMessage(
                strings.goalsPage.goalsDossier.goalAlignments.emptyState
              )}
            </Text>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default injectIntl(OutgoingAlignedGoals)
