import React from "react"
import { FormattedDate } from "react-intl"

const ZugataFormattedDate = props => <FormattedDate {...props} />

ZugataFormattedDate.propTypes = {
  ...FormattedDate.propTypes
}

ZugataFormattedDate.defaultProps = {
  month: "long",
  day: "numeric",
  year: "numeric"
}

export default ZugataFormattedDate
