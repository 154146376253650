import React, { MutableRefObject, Suspense } from "react"
// @ts-ignore
import Loader from "../components/widgets/Loader/Loader"

// Takes in a dynamic module import. While that chunk/module is getting fetched,
// the <Loader /> component will show.
// example usage:
//  lazyLoader(() => import("./components/MyComponent"))
// Code adapted from the react docs:
//   https://reactjs.org/docs/code-splitting.html#reactlazy
export const lazyLoader = (
  module: () => Promise<{ default: React.ComponentType }>
) => {
  const LazyComponent = React.lazy(module)

  // forwardedRef - gives the ability to add a ref to the lazy loaded component.
  // I tried to do this with the React.forwardRef function instead, but it
  // was not working with React.lazy. https://github.com/zeit/next.js/issues/4957
  const LazyLoader = ({
    forwardedRef,
    ...rest
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardedRef: (ref: any) => void | MutableRefObject<object>
  }) => {
    return (
      <Suspense fallback={<Loader />}>
        <LazyComponent {...rest} ref={forwardedRef} />
      </Suspense>
    )
  }

  return LazyLoader
}
