import { useContext, useEffect } from "react"
import { getTeamGoalsResponseDecoder } from "../decoders/GetTeamGoalsResponseDecoder/getTeamGoalsResponseDecoder"
import { GoalsContext } from "../state/GoalsState"
import { TeamSummariesContext } from "../state/TeamSummariesState"
import { TeamGoalPreview } from "../types/Goals"
import useDataAPI from "./useDataAPI"

type Filters = {
  from?: Date
  to?: Date
}

const useTeamGoals = (teamId: string, filters: Filters = {}) => {
  const { state: goals, dispatch } = useContext(GoalsContext)
  const { dispatch: teamsDispatch, state: teamSummaries } = useContext(
    TeamSummariesContext
  )
  const fetchAPI = useDataAPI(`goals/team_goals_index?team_id=${teamId}`, "GET")

  const fetch = () => {
    fetchAPI.fire({ ...filters })
  }

  useEffect(() => {
    if (fetchAPI.data) {
      getTeamGoalsResponseDecoder
        .decodePromise(fetchAPI.data)
        .then(({ goals, overallProgress }) => {
          dispatch({ type: "FETCH_TEAM", payload: { teamId, goals } })
          teamsDispatch({
            type: "UPDATE_TEAM_OVERALL_PROGRESS",
            payload: {
              teamId,
              progress: overallProgress
            }
          })
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAPI.data])

  const teamGoalIds = goals.goalsIdsByTeamId[teamId] || []
  const teamGoals = teamGoalIds.reduce<TeamGoalPreview[]>(
    (teamGoals, goalId) => {
      const goalPreview = goals.goalsPreviewsById[goalId] as TeamGoalPreview
      if (goalPreview) {
        return [...teamGoals, goalPreview]
      }
      return teamGoals
    },
    []
  )

  const teamSummary = teamSummaries.teamSummariesById[teamId]

  return {
    fetch,
    teamGoals,
    goalsCount: teamSummary?.goalsCount || teamGoals.length,
    loading: fetchAPI.loading
  }
}

export default useTeamGoals
