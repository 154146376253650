import { Button, Text } from "@kaizen/component-library"
import { TextField, Select } from "@kaizen/component-library/draft"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import cx from "classnames"
import * as React from "react"
import { useState } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import KeyResultList from "../KeyResultList/KeyResultList"
import { KeyResult } from "../KeyResultList/KeyResultListItem"
import PrioritySelector from "../../elements/PrioritySelector/PrioritySelector"
import DatePicker from "../DatePicker/DatePicker"
import AlignableGoal from "../AlignableGoals/AlignableGoals"
import GoalUnsavedChangesModal from "../GoalUnsavedChangesModal/GoalUnsavedChangesModal"
import UserCard from "../UserCard/UserCard"
import UserSearch from "../UserSearch/UserSearch"
import strings from "../../../locale/strings"
import { User } from "../../../types/User"
import styles from "./PersonalGoalForm.scss"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"
import {
  trackIndividualGoalNameAdded,
  trackIndividualGoalDescriptionAdded,
  trackKeyResultsAdded,
  trackIndividualGoalAligned
} from "../../../lib/analytics/events"
import Aid from "../../../constants/automationId"

// we set this ourselves for UI sake - the real max is 65535
const SQL_TEXT_MAX_LENGTH = 1000
const { individualGoalForm } = strings

type Priority = 0 | 1 | 2
type Visibility = "everyone" | "owner" | "individuals" | "manager"

export interface PersonalGoalFields {
  name: string
  description: string
  dueDate: Date
  priority: Priority
  visibility?: Visibility
  keyResults: KeyResult[]
  alignedGoalIds: number[]
  viewers: User[]
}

type PersonalGoalForm = React.FunctionComponent<{
  title: React.ReactNode
  disabled: boolean
  submitLabel: string
  defaultFields: PersonalGoalFields
  onSubmit: (fields: PersonalGoalFields) => void
  onCancel: () => void
  goalId?: string
  intl: InjectedIntl
}>

type TextFieldValidation = {
  status: string
  validationMessage?: string
}

type Option = {
  value: string
  label: string
}

const PersonalGoalForm: PersonalGoalForm = ({
  title,
  disabled,
  submitLabel,
  goalId,
  defaultFields,
  onCancel,
  onSubmit,
  intl
}) => {
  const { formatMessage } = intl

  const [fields, setFields] = useState<PersonalGoalFields>(defaultFields)
  const [nameTextField, setNameTextField] = useState<TextFieldValidation>({
    status: "default",
    validationMessage: undefined
  })
  const [selectedVisibility, setSelectedVisibility] = useState<
    Option | undefined
  >(
    defaultFields.visibility
      ? {
          value: defaultFields.visibility,
          label: formatMessage(
            strings.goals.visibility[defaultFields.visibility]
          )
        }
      : undefined
  )
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [descriptionTextField, setDescriptionTextField] = useState<
    TextFieldValidation
  >({
    status: "default",
    validationMessage: undefined
  })

  const {
    name,
    description,
    alignedGoalIds,
    keyResults,
    visibility,
    viewers
  } = fields

  const keys = Object.keys(fields) as Array<keyof typeof fields>
  // isDiff returns true if there are unsaved changes on the form
  const isDiff = keys.some(
    key => JSON.stringify(fields[key]) !== JSON.stringify(defaultFields[key])
  )

  // validations
  const hasInvalidNameStringLen = (value: string) =>
    !value || value.trim().length === 0 || value.length > SQL_TEXT_MAX_LENGTH
  const hasInvalidDescriptionStringLen = (value: string) =>
    value.length > SQL_TEXT_MAX_LENGTH
  const validateGoalInputLength = (
    isInvalid: boolean,
    validationMessage: string,
    setValidationField: (obj: TextFieldValidation) => void
  ) => {
    if (isInvalid) {
      setValidationField({
        status: "error",
        validationMessage: validationMessage
      })
    } else {
      setValidationField({
        status: "default",
        validationMessage: undefined
      })
    }
  }

  // check the required fields and ensure they store valid values
  const isFormDisabled =
    !selectedVisibility ||
    hasInvalidNameStringLen(name) ||
    (fields.visibility === "individuals" &&
      fields.viewers &&
      fields.viewers.length === 0) ||
    !fields.dueDate

  const handleUnsavedChanges = React.useCallback(() => {
    setShowUnsavedChangesModal(true)
  }, [])

  const addKeyResult = () => {
    setFields({
      ...fields,
      keyResults: [...fields.keyResults, newKeyResult()]
    })
    trackKeyResultsAdded()
  }

  const removeKeyResult = (id: string) => {
    const keyResults = fields.keyResults.filter(kr => kr.id !== id)
    setFields({
      ...fields,
      keyResults: keyResults
    })
  }

  const updateKeyResult = (id: string, title: string) => {
    const keyResults = fields.keyResults.map(kr =>
      id === kr.id ? { ...kr, title } : kr
    )
    setFields({
      ...fields,
      keyResults
    })
  }

  const updateVisibility = (value: Option) => {
    setSelectedVisibility(value)
    setFields(fields => ({
      ...fields,
      visibility: value.value as Visibility
    }))
  }

  return (
    <div className={styles.container}>
      <Button
        secondary
        label={formatMessage(strings.general.back)}
        icon={leftIcon}
        onClick={onCancel}
      />
      <Text tag="h1" style="zen-heading-2">
        {title}
      </Text>
      {showUnsavedChangesModal && (
        <GoalUnsavedChangesModal
          onLeavePage={onCancel}
          onCancel={() => setShowUnsavedChangesModal(false)}
        />
      )}
      {/* including "search" in the id stops Lastpass icon from appearing in field */}
      <form id="search-form">
        <TextField
          id={Aid.goalNameFieldInput}
          inputValue={name}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            const invalidNameLength = value.length > SQL_TEXT_MAX_LENGTH
            validateGoalInputLength(
              invalidNameLength,
              formatMessage(
                individualGoalForm.nameField.error.validationMessage
              ),
              setNameTextField
            )
            setFields(fields => ({
              ...fields,
              name: (evt.target as HTMLInputElement).value
            }))
          }}
          onBlur={() => trackIndividualGoalNameAdded()}
          type="text"
          labelText={formatMessage(individualGoalForm.nameField.labelText)}
          placeholder={formatMessage(individualGoalForm.nameField.placeholder)}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={nameTextField.status}
          validationMessage={nameTextField.validationMessage}
          required
          disabled={disabled}
        />

        <TextField
          id={Aid.goalDescriptionFieldInput}
          inputValue={description}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            validateGoalInputLength(
              hasInvalidDescriptionStringLen(value),
              formatMessage(
                individualGoalForm.descriptionField.error.validationMessage
              ),
              setDescriptionTextField
            )
            setFields(fields => ({
              ...fields,
              description: (evt.target as HTMLInputElement).value
            }))
          }}
          onBlur={() => trackIndividualGoalDescriptionAdded()}
          type="text"
          labelText={formatMessage(
            individualGoalForm.descriptionField.labelText
          )}
          placeholder={formatMessage(
            individualGoalForm.descriptionField.placeholder
          )}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={descriptionTextField.status}
          validationMessage={descriptionTextField.validationMessage}
          required
          disabled={disabled}
        />

        <div className={styles.section}>
          <KeyResultList
            title={formatMessage(strings.teamGoalForm.keyResultsListTitle)} //Update for Personal goals
            keyResults={fields.keyResults}
            addKeyResult={addKeyResult}
            removeKeyResult={removeKeyResult}
            updateKeyResult={updateKeyResult}
          />
        </div>

        <div className={styles.row}>
          <div
            className={cx(styles.field, styles.small, styles.priorityContainer)}
          >
            <PrioritySelector
              onChange={evt => {
                evt.persist()
                setFields(fields => ({
                  ...fields,
                  // @ts-ignore
                  priority: parseInt(evt.target.value) as Priority
                }))
              }}
              selectedValue={fields.priority}
            />
          </div>
          <div className={cx(styles.field, styles.small)}>
            <div className={cx(styles.labelText)}>
              <Text tag="h3" style="label">
                {formatMessage(strings.teamGoalForm.dueDateLabel)}
              </Text>
            </div>
            <DatePicker
              id={Aid.goalDatePickerFieldInput}
              initialDate={[fields.dueDate]}
              onChange={([dueDate]) => {
                setFields(fields => ({
                  ...fields,
                  dueDate
                }))
              }}
            />
          </div>
          <div
            className={cx(styles.field, styles.small)}
            data-testid="visibility"
          >
            <div className={cx(styles.labelText)}>
              <Text tag="h3" style="label">
                {formatMessage(strings.teamGoalForm.visibilityLabel)}
              </Text>
            </div>
            <Select
              id="visibility"
              key="visibility"
              value={selectedVisibility}
              placeholder={formatMessage(
                individualGoalForm.visibilityField.placeholder
              )}
              options={[
                {
                  value: "everyone",
                  label: formatMessage(strings.goals.visibility.everyone)
                },
                {
                  value: "manager",
                  label: formatMessage(strings.goals.visibility.manager)
                },
                {
                  value: "owner",
                  label: formatMessage(strings.goals.visibility.owner)
                },
                {
                  value: "individuals",
                  label: formatMessage(strings.goals.visibility.individuals)
                }
              ]}
              // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
              onChange={updateVisibility}
              isSearchable={false}
              disabled={disabled}
            />
          </div>
        </div>
        {visibility === "individuals" && (
          <React.Fragment>
            <UserSearch
              includeCurrentUser={false}
              id="goalViewersSearch"
              labelText=""
              onUserSelect={user => {
                setFields(fields => ({
                  ...fields,
                  viewers: [...fields.viewers, user]
                }))
              }}
              disabled={disabled}
              filter={user => {
                const isSelected = viewers
                  .map(u => u.aggregateId)
                  .includes(user.aggregateId)
                return !isSelected
              }}
            />
            <div>
              {viewers.map(user => (
                <UserCard
                  key={user.id}
                  user={user}
                  onRemove={evt => {
                    setFields(fields => ({
                      ...fields,
                      viewers: fields.viewers.filter(o => o.id !== user.id)
                    }))
                  }}
                />
              ))}
            </div>
          </React.Fragment>
        )}
        <AlignableGoal
          currentGoalId={goalId ? Number(goalId) : undefined}
          alignedGoalIds={alignedGoalIds}
          onAdd={id => {
            setFields(fields => ({
              ...fields,
              alignedGoalIds: [...fields.alignedGoalIds, id]
            }))
            trackIndividualGoalAligned({ goals_aligned_id: id })
          }}
          onRemove={id => {
            setFields(fields => ({
              ...fields,
              alignedGoalIds: fields.alignedGoalIds.filter(
                goalId => id !== goalId
              )
            }))
          }}
          disabled={disabled}
        />
        <div className={styles.actions}>
          <div className={styles.action}>
            <Button
              disabled={disabled}
              automationId={Aid.cancelIndividualGoalButton}
              secondary
              label={formatMessage(strings.teamGoalForm.cancelButtonLabel)}
              onClick={isDiff ? handleUnsavedChanges : onCancel}
            />
          </div>
          <div className={styles.action}>
            <Button
              automationId={Aid.createIndividualGoalButton}
              disabled={isFormDisabled}
              primary
              label={submitLabel}
              onClick={() => {
                onSubmit({
                  ...fields,
                  viewers:
                    fields.visibility === "individuals" ? fields.viewers : [],
                  keyResults: keyResults.filter(({ title }) => title.length > 0)
                })
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(PersonalGoalForm)
