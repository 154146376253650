import React from "react"
import cx from "classnames"
import Breadcrumb from "../../elements/Breadcrumb/Breadcrumb"
import { isMobileBrowser } from "../../../util/browser"
import Button, { ButtonActionType } from "../../elements/Button/Button"

import "./BreadcrumbAndMenuHeader.less"

type Props = {
  breadcrumb?: {
    to: string | object
    query?: object
    text: React.ReactNode
  }
  headerText?: React.ReactNode
  subheaderText?: React.ReactNode
  hasMenu?: boolean
  actionButton?: {
    name?: string
    text: string
    icon?: React.SVGAttributes<SVGSymbolElement>
    automationId?: string
    handleClick: () => void
  }
  actionButtonType?: ButtonActionType
  leftAligned?: boolean
}

export default class BreadcrumbAndMenuHeader extends React.Component<Props> {
  static defaultProps = {
    actionButtonType: "secondary",
    hasMenu: false
  }

  renderText() {
    const { headerText, subheaderText, leftAligned } = this.props
    return (
      (headerText || subheaderText) && (
        <div
          className={cx("BreadcrumbAndMenuHeader--header", {
            "BreadcrumbAndMenuHeader--header-leftAligned": leftAligned
          })}
        >
          {headerText && (
            <h2 className="BreadcrumbAndMenuHeader--header-text">
              {headerText}
            </h2>
          )}
          {subheaderText && (
            <h4 className="BreadcrumbAndMenuHeader--subheader-text">
              {subheaderText}
            </h4>
          )}
        </div>
      )
    )
  }

  renderMenu() {
    const { hasMenu, actionButton, children } = this.props
    if (hasMenu) {
      return (
        <div className="layout horizontal end-justified flex one">
          {children}
        </div>
      )
    } else if (!actionButton) {
      return <div className="flex one" />
    }
    return null
  }

  render() {
    const {
      breadcrumb,
      actionButton,
      actionButtonType,
      leftAligned
    } = this.props

    return (
      <div className="BreadcrumbAndMenuHeader">
        <div className="layout horizontal">
          {breadcrumb && !isMobileBrowser() ? (
            <Breadcrumb
              className="flex one"
              to={breadcrumb.to}
              query={breadcrumb.query}
            >
              {breadcrumb.text}
            </Breadcrumb>
          ) : leftAligned ? (
            undefined
          ) : (
            <div className="flex one" />
          )}

          {this.renderText()}
          {this.renderMenu()}

          {actionButton && (
            <div
              key={actionButton.name}
              className={
                "BreadcrumbAndMenuHeader--action-button layout horizontal   end-justified flex one"
              }
            >
              {
                <Button
                  // There is a defaultProp of "secondary", so this assertion is fine
                  actionType={actionButtonType as ButtonActionType}
                  iconPosition={"end"}
                  icon={actionButton.icon}
                  onClick={() => actionButton.handleClick()}
                  automationId={actionButton.automationId}
                >
                  {actionButton.text}
                </Button>
              }
            </div>
          )}
        </div>
      </div>
    )
  }
}
