import { useContext, useEffect, useCallback } from "react"
import { getDepartmentGoalsResponseDecoder } from "../decoders/GetDepartmentGoalsResponseDecoder/getDepartmentGoalsResponseDecoder"
import { GoalsContext } from "../state/GoalsState"
import { DepartmentSummariesContext } from "../state/DepartmentSummariesState"
import { DepartmentGoalPreview } from "../types/Goals"
import useDataAPI from "./useDataAPI"

interface Filters {
  from?: Date
  to?: Date
}

const useDepartmentGoals = (departmentId?: number, filters?: Filters) => {
  const { state: goals, dispatch } = useContext(GoalsContext)
  const {
    dispatch: departmentsDispatch,
    state: departmentSummaries
  } = useContext(DepartmentSummariesContext)
  const fetchAPI = useDataAPI(
    `goals/departments_goals_index?department_id=${departmentId}`,
    "GET"
  )
  const { fire } = fetchAPI
  const fetch = useCallback(() => {
    if (departmentId) {
      fire({ ...filters })
    }
  }, [departmentId, filters, fire])

  useEffect(() => {
    if (fetchAPI.data && departmentId) {
      getDepartmentGoalsResponseDecoder
        .decodePromise(fetchAPI.data)
        .then(({ goals, overallProgress }) => {
          dispatch({
            type: "FETCH_DEPARTMENT",
            payload: { departmentId: departmentId, goals }
          })
          departmentsDispatch({
            type: "UPDATE_DEPARTMENT_OVERALL_PROGRESS",
            payload: {
              departmentId: departmentId,
              progress: overallProgress
            }
          })
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
          throw error
        })
    }
  }, [departmentId, departmentsDispatch, dispatch, fetchAPI.data])

  const departmentGoalIds =
    (departmentId && goals.goalsIdsByDepartmentId[departmentId]) || []
  const departmentGoals = departmentGoalIds.reduce<DepartmentGoalPreview[]>(
    (departmentGoals, goalId) => {
      const goalPreview = goals.goalsPreviewsById[
        goalId
      ] as DepartmentGoalPreview
      if (goalPreview) {
        return [...departmentGoals, goalPreview]
      }
      return departmentGoals
    },
    []
  )

  const departmentSummary =
    departmentId && departmentSummaries.departmentSummariesById[departmentId]

  return {
    fetch,
    departmentGoals,
    goalsCount:
      (departmentSummary && departmentSummary.goalsCount) ||
      departmentGoals.length,
    loading: fetchAPI.loading
  }
}

export default useDepartmentGoals
