import Reflux from "reflux-core"
import _ from "lodash"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import strings from "../locale/strings"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "./lib/apiActionHelpers"
import { GENERAL_URLS, SKILLS_URLS } from "../api/endpoints.json"

const { SUMMARY_SHARES_URL } = GENERAL_URLS
const { SKILL_SCORES_URL } = SKILLS_URLS
const agent = SteadyfootAgent.defaultInstance

const UserSummaryActions = Reflux.createActions({
  getCategories: { asyncResult: true },
  getCompanyCategories: { asyncResult: true },

  getAllSkills: { asyncResult: true },

  getSkillsBySkillDisplay: { asyncResult: true, children: ["forceLoad"] },
  getCompanySkillsBySkillDisplay: {
    asyncResult: true,
    children: ["forceLoad"]
  },
  getAllCategoryProgress: { asyncResult: true },
  getCompanyCategoryProgress: { asyncResult: true, children: ["forceLoad"] },

  getReviewersBySkill: { asyncResult: true },

  localClear: {},

  setCategoryIndex: {},
  nextCategory: {},
  previousCategory: {},

  shareSummary: { asyncResult: true }
})

UserSummaryActions.getCategories.listenAndPromise(
  ({ summaryShareToken = null } = {}) =>
    addLoadingState(
      true,
      getScoredSkills({ summaryShareToken, skillType: "category" })
    )
)

UserSummaryActions.getCompanyCategories.listenAndPromise(
  ({ summaryShareToken = null } = {}) =>
    addLoadingState(
      true,
      getScoredSkills({
        summaryShareToken,
        forCompany: true,
        skillType: "category"
      })
    )
)

UserSummaryActions.getAllSkills.listenAndPromise(
  ({ summaryShareToken = null } = {}) =>
    addLoadingState(
      true,
      getScoredSkills({ summaryShareToken, skillType: "attribute" })
    )
)

UserSummaryActions.getSkillsBySkillDisplay.listenAndPromise(
  ({ parentSkillId }) =>
    getScoredSkills({
      parentSkillId,
      forCompany: false,
      order: "review_completed_at",
      skillType: "attribute"
    })
)

UserSummaryActions.getCompanySkillsBySkillDisplay.listenAndPromise(
  ({ parentSkillId }) =>
    getScoredSkills({
      parentSkillId,
      forCompany: true,
      order: "review_completed_at",
      skillType: "attribute"
    })
)

UserSummaryActions.getReviewersBySkill.listenAndPromise(skillId =>
  extractResponseBody(
    agent.get(`${SKILL_SCORES_URL}/named_reviewers`).query({
      skill_id: skillId,
      per_page: 5,
      include_deactivated: true
    })
  ).then(result => ({ skillId, result }))
)

UserSummaryActions.getAllCategoryProgress.listenAndPromise(
  ({ startDate, endDate, summaryShareToken = null } = {}) =>
    addLoadingState(
      true,
      getSkillScores({ startDate, endDate, summaryShareToken })
    )
)

UserSummaryActions.getCompanyCategoryProgress.listenAndPromise(
  ({ startDate, endDate, categoryId }) =>
    getSkillScores({
      forCompany: true,
      categoryId,
      startDate,
      endDate
    }).then(result => ({ categoryId, categoryProgress: result }))
)

UserSummaryActions.shareSummary.listenAndPromise(
  ({ userIds, startDate, endDate }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {
          defaultError: strings.summary.genericShareError,
          // TODO add names (as i14ed noun conjunction)
          success: strings.summary.shareSuccess
        },
        agent.post(SUMMARY_SHARES_URL).send({
          summary_share: {
            after_date: startDate,
            before_date: endDate,
            summary_share_permissions_attributes: userIds.map(userId => ({
              shared_with_user_id: userId
            }))
          }
        })
      )
    )
)

function getSkillScores({
  forCompany = false,
  startDate,
  endDate,
  categoryId,
  summaryShareToken
} = {}) {
  return extractResponseKey(
    "skill_scores",
    agent.get(`${SKILL_SCORES_URL}${forCompany ? "/company" : ""}`).query({
      after_date: startDate ? startDate.toISOString() : undefined,
      before_date: endDate ? endDate.toISOString() : undefined,
      order: "review_completed_at",
      skill_id: categoryId || undefined,
      summary_share_token: summaryShareToken || undefined
    })
  )
}

const formatSkillScoreObjectAsScoredSkills = ({
  skill_id,
  skill_name,
  skill_color,
  review_count,
  ...params
}) => ({
  // Format the skill score objects to look like scored skills (scored
  // skills are basically a skill with a score attribute)
  ...params,
  id: skill_id,
  name: skill_name,
  color: skill_color,
  reviews_count: review_count
})

/**
 * Formats the response of the /skill_scores API to look like the response of the
 * /{my,team}_scored_skills APIs. Team summary is still based on scored_skills, and much
 * of the summary code still depends on that format.
 */
function getScoredSkills({
  forCompany,
  skillType,
  summaryShareToken,
  order,
  parentSkillId
}) {
  return extractResponseKey(
    "skill_scores",
    agent.get(`${SKILL_SCORES_URL}${forCompany ? "/company" : ""}`).query({
      skill_type: skillType || undefined,
      summary_share_token: summaryShareToken || undefined,
      order: order || undefined,
      parent_skill_id: parentSkillId || undefined,

      // SF defaults before_date to 1 month ago, so we need to explicitly set it to
      // the current time if we want the latest data. But for summary shares, we don't
      // want to supply after_date or before_date, because those are supplied by the
      // original share.
      //
      before_date: summaryShareToken ? undefined : new Date().toISOString()
    })
  ).then(skillScores => {
    const latestSkillScoresChain = _(skillScores)
      .groupBy("skill_id")
      .mapValues(scores => _.max(scores, s => new Date(s.review_completed_at)))
      .map(formatSkillScoreObjectAsScoredSkills)
      .sortByOrder(["score"], ["desc"])
      .value()

    if (parentSkillId) {
      return {
        parentSkillId,
        skills: latestSkillScoresChain
      }
    } else {
      return latestSkillScoresChain
    }
  })
}

export default UserSummaryActions
