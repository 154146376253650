import useDataAPI from "./useDataAPI"
import { Goal } from "../types/Goals"
import { CompanyGoalFields } from "../components/widgets/CompanyGoalForm/CompanyGoalForm"
import { updatedKeyResults } from "./utils/goalKeyResults"

const useUpdateCompanyGoal = (goalId: string, companyId: string) => {
  const fetchAPI = useDataAPI(`/goals/${goalId}`, "PUT")
  const updateCompanyGoal = (fields: CompanyGoalFields, goal: Goal) => {
    const keyResults = updatedKeyResults(fields.keyResults, goal.keyResults)

    const removedAlignedGoals = (goal.outgoingAlignmentAssociations || [])
      .filter(
        alignedGoal =>
          !fields.alignedGoalIds.includes(alignedGoal.alignedGoalId)
      )
      .map(alignedGoal => ({
        incoming_alignment_goal_id: Number(alignedGoal.alignedGoalId),
        id: alignedGoal.id,
        _destroy: true
      }))
    const addedAlignedGoals = fields.alignedGoalIds
      .filter(
        alignedGoalId =>
          !(goal.outgoingAlignmentAssociations || [])
            .map(alignedGoal => alignedGoal.alignedGoalId.toString())
            .includes(alignedGoalId.toString())
      )
      .map(id => ({
        incoming_alignment_goal_id: id
      }))
    const goaldata = {
      type: "company_goal",
      company_id: companyId,
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map(owner => owner.aggregateId),
      key_results: keyResults.map(kr => ({
        id: kr.isNew ? undefined : kr.id,
        title: kr.title,
        completion: kr.completion,
        _destroy: kr._destroy
      })),
      outgoing_alignment_associations_attributes: [
        ...removedAlignedGoals,
        ...addedAlignedGoals
      ]
    }
    fetchAPI.fire({ goal: goaldata })
  }
  return {
    updateCompanyGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading
  }
}

export default useUpdateCompanyGoal
