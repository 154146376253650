// Helpers for Goals

/**
 * @param {number} page
 * @param {string=} query
 * @param {string=} managerEmail
 * @param {number=} jobTitleId
 * @param {number=} departmentId
 * @param {string=} status
 * @param {Date|string=} startDate
 * @param {Date|string=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name | completion
 * @param {string=} sortOrder - asc | desc
 */
export function adaptGoalsParams({
  query,
  managerEmail,
  managerId,
  jobTitleId,
  departmentId,
  status,
  startDate,
  endDate,
  groupType,
  sortBy,
  sortOrder = "asc",
  page
}) {
  return {
    searchParams: {
      query,
      manager_email: managerEmail,
      manager_id: managerId,
      job_title_id: jobTitleId,
      department_id: departmentId,
      from:
        startDate && startDate.toISOString
          ? startDate.toISOString()
          : startDate,
      to: endDate && endDate.toISOString ? endDate.toISOString() : endDate,
      sort_by: sortBy,
      sort_order: sortBy ? sortOrder : undefined,
      group_type: groupType ? groupType : undefined,
      status
    },
    page
  }
}
