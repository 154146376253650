import PropTypes from "prop-types"
import React from "react"
import Conversation from "./Conversation"
import ConversationMessage from "./ConversationMessage"
import FeedbackMessage from "./FeedbackMessage"

export default class FeedbackConversation extends React.Component {
  static propTypes = {
    review: PropTypes.object.isRequired,
    replies: PropTypes.array,
    lastSavedReplyId: PropTypes.number,
    inputPlaceholder: PropTypes.string,

    commentActions: PropTypes.shape({
      seen: PropTypes.func
    }),

    draftActions: PropTypes.shape({
      get: PropTypes.func,
      save: PropTypes.func,
      notifyEditing: PropTypes.func
    }),

    updateReactionsAction: PropTypes.func,

    loadingReplies: PropTypes.bool,
    onLike: PropTypes.func
  }

  standardizeSender(sender) {
    /*
     * SF doesn't include an "id" property for reviewers.
     * Instead, they have a "user_id" property. Reviewees
     * have both properties. This takes an object and
     * duplicates its "user_id" property to its "id" property.
     */

    return { ...sender, id: sender.user_id }
  }

  getSender(message) {
    // SF doesn't send the reviewee if this is a message from the reviewer,
    // but it will send both the reviewee and reviewer if this is a message
    // from the reviewee.
    //
    // So the reviewee is the sender if it's available, otherwise the sender
    // must be the reviewer.
    return message.reviewee || this.standardizeSender(message.reviewer)
  }

  render() {
    const {
      review,
      replies,
      loadingReplies,
      onLike,
      updateReactionsAction
    } = this.props
    const {
      id,
      completed_at: completedAt,
      followups_recent: followupsRecent,
      followups_count: followupsCount
    } = review
    const reviewer = this.standardizeSender(review.reviewer)

    const followUpsAsMessages = (replies || followupsRecent)
      .reverse() // SF sends feedback messages in reverse chronological order
      .map(followup => {
        const { id, body, created_at: createdAt } = followup
        return (
          <ConversationMessage
            key={id}
            body={body}
            timestamp={createdAt}
            id={id}
            sender={this.getSender(followup)}
          />
        )
      })

    const feedbackMessage = (
      <FeedbackMessage
        review={review}
        onLike={onLike}
        timestamp={completedAt}
        id={id}
        updateReactionsAction={updateReactionsAction}
        sender={reviewer}
      />
    )

    const messages = [feedbackMessage].concat(followUpsAsMessages)

    return (
      <Conversation
        {...this.props}
        sourceType="review"
        sourceId={id}
        messages={messages}
        loading={loadingReplies}
        hasMoreReplies={followupsCount > messages.length}
      />
    )
  }
}
