import _ from "lodash"
import moment from "moment"

const StatsDateRange = {
  ThirtyDays: "30d",
  ThreeMonths: "3m",
  SixMonths: "6m",
  TwelveMonths: "12m"
}

const momentDiffForDateRange = {
  [StatsDateRange.ThirtyDays]: { days: 30 },
  [StatsDateRange.ThreeMonths]: { months: 3 },
  [StatsDateRange.SixMonths]: { months: 6 },
  [StatsDateRange.TwelveMonths]: { months: 12 }
}

// Use defineProperties to add static methods so they're non-enumerable, thus aren't confused with
// enum values when used with lodash, Object.keys, etc.
Object.defineProperties(StatsDateRange, {
  isDays: {
    value: v => v === StatsDateRange.ThirtyDays
  },
  getNumber: {
    value: v => parseInt(v) // HACK - relies on the string values formatted as '<num><unit>'
  },
  values: {
    value: _.values(StatsDateRange)
  },
  getStartDate: {
    value: (statsDateRange, endDate = new Date()) => {
      const momentDiff = momentDiffForDateRange[statsDateRange]
      return moment(endDate)
        .subtract(momentDiff)
        .toDate()
    }
  }
})

export default StatsDateRange
