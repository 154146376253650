import Reflux from "reflux-core"
import Actions from "../actions"
import BaseSurveyResponseStore from "./BaseSurveyResponseStore"

const SurveyResponseStore = Reflux.createStore({
  ...BaseSurveyResponseStore,

  init() {
    BaseSurveyResponseStore.init.call(this, { actions: Actions.SurveyResponse })

    this.data = {
      ...this.data,
      surveyById: {},
      pastReviewsByUserId: {},
      selfReflectionsByUserId: {}
    }

    this.listenTo(
      Actions.SurveyResponse.getSurveyResponse.completed,
      surveyResponse => {
        this.data = {
          ...this.data,
          surveyById: {
            ...this.data.surveyById,
            [surveyResponse.id]: surveyResponse
          }
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      Actions.SurveyResponse.loadCompletedSelfReflectionByUserId.completed,
      ({ userId, selfReflections }) => {
        this.data = {
          ...this.data,
          selfReflectionsByUserId: {
            ...this.data.selfReflectionsByUserId,
            [userId]: selfReflections
          }
        }
        this.trigger(this.data)
      }
    )
  }
})

export default SurveyResponseStore
