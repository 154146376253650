import _ from "lodash"
import { Map } from "immutable"
import observeSearchActions from "./lib/observeSearchActions"

export default {
  init({ actions: { searchSurveys, pageSurveys, loadSurvey, clearSurveys } }) {
    this.initializeState()

    observeSearchActions(this, {
      getResults: () => this.data.surveySearchResults,
      searchAction: searchSurveys,
      pageAction: pageSurveys,
      clearAction: clearSurveys,
      onChange: (surveySearchResults, surveySearchState) => {
        if (!surveySearchResults) {
          // This means the clearAction was called, and it's handled below in a dedicated listener
          return
        }

        this.data = {
          ...this.data,
          surveySearchResults,
          surveyPagination: _.get(surveySearchState, "pagination", null)
        }

        this.trigger(this.data)
      }
    })

    this.listenTo(clearSurveys, () => {
      this.initializeState()
      this.trigger(this.data)
    })

    this.listenTo(loadSurvey.completed, survey => {
      this.data = {
        ...this.data,
        surveysById: this.data.surveysById.set(survey.id, survey)
      }

      this.trigger(this.data)
    })
  },

  initializeState() {
    this.data = {
      surveySearchResults: null,
      surveyPagination: null,
      surveysById: Map()
    }
  },

  getInitialState() {
    return this.data
  }
}
