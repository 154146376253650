import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { injectIntl } from "react-intl"
import connect from "../../decorators/connect"
import Actions from "../../../actions"
import modalDeprecated from "../../decorators/modalDeprecated"
import FeedbackReplyStore from "../../../stores/FeedbackReplyStore"
import FeedbackStore from "../../../stores/FeedbackStore"
import ZugiPointsStore from "../../../stores/ZugiPointsStore"
import Loader from "../Loader/Loader"
import ConversationModalWrapper from "../conversations/ConversationModalWrapper"
import FeedbackConversation from "../conversations/FeedbackConversation"
import User from "../../../models/User"
import LockedFeedbackWrapper from "../LockedFeedbackWrapper/LockedFeedbackWrapper"
import ReviewNotesComment from "../ReviewNotesComment/ReviewNotesComment"
import strings from "../../../locale/strings"
import { getLikeReaction } from "../../../models/FeedbackReactionTypes"
import Aid from "../../../constants/automationId"
import "./FeedbackModal.less"

class FeedbackModal extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    reviewId: PropTypes.number,
    onClose: PropTypes.func
  }

  componentDidMount() {
    if (this.props.reviewId) {
      this.loadContent()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.reviewId && prevProps.reviewId !== this.props.reviewId) {
      this.loadContent()
    }
  }

  loadContent() {
    Actions.Feedback.loadReview(this.props.reviewId)
      .catch(() => this.props.onClose())
      .then(review => {
        if (!this.userIsThirdParty(review)) {
          Actions.Feedback.loadReplies(review)
        }
      })
  }

  userIsReviewee(review) {
    return review.reviewee.email === this.context.user.email
  }

  userIsThirdParty(review) {
    return !this.userIsReviewee(review) && !this.userIsReviewer(review)
  }

  userIsReviewer(review) {
    return review.reviewer.email === this.context.user.email
  }

  getTitle(review) {
    const {
      intl: { formatMessage }
    } = this.props

    const { reviewer, reviewee, type } = review

    const revieweeName = User.getFirstName(reviewee)
    const reviewerName = this.context.user.company.named_reviewer
      ? User.getFirstName(reviewer)
      : formatMessage(strings.general.someone)

    return do {
      if (this.userIsReviewee(review)) {
        formatMessage(
          type === "praise_review"
            ? strings.praise.gaveYouPraise
            : strings.pastFeedback.gaveYouFeedback,
          { reviewerName }
        )
      } else if (this.userIsReviewer(review)) {
        formatMessage(
          type === "praise_review"
            ? strings.praise.yourPraiseTo
            : strings.pastFeedback.yourFeedbackTo,
          { revieweeName }
        )
      } else if (type === "praise_review") {
        formatMessage(strings.praise.aGavePraiseToB, {
          revieweeName,
          reviewerName
        })
      }
    }
  }

  getPlaceholder(review) {
    const {
      intl: { formatMessage }
    } = this.props
    const revieweeName = User.getFirstName(review.reviewee)
    const reviewerName = this.context.user.company.named_reviewer
      ? User.getFirstName(review.reviewer)
      : ""

    return this.userIsReviewee(review)
      ? formatMessage(strings.pastFeedback.suggestionsToImprove, {
          reviewerName
        })
      : formatMessage(strings.pastFeedback.keepItUp, { revieweeName })
  }

  loadReplies() {
    Actions.Feedback.loadReplies(this.props.reviewData.review)
  }

  handleReply(body) {
    Actions.Feedback.reply(this.props.reviewData.review, body)
  }

  handleLike({ review, updates }) {
    Actions.Feedback.updateReactions({ review, updates })
  }

  handleOpen(review) {
    Actions.Feedback.buy(review, {
      user: this.context.user,
      zugiPoints: this.props.zugiPointsData.zugiPoints
    })
  }

  getReview() {
    const { review } = this.props.reviewData

    return (
      review && {
        ...review,
        notes: <ReviewNotesComment review={review} />
      }
    )
  }

  shouldDisplayLikeButton() {
    const {
      reviewData: { review }
    } = this.props
    return (
      this.userIsReviewee(review) ||
      (review.reactions && getLikeReaction(review.reactions))
    )
  }

  render() {
    const {
      reviewId,
      feedbackReplyData: {
        repliesByReviewId,
        reviewIdsLoadingReplies,
        lastSavedReplyIdByReviewId
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      reviewData: { review: originalReview }
    } = this.props
    const { user } = this.context
    const review = this.getReview()
    const userIsReviewee = review && this.userIsReviewee(review)

    return review && review.id === reviewId ? (
      <div
        className="FeedbackModal--container"
        data-automation-id={Aid.feedbackModal}
      >
        {!canViewReview(this.props, user) && (
          <h2 className="FeedbackModal--locked-header">
            {this.getTitle(review)}
          </h2>
        )}
        <LockedFeedbackWrapper
          review={review}
          onOpen={this.handleOpen.bind(this, review)}
          userIsReviewee={userIsReviewee}
          feedbackContent={
            <ConversationModalWrapper title={this.getTitle(review)}>
              <FeedbackConversation
                review={review}
                inputPlaceholder={this.getPlaceholder(review)}
                onReply={this.handleReply.bind(this)}
                onRepliesNeeded={this.loadReplies.bind(this)}
                onLike={
                  this.shouldDisplayLikeButton()
                    ? this.handleLike.bind(this)
                    : null
                }
                replies={repliesByReviewId[review.id]}
                loading={reviewIdsLoadingReplies[review.id]}
                lastSavedReplyId={lastSavedReplyIdByReviewId[review.id]}
                readOnly={
                  !this.userIsReviewee(review) && !this.userIsReviewer(review)
                }
                updateReactionsAction={Actions.Feedback.updateReactions}
              />
            </ConversationModalWrapper>
          }
        />
      </div>
    ) : reviewId ? (
      <Loader />
    ) : null
  }
}

function canViewReview(props, user) {
  const { review } = props.reviewData
  const userIsReviewee = review && review.reviewee.email === user.email
  return !userIsReviewee || (review && review.rating_summary)
}

export default _.compose(
  connect(FeedbackStore, "reviewData"),
  connect(FeedbackReplyStore, "feedbackReplyData"),
  connect(ZugiPointsStore, "zugiPointsData"),
  modalDeprecated({
    className: (props, user) =>
      !canViewReview(props, user)
        ? "FeedbackModal FeedbackModal--locked"
        : "FeedbackModal",
    opened: props => !!props.reviewId
  }),
  injectIntl
)(FeedbackModal)

export { FeedbackModal as RawFeedbackModal }
