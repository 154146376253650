import "./QuestionsAndAnswersComment.less"
import PropTypes from "prop-types"
import React from "react"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"

export default class QuestionsAndAnswersComment extends React.Component {
  static propTypes = {
    questionsAndAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        answerContent: PropTypes.element.isRequired
      })
    ).isRequired
  }

  render() {
    const { questionsAndAnswers } = this.props

    return (
      <div>
        {questionsAndAnswers.map(({ question, answerContent }, i) => (
          <div key={i} className="QuestionsAndAnswersComment--item">
            <MarkdownText
              className="QuestionsAndAnswersComment--question"
              text={question}
              inline={true}
            />
            {answerContent}
          </div>
        ))}
      </div>
    )
  }
}
