import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import strings from "../../../locale/strings"
import Loading from "../../elements/Loading/Loading"
import useGoalDetails from "../../../hooks/useGoalDetails"
import useNotifications from "../../../hooks/useNotifications"
import { User } from "../../../types/User"
import TeamGoalForm, {
  TeamGoalFields,
  Visibility
} from "../../widgets/TeamGoalForm/TeamGoalForm"
import useUpdateTeamGoal from "../../../hooks/useUpdateTeamGoal"
import { TeamGoal } from "../../../types/Goals"

type EditTeamGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    goalId: string
    teamId: string
  }
}>

const EditTeamGoal: EditTeamGoal = ({ intl, router, params }) => {
  const { goalId, teamId } = params
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const { goal, loading: loadingGoalDetail } = useGoalDetails(Number(goalId))
  const { updateTeamGoal, loading, data, error } = useUpdateTeamGoal(
    goalId,
    teamId
  )

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.editGoalNotifications.successTitle),
        message: formatMessage(
          strings.editGoalNotifications.successNotification
        )
      })
      router.push({
        pathname: `/new_goals/team`,
        query: {
          goalId
        }
      })
    }
  }, [data, formatMessage, goalId, router, showNotification])

  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.editGoalNotifications.errorTitle),
        message: formatMessage(strings.editGoalNotifications.errorNotification)
      })
    }
  }, [error, formatMessage, showNotification])

  if (!goal) return <Loading />
  let priority
  switch (goal.priority) {
    case "low":
      priority = 0
      break
    case "medium":
      priority = 1
      break
    case "high":
      priority = 2
      break
  }

  const initOwners: User[] = (goal as TeamGoal).owners

  const goalFields: TeamGoalFields = {
    name: goal.name,
    teamId: (goal as TeamGoal).teamId,
    description: goal.description ? goal.description : "",
    dueDate: goal.dueDate,
    priority: priority as 0 | 1 | 2,
    visibility: goal.visibility as Visibility,
    keyResults: goal.keyResults,
    alignedGoalIds: goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.map(goal => goal.id)
      : [],
    owners: initOwners
  }

  return (
    <TeamGoalForm
      title={formatMessage(strings.editTeamGoal.title)}
      submitLabel={formatMessage(strings.editTeamGoal.submitLabel)}
      defaultFields={goalFields}
      initialTeam={
        goal
          ? {
              value: (goal as TeamGoal).teamId,
              label: (goal as TeamGoal).teamName
            }
          : undefined
      }
      onSubmit={fields => updateTeamGoal(fields, goal)}
      onLeavePage={() => router.push("/new_goals/team")}
      disabled={loading || loadingGoalDetail}
      initOwners={initOwners}
      goalId={goalId}
    />
  )
}

export default injectIntl(EditTeamGoal)
