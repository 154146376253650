import "./NewGoalLabel.less"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import strings from "../../../locale/strings"

const NewGoalLabel = ({ goal, viewer }) =>
  viewer.id === goal.user_manager_id &&
  !goal.manager_seen_at &&
  moment().diff(goal.created_at, "month") <= 1 && (
    <div className="NewGoalLabel">
      <FormattedMessage {...strings.general.new} />
    </div>
  )

NewGoalLabel.propTypes = {
  goal: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired
}

export default NewGoalLabel
