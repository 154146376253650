import _ from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import cx from "classnames"
import PropTypes from "prop-types"
import React from "react"
import strings from "../../../locale/strings"
import Button from "../../elements/Button/Button"
import { allFilled } from "../../../util/forms"
import SurveyQuestion from "../SurveyQuestion/SurveyQuestion"
import SurveyItem from "../../../models/SurveyItem"
import User from "../../../models/User"
import Checkbox from "../../elements/Checkbox/Checkbox"
import "./SurveyForm.less"
import Aid from "../../../constants/automationId"

class SurveyForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    surveyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    reviewee: PropTypes.object,
    answers: PropTypes.arrayOf(PropTypes.object).isRequired,
    submitButtonText: PropTypes.node,
    onAnswerChange: PropTypes.func,
    onShareWithRevieweeChange: PropTypes.func,
    onSubmit: PropTypes.func,
    preview: PropTypes.bool,
    allowShareWithReviewee: PropTypes.bool,
    shareWithReviewee: PropTypes.bool
  }

  static defaultProps = {
    submitButtonText: (
      <FormattedMessage {...strings.selfReflection.sendToYourManager} />
    ),
    allowShareWithReviewee: false
  }

  handleAnswerChange = ({ debounce }, { answer }, value) => {
    this.props.onAnswerChange({ debounce, content: value, answer })
  }

  handleSubmit = () => {
    this.props.onSubmit()
  }

  getFormFields(answers) {
    const {
      surveyId,
      preview,
      intl: { formatMessage }
    } = this.props
    return (
      answers &&
      answers.map((answer, index) => ({
        component: (
          <SurveyQuestion
            key={answer.id}
            index={index + 1}
            htmlId={`survey-${surveyId}-answer-${answer.id}`}
            question={{
              title: answer.question_content || answer.question_title,
              description: answer.question_description,
              type: answer.question_type || "open-ended",
              choices: answer.choices,
              required: answer.required
            }}
            answer={answer}
            highlightedInitialValue={
              preview
                ? ""
                : index === 0
                ? formatMessage(strings.general.yourResponse)
                : ""
            }
            onBlur={
              preview
                ? _.noop
                : this.handleAnswerChange.bind(null, { debounce: false })
            }
            onChange={this.handleAnswerChange.bind(null, { debounce: true })}
          />
        ),
        value: SurveyItem.getAnswer(answer),
        optional: answer.required === false
      }))
    )
  }

  handleShareWithRevieweeChange(checked) {
    const { onShareWithRevieweeChange } = this.props

    onShareWithRevieweeChange(checked)
  }

  render() {
    const {
      className,
      answers,
      submitButtonText,
      preview,
      reviewee,
      allowShareWithReviewee,
      shareWithReviewee
    } = this.props
    const fields = this.getFormFields(answers)
    const readyToSubmit = !preview && fields && allFilled(fields)
    return (
      <div className={cx("SurveyForm", className)}>
        {fields.map(field => field.component)}
        <p
          className={cx("SurveyForm--submit-message", {
            "SurveyForm--blurred-message": !readyToSubmit
          })}
        >
          <FormattedMessage {...strings.cycle.submitWarningMessage} />
        </p>
        {allowShareWithReviewee && (
          <div className="SurveyForm--share-reviewee">
            <Checkbox
              id="share"
              onChange={e =>
                this.handleShareWithRevieweeChange(e.target.checked)
              }
              checked={shareWithReviewee}
              data-automation-id={Aid.shareFeedbackCheckbox}
            />
            <FormattedMessage
              {...strings.cycle.shareWithReviewee}
              values={{ name: User.getFirstName(reviewee) }}
            />
          </div>
        )}
        <div className="SurveyForm--submit-container">
          <Button
            className="SurveyForm--submit"
            actionType="primary"
            disabled={!readyToSubmit}
            onClick={preview ? null : this.handleSubmit}
            automationId={Aid.submitFeedbackForm}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
    )
  }
}

export default injectIntl(SurveyForm)
export { SurveyForm as RawSurveyForm }
