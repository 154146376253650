import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { addLoadingState, extractResponseKey } from "./lib/apiActionHelpers"
import SearchAgent from "./lib/SearchAgent"
import { GENERAL_URLS } from "../api/endpoints.json"

const { BASE_URL, DEVELOPMENT_RESOURCES_URL, TAGS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const resourceName = "development_resources"

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: `${BASE_URL}/${resourceName}?default=true`,
  resourceName,
  pageSize: 24
})

const DevelopmentResourceActions = Reflux.createActions({
  searchResources: { asyncResult: true },
  pageResources: { asyncResult: true },
  searchTags: { asyncResult: true },
  likeResource: { asyncResult: true },
  unlikeResource: { asyncResult: true },
  localClear: {}
})

function searchResources({
  loadingState = false,
  tagContents,
  types,
  page = 1
}) {
  return addLoadingState(
    loadingState,
    searchAgent.search({
      searchParams: {
        tag_contents:
          tagContents.length > 0 ? tagContents.join(",") : undefined,
        types: types.length > 0 ? types.join(",") : undefined
      },
      page
    })
  )
}

DevelopmentResourceActions.searchResources.listenAndPromise(params =>
  searchResources({
    ...params,
    loadingState: [true, { light: true }]
  })
)

DevelopmentResourceActions.pageResources.listenAndPromise(params =>
  searchResources(params)
)

DevelopmentResourceActions.searchTags.listenAndPromise(({ query }) =>
  extractResponseKey(
    "tags",
    agent.get(TAGS_URL).query({
      q: query
    })
  )
)

DevelopmentResourceActions.likeResource.listenAndPromise(({ id, type }) =>
  likeOrUnlikeResource({ id, type, action: "like" })
)

DevelopmentResourceActions.unlikeResource.listenAndPromise(({ id, type }) =>
  likeOrUnlikeResource({ id, type, action: "unlike" })
)

function likeOrUnlikeResource({ id, type, action }) {
  return agent
    .post(`${DEVELOPMENT_RESOURCES_URL}/${action}`)
    .send({ development_resource: { id, type } })
    .then(() => ({
      id,
      type,
      action
    }))
}

export default DevelopmentResourceActions
