import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions/index"

const ExperimentStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function() {
    this.data = {}
    this.listenTo(Actions.Experiment.setVariations, this.setVariations)
  },

  getInitialState: function() {
    return this.data
  },

  // Accepts a hash of experiment name / variation name pairs
  setVariations: function(experimentSettings) {
    // db.updateData(experimentSettings);
    if (!_.isEqual(this.data, experimentSettings)) {
      this.data = experimentSettings
      this.trigger(this.data)
    }
  }
})

export default ExperimentStore
