import { JsonDecoder } from "ts.data.json"
import { User } from "../../types/User"
const { array, object, number, failover, string } = JsonDecoder

const usersDecoder = object(
  {
    users: array(
      object(
        {
          id: number,
          employee_aggregate_id: failover("", string),
          best_name: string,
          job_title_name: failover("", string),
          profile_image_url: failover("", string),
          avatar_images: object(
            {
              thumb_url: string
            },
            "usersDecoder avatar_images"
          )
        },
        "usersDecoder user"
      ),
      "usersDecoder users"
    )
  },
  "usersDecoder"
).map<User[]>(({ users }) =>
  users.map(user => ({
    id: user.id,
    aggregateId: user.employee_aggregate_id,
    name: user.best_name,
    role: user.job_title_name,
    avatar: user.avatar_images.thumb_url,
    profileImage: user.profile_image_url
  }))
)

export default usersDecoder
