import { Text } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import AvatarDetails from "../../elements/AvatarDetails/AvatarDetails"
import strings from "../../../locale/strings"
import styles from "./GoalOwnersDetails.scss"

type GoalOwnersDetailsProps = {
  owners: {
    name: string
    avatar: string
    role: string
    profileImage: string
  }[]
  intl: InjectedIntl
}

const GoalOwnersDetails = ({ owners, intl }: GoalOwnersDetailsProps) => {
  const { formatMessage } = intl
  const itemsPerRow = 2
  const numberOfRows = Math.round(owners.length / itemsPerRow + 0.5)
  return (
    <div className={styles.container}>
      <Text tag="h6">
        {formatMessage(strings.goalsPage.goalsDossier.owners)}
      </Text>
      {[...Array(numberOfRows).keys()].map(row => {
        return (
          <div className={styles.row} key={`row-${row}`}>
            {[...Array(itemsPerRow).keys()].map(item => {
              const owner = owners[itemsPerRow * row + item]
              return (
                owner && (
                  <div className={styles.item} key={`row-${row}-item-${item}`}>
                    <AvatarDetails
                      bestName={owner.name}
                      avatarURL={owner.profileImage}
                      jobTitle={owner.role}
                    />
                  </div>
                )
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default injectIntl(GoalOwnersDetails)
