import { Location } from "history"
import * as React from "react"
import { hot } from "react-hot-loader/root"
import { IndexRedirect, InjectedRouter, Route } from "react-router"
// @ts-ignore
import Authenticate from "../../pages/lib/Authenticate"
import useGoalDossier from "../../../hooks/useGoalDossier"
import EditPersonalGoal from "../EditPersonalGoal/EditPersonalGoal"
import EditTeamGoal from "../../pages/EditTeamGoal/EditTeamGoal"
import EditDepartmentGoal from "../EditDepartmentGoal/EditDepartmentGoal"
import EditCompanyGoal from "../EditCompanyGoal/EditCompanyGoal"
import GoalDossier from "../GoalDossier/GoalDossier"
import GoalsList from "../GoalsList/GoalsList"
import NewPersonalGoal from "../NewPersonalGoal/NewPersonalGoal"
import NewTeamGoal from "../../pages/NewTeamGoal/NewTeamGoal"
import NewDepartmentGoal from "../NewDepartmentGoal/NewDepartmentGoal"
import NewCompanyGoal from "../NewCompanyGoal/NewCompanyGoal"
import PersonalGoalsList from "../../pages/PersonalGoalsList/PersonalGoalsList"
import TeamSummariesList from "../TeamSummariesList/TeamSummariesList"
import DepartmentSummariesList from "../DepartmentSummariesList/DepartmentSummariesList"
import TeamSummaryDetail from "../TeamSummaryDetail/TeamSummaryDetail"
import CompanySummariesList from "../../pages/CompanySummariesList/CompanySummariesList"
import styles from "./GoalsApp.scss"

type GoalsApp = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
}>

const GoalsApp: GoalsApp = ({ children, location, router }) => {
  const { isOpen: dossierIsOpen, goalId } = useGoalDossier(router, location)
  return (
    <div className={styles.goals}>
      <div className={styles.content}>{children}</div>
      {dossierIsOpen && (
        <div className={styles.dossier}>
          {
            // @ts-ignore: Router props are being given by withRouter HOC
            <GoalDossier goalId={goalId} />
          }
        </div>
      )}
    </div>
  )
}

export const routes = (
  <Route
    path="new_goals"
    component={hot(GoalsApp)}
    onEnter={Authenticate({
      // @ts-ignore
      predicate: user => user.company.allow_new_goals_interface
    })}
  >
    <Route path="team/new" component={hot(NewTeamGoal)} />
    <Route component={hot(GoalsList)}>
      <Route path="personal" component={hot(PersonalGoalsList)} />
      <Route path="team" component={hot(TeamSummariesList)} />
      <Route path="department" component={hot(DepartmentSummariesList)} />
      <Route path="company" component={hot(CompanySummariesList)} />
      <Route path="team/:teamId" component={hot(TeamSummaryDetail)} />
    </Route>
    <Route path="personal/new" component={hot(NewPersonalGoal)} />
    <Route path="company/new" component={hot(NewCompanyGoal)} />
    <Route path="personal/edit/:goalId" component={hot(EditPersonalGoal)} />
    <Route path="department/new" component={hot(NewDepartmentGoal)} />
    <Route path="company/edit/:goalId" component={hot(EditCompanyGoal)} />
    <Route
      path="department/:departmentId/edit/:goalId"
      component={hot(EditDepartmentGoal)}
    />
    <Route path="team/:teamId/new" component={hot(NewTeamGoal)} />
    <Route path="team/:teamId/edit/:goalId" component={hot(EditTeamGoal)} />
    <IndexRedirect to="personal" />
  </Route>
)
