import React from "react"
import { useRef, useState, useEffect, useCallback } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Button } from "@kaizen/component-library"
import { Popover } from "@kaizen/component-library/draft"
import clearIcon from "@kaizen/component-library/icons/clear.icon.svg"
import strings from "../../../locale/strings"
import styles from "./DismissRequestFeedbackButton.scss"
import { useDimensions } from "../../../hooks/dimensions"
import { calculatePopoverOffset } from "../../../util/popover"

type option = {
  text: ReactIntl.FormattedMessage.MessageDescriptor
  value: string
}

type Props = {
  skipOptions: option[]
  onSkipOptionSelect: Function
  iconPosition?: "end" | "start"
}

export type RawProps = Props & {
  intl: InjectedIntl
}

const DismissRequestFeedbackButton = ({
  skipOptions,
  onSkipOptionSelect,
  iconPosition = "start",
  intl: { formatMessage }
}: RawProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const rootRef = useRef<HTMLElement>(null)
  const rootDims = useDimensions(rootRef)

  const popoverRef = useRef<HTMLElement>(null)
  const popoverDims = useDimensions(popoverRef, [menuOpen])

  const boxOffset = calculatePopoverOffset(rootDims, popoverDims)
  const handleMenuClose = useCallback(() => {
    return setMenuOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener("click", handleMenuClose)
    return () => window.removeEventListener("click", handleMenuClose)
  }, [handleMenuClose])

  return (
    // @ts-ignore
    <div className={styles.DismissButton} ref={rootRef}>
      <Button
        label={formatMessage(strings.peerReview.dismissRequest)}
        secondary
        icon={clearIcon}
        iconPosition={iconPosition}
        onClick={e => {
          setMenuOpen(!menuOpen)
          e.stopPropagation()
        }}
      />
      {menuOpen && (
        <Popover
          side="top"
          boxOffset={boxOffset}
          // @ts-ignore
          ref={popoverRef}
        >
          <div className={styles.PopoverMenu}>
            {skipOptions.map(({ text, value }) => (
              <Button
                key={value}
                secondary
                onClick={e => {
                  onSkipOptionSelect(value)
                  e.stopPropagation()
                }}
                label={formatMessage(text)}
              />
            ))}
          </div>
        </Popover>
      )}
    </div>
  )
}

export default injectIntl(DismissRequestFeedbackButton)
