import {
  TableCard,
  TableContainer
} from "@kaizen/component-library/draft/Kaizen/Table"
import * as React from "react"
import { useEffect, useCallback } from "react"
import { WithRouterProps } from "react-router"
import Loading from "../../elements/Loading/Loading"
import useTeamSummary from "../../../hooks/useTeamSummary"
import TeamGoalsList from "../TeamGoalsList/TeamGoalsList"
import GoalsEmptyState from "../GoalsEmptyState/GoalsEmptyState"
import styles from "./TeamSummaryDetail.scss"
import strings from "../../../locale/strings"

type TeamSummaryDetail = React.FunctionComponent<WithRouterProps>

const TeamSummaryDetail: TeamSummaryDetail = ({ router, location, params }) => {
  const { team: teamSummary, loading } = useTeamSummary(params.teamId)
  useEffect(() => {
    router.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        selectedTeamId: params.teamId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.teamId])

  const handleCreateButtonClick = useCallback(() => {
    router.push(`/new_goals/team/${params.teamId}/new`)
  }, [params.teamId, router])

  return (
    <div data-testid="team-summary-detail">
      <TableContainer>
        {loading ? (
          <Loading />
        ) : teamSummary && teamSummary.goalsCount > 0 ? (
          <TableCard key={teamSummary.id} expanded={true}>
            <div className={styles.topSpace} />
            <TeamGoalsList team={teamSummary} />
          </TableCard>
        ) : (
          <GoalsEmptyState
            headingText={strings.teamSummariesList.emptyStateHeadingText}
            bodyText={strings.teamSummariesList.emptyStateBodyText}
            createGoalAction={handleCreateButtonClick}
          />
        )}
      </TableContainer>
    </div>
  )
}

export default TeamSummaryDetail
