import PropTypes from "prop-types"
import React from "react"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import { FormattedMessage, injectIntl } from "react-intl"
import InputField from "../../elements/InputField/InputField"
import TextEditor from "../../elements/TextEditor"
import Button from "../../elements/Button/Button"
import InputClearButton from "../../elements/InputClearButton/InputClearButton"
import messageDescriptorType from "../../prop-types/messageDescriptorType"
import Toggle from "../../elements/Toggle/Toggle"
import strings from "../../../locale/strings"
import "./BaseEditableQuestion.less"
import Aid from "../../../constants/automationId"

export const baseEditableQuestionPropTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  prompt: PropTypes.string,
  showPrompt: PropTypes.bool,
  description: PropTypes.string,
  showDescription: PropTypes.bool,
  showRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  titlePlaceholder: messageDescriptorType.isRequired,
  promptMessages: PropTypes.shape({
    label: messageDescriptorType.isRequired,
    addLabel: messageDescriptorType.isRequired,
    placeholder: messageDescriptorType.isRequired
  }),
  children: PropTypes.node,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func,
  onChangePrompt: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onRequiredChange: PropTypes.func
}

class BaseEditableQuestion extends React.PureComponent {
  static propTypes = baseEditableQuestionPropTypes

  static defaultProps = {
    className: "",
    showPrompt: false,
    showDescription: false,
    showRequired: false
  }

  constructor(props) {
    super(props)

    this.state = {
      hasPrompt: !!(props.showPrompt && props.prompt)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasPrompt && !this.state.hasPrompt) {
      this.props.onChangePrompt("")
    }
  }

  renderDescriptionEditor() {
    const {
      description,
      onChangeDescription,
      intl: { formatMessage }
    } = this.props

    return (
      <TextEditor
        className="BaseEditableQuestion--description-field"
        initialValue={description}
        placeholder={formatMessage(
          strings.customizedQuestions.descriptionPlaceholder
        )}
        toolbarButtonsToUse={["bold", "ul", "ol", "link"]}
        borderless={true}
        clearable={false}
        onChange={onChangeDescription}
        automationId={Aid.questionDescription}
      />
    )
  }

  renderPromptEditor() {
    const {
      prompt,
      promptMessages: { label, placeholder },
      onChangePrompt,
      intl: { formatMessage }
    } = this.props

    return (
      <div className="BaseEditableQuestion--prompt-editor layout horizontal">
        <div className="flex one">
          <div className="BaseEditableQuestion--prompt-label">
            <FormattedMessage {...label} />
          </div>
          <InputField
            className="BaseEditableQuestion--prompt-field"
            value={prompt || ""}
            placeholder={formatMessage(placeholder)}
            borderlessStyle={true}
            multiline={true}
            onChange={({ target: { value } }) => onChangePrompt(value)}
          />
        </div>
        <InputClearButton
          className="BaseEditableQuestion--clear-button flex none"
          onClick={() => this.setState({ hasPrompt: false })}
        />
      </div>
    )
  }

  renderAddPromptButton() {
    const {
      promptMessages: { addLabel }
    } = this.props

    return (
      <div className="BaseEditableQuestion--add-prompt-button layout horizontal center-center">
        <Button
          actionType="secondary"
          icon={addIcon}
          onClick={() => this.setState({ hasPrompt: true })}
        >
          <FormattedMessage {...addLabel} />
        </Button>
      </div>
    )
  }

  renderRequiredSlider() {
    const { onRequiredChange, required } = this.props
    return (
      <div className="BaseEditableQuestion--optional-slider layout horizontal start">
        <Toggle
          name="optionalCheckbox"
          checked={required}
          onChange={checked => onRequiredChange(checked)}
          startLabel={strings.general.slider.required}
        />
      </div>
    )
  }

  render() {
    const {
      className,
      title,
      showDescription,
      showPrompt,
      autoFocus,
      titlePlaceholder,
      children,
      onChangeTitle,
      showRequired,
      onDelete,
      intl: { formatMessage }
    } = this.props

    const { hasPrompt } = this.state

    return (
      <div className={`BaseEditableQuestion layout vertical ${className}`}>
        <div className="BaseEditableQuestion--container layout vertical">
          <TextEditor
            className="BaseEditableQuestion--title-field"
            initialValue={title}
            placeholder={formatMessage(titlePlaceholder)}
            toolbarButtonsToUse={["link"]}
            borderless={true}
            autoFocus={autoFocus}
            clearable={true}
            onChange={onChangeTitle}
            onClear={onDelete}
            automationId={Aid.questionTitle}
            clearButtonAutomationId={Aid.questionClearButton}
          />

          {showDescription && this.renderDescriptionEditor()}

          {children}

          {showPrompt && hasPrompt && this.renderPromptEditor()}
        </div>
        {showRequired && this.renderRequiredSlider()}
        {showPrompt && !hasPrompt && this.renderAddPromptButton()}
      </div>
    )
  }
}

export default injectIntl(BaseEditableQuestion)
export { BaseEditableQuestion as RawBaseEditableQuestion }
