import "./MessageGroup.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import strings from "../../../locale/strings"
import User from "../../../models/User"
import ProfilePic from "../ProfilePic/ProfilePic"

export default class MessageGroup extends React.Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    className: ""
  }

  render() {
    const { messages, className } = this.props

    const messageGroupSender = messages[0].props.sender
    const isDeleted = messageGroupSender === null
    const isAnon = !isDeleted && !messageGroupSender.email
    let author
    if (isAnon) {
      author = <FormattedMessage {...strings.general.anonymous} />
    } else if (isDeleted) {
      author = <FormattedMessage {...strings.user.deleted} />
    } else {
      author = User.getDisplayName(messageGroupSender)
    }

    return (
      <div className={`MessageGroup ${className} layout horizontal`}>
        <ProfilePic
          className="MessageGroup--profile-pic flex none"
          user={messageGroupSender}
        />
        <div className="MessageGroup--content layout vertical start flex one">
          <div className="layout horizontal">
            {!isDeleted ? (
              <div className="MessageGroup--author">{author}</div>
            ) : (
              <i className="MessageGroup--author">{author}</i>
            )}
            {messageGroupSender && User.isDeactivated(messageGroupSender) && (
              <div className="MessageGroup--author-deactivated">
                <FormattedMessage {...strings.user.deactivated} />
              </div>
            )}
          </div>
          <div className="MessageGroup--messages">
            {messages.map((message, index) =>
              React.cloneElement(message, { key: index })
            )}
          </div>
        </div>
      </div>
    )
  }
}
