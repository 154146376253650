import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { extractResponseKey } from "./lib/apiActionHelpers"
import { GENERAL_URLS } from "../api/endpoints.json"

const { DRAFTS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const DraftActions = Reflux.createActions({
  get: { asyncResult: true },
  save: { asyncResult: true },
  notifyEditing: {}
})

class DraftError extends Error {
  constructor({ cause, parentObjType, parentObjId, recipientId = null }) {
    super((cause && cause.message) || cause)

    this.name = "DraftError"
    this.cause = cause
    this.parentObjType = parentObjType
    this.parentObjId = parentObjId
    this.recipientId = recipientId
  }
}

DraftActions.save.listen(
  ({ parentObjType, parentObjId, body, recipientId = null }) =>
    extractResponseKey(
      "draft",
      agent.post(DRAFTS_URL).send({
        parent_obj_type: parentObjType,
        parent_obj_id: parentObjId,
        recipient_id: recipientId,
        body
      })
    )
      .then(draft =>
        DraftActions.save.completed({
          draft,
          parentObjType,
          parentObjId,
          recipientId
        })
      )
      .catch(err => {
        DraftActions.save.failed({ parentObjType, parentObjId, recipientId })
        Promise.reject(
          new DraftError({
            cause: err,
            parentObjType,
            parentObjId,
            recipientId
          })
        )
      })
)

DraftActions.get.listenAndPromise(
  ({ parentObjType, parentObjId, recipientId = null }) =>
    extractResponseKey(
      "drafts",
      agent.get(DRAFTS_URL).query({
        parent_obj_type: parentObjType,
        parent_obj_id: parentObjId,
        recipient_id: recipientId
      })
    ).then(drafts =>
      // Even though the API returns the data under the key "draft" (singular), the data is
      // actually an array of one draft
      ({ draft: drafts[0], parentObjType, parentObjId, recipientId })
    )
)

export default DraftActions
