import useDataAPI from "./useDataAPI"
import { PersonalGoalFields } from "../components/widgets/PersonalGoalForm/PersonalGoalForm"

const useCreatePersonalGoal = () => {
  const fetchAPI = useDataAPI("/goals", "POST")
  const createPersonalGoal = (fields: PersonalGoalFields) => {
    const goaldata = {
      type: "my_goal",
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      key_results: fields.keyResults
        .filter(({ title }) => title.length > 0)
        .map(({ title }) => ({
          title,
          completion: 0
        })),
      outgoing_alignment_associations_attributes: fields.alignedGoalIds.map(
        id => ({
          incoming_alignment_goal_id: id
        })
      ),
      access_permission: fields.viewers
        ? { user_ids: fields.viewers.map(u => u.id), permission: "read" }
        : undefined
    }
    fetchAPI.fire({ goal: goaldata })
  }
  return {
    createPersonalGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading
  }
}

export default useCreatePersonalGoal
