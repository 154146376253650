import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import MultipleChoice from "../MultipleChoice/MultipleChoice"
import LongAnswer from "../LongAnswer/LongAnswer"
import SurveyItem from "../../../models/SurveyItem"
import Aid from "../../../constants/automationId"

import "./SurveyQuestion.less"

export default class SurveyQuestion extends React.Component {
  static propTypes = {
    question: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.oneOf(["multiple-choice", "open-ended", "rating"])
        .isRequired,
      choices: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
          description: PropTypes.string
        })
      )
    }).isRequired,
    answer: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    }),
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    htmlId: PropTypes.string.isRequired,
    hideAsterisk: PropTypes.bool,
    highlightedInitialValue: PropTypes.string,
    hideQuestion: PropTypes.bool
  }

  static defaultProps = {
    hideQuestion: false,
    onChange: _.noop,
    onBlur: _.noop
  }

  render() {
    const {
      question,
      answer,
      readOnly,
      htmlId,
      onChange,
      onBlur,
      index,
      hideAsterisk,
      highlightedInitialValue,
      hideQuestion
    } = this.props
    return question.type === "multiple-choice" || question.type === "rating" ? (
      <MultipleChoice
        className={`SurveyQuestion SurveyQuestion--${question.type}`}
        question={question}
        selectedAnswerValue={SurveyItem.getMultipleChoiceAnswer(answer)}
        onSelectAnswer={value => onChange({ question, answer }, value)}
        htmlId={htmlId}
        index={index}
        readOnly={readOnly}
        orientation={
          question.type === "multiple-choice" ? "vertical" : "horizontal"
        }
        textAlign={
          question.type === "multiple-choice" ? "left-align" : "center-center"
        }
        disableColor={question.type === "multiple-choice"}
        answerOptionsMapper={question => question.choices}
        hideAsterisk={hideAsterisk}
        hideQuestion={hideQuestion}
        automationId={Aid.surveyQuestion}
      />
    ) : (
      <LongAnswer
        className="SurveyQuestion"
        question={question}
        answer={{ ...answer, text: SurveyItem.getLongAnswer(answer) }}
        onTypeAnswer={value => onChange({ question, answer }, value)}
        onBlur={(_evt, value) => onBlur({ question, answer }, value)}
        index={index}
        readOnly={readOnly}
        hideAsterisk={hideAsterisk}
        highlightedInitialValue={highlightedInitialValue}
        hideQuestion={hideQuestion}
        automationId={Aid.surveyQuestion}
      />
    )
  }
}
