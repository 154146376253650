import Reflux from "../../actions/ActionsInitializer"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"
import endpoints from "../../api/endpoints.json"
import StatsDateRange from "../../models/StatsDateRange"
import { showCsvConfirmation } from "../../actions/lib/csvExportation"
import strings from "../../locale/strings"
import SurveyResponse from "../../models/SurveyResponse"

const {
  ADMIN_SURVEYS_URLS: {
    ADMIN_SURVEY_MRF_STATS,
    ADMIN_SURVEY_MRF_INVITATION_STATS,
    ADMIN_SURVEY_MRF_MANAGER_STATS,
    ADMIN_SURVEY_MRF_EXPORT_INACTIVE_MANAGERS,
    ADMIN_SURVEY_EXPORT_URL,
    ADMIN_SURVEY_MRF_URL
  }
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const AdminManagerFeedbackStatsActions = Reflux.createActions({
  getManagerFeedbackStats: { asyncResult: true },
  getInvitationStats: { asyncResult: true },
  pageInvitationStats: { asyncResult: true },
  exportStats: { asyncResult: true },
  getIndividualStats: { asyncResult: true },
  pageIndividualStats: { asyncResult: true },
  exportInactiveManagers: { asyncResult: true },
  getInvitationSurveyStats: { asyncResult: true },
  getManagerFeedbackSurveyStats: { asyncResult: true },
  getInvitation: { asyncResult: true },
  getInvitationsForUser: { asyncResult: true }
})

AdminManagerFeedbackStatsActions.getManagerFeedbackStats.listenAndPromise(
  ({ dateRangeId, departmentId, endDate = new Date() }) =>
    addToasts(
      {},
      addLoadingState(
        [true, { light: true }],
        extractResponseKey(
          "stats",
          agent.get(ADMIN_SURVEY_MRF_STATS).query({
            department_ids: departmentId ? departmentId : undefined,
            from: StatsDateRange.getStartDate(
              dateRangeId,
              endDate
            ).toISOString(),
            kind: "MRF",
            to: endDate.toISOString()
          })
        ).then(feedbackStats => ({ feedbackStats, departmentId, dateRangeId }))
      )
    )
)

const getInvitationStats = ({ dateRangeId, departmentId, endDate, page }) =>
  addToasts(
    {},
    extractResponseBody(
      agent.get(ADMIN_SURVEY_MRF_INVITATION_STATS).query({
        department_ids: departmentId ? departmentId : undefined,
        kind: "MRF",
        from: StatsDateRange.getStartDate(dateRangeId, endDate).toISOString(),
        to: endDate.toISOString(),
        page
      })
    ).then(({ stats, meta }) => ({
      results: stats,
      meta,
      clientMeta: { departmentId, dateRangeId }
    }))
  )

AdminManagerFeedbackStatsActions.getInvitationStats.listenAndPromise(
  ({ dateRangeId, departmentId, endDate = new Date() }) =>
    addLoadingState(
      [true, { light: true }],
      getInvitationStats({
        dateRangeId,
        departmentId,
        endDate
      })
    )
)

AdminManagerFeedbackStatsActions.pageInvitationStats.listenAndPromise(
  ({ dateRangeId, departmentId, page, endDate = new Date() }) =>
    getInvitationStats({
      dateRangeId,
      departmentId,
      endDate,
      page
    })
)

const getIndividualStats = ({
  dateRangeId,
  managerId,
  endDate,
  fromDate,
  toDate,
  page
}) => {
  const from = do {
    if (dateRangeId) {
      StatsDateRange.getStartDate(dateRangeId, endDate).toISOString()
    } else {
      fromDate.toISOString()
    }
  }

  const to = do {
    if (dateRangeId) {
      endDate.toISOString()
    } else {
      toDate.toISOString()
    }
  }

  return addToasts(
    {},
    extractResponseBody(
      agent.get(ADMIN_SURVEY_MRF_MANAGER_STATS).query({
        kind: "MRF",
        manager_id: managerId,
        from,
        to,
        page
      })
    ).then(({ stats, meta }) => ({
      results: stats,
      meta,
      clientMeta: { managerId, dateRangeId }
    }))
  )
}

AdminManagerFeedbackStatsActions.getIndividualStats.listenAndPromise(
  ({ dateRangeId, managerId, endDate = new Date(), fromDate, toDate }) =>
    addLoadingState(
      [true, { light: true }],
      getIndividualStats({
        dateRangeId,
        managerId,
        endDate,
        fromDate,
        toDate
      })
    )
)

AdminManagerFeedbackStatsActions.pageIndividualStats.listenAndPromise(
  ({ dateRangeId, managerId, page, fromDate, toDate, endDate = new Date() }) =>
    getIndividualStats({
      dateRangeId,
      managerId,
      endDate,
      fromDate,
      toDate,
      page
    })
)

AdminManagerFeedbackStatsActions.exportStats.listenAndPromise(
  ({ departmentId, dateRangeId, endDate = new Date() }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.feedbackStats.exportFailed },
        extractResponseKey(
          "export_request",
          agent.post(ADMIN_SURVEY_EXPORT_URL).send({
            options: {
              department_ids: departmentId,
              from: StatsDateRange.getStartDate(
                dateRangeId,
                endDate
              ).toISOString()
            }
          })
        ).then(showCsvConfirmation)
      )
    )
)

AdminManagerFeedbackStatsActions.exportInactiveManagers.listenAndPromise(
  ({ departmentId, dateRangeId, endDate = new Date() }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.feedbackStats.exportFailed },
        extractResponseKey(
          "export_request",
          agent.post(ADMIN_SURVEY_MRF_EXPORT_INACTIVE_MANAGERS).send({
            [`inactive_managers_in_mrf_export_request`]: {
              options: {
                department_ids: departmentId,
                from: StatsDateRange.getStartDate(
                  dateRangeId,
                  endDate
                ).toISOString()
              }
            }
          })
        ).then(showCsvConfirmation)
      )
    )
)

AdminManagerFeedbackStatsActions.getInvitation.listenAndPromise(id =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "manager_requested_feedback",
        SurveyResponse.of,
        agent.get(`${ADMIN_SURVEY_MRF_URL}/${id}`)
      )
    )
  )
)

AdminManagerFeedbackStatsActions.getInvitationsForUser.listenAndPromise(
  ({ userId, dateRangeId, endDate = new Date() }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {},
        extractResponseKey(
          "manager_requested_feedbacks",
          SurveyResponse.of,
          agent.get(ADMIN_SURVEY_MRF_URL).query({
            user_id: userId,
            from: StatsDateRange.getStartDate(
              dateRangeId,
              endDate
            ).toISOString()
          })
        ).then(invites => ({ userId, invites }))
      )
    )
)

export default AdminManagerFeedbackStatsActions
