import Reflux from "reflux-core"
import { Map } from "immutable"
import Actions from "../actions"

export default Reflux.createStore({
  init() {
    this.data = {
      usersById: Map(),
      noteByUserId: Map()
    }

    Actions.User.loadById.completed.listen(user => {
      this.data = {
        ...this.data,
        usersById: this.data.usersById.set(user.id, user)
      }

      this.trigger(this.data)
    })

    Actions.User.notifyNoteEditing.listen(({ noteBody, userId }) => {
      this.updateNote({
        body: noteBody,
        about_user_id: userId,
        saveState: "saving"
      })
      this.trigger(this.data)
    })

    Actions.User.getNote.completed.listen(note => {
      this.updateNote({ ...note, saveState: null })
      this.trigger(this.data)
    })

    Actions.User.getNote.failed.listen(({ userId }) => {
      this.updateNote({ about_user_id: userId, saveState: null })
      this.trigger(this.data)
    })

    Actions.User.saveNote.completed.listen(note => {
      this.updateNote({ ...note, saveState: "saved" })
      this.trigger(this.data)
    })

    Actions.User.saveNote.failed.listen(({ noteBody, userId }) => {
      this.updateNote({
        body: noteBody,
        about_user_id: userId,
        saveState: "failed"
      })
      this.trigger(this.data)
    })
  },

  updateNote(updatedNote) {
    const currentNote = this.data.noteByUserId.get(updatedNote.about_user_id)
    this.data = {
      ...this.data,
      noteByUserId: this.data.noteByUserId.set(
        updatedNote.about_user_id,
        currentNote ? { ...currentNote, ...updatedNote } : updatedNote
      )
    }
  },

  getInitialState() {
    return this.data
  }
})
