import { Text, Button } from "@kaizen/component-library"
import * as React from "react"
import { useState } from "react"
import { InjectedRouter } from "react-router"
import { injectIntl, InjectedIntl } from "react-intl"
import chevronLeftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
// @ts-ignore
import SearchField from "../SearchField/SearchField.jsx"
import useDebounce from "../../../hooks/useDebounce"
import useArchivedTeams from "../../../hooks/useArchivedTeams"
import ScrollContainer from "../ScrollContainer/ScrollContainer"
import Tiles from "../Tiles/Tiles"
import ArchivedTeamCard from "./ArchivedTeamCard"
import styles from "../TeamsList/TeamsList.scss"
import { Team } from "../../../state/TeamsState"
import strings from "../../../locale/strings"
const { archivedTeamsList } = strings.teams

type BackButtonType = React.FunctionComponent<{
  onClick: () => void
  intl: InjectedIntl
}>

export const BackButton: BackButtonType = ({ onClick, intl }) => (
  <Button
    icon={chevronLeftIcon}
    label={intl.formatMessage(archivedTeamsList.backButton.label)}
    secondary={true}
    onClick={onClick}
  />
)

type TeamsList = React.FunctionComponent<{
  router: InjectedRouter
  search: string
  allTeams?: boolean
  intl: InjectedIntl
}>

const TeamsList: TeamsList = ({ router, search, intl, allTeams = true }) => {
  const [restoredTeamsList, setRestoredTeamsList] = useState<string[]>([])

  const { teams, hasMore, fetchMore } = useArchivedTeams({
    all_teams: allTeams,
    q: search,
    archived_teams: true
  })

  const updateTeams = (restoredTeam: Team) => {
    setRestoredTeamsList([...restoredTeamsList, restoredTeam.id])
  }

  const filteredTeams = React.useCallback(
    () => teams.filter(team => !restoredTeamsList.includes(team.id)),
    [teams, restoredTeamsList]
  )

  return (
    <ScrollContainer onScrolledToBottom={() => hasMore && fetchMore()}>
      <Tiles.Tiles>
        {filteredTeams().length > 0 ? (
          filteredTeams().map((team: Team) => (
            <ArchivedTeamCard
              key={team.id}
              team={team}
              onActionClick={() => router.push(`teams/archived/${team.id}`)}
              updateTeamsList={() => updateTeams(team)}
            />
          ))
        ) : (
          <Text tag="p">
            {intl.formatMessage(archivedTeamsList.teams.emptyState)}
          </Text>
        )}
      </Tiles.Tiles>
    </ScrollContainer>
  )
}

type SearchTeams = React.FunctionComponent<{
  setSearchTerm: (value: string) => void
  searchTerm: string
  intl: InjectedIntl
}>

/**
 * This is a debouncing fix to ensure the ArchivedTeamsList in
 * not continually rerendering prematurely.
 *
 * @returns {React.FunctionComponent} SearchTeams wrapper for search field
 */
const SearchTeams: SearchTeams = ({ setSearchTerm, searchTerm, intl }) => {
  return (
    <SearchField
      onQueryChange={setSearchTerm}
      fullWidth
      className={styles.search}
      query={searchTerm}
      placeholder={intl.formatMessage(
        archivedTeamsList.searchField.placeholder
      )}
    />
  )
}

type ArchivedTeamsList = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
}>

const ArchivedTeamsList: ArchivedTeamsList = ({ router, intl }) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  return (
    <div>
      <div>
        <BackButton intl={intl} onClick={() => router.push("teams")} />
        <Text tag="h1" style="zen-heading-1">
          {intl.formatMessage(archivedTeamsList.title)}
        </Text>
      </div>
      <SearchTeams
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        intl={intl}
      />
      <div>
        <Text tag="h2" style="zen-heading-2">
          {intl.formatMessage(archivedTeamsList.myTeams)}
        </Text>
      </div>
      <TeamsList
        intl={intl}
        router={router}
        search={debouncedSearchTerm}
        allTeams={false}
      />
      <div>
        <Text tag="h2" style="zen-heading-2">
          {intl.formatMessage(archivedTeamsList.allTeams)}
        </Text>
      </div>
      <TeamsList router={router} search={searchTerm} intl={intl} />
    </div>
  )
}

export default injectIntl(ArchivedTeamsList)
