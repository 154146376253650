import { useCallback, useContext, useEffect } from "react"
import { getTeamSummariesResponseDecoder } from "../decoders/GetTeamSummariesResponseDecoder/getTeamSummariesResponseDecoder"
import { TeamSummariesContext, TeamSummary } from "../state/TeamSummariesState"
import useDataAPI from "./useDataAPI"

interface Filters {
  all_teams?: boolean
  q?: string
  from?: string
  to?: string
}

const useTeamSummaries = (filters?: Filters) => {
  const { state, dispatch } = useContext(TeamSummariesContext)
  const { fire, data, loading } = useDataAPI("/goals/teams_summary", "GET")
  const hasSearchFilter = filters?.q && filters.q.length > 0

  useEffect(() => {
    fire({
      ...filters,
      page: 1
    })
  }, [filters, fire])

  useEffect(() => {
    if (data) {
      getTeamSummariesResponseDecoder
        .decodePromise(data)
        .then(response => {
          if (hasSearchFilter) {
            dispatch({
              type: "FETCH_TEAM_SUMMARIES_SEARCH",
              payload: response
            })
          } else {
            if (filters?.all_teams) {
              dispatch({
                type: "FETCH_ALL_TEAM_SUMMARIES",
                payload: response
              })
            } else {
              dispatch({
                type: "FETCH_MY_TEAM_SUMMARIES",
                payload: response
              })
            }
          }
        })
        .catch(error => {
          throw error
        })
    }
  }, [data, dispatch, filters, hasSearchFilter])

  const filteredIds = hasSearchFilter
    ? state.searchResults
    : state[filters?.all_teams ? "allTeams" : "myTeams"]

  const fetchMore = useCallback(() => {
    const nextPage = filteredIds.nextPage
    if (nextPage) {
      fire({ ...filters, page: nextPage })
    }
  }, [filteredIds.nextPage, filters, fire])

  const refetch = useCallback(() => {
    const { currentPage } = filteredIds
    fire({ ...filters, page: currentPage })
  }, [filteredIds, filters, fire])

  const teams = Object.values(filteredIds.pages)
    .reduce((res, teams) => [...res, ...teams], [])
    .map(goalId => state.teamSummariesById[goalId] as TeamSummary)

  const hasMore = filteredIds.totalCount > teams.length

  return {
    hasMore,
    fetchMore,
    refetch,
    teams,
    loading
  }
}

export default useTeamSummaries
