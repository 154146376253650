import Reflux from "reflux-core"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import strings from "../../locale/strings"
import endpoints from "../../api/endpoints.json"
import {
  addLoadingState,
  addToasts,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"

const {
  ADMIN_GENERAL_URLS: { ADMIN_CSV_TO_USER_ID_URL },
  ADMIN_USERS_URLS: { ADMIN_USER_IDS_URL }
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const ManageUsersActions = Reflux.createActions({
  toggleMatchingUserIds: { asyncResult: true },
  updateSelectedUsers: { sync: true },
  setInitialUsers: { sync: true },
  clearSelectedUsers: { sync: true },
  submitUserCsv: { asyncResult: true }
})

ManageUsersActions.toggleMatchingUserIds.listenAndPromise(
  (params = {}, { checked = false } = {}) =>
    addToasts(
      {},
      extractResponseKey(
        "user_ids",
        agent.get(ADMIN_USER_IDS_URL).query(params)
      )
    ).then(userIds => ({ userIds, checked }))
)

ManageUsersActions.submitUserCsv.listenAndPromise(({ csv }) =>
  addLoadingState(
    true,
    addToasts(
      { defaultError: strings.cycle.userUpload.defaultUploadError },
      extractResponseKey(
        "csv_to_user_id",
        agent.post(ADMIN_CSV_TO_USER_ID_URL).attach({
          name: "csv",
          file: csv
        })
      )
    )
  ).then(({ user_ids, deactivated_emails, no_user_emails }) => ({
    addableUserIds: user_ids,
    deactivatedEmails: deactivated_emails,
    notFoundEmails: no_user_emails
  }))
)

export default ManageUsersActions
