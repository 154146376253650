import "./DraggableTile.less"
import PropTypes from "prop-types"
import React from "react"
import ReactDOM from "react-dom"
import Hammer from "hammerjs"
import _ from "lodash"
import cx from "classnames"

export default class DraggableTile extends React.Component {
  static propTypes = {
    onDrag: PropTypes.func,
    onTap: PropTypes.func,
    disableDragging: PropTypes.bool
  }

  static defaultProps = {
    disableDragging: false
  }

  constructor(props) {
    super(props)

    this.hammer = null

    this.state = {
      dragging: false,
      translation: [null, null]
    }
  }

  componentDidMount() {
    const { disableDragging } = this.props

    // eslint-disable-next-line react/no-find-dom-node
    this.hammer = new Hammer.Manager(ReactDOM.findDOMNode(this))

    this.hammer.add(new Hammer.Tap())
    this.hammer.on("pan", this.handlePan.bind(this))
    this.hammer.on("tap", this.handleTap.bind(this))

    this.panDetector = new Hammer.Pan({
      direction: Hammer.DIRECTION_ALL
    })

    if (!disableDragging) {
      this.enableDrag()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { disableDragging } = this.props
    if (disableDragging !== nextProps.disableDragging) {
      if (nextProps.disableDragging) {
        this.disableDrag()
        this.setState({ dragging: false, translation: [null, null] })
      } else {
        this.enableDrag()
      }
    }
  }

  componentWillUnmount() {
    if (this.hammer) {
      this.hammer.stop()
      this.hammer.destroy()
    }
    this.hammer = null
  }

  enableDrag() {
    this.hammer.add(this.panDetector)
  }

  disableDrag() {
    this.hammer.remove(this.panDetector)
  }

  handleTap() {
    const { onTap } = this.props
    if (onTap) {
      onTap()
    }
  }

  handlePan(event) {
    const { isFinal, deltaX, deltaY } = event
    const { disableDragging, overDragTarget, onDrag } = this.props

    if (disableDragging) {
      return
    }

    if (isFinal) {
      this.setState({
        dragging: false,
        // If the dragified element is being dropped over
        // a drag target then it should not be moved back
        // its start position so that it gives the impression
        // of being dropped into the target.
        ...(overDragTarget ? {} : { translation: [0, 0] })
      })
    } else {
      this.setState({
        dragging: true,
        translation: [deltaX, deltaY]
      })
    }

    if (onDrag) {
      onDrag(event)
    }
  }

  render() {
    const onlyChild = React.Children.only(this.props.children)
    const {
      dragging,
      translation: [transX, transY]
    } = this.state

    const style = _.assign(
      {},
      onlyChild.props.style,
      // assumes position: relative; on the tiles
      (transX || transX === 0) && { left: transX, top: transY }
    )

    return React.cloneElement(onlyChild, {
      style,
      className: cx(
        "DraggableTile",
        {
          "DraggableTile--dragging": dragging
        },
        onlyChild.props.className
      )
    })
  }
}
