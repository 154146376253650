import Reflux from "../../actions/ActionsInitializer"
import strings from "../../locale/strings"
import EmployeeGrouping, { JOB_TITLE_TYPE } from "../../models/EmployeeGrouping"
import User from "../../models/User"
import UIActions from "../../actions/UIActions"
import UserActions, { augmentSteadyfootUser } from "../../actions/UserActions"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"
import RequestSupplanter from "../../actions/lib/RequestSupplanter"
import endpoints from "../../api/endpoints.json"

const {
  USERS_URLS: { USERS_URL },
  ADMIN_GENERAL_URLS: { ADMIN_JOB_TITLES_URL, ADMIN_COMPANIES_URL },
  ADMIN_USERS_URLS: { ADMIN_USERS_URL },
  ADMIN_SURVEYS_URLS: {
    ADMIN_SURVEY_MRF_REQUEST_ABOUT_URL,
    ADMIN_SURVEY_MRF_ON_BEHALF_OF
  }
} = endpoints

const listUsersRequestSupplanter = new RequestSupplanter()

const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}

const CompanyActions = Reflux.createActions({
  update: ASYNC_SETTINGS,

  listUsers: ASYNC_SETTINGS,
  listManagers: ASYNC_SETTINGS,

  updateUser: ASYNC_SETTINGS,
  updateAvatar: ASYNC_SETTINGS,
  inviteUser: ASYNC_SETTINGS,
  downloadUserData: ASYNC_SETTINGS,
  deleteUser: ASYNC_SETTINGS,
  createUser: ASYNC_SETTINGS,
  deactivateUser: ASYNC_SETTINGS,
  reactivateUser: ASYNC_SETTINGS,
  suggestUsers: ASYNC_SETTINGS,
  loadById: ASYNC_SETTINGS,

  suggestEmployeeGroupings: ASYNC_SETTINGS,
  listEmployeeGroupings: ASYNC_SETTINGS,
  createEmployeeGrouping: ASYNC_SETTINGS,
  deleteEmployeeGrouping: ASYNC_SETTINGS,
  requestsurveyMRFAbout: ASYNC_SETTINGS,
  requestsurveyOnBehalfOf: ASYNC_SETTINGS
})

CompanyActions.update.listenAndPromise(company =>
  addLoadingState(
    [true, { light: true }],
    agent.put(ADMIN_COMPANIES_URL).send({ company })
  ).then(() =>
    // Refresh the current user as that's what other parts of the app
    // draw company information from.
    UserActions.reloadCurrent()
  )
)

CompanyActions.listUsers.listenAndPromise(({ search, showLoadingState } = {}) =>
  addLoadingState(
    [showLoadingState, { light: true }],
    extractResponseBody(
      listUsersRequestSupplanter.replaceRequest(
        agent.get(ADMIN_USERS_URL).query(search)
      )
    )
  )
)

CompanyActions.listManagers.listenAndPromise(({ query, currentUser }) =>
  addToasts(
    {},
    extractResponseKey(
      "users",
      agent
        // Even though HRBPs (department-scoped admins) normally use the admin app & dashboard APIs,
        // SF requires them to use the non-dashboard API for searching managers
        .get(User.isAdmin(currentUser) ? ADMIN_USERS_URL : USERS_URL)
        .query({
          q: query,
          managers_only: true
        })
    )
  )
)

CompanyActions.suggestUsers.listenAndPromise(search => {
  UIActions.setLoadingState(true, { light: true })

  return new Promise((resolve, reject) => {
    agent
      .get(ADMIN_USERS_URL)
      .query({ deactivated: false, ...search })
      .end((err, res) => {
        UIActions.setLoadingState(false)

        const { body } = res

        if (err || !body.users) {
          reject(res && body, err)
        } else {
          resolve({
            users: body.users.map(augmentSteadyfootUser),
            meta: body.meta
          })
        }
      })
  })
})

CompanyActions.loadById.listenAndPromise(
  (id, { showLoadingState = false } = {}) =>
    addLoadingState(
      showLoadingState,
      extractResponseKey("user", agent.get(`${ADMIN_USERS_URL}/${id}`))
    )
)

CompanyActions.updateUser.listenAndPromise(user =>
  addToasts(
    { success: strings.adminUsers.updatedUser },
    extractResponseKey(
      "user",
      agent.put(`${ADMIN_USERS_URL}/${user.id}`).send({ user })
    )
  )
)

CompanyActions.updateAvatar.listenAndPromise(
  ({ user, avatar, didCrop = false }) =>
    addToasts(
      { success: strings.adminUsers.updatedAvatar },
      extractResponseKey(
        "user",
        agent.put(`${ADMIN_USERS_URL}/${user.id}`).send({
          user: { ...user, avatar }
        })
      )
    ).then(user => {
      if (didCrop) {
        CompanyActions.updateUser.completed(user)
      }
    })
)

CompanyActions.inviteUser.listenAndPromise(({ user }) =>
  addToasts(
    {},
    extractResponseKey(
      "user",
      agent.post(`${ADMIN_USERS_URL}/${user.id}/send_signup_invite`)
    )
  ).then(user => {
    UIActions.success(strings.adminUsers.sentInvite, { email: user.email })
    return user
  })
)

CompanyActions.deleteUser.listenAndPromise(({ user }) =>
  addToasts(
    { success: strings.adminUsers.deletedUser },
    addLoadingState(
      [true, { light: true }],
      agent.del(`${ADMIN_USERS_URL}/${user.id}`)
    )
  )
)

CompanyActions.deactivateUser.listenAndPromise(({ user }) =>
  addToasts(
    {},
    extractResponseKey(
      "user",
      agent.post(`${ADMIN_USERS_URL}/${user.id}/deactivate`)
    )
  ).then(user => {
    UIActions.success(strings.adminUsers.deactivatedUser, { email: user.email })
    return user
  })
)

CompanyActions.reactivateUser.listenAndPromise(({ user }) =>
  addToasts(
    {},
    extractResponseKey(
      "user",
      agent.post(`${ADMIN_USERS_URL}/${user.id}/activate`)
    )
  ).then(user => {
    UIActions.success(strings.adminUsers.reactivatedUser, { email: user.email })
    return user
  })
)

CompanyActions.suggestEmployeeGroupings.listenAndPromise(
  ({ q, ids, departmentIds, type, page = 1, paginate }) => {
    const request = agent.get(ADMIN_JOB_TITLES_URL).query({
      q,
      ids: ids && ids.join(","),
      department_ids: departmentIds && departmentIds.join(","),
      job_title_type: type || undefined,
      ...(paginate ? { page, per_page: 20 } : {})
    })

    return paginate
      ? extractResponseBody(request).then(({ job_titles, meta }) => ({
          results: job_titles,
          meta
        }))
      : extractResponseKey("job_titles", request)
  }
)

CompanyActions.listEmployeeGroupings.listenAndPromise(() =>
  extractResponseKey(
    "job_titles",
    EmployeeGrouping.of,
    agent.get(ADMIN_JOB_TITLES_URL).query({
      per_page: 1e9
    })
  )
)

CompanyActions.createEmployeeGrouping.listenAndPromise(employeeGrouping =>
  addToasts(
    {},
    extractResponseKey(
      "job_title",
      EmployeeGrouping.of,
      agent.post(ADMIN_JOB_TITLES_URL).send({ job_title: employeeGrouping })
    )
  )
)

CompanyActions.deleteEmployeeGrouping.listenAndPromise(({ id, type }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {
        defaultError:
          strings.departments[
            type === JOB_TITLE_TYPE
              ? "genericJobTitleDeletionError"
              : "genericDepartmentDeletionError"
          ]
      },
      extractResponseKey(
        "job_title",
        EmployeeGrouping.of,
        agent.del(`${ADMIN_JOB_TITLES_URL}/${id}`)
      )
    )
  )
)

CompanyActions.downloadUserData.listenAndPromise(({ user }) =>
  addToasts(
    { success: strings.user.exportDataSuccess },
    addLoadingState(
      [true, { light: true }],
      agent.post(`${ADMIN_USERS_URL}/${user.id}/export`)
    )
  )
)

CompanyActions.createUser.listenAndPromise(user =>
  addToasts(
    { success: strings.adminDirectory.createUser.success },
    agent.post(ADMIN_USERS_URL).send({ user })
  )
)

CompanyActions.requestsurveyMRFAbout.listenAndPromise((managerIds, query) =>
  extractResponseBody(
    agent.get(ADMIN_SURVEY_MRF_REQUEST_ABOUT_URL).query({
      q: query,
      // Support up to 200 directs per manager
      per_page: 200,
      manager_ids: managerIds.length > 1 ? managerIds.join(",") : managerIds
    })
  )
)

CompanyActions.requestsurveyOnBehalfOf.listenAndPromise(query =>
  extractResponseBody(
    agent.get(ADMIN_SURVEY_MRF_ON_BEHALF_OF).query({
      q: query
    })
  )
)

export default CompanyActions
