import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions"
import observeSearchActions from "./lib/observeSearchActions"
import handleLazyLoadAction from "./lib/handleLazyLoadAction"

const TeamStore = Reflux.createStore({
  init() {
    this.data = {
      teamSearchResults: null,
      teamSearchState: null,
      teamPreviewResults: null,
      teamNumberOfUsers: null,
      teamCategories: null,
      companyCategories: null,
      teamSkillsBySkillDisplay: {},
      companySkillsBySkillDisplay: {},
      reviewersBySkill: {},
      reviewersBySkillDisplay: {},
      categoryIndex: null,
      topStrengths: null,
      topAreasToImprove: null,
      subManagers: null,
      individualStats: null,
      pagination: null,
      dateRangeId: null,
      invitesByUserId: {},
      invitesById: {}
    }

    observeSearchActions(this, {
      getResults: () => this.data.teamSearchResults,
      searchAction: Actions.Team.search,
      pageAction: Actions.Team.pageSearchResults,
      onChange: (teamSearchResults, teamSearchState) => {
        this.data = {
          ...this.data,
          teamSearchResults,
          teamSearchState
        }
        this.trigger(this.data)
      }
    })

    observeSearchActions(this, {
      getResults: () => this.data.individualStats,
      searchAction: Actions.Team.getIndividualStats,
      pageAction: Actions.Team.pageIndividualStats,
      onChange: (individualStats, { stats, pagination, dateRangeId }) => {
        this.data = {
          ...this.data,
          individualStats,
          pagination: pagination,
          dateRangeId: dateRangeId,
          managerChartStats: stats
        }
        this.trigger(this.data)
      }
    })

    this.listenTo(
      Actions.Team.getMemberPreview.completed,
      ({ users, teamNumberOfUsers }) => {
        this.data = {
          ...this.data,
          teamPreviewResults: users,
          teamNumberOfUsers
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Team.resetMemberPreview, () => {
      this.data = {
        ...this.data,
        teamPreviewResults: null
      }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.getMemberPreview.failed, () => {
      this.data = {
        ...this.data,
        teamPreviewResults: null
      }
      this.trigger(this.data)
    })

    this.listenTo(
      Actions.Team.getReviewersSuggestions.completed,
      ({ users }) => {
        this.data = {
          ...this.data,
          suggestions: users
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Team.getTeamSkills.completed, skills => {
      const sortedSkills = this.sortSkills(skills)
      this.data = {
        ...this.data,
        topStrengths: _.filter(
          sortedSkills,
          skill => (skill.score > 0 || skill.score === 0) && skill.sum_pos > 0
        ).slice(0, 3),
        topAreasToImprove: _.filter(
          sortedSkills,
          skill => (skill.score > 0 || skill.score === 0) && skill.sum_neg > 0
        ).slice(-3)
      }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.getTeamCategories.completed, teamCategories => {
      this.data = {
        ...this.data,
        teamCategories: this.sortScorelessSkillsToBottom(teamCategories),
        categoryIndex: this.data.categoryIndex ? 0 : null
      }
      this.trigger(this.data)
    })

    this.listenTo(
      Actions.Team.getCompanyCategories.completed,
      companyCategories => {
        this.data = { ...this.data, companyCategories }
        this.trigger(this.data)
      }
    )

    handleLazyLoadAction(this, Actions.Team.getTeamSkillsBySkillDisplay, {
      getLocalData: skillDisplayId => {
        const teamSkills = this.data.teamSkillsBySkillDisplay[skillDisplayId]
        return teamSkills ? { skillDisplayId, teamSkills } : null
      },
      handleCompletion: ({ skillDisplayId, teamSkills }) => {
        this.data.teamSkillsBySkillDisplay = {
          ...this.data.teamSkillsBySkillDisplay,
          [skillDisplayId]: this.sortScorelessSkillsToBottom(teamSkills)
        }
        this.trigger(this.data)
      }
    })

    handleLazyLoadAction(this, Actions.Team.getCompanySkillsBySkillDisplay, {
      getLocalData: skillDisplayId => {
        const companySkills = this.data.companySkillsBySkillDisplay[
          skillDisplayId
        ]
        return companySkills ? { skillDisplayId, companySkills } : null
      },
      handleCompletion: ({ skillDisplayId, companySkills }) => {
        this.data.companySkillsBySkillDisplay = {
          ...this.data.companySkillsBySkillDisplay,
          [skillDisplayId]: companySkills
        }
        this.trigger(this.data)
      }
    })

    this.listenTo(
      Actions.Team.getReviewersBySkill.completed,
      ({ result, skillId }) => {
        this.data = {
          ...this.data,
          reviewersBySkill: { ...this.data.reviewersBySkill, [skillId]: result }
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      Actions.Team.getReviewersBySkillDisplay.completed,
      ({ result, skillDisplayId }) => {
        this.data = {
          ...this.data,
          reviewersBySkillDisplay: {
            ...this.data.reviewersBySkillDisplay,
            [skillDisplayId]: result
          }
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Team.setCategoryIndex, categoryIndex => {
      this.data = { ...this.data, categoryIndex }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.nextCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex + 1 }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.previousCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex - 1 }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.getSubManagers.completed, ({ users }) => {
      this.data = { ...this.data, subManagers: users }
      this.trigger(this.data)
    })

    this.listenTo(Actions.Team.clearTeamSkills, () => {
      this.data = { ...this.data, teamSkillsBySkillDisplay: {} }
      this.trigger(this.data)
    })

    this.listenTo(
      Actions.Team.getInvitationsForUser.completed,
      ({ userId, invites }) => {
        this.data = {
          ...this.data,
          invitesByUserId: {
            ...this.data.invitesByUserId,
            [userId]: invites
          }
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Team.getInvitation.completed, invite => {
      this.data = {
        ...this.data,
        invitesById: {
          ...this.data.invitesById,
          [invite.id]: invite
        }
      }
      this.trigger(this.data)
    })
  },

  getInitialState() {
    return this.data
  },

  sortSkills(skills) {
    return _.sortBy(skills, skill => -skill.score)
  },

  sortScorelessSkillsToBottom(skills) {
    return _.sortBy(skills, (skill, index) =>
      skill.score || skill.score === 0 ? index : Infinity
    )
  }
})

export default TeamStore
