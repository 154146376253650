import _ from "lodash"
import moment from "moment"
import observeSearchActions from "./observeSearchActions"
import PerformanceReview from "../../models/PerformanceReview"

export default {
  init({
    actions: { searchReviews, pageSearchResults, clearSearchResults = null }
  }) {
    this.data = {
      cycles: null,
      cyclesById: {},
      inSessionCycles: null,
      searchResults: null,
      searchState: null,
      managersByCycleId: {},
      teamReviews: null,
      review: null
    }

    observeSearchActions(this, {
      getResults: () => this.data.searchResults,
      searchAction: searchReviews,
      pageAction: pageSearchResults,
      clearAction: clearSearchResults,
      onChange: (searchResults, searchState) => {
        this.data = {
          ...this.data,
          searchResults,
          searchState
        }
        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  },

  clearReviews() {
    this.data = {
      ...this.data,
      review: null,
      teamReviews: null,
      searchResults: null,
      searchState: null
    }

    this.trigger(this.data)
  },

  onGetReview(updatedReview) {
    this.transformTeamReviewsAndSearchResults(review =>
      review.id === updatedReview.id ? updatedReview : review
    )

    this.trigger(this.data)
  },

  onGetTeamReviews(teamReviews) {
    const { review } = this.data

    this.data = {
      ...this.data,
      teamReviews,
      review:
        review || _.find(teamReviews, "incomplete") || _.get(teamReviews, "[0]")
    }

    this.trigger(this.data)
  },

  onLocalUpdateReview(updatedReviewProps) {
    this.transformTeamReviewsAndSearchResults(review =>
      review.id === updatedReviewProps.id
        ? review.with(updatedReviewProps)
        : review
    )

    this.trigger(this.data)
  },

  transformTeamReviewsAndSearchResults(transformReview) {
    const { teamReviews, searchResults, review } = this.data

    const updatedTeamReviews = teamReviews && teamReviews.map(transformReview)

    const updatedSearchResults =
      searchResults && searchResults.map(transformReview)

    const updatedReview = review
      ? transformReview(review)
      : updatedTeamReviews
      ? _.find(updatedTeamReviews, { id: review.id })
      : null
    this.data = {
      ...this.data,
      teamReviews: updatedTeamReviews,
      review: updatedReview,
      searchResults: updatedSearchResults
    }
  },

  onSetNextReview(nextReview) {
    const { teamReviews, review } = this.data

    this.data = {
      ...this.data,

      review: nextReview
        ? // Try to use the review object that's in the store
          _.find(teamReviews, { id: nextReview.id }) || nextReview
        : teamReviews
        ? _.find(teamReviews, "incomplete") || _.get(teamReviews, "[0]")
        : review
    }

    this.trigger(this.data)
  },

  setCycles(cycles, { shouldTrigger = true } = {}) {
    const inSessionCycles = cycles.filter(
      c =>
        c.completed_copy_at && !c.completed_at && moment().isAfter(c.start_date)
    )

    this.data = {
      ...this.data,
      cycles,
      cyclesById: _.indexBy(cycles, "id"),
      inSessionCycles
    }

    if (shouldTrigger) {
      this.trigger(this.data)
    }
  },

  addOrReplaceCycle(newCycle, { shouldTrigger = true } = {}) {
    let cycles = _.map(this.data.cycles, cycle =>
      cycle.id === newCycle.id ? newCycle : cycle
    )

    if (!_.any(cycles, { id: newCycle.id })) {
      cycles = [newCycle, ...cycles]
    }

    this.setCycles(cycles, { shouldTrigger })
  },

  setManagersForCycleId({ results, clientMeta: { cycleId } }) {
    this.data = {
      ...this.data,
      managersByCycleId: {
        ...this.data.managersByCycleId,
        [cycleId]: results
      }
    }
    this.trigger(this.data)
  },

  onAnswerEdit({ review: { id: reviewId }, question, value }) {
    this.transformTeamReviewsAndSearchResults(review => {
      if (review.id !== reviewId) {
        return review
      }

      const answers = review.performance_answers

      const currentAnswer = _.find(
        answers,
        answer => answer.performance_question_in_cycle.id === question.id
      )

      const [text, choice] =
        question.question_type === "open-ended" ? [value, null] : [null, value]

      const updatedAnswer = {
        text,
        choice,
        rating: choice,
        performance_question_in_cycle: question, // eslint-disable-line camelcase
        saveState: "saving"
      }

      const updatedPerformanceAnswers = do {
        if (!currentAnswer) {
          ;[...answers, updatedAnswer]
        } else {
          answers.map(
            answer => do {
              if (answer.performance_question_in_cycle.id === question.id) {
                ;({ ...answer, ...updatedAnswer })
              } else {
                answer
              }
            }
          )
        }
      }
      return review.with({
        performance_answers: updatedPerformanceAnswers // eslint-disable-line camelcase
      })
    })

    this.trigger(this.data)
  },

  onAddBucketAssignment(newBucketAssignment) {
    const bucketAssignmentProps = {
      latest_bucket_assignment: newBucketAssignment // eslint-disable-line camelcase
    }

    this.transformTeamReviewsAndSearchResults(review =>
      review.id === newBucketAssignment.performance_review_id
        ? review.with(bucketAssignmentProps)
        : review
    )

    this.trigger(this.data)
  },

  onUpdateAnswer(saveState, { review, question, revert }) {
    if (revert) {
      const originalAnswer = review.performance_answers.find(
        answer => answer.performance_question_in_cycle.id === question.id
      )

      this.onAnswerEdit({
        review,
        question,
        value: PerformanceReview.getAnswerValue(originalAnswer)
      })
    } else if (this.data.review) {
      this.onLocalUpdateReview({
        id: review.id,
        performance_answers: this.data.review.performance_answers.map(answer =>
          answer.performance_question_in_cycle.id === question.id
            ? { ...answer, saveState }
            : answer
        )
      })
    }
  },

  onShareReview(review) {
    const { searchState } = this.data
    const stats = _.get(searchState, "stats")

    if (stats) {
      this.data = {
        ...this.data,
        searchState: {
          ...searchState,
          stats: {
            ...stats,
            /* eslint-disable camelcase */
            completed_not_shared_reviews_count:
              stats.completed_not_shared_reviews_count - 1,
            shared_reviews_count: stats.shared_reviews_count + 1
            /* eslint-enable camelcase */
          }
        }
      }
    }

    this.onGetReview(review)
  }
}
