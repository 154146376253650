import { Text } from "@kaizen/component-library"
import cx from "classnames"
import * as React from "react"
import { User } from "../../../types/User"
import Avatar from "../../Avatar/Avatar"
import styles from "./UserAvatarNameAndRole.scss"

interface UserProps {
  user: User
  small?: true
}

const UserAvatarNameAndRole = ({ user, small }: UserProps) => (
  <article className={cx(styles.user, { [styles.small]: small })}>
    <div className={styles.avatar}>
      <Avatar size={small ? "small" : "medium"} imageURL={user.profileImage} />
    </div>
    <div className={styles.content}>
      <div className={styles.name}>
        <Text tag="h1" style={small ? "body" : "heading"} inline>
          {user.name}
        </Text>
      </div>
      <div>
        <Text tag="h2" style={small ? "small" : "body-bold"} inline>
          {user.role}
        </Text>
      </div>
    </div>
  </article>
)

export default UserAvatarNameAndRole
