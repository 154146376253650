import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions/index"
import observeSearchActions from "./lib/observeSearchActions"

const FeedbackRequestsStore = Reflux.createStore({
  init() {
    this.data = {
      reviewees: null,
      selectedReviewee: null,
      reviewers: null,
      customQuestion: "",
      requestsSentSearchResults: null,
      requestsSentPagination: null
    }

    this.listenTo(Actions.FeedbackRequests.reset, () => {
      this.data = {
        ...this.data,
        reviewers: [],
        customQuestion: ""
      }
      this.trigger(this.data)
    })

    this.listenTo(Actions.FeedbackRequests.setReviewers, this.setReviewers)

    this.listenTo(
      Actions.FeedbackRequests.setCustomQuestion,
      customQuestion => {
        this.data = { ...this.data, customQuestion }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      Actions.FeedbackRequests.loadReviewerByEmail.completed,
      user => {
        if (user) {
          const { reviewers } = this.data
          const newReviewers = reviewers
            ? [user, ..._.reject(reviewers, { email: user.email })]
            : [user]
          this.setReviewers(newReviewers)
        }
      }
    )

    observeSearchActions(this, {
      getResults: () => this.data.requestsSentSearchResults,
      searchAction: Actions.FeedbackRequests.loadRequestHistory,
      pageAction: Actions.FeedbackRequests.pageRequestHistory,
      onChange: (requestsSentSearchResults, { pagination }) => {
        this.data = {
          ...this.data,
          requestsSentSearchResults,
          requestsSentPagination: pagination
        }
        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  },

  setReviewers(reviewers) {
    this.data = { ...this.data, reviewers }
    this.trigger(this.data)
  }
})

export default FeedbackRequestsStore
