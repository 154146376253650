import * as React from "react"
import { createContext, useReducer } from "react"
import { Pagination } from "../decoders/PaginationDecoder/paginationDecoder"
import { getPaginationState, PaginatedResults } from "./GoalsState"

export type DepartmentSummary = {
  id: number
  name: string
  description?: string
  goalsCount: number
  overallProgress: number
}

const INITIAL_STATE: DepartmentSummariesState = {
  departmentSummariesById: {},
  myDepartments: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  },
  allDepartments: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  },
  searchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  }
}

type DepartmentSummariesState = {
  departmentSummariesById: Record<number, DepartmentSummary>
  myDepartments: PaginatedResults<number>
  allDepartments: PaginatedResults<number>
  searchResults: PaginatedResults<number>
}

type DepartmentSummariesAction =
  | FetchMySummariesAction
  | FetchAllSummariesAction
  | FetchSummariesSearchAction
  | FetchSummaryAction
  | UpdateDepartmentOverallProgress

type FetchMySummariesAction = {
  type: "FETCH_MY_DEPARTMENT_SUMMARIES"
  payload: {
    departmentSummaries: DepartmentSummary[]
    pagination: Pagination
  }
}

type FetchAllSummariesAction = {
  type: "FETCH_ALL_DEPARTMENT_SUMMARIES"
  payload: {
    departmentSummaries: DepartmentSummary[]
    pagination: Pagination
  }
}

type FetchSummariesSearchAction = {
  type: "FETCH_DEPARTMENT_SUMMARIES_SEARCH"
  payload: {
    departmentSummaries: DepartmentSummary[]
    pagination: Pagination
  }
}

type FetchSummaryAction = {
  type: "FETCH_DEPARTMENT_SUMMARY"
  payload: {
    departmentSummary: DepartmentSummary
    pagination: Pagination
  }
}

type UpdateDepartmentOverallProgress = {
  type: "UPDATE_DEPARTMENT_OVERALL_PROGRESS"
  payload: {
    departmentId: number
    progress: number
  }
}

export const departmentsReducer = (
  state: DepartmentSummariesState,
  action: DepartmentSummariesAction
): DepartmentSummariesState => {
  switch (action.type) {
    case "FETCH_MY_DEPARTMENT_SUMMARIES": {
      const departmentSummariesById: Record<number, DepartmentSummary> = {}
      const myDepartments: number[] = []
      const { departmentSummaries, pagination } = action.payload
      departmentSummaries.map(departmentSummary => {
        departmentSummariesById[departmentSummary.id] = departmentSummary
        myDepartments.push(departmentSummary.id)
      })
      return {
        ...state,
        departmentSummariesById: {
          ...state.departmentSummariesById,
          ...departmentSummariesById
        },
        myDepartments: getPaginationState(
          state.myDepartments,
          pagination,
          myDepartments
        )
      }
    }

    case "FETCH_ALL_DEPARTMENT_SUMMARIES": {
      const departmentSummariesById: Record<number, DepartmentSummary> = {}
      const allDepartments: number[] = []
      const { departmentSummaries, pagination } = action.payload
      departmentSummaries.map(departmentSummary => {
        departmentSummariesById[departmentSummary.id] = departmentSummary
        allDepartments.push(departmentSummary.id)
      })
      return {
        ...state,
        departmentSummariesById: {
          ...state.departmentSummariesById,
          ...departmentSummariesById
        },
        allDepartments: getPaginationState(
          state.allDepartments,
          pagination,
          allDepartments
        )
      }
    }

    case "FETCH_DEPARTMENT_SUMMARIES_SEARCH": {
      const departmentSummariesById: Record<number, DepartmentSummary> = {}
      const searchResults: number[] = []
      const { departmentSummaries, pagination } = action.payload
      departmentSummaries.map(departmentSummary => {
        departmentSummariesById[departmentSummary.id] = departmentSummary
        searchResults.push(departmentSummary.id)
      })
      return {
        ...state,
        departmentSummariesById: {
          ...state.departmentSummariesById,
          ...departmentSummariesById
        },
        searchResults: getPaginationState(
          state.searchResults,
          pagination,
          searchResults
        )
      }
    }

    case "FETCH_DEPARTMENT_SUMMARY": {
      const { departmentSummary } = action.payload
      return {
        ...state,
        departmentSummariesById: {
          ...state.departmentSummariesById,
          [departmentSummary.id]: departmentSummary
        }
      }
    }

    case "UPDATE_DEPARTMENT_OVERALL_PROGRESS": {
      const { departmentId, progress } = action.payload
      return {
        ...state,
        departmentSummariesById: {
          ...state.departmentSummariesById,
          [departmentId]: {
            ...state.departmentSummariesById[departmentId],
            overallProgress: progress
          }
        }
      }
    }
  }
  return { ...state }
}

export const DepartmentSummariesContext: React.Context<{
  state: DepartmentSummariesState
  dispatch: React.Dispatch<DepartmentSummariesAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: DepartmentSummariesAction) => {}
})

export const DepartmentSummariesProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(departmentsReducer, INITIAL_STATE)

  return (
    <DepartmentSummariesContext.Provider value={{ state, dispatch }}>
      {children}
    </DepartmentSummariesContext.Provider>
  )
}
