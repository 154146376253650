import Treasure from "td-js-sdk"
import * as Sentry from "@sentry/browser"
// @ts-ignore
import Actions from "../../actions/index"
import Settings from "../../settings"

export const TABLE_NAME = "tracked_events"

// TODO - add actual user type
let user: any

const options = {
  host: Settings.TREASURE_DATA_HOST,
  writeKey: Settings.TREASURE_DATA_WRITE_KEY,
  database: Settings.TREASURE_DATA_DATABASE,
  pathname: Settings.TREASURE_DATA_PATHNAME,
  development: Settings.TREASURE_DATA_DEVELOPMENT
}

const td = new Treasure(options)

// temp flag to see if we can capture failed td inits
// and reduce errors thrown from attempted errors thrown to Sentry
// this should fall under the idea that undefined td is false
// locally this variable defined to run once and once only during load
const didTreasureInitSuccessfullyOnLoad = td ? true : false
if (!didTreasureInitSuccessfullyOnLoad) {
  Sentry.captureException(
    new Error("Treasure Data instance td failed to initialise")
  )
}

const initGlobals = () =>
  new Promise(async resolve => {
    let timezoneName
    try {
      // Not supported on IE10 or old Android.
      timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (err) {
      timezoneName = null
    }

    const user = await Actions.User.loadCurrent()
    //$global options send on every call
    // NOTE: This needs to be sorted out too
    td.set("$global", "user_aggregate_id", user.employee_aggregate_id)
    td.set("$global", "timezone_offset", new Date().getTimezoneOffset())
    td.set("$global", "timezone_name", timezoneName)
    resolve()
  })

//don't send any more user data than is necessary
td.setAnonymousMode()

//with the tracker configured above, you are now ready to start tracking
td.trackClicks({ tableName: "clicks" }) //starts listening to click events, and will send each one to endpoint

/**
 * Helper to track page impressions. Given this is an SPA, to be called via
 * router hook.
 * @see https://github.com/treasure-data/td-js-sdk#page-impressions
 */
export const trackPageview = async (attempt: number = 1) => {
  // recursive check to ensure td has loaded from above whenReady call to handle first page
  // we attempt to load td 5 times and then we send an error to Sentry if td is still empty
  if (attempt > 5) {
    if (didTreasureInitSuccessfullyOnLoad) {
      Sentry.captureException(
        new Error("Treasure Data trackPageview isn't loading after 5 attempts")
      )
    }
    return
  }

  if (td) {
    if (!td.get("$global", "user")) {
      await initGlobals()
    }
    td.trackPageview("pageviews")
  } else {
    window.setTimeout(function() {
      trackPageview(attempt + 1)
    }, 500)
  }
}

export type Record = {
  event_name: string
  event_properties: Object
}

/**
 * Helper to track events.
 * @see https://github.com/treasure-data/td-js-sdk#event-tracking
 */
export const trackEvent = async (
  record: Record,
  table: string = TABLE_NAME,
  success?: Function,
  failure?: Function,
  attempt: number = 1
) => {
  // recursive check to ensure td has loaded from above whenReady call to handle first page
  // we attempt to load td 5 times and then we send an error to Sentry if td is still empty

  if (attempt > 5) {
    if (didTreasureInitSuccessfullyOnLoad) {
      Sentry.captureException(
        new Error("Treasure Data trackEvent isn't loading after 5 attempts")
      )
    }
    return
  }

  if (td) {
    if (!td.get("$global", "user")) {
      await initGlobals()
    }

    td.trackEvent(table, record, success, failure)
  } else {
    window.setTimeout(function() {
      trackEvent(record, table, success, failure, attempt + 1)
    }, 500)
  }
}
