/**
 * Decorator for a lazily computed property. The accessor function is only run
 * once, the first time the property is accessed on an object. The result is
 * saved and subsequent accesses of the property return this saved value.
 *
 * @example
 *
 *    class MyLazyClass {
 *      @lazy
 *      get myProp() {
 *         return someExpensiveOperation();
 *      }
 *    }
 *
 *    const obj = new MyLazyClass();
 *    obj.myProp; // invokes myProp() accessor function and calls someExpensiveOperation()
 *    obj.myProp; // uses the result from the first invocation
 *
 */
export default function lazy(target, key, descriptor) {
  if (!descriptor.get) {
    throw new Error("Only accessors may be passed to @lazy")
  }

  const computedValueKey = `__lazy__${key}`

  return {
    ...descriptor,

    get() {
      let computedValue = this[computedValueKey]

      if (computedValue === undefined) {
        // eslint-disable-next-line prefer-rest-params
        computedValue = descriptor.get.apply(this, arguments)

        Object.defineProperty(this, computedValueKey, {
          value: computedValue,
          writable: true,
          enumerable: false,
          configurable: true
        })
      }

      return computedValue
    }
  }
}
