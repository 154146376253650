import Reflux from "reflux-core"
import Actions from "../actions"
import observeSearchActions from "./lib/observeSearchActions"

const PublicPraiseStore = Reflux.createStore({
  init() {
    this.data = {
      publicPraiseSearchResults: null,
      publicPraisePagination: null
    }

    observeSearchActions(this, {
      getResults: () => this.data.publicPraiseSearchResults,
      searchAction: Actions.Feedback.loadPublicPraise,
      pageAction: Actions.Feedback.pagePublicPraise,
      onChange: (publicPraiseSearchResults, { pagination }) => {
        this.data = {
          ...this.data,
          publicPraiseSearchResults,
          publicPraisePagination: pagination
        }
        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  }
})

export default PublicPraiseStore
