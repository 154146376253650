import promiseFinally from "../../util/promiseFinally"
import UIActions from "../UIActions"

/**
 * Utility for ensuring that at most one superagent request is running at a time.
 */
export default class RequestSupplanter {
  _ongoingRequest = null

  /**
   * Sets `request` as the current request, canceling the previous request, if
   * any.
   *
   * @param {supremeagent.Request} request - the request to adopt
   * @return a promise that will resolve when the given request succeeds and
   *    the RequestSupplanter cleans up its tracking of the request.
   */
  replaceRequest(request) {
    if (this._ongoingRequest) {
      // eslint-disable-next-line no-console
      console.log("Previous request aborted!")
      this._ongoingRequest.abort()
      this._ongoingRequest = null
      UIActions.setLoadingState(false)
    }

    this._ongoingRequest = request

    return promiseFinally(request, () => {
      this._ongoingRequest = null
    })
  }
}
