import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import strings from "../../../locale/strings"
import { copyToClipboard } from "../../util/clipboard"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import LabeledEmployeePic from "../LabeledEmployeePic/LabeledEmployeePic"
import ZugataFormattedRelativeDate from "../../elements/ZugataFormattedDate/ZugataFormattedRelativeDate"
import CopyToClipboardButton from "../../elements/CopyToClipboardButton/CopyToClipboardButton"
import CopyableSurveyItem from "./CopyableSurveyItem"
import SurveyQuestion from "../SurveyQuestion/SurveyQuestion"
import User from "../../../models/User"
import Conversation from "../conversations/Conversation"
import ConversationMessage from "../conversations/ConversationMessage"

import "./SurveyQuestionConversation.less"

class SurveyQuestionConversation extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    className: PropTypes.string,
    index: PropTypes.number.isRequired,
    surveyItem: PropTypes.object.isRequired,
    htmlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    previewMode: PropTypes.bool,
    surveyResponse: PropTypes.object.isRequired,
    handleReply: PropTypes.func,
    onRepliesNeeded: PropTypes.func,
    enableCopyToClipboard: PropTypes.bool
  }

  static defaultProps = {
    enableCopyToClipboard: false,
    previewMode: false,
    readOnly: false,
    className: ""
  }

  canCopyToClipboard() {
    const { surveyItem, enableCopyToClipboard } = this.props

    // Copy/paste only supported for text questions
    return enableCopyToClipboard && surveyItem.question_type === "open-ended"
  }

  handleCopyToClipboardClick = e => {
    e.stopPropagation()

    copyToClipboard({
      node: this.copyableContentNode,
      successMessage: strings.profile.SRcopiedToClipboard,
      clearAfter: true
    })
  }

  renderQuestion() {
    const { surveyItem } = this.props
    const requiredIndicator = do {
      if (!surveyItem.required) {
        ;<FormattedMessage {...strings.customizedQuestions.optionalIndicator} />
      }
    }

    return [
      <div key="title" className="layout horizontal">
        <div className="SurveyQuestionConversation--title flex one">
          <MarkdownText text={surveyItem.question_title} inline={true} />
          <span className="SurveyQuestionConversation--question-optional">
            {" "}
            {requiredIndicator}
          </span>
        </div>

        {do {
          if (this.canCopyToClipboard()) {
            ;<div className="SurveyQuestionConversation--copy-button">
              <CopyToClipboardButton
                getTooltipContainer={() => this.container}
                onClick={this.handleCopyToClipboardClick}
              />
            </div>
          }
        }}
      </div>,

      do {
        if (surveyItem.question_description) {
          ;<MarkdownText
            key="description"
            className="SurveyQuestionConversation--description"
            text={surveyItem.question_description}
          />
        }
      }
    ]
  }

  renderAnswer() {
    const { surveyItem, surveyResponse, htmlId } = this.props

    return [
      <LabeledEmployeePic
        key="employee-pic"
        user={surveyResponse.author || surveyResponse.subject}
        detailLine={
          surveyItem.updated_at && (
            <ZugataFormattedRelativeDate value={surveyItem.updated_at} />
          )
        }
      />,

      <SurveyQuestion
        key="survey-question"
        question={{
          type: surveyItem.question_type,
          title: surveyItem.question_title,
          choices: surveyItem.choices
        }}
        answer={surveyItem}
        htmlId={`${htmlId}-survey-question`}
        hideQuestion={true}
        readOnly={true}
      />
    ]
  }

  /**
   * Renders a hidden div that represents the content that should actually be copied when copying
   * to clipboard. For example, omits the question description and author name.
   */
  renderCopyableContent() {
    const { surveyItem } = this.props

    if (this.canCopyToClipboard()) {
      return (
        <CopyableSurveyItem
          surveyItem={surveyItem}
          containerRef={node => (this.copyableContentNode = node)}
        />
      )
    }
  }

  renderConversation = () => {
    const {
      surveyItem,
      readOnly,
      onRepliesNeeded,
      handleReply,
      previewMode,
      surveyResponse: { lastSavedCommentId, loadingComments, subject }
    } = this.props
    const { comments, discussion_prompt: discussionPrompt } = surveyItem
    const currentUser = this.context.user

    const displayTooltip =
      User.isAdmin(currentUser) || User.isManagerOf(currentUser, subject)
    const commentsAsMessages =
      comments &&
      comments.map((comment, index) => {
        const { id, body, author, created_at: createdAt } = comment
        const authorObject = author[author.type]

        return (
          <ConversationMessage
            body={body}
            tooltip={
              // Show tooltip with discussion prompt for the first comment if discussion prompt
              // is present
              displayTooltip && index === 0 ? discussionPrompt : null
            }
            timestamp={createdAt}
            id={id}
            sender={authorObject}
            key={id}
          />
        )
      })

    const currentUserHasMadeComments = _.some(comments, comment => {
      const author = _.get(comment, "author.user")
      return author && User.equals(author, currentUser)
    })

    const prompt = do {
      if (
        !currentUserHasMadeComments &&
        User.isManagerOf(currentUser, subject)
      ) {
        discussionPrompt
      }
    }

    return (
      <Conversation
        key={surveyItem.id}
        sourceType="survey_item"
        sourceId={surveyItem.id}
        messages={commentsAsMessages}
        loading={loadingComments}
        lastSavedReplyId={lastSavedCommentId}
        inputPrompt={prompt}
        onRepliesNeeded={onRepliesNeeded}
        onReply={handleReply ? handleReply.bind(null, surveyItem) : null}
        scrollToBottom={false}
        readOnly={readOnly}
        preview={previewMode}
      />
    )
  }

  render() {
    const { className, index } = this.props

    return (
      <div
        ref={node => (this.container = node)}
        className={`SurveyQuestionConversation layout horizontal ${className}`}
      >
        <div className="SurveyQuestionConversation--question-number flex none">
          {index + 1}.
        </div>

        <div className="flex one">
          {this.renderCopyableContent()}
          {this.renderQuestion()}
          {this.renderAnswer()}

          {this.renderConversation()}
        </div>
      </div>
    )
  }
}

export default SurveyQuestionConversation
