import _ from "lodash"
import UserFilterType from "../../models/UserFilterType"
import { extractResponseBody } from "./apiActionHelpers"
import { collapseGroupTypes } from "./teamHelpers"
import SteadyfootAgent from "./SteadyfootAgent"

/**
 * @param {Object<string, string>} urls - from endpoints.json. Object that
 *    maps `UserFilterType` values to the endpoints for loading filter options. E.g. the
 *    `ADMIN_PERF_REVIEW_FILTER_URLS` entry.
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} cycleIds - Self Reflection/Evaluation/Survey cycle ID(s)
 * @param {string} cycleParamName - name of the cycle IDs param in the API, e.g.,
 *    `performance_cycle_ids`
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
export async function loadUserFilterOptions({
  urls,
  userFilterType,
  optionalAttributeType,
  cycleIds,
  cycleParamName,
  currentFilters,
  query,
  ids,
  page = 1,
  agent = SteadyfootAgent.defaultInstance
}) {
  const url = urls[userFilterType]
  const { departmentIds, managerIds, groupTypes, jobTitleIds } = currentFilters

  const searchParamName = do {
    if (userFilterType === UserFilterType.Levels) {
      ;("level_q")
    } else if (userFilterType === UserFilterType.OptionalAttributes) {
      ;("value_q")
    } else {
      ;("q")
    }
  }

  const rawParams = {
    [cycleParamName]: cycleIds,
    [searchParamName]: query,
    ids,
    page,
    per_page: 20,
    ...do {
      if (userFilterType === UserFilterType.Users) {
        ;({
          department_ids: departmentIds,
          manager_ids: managerIds,
          job_title_ids: jobTitleIds,
          group_type: collapseGroupTypes(groupTypes),
          per_page: 1000
        })
      } else if (userFilterType === UserFilterType.OptionalAttributes) {
        ;({
          department_ids: departmentIds,
          manager_ids: managerIds,
          attribute_type: optionalAttributeType
        })
      } else if (userFilterType === UserFilterType.Levels) {
        ;({
          job_title_ids: jobTitleIds,
          department_ids: departmentIds
        })
      } else if (userFilterType !== UserFilterType.Departments) {
        ;({ department_ids: departmentIds })
      }
    }
  }

  // Remove blank params and convert arrays to comma-separated strings
  // TODO: move to util?
  const params = _(rawParams)
    .pick(v => v || v === 0)
    .mapValues(v => (Array.isArray(v) ? v.join(",") : v))
    .value()

  let responseBody

  try {
    responseBody = await extractResponseBody(agent.get(url).query(params))
  } catch (error) {
    if (_.get(error, "status") !== 404) {
      throw error
    }
    return { results: [], meta: {} }
  }

  // Since SF wraps the array of results in a key specific to the endpoint, like
  // "performance_managers", just look for the first entry other than "meta"
  const results = _(responseBody)
    .omit("meta")
    .values()
    .first()

  return { results, meta: responseBody.meta }
}
