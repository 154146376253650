import Reflux from "../../actions/ActionsInitializer"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"
import { adaptSurveyResponseSearchParams } from "../../actions/lib/surveyHelpers"
import { loadUserFilterOptions } from "../../actions/lib/userFilterHelpers"
import SearchAgent from "../../actions/lib/SearchAgent"
import SurveyResponse from "../../models/SurveyResponse"
import endpoints from "../../api/endpoints.json"
import UIActions from "../../actions/UIActions"
import strings from "../../locale/strings"

const {
  ADMIN_SURVEYS_URLS: { ADMIN_SURVEY_RESPONSES_URL },
  ADMIN_SURVEY_RESPONSE_FILTER_URLS
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: ADMIN_SURVEY_RESPONSES_URL,
  resourceName: "survey_responses",
  pageSize: 25,
  modelWrapper: SurveyResponse.of
})

const AdminSurveyResponseActions = Reflux.createActions({
  searchSurveyResponses: { asyncResult: true },
  pageSurveyResponses: { asyncResult: true },
  clearSurveyResponses: {},
  loadFilterOptions: { asyncResult: true },
  getSurveyResponse: { asyncResult: true },
  createSurveyResponse: { asyncResult: true }
})

/**
 * @param {SurveyKind} kind
 * @param {number=} surveyId
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds, userIds}=} filters
 */
AdminSurveyResponseActions.searchSurveyResponses.listenAndPromise(
  ({ kind, surveyId = null, filters = {} }) =>
    addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        searchParams: {
          kind,
          survey_id: surveyId,
          ...adaptSurveyResponseSearchParams(filters)
        }
      })
    )
)

/**
 * @param {number} page
 * @param {SurveyKind} kind
 * @param {number=} surveyId
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds, userIds}=} filters
 */
AdminSurveyResponseActions.pageSurveyResponses.listenAndPromise(
  ({ page, kind, surveyId = null, filters = {} }) =>
    searchAgent.search({
      page,
      searchParams: {
        kind,
        survey_id: surveyId,
        ...adaptSurveyResponseSearchParams(filters)
      }
    })
)

/**
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} surveyIds
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
AdminSurveyResponseActions.loadFilterOptions.listenAndPromise(
  ({
    userFilterType,
    optionalAttributeType,
    surveyIds,
    currentFilters,
    query,
    ids,
    page = 1
  }) =>
    loadUserFilterOptions({
      urls: ADMIN_SURVEY_RESPONSE_FILTER_URLS,
      userFilterType,
      optionalAttributeType,
      cycleIds: surveyIds,
      cycleParamName: "survey_ids",
      currentFilters,
      query,
      ids,
      page
    })
)

AdminSurveyResponseActions.getSurveyResponse.listenAndPromise(id =>
  addLoadingState(
    true,
    extractResponseKey(
      "survey_response",
      SurveyResponse.of,
      agent.get(`${ADMIN_SURVEY_RESPONSES_URL}/${id}`)
    )
  )
)

AdminSurveyResponseActions.createSurveyResponse.listenAndPromise(
  (surveyResponse, numberOfReviewers, subjectFirstName) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {},
        agent.post(ADMIN_SURVEY_RESPONSES_URL).send({
          survey_response: {
            kind: surveyResponse.kind,
            subject_id: surveyResponse.subjectId,
            questions: surveyResponse.questions,
            author_ids: surveyResponse.authorIds
          }
        })
      ).then(() => {
        UIActions.success(strings.survey.MRF.createSurveyResponseSuccess, {
          numEmployees: numberOfReviewers,
          subject: subjectFirstName
        })
      })
    )
)

export default AdminSurveyResponseActions
