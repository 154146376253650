import Reflux from "reflux-core"
import Actions from "../actions"
import observeSearchActions from "./lib/observeSearchActions"

const GivenFeedbackStore = Reflux.createStore({
  init() {
    this.data = {
      reviewsGivenByRevieweeEmail: {},
      reviewsGivenSearchResults: null,
      reviewsGivenPagination: null
    }

    this.listenTo(
      Actions.Feedback.localClearReviewsGivenByRevieweeEmail,
      this.onLocalClearReviewsGivenByRevieweeEmail
    )

    this.listenTo(
      Actions.Feedback.loadReviewsGivenByRevieweeEmail.completed,
      this.onLoadReviewsGivenByRevieweeEmail
    )

    this.listenTo(Actions.Review.complete.completed, this.onReviewCompleted)

    observeSearchActions(this, {
      getResults: () => this.data.reviewsGivenSearchResults,
      searchAction: Actions.Feedback.loadReviewsGiven,
      pageAction: Actions.Feedback.pageReviewsGiven,
      onChange: (reviewsGivenSearchResults, { pagination }) => {
        this.data = {
          ...this.data,
          reviewsGivenSearchResults,
          reviewsGivenPagination: pagination
        }
        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  },

  appendReviewsToReviewsGivenByRevieweeEmail({
    currentReviewData,
    reviewsToAppend
  }) {
    const reviews = currentReviewData && currentReviewData.reviews

    if (!reviewsToAppend) {
      return { reviews, hasMore: false }
    } else if (!reviews) {
      return { reviews: reviewsToAppend, hasMore: !!reviewsToAppend.length }
    } else {
      return {
        reviews: [...reviews, ...reviewsToAppend],
        hasMore: !!reviewsToAppend.length
      }
    }
  },

  _replaceReviewsGivenByRevieweeEmail({ revieweeEmail, reviews }) {
    this.data = {
      ...this.data,
      reviewsGivenByRevieweeEmail: {
        ...this.data.reviewsGivenByRevieweeEmail,
        [revieweeEmail]: this.appendReviewsToReviewsGivenByRevieweeEmail({
          currentReviewData: this.data.reviewsGivenByRevieweeEmail[
            revieweeEmail
          ],
          reviewsToAppend: reviews
        })
      }
    }
  },

  onLoadReviewsGivenByRevieweeEmail({ revieweeEmail, reviews }) {
    this._replaceReviewsGivenByRevieweeEmail({ revieweeEmail, reviews })
    this.trigger(this.data)
  },

  onLocalClearReviewsGivenByRevieweeEmail({ revieweeEmail }) {
    this.data = {
      ...this.data,
      reviewsGivenByRevieweeEmail: {
        ...this.data.reviewsGivenByRevieweeEmail,
        [revieweeEmail]: null
      }
    }
    this.trigger(this.data)
  },

  onReviewCompleted(review) {
    const revieweeEmail = review.reviewee.email
    const existingReviews =
      this.data.reviewsGivenByRevieweeEmail[revieweeEmail] || []

    this._replaceReviewsGivenByRevieweeEmail(revieweeEmail, [
      ...existingReviews,
      { source_obj: { review, type: "review" } }
    ])

    this.trigger(this.data)
  }
})

export default GivenFeedbackStore
