import "./EmptyState.less"
import React from "react"
import Button, { Props as ButtonProps } from "../../elements/Button/Button"
import Aid from "../../../constants/automationId"

type Props = {
  imgSrc: string
  header: React.ReactNode
  body: React.ReactNode
  ctaProps?: Partial<ButtonProps>
  ctaText?: string
  automationId?: Aid
}

const EmptyState = ({
  imgSrc,
  body,
  ctaText,
  ctaProps,
  header,
  automationId
}: Props) => {
  return (
    <div className="EmptyState" data-automation-id={automationId}>
      {imgSrc && (
        <div className="EmptyState--image-container">
          <img className="EmptyState--img" src={imgSrc} />
        </div>
      )}
      <div className="EmptyState--text-container">
        <h2 className="EmptyState--header">{header}</h2>
        {body && <p className="EmptyState--body">{body}</p>}
        {ctaText && (
          <Button actionType="primary" {...ctaProps}>
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyState
