import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import { Team } from "../../../state/TeamsState"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useNotifications from "../../../hooks/useNotifications"
import TeamGoalForm, {
  TeamGoalFields
} from "../../widgets/TeamGoalForm/TeamGoalForm"
import useCreateTeamGoal from "../../../hooks/useCreateTeamGoal"
import strings from "../../../locale/strings"
import { User } from "../../../types/User"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"
import { trackTeamGoalCreated } from "../../../lib/analytics"

type NewTeamGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
}>

export const formDisabled = (loading: boolean, teams: Team[]) =>
  loading || teams.length === 0

const NewTeamGoal: NewTeamGoal = ({ intl, router }) => {
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const currentUser = useCurrentUser()

  const { createTeamGoal, loading, data, error } = useCreateTeamGoal()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.newTeamGoal.newTeamGoalSuccessTitle),
        message: formatMessage(
          strings.newTeamGoal.newTeamGoalSuccessNotification
        )
      })

      trackTeamGoalCreated()

      router.push({
        pathname: `/new_goals/team`,
        query: {
          // @ts-ignore
          goalId: data.goal.id
        }
      })
    }
  }, [data, formatMessage, router, showNotification])

  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.newTeamGoal.newTeamGoalErrorTitle),
        message: formatMessage(strings.newTeamGoal.newTeamGoalErrorNotification)
      })
    }
  }, [error, formatMessage, showNotification])

  const initOwners: User[] = [currentUser]
  const newGoal: TeamGoalFields = {
    name: "",
    teamId: "",
    description: "",
    dueDate: currentUser.company.goalCycle.end,
    priority: 1,
    owners: initOwners,
    keyResults: [newKeyResult()],
    alignedGoalIds: []
  }

  return (
    <TeamGoalForm
      title={formatMessage(strings.newTeamGoal.title)}
      submitLabel={formatMessage(strings.newTeamGoal.submitLabel)}
      defaultFields={newGoal}
      onSubmit={createTeamGoal}
      onLeavePage={() => router.push("/new_goals/team")}
      disabled={loading}
      initOwners={initOwners}
    />
  )
}

export default injectIntl(NewTeamGoal)
