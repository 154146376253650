import { useState, useEffect } from "react"
import useDataAPI from "./useDataAPI"
import teamFeedbackReviewersSuggestionsDecoder, {
  ReviewerSuggestion
} from "../decoders/teamFeedbackReviewersSuggestionsDecoder"

const useTeamFeedbackReviewersSuggestions = (
  userId?: string,
  teamId?: string
) => {
  const [suggestions, setSuggestions] = useState<ReviewerSuggestion[]>([])
  const { data, loading, error, fire } = useDataAPI(
    "/users/team_based_feedback_suggestions",
    "GET"
  )

  useEffect(() => {
    if (userId && teamId) {
      fire({
        user_aggregate_id: userId,
        team_id: teamId
      })
    }
  }, [userId, teamId, fire])

  useEffect(() => {
    if (data) {
      const saveSuggestions = async () => {
        const suggestions = await teamFeedbackReviewersSuggestionsDecoder.decodePromise(
          data
        )

        setSuggestions(suggestions)
      }

      saveSuggestions()
    }
  }, [data])

  return {
    suggestions,
    loading,
    error
  }
}

export default useTeamFeedbackReviewersSuggestions
