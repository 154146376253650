import PropTypes from "prop-types"
import React from "react"
import uniqueId from "lodash/utility/uniqueId"
import { copyToClipboard } from "../../../util/clipboard"
import CopyToClipboardButton from "../../../elements/CopyToClipboardButton/CopyToClipboardButton"
import CopyableSurveyItem from "../CopyableSurveyItem"
import MarkdownText from "../../../elements/MarkdownText/MarkdownText"
import SurveyQuestion from "../../SurveyQuestion/SurveyQuestion"
import strings from "../../../../locale/strings"
import "./CompletedMRFSurveyResponse.less"

class CompletedMRFSurveyResponse extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    surveyResponse: PropTypes.object,
    enableCopyToClipboard: PropTypes.bool
  }

  static defaultProps = {
    className: ""
  }

  constructor(props) {
    super(props)

    this.copyableContentNodes = {}
  }

  htmlId = uniqueId("CompletedMRFSurveyResponse-")

  handleCopyToClipboardClick = (nodeId, e) => {
    e.stopPropagation()

    copyToClipboard({
      node: this.copyableContentNodes[nodeId],
      successMessage: strings.profile.copiedToClipboard,
      clearAfter: true
    })
  }

  renderSurveyItem = surveyItem => {
    const { enableCopyToClipboard } = this.props
    const itemIsCopyable = surveyItem.question_type === "open-ended"

    return (
      <div key={surveyItem.id} className="layout horizontal">
        <div className="flex one CompletedMRFSurveyResponse--item">
          {enableCopyToClipboard && itemIsCopyable && (
            <CopyableSurveyItem
              surveyItem={surveyItem}
              containerRef={ref =>
                (this.copyableContentNodes[surveyItem.id] = ref)
              }
            />
          )}

          {surveyItem.question_title !== "" &&
            surveyItem.question_title !== "\u200b" && (
              <div className="CompletedMRFSurveyResponse--title flex one">
                <MarkdownText text={surveyItem.question_title} inline={true} />
              </div>
            )}

          {surveyItem.question_description && (
            <MarkdownText
              className="CompletedMRFSurveyResponse--description"
              text={surveyItem.question_description}
            />
          )}

          <SurveyQuestion
            question={{
              type: surveyItem.question_type,
              title: surveyItem.question_title,
              choices: surveyItem.choices
            }}
            answer={surveyItem}
            htmlId={`${this.htmlId}-${surveyItem.id}`}
            hideQuestion={true}
            readOnly={true}
          />
        </div>

        {enableCopyToClipboard && (
          <div
            className="CompletedMRFSurveyResponse--copy-button-container flex none"
            style={
              // When the overall component has copying enabled but this item isn't copyable,
              // we render the copy button but with `visibility: hidden`. This is an easy way
              // to ensure a consistent right margin between copyable and non-copyable items
              !itemIsCopyable ? { visibility: "hidden" } : {}
            }
          >
            <CopyToClipboardButton
              getTooltipContainer={() => this.container}
              onClick={e => this.handleCopyToClipboardClick(surveyItem.id, e)}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { className, surveyResponse } = this.props

    return (
      <div
        className={`CompletedMRFSurveyResponse ${className}`}
        ref={ref => (this.container = ref)}
      >
        {surveyResponse.survey_items.map(this.renderSurveyItem)}
      </div>
    )
  }
}

export default CompletedMRFSurveyResponse
