import "./CompensationBucketPicker.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { injectIntl } from "react-intl"
import strings from "../../../locale/strings"
import MultipleChoice from "../MultipleChoice/MultipleChoice"
import CollaboratorCommentsList from "../CollaboratorCommentsList/CollaboratorCommentsList"

class CompensationBucketPicker extends React.Component {
  static propTypes = {
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string
      }).isRequired
    ).isRequired,

    assignedBucket: PropTypes.object,
    onSelect: PropTypes.func,
    review: PropTypes.object,
    readOnly: PropTypes.bool,
    index: PropTypes.number,
    questionTitle: PropTypes.string,
    questionDescription: PropTypes.string,
    shareable: PropTypes.bool,
    displayPosition: PropTypes.number,
    displayComments: PropTypes.bool,
    automationId: PropTypes.string
  }

  static defaultProps = {
    onSelect: _.noop
  }

  mapBucketsToAnswers = ({ buckets }) => {
    return buckets.map(bucket => ({
      title: bucket.title,
      description: bucket.description,
      value: bucket.value
    }))
  }

  handleSelectAnswer = value => {
    if (!value) {
      return
    }
    const { onSelect, buckets } = this.props
    onSelect(buckets.find(bucket => bucket.value === value).id)
  }

  render() {
    const {
      buckets,
      assignedBucket,
      shareable,
      review,
      readOnly,
      displayPosition,
      questionTitle,
      questionDescription,
      displayComments,
      intl: { formatMessage },
      automationId
    } = this.props

    const title =
      questionTitle || formatMessage(strings.performanceReviews.bucketQuestion)

    return (
      <div>
        <MultipleChoice
          className="CompensationBucketPicker"
          question={{
            id: "buckets",
            buckets,
            title: title,
            description: questionDescription,
            can_share: shareable
          }}
          htmlId={`evalutaion-${review && review.id}-question-buckets`}
          answerOptionsMapper={this.mapBucketsToAnswers}
          selectedAnswerValue={_.get(assignedBucket, "value")}
          onSelectAnswer={this.handleSelectAnswer}
          reviewId={review && review.id}
          allowDeselect={false}
          textAlign={"left-align"}
          readOnly={readOnly}
          orientation="vertical"
          index={displayPosition}
          disableColor={true}
          automationId={automationId}
        />
        {displayComments && _.get(review, "commenters.length") > 0 && (
          <div className="CompensationBucketPicker--comments">
            <CollaboratorCommentsList
              collapsable={true}
              initiallyExpanded={review.status === "incomplete"}
              commenters={review.commenters}
              user={review.user}
            />
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(CompensationBucketPicker)
export { CompensationBucketPicker as RawCompensationBucketPicker }
