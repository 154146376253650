import uuid from "uuid/v4"
import useDataAPI from "./useDataAPI"
import { TeamMember } from "../state/TeamsState"
import { User } from "../types/User"

const membershipData = (teamId: string) => (user: User | TeamMember) => ({
  // @ts-ignore
  team_membership_id: user.membershipId ? user.membershipId : uuid(),
  member_id: user.aggregateId,
  team_id: teamId
})

type Team = {
  name: string
  description: string
  teamLeads: Array<User>
  teamMembers: Array<User>
}

const useUpdateTeam = (teamId: string) => {
  const fetchAPI = useDataAPI(`/teams/${teamId}`, "PUT")
  const updateTeam = ({ name, description, teamLeads, teamMembers }: Team) => {
    const membershipForTeamData = membershipData(teamId)
    const teamData = {
      team: {
        team_id: teamId,
        name: name,
        description: description
      },
      team_leads: teamLeads.map(membershipForTeamData),
      team_members: teamMembers.map(membershipForTeamData)
    }
    fetchAPI.fire(teamData)
  }
  return {
    updateTeam,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading
  }
}

export default useUpdateTeam
