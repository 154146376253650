import PropTypes from "prop-types"
import React from "react"
import { intersperseElement } from "../../../../util/react"
import SurveyQuestionConversation from "../SurveyQuestionConversation"

import "./CompletedSelfReflectionSurveyResponse.less"

const CompletedSelfReflectionSurveyResponse = ({
  className,
  surveyResponse,
  loadSurveyResponse,
  handleReply,
  htmlId,
  readOnly,
  enableCopyToClipboard,
  previewMode,
  hideDivider
}) => (
  <div className={`CompletedSelfReflectionSurveyResponse ${className}`}>
    {intersperseElement(
      surveyResponse.survey_items.map((surveyItem, index) => (
        <SurveyQuestionConversation
          key={`survey-item-${surveyItem.id}`}
          htmlId={`${htmlId}-${surveyResponse.id}-survey-item-${surveyItem.id}`}
          index={index}
          surveyItem={surveyItem}
          readOnly={readOnly}
          surveyResponse={surveyResponse}
          onRepliesNeeded={loadSurveyResponse}
          handleReply={handleReply}
          enableCopyToClipboard={enableCopyToClipboard}
          previewMode={previewMode}
        />
      )),
      <hr
        className={`CompletedSelfReflectionSurveyResponse--divider ${
          hideDivider ? "hidden" : ""
        }`}
      />
    )}
  </div>
)

CompletedSelfReflectionSurveyResponse.propTypes = {
  surveyResponse: PropTypes.object,
  loadSurveyResponse: PropTypes.func,
  readOnly: PropTypes.bool,
  handleReply: PropTypes.func,
  htmlId: PropTypes.string.isRequired,
  className: PropTypes.string,
  enableCopyToClipboard: PropTypes.bool,
  hideDivider: PropTypes.bool
}

CompletedSelfReflectionSurveyResponse.defaultProps = {
  className: "",
  hideDivider: false
}

export default CompletedSelfReflectionSurveyResponse
