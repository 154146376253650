import {
  TableCard,
  TableContainer
} from "@kaizen/component-library/draft/Kaizen/Table"
import { InlineNotification } from "@kaizen/component-library"
import classnames from "classnames"
import * as React from "react"
import { useState } from "react"
import { Location } from "history"
import { InjectedRouter } from "react-router"
import { parse } from "query-string"
import { injectIntl, InjectedIntl } from "react-intl"
import Loading from "../../elements/Loading/Loading"
import useTeamSummaries from "../../../hooks/useTeamSummaries"
import GoalsSummaryCell from "../../elements/GoalsSummaryCell/GoalsSummaryCell"
import ScrollContainer from "../ScrollContainer/ScrollContainer"
import TeamGoalsList from "../TeamGoalsList/TeamGoalsList"
import styles from "./TeamSummariesList.scss"
import GoalsTableHeader from "../../elements/GoalsTableHeader/GoalsTableHeader"
import FiltersBar, { Filters } from "../FiltersBar/FiltersBar"
import GoalsEmptyState from "../GoalsEmptyState/GoalsEmptyState"
import strings from "../../../locale/strings"
import useCurrentUser from "../../../hooks/useCurrentUser"

type TeamSummariesList = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
  intl: InjectedIntl
}>

const datePickerFilterName = (index: number) => (index === 0 ? "from" : "to")

const TeamSummariesList: TeamSummariesList = ({ router, location, intl }) => {
  const currentUser = useCurrentUser()
  const defaultFilters = {
    all_teams: false,
    q: "",
    from: currentUser.company.goalCycle.start,
    to: currentUser.company.goalCycle.end
  }

  const [filters, setFilters] = useState<Filters>(defaultFilters)
  const {
    teams,
    fetchMore: fetchMoreTeams,
    loading,
    hasMore: hasMoreTeams
  } = useTeamSummaries(filters)

  const setSelectedTeamId = (selectedTeamId: string | undefined) => {
    router.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        selectedTeamId
      }
    })
  }
  const { formatMessage } = intl
  const { selectedTeamId } = parse(location.search)

  const teamsWithGoals = teams.filter(team => team.goalsCount > 0)

  return (
    <div>
      <InlineNotification
        title={formatMessage(
          strings.goalsPage.teamGoals.lookingForDirectReports
        )}
        type="informative"
        persistent={true}
      >
        {formatMessage(strings.goalsPage.teamGoals.youCanView)}{" "}
        <a href="/performance/goals/team">
          {formatMessage(strings.goalsPage.teamGoals.viewGoals)}
        </a>
      </InlineNotification>
      <FiltersBar onFiltersChange={setFilters} defaultFilters={defaultFilters}>
        <div className={classnames([styles.filter])}>
          <FiltersBar.Select
            name="all_teams"
            options={[
              {
                value: false,
                label: formatMessage(strings.teams.teamsList.myTeams)
              },
              {
                value: true,
                label: formatMessage(strings.teams.teamsList.allTeams)
              }
            ]}
          />
        </div>
        <div className={styles.filter}>
          <FiltersBar.DatePicker name={datePickerFilterName} allowDateRange />
        </div>
        <div className={classnames([styles.filter, styles.filterRight])}>
          <FiltersBar.Search name="q" />
        </div>
      </FiltersBar>
      <ScrollContainer
        onScrolledToBottom={() => hasMoreTeams && fetchMoreTeams()}
      >
        <TableContainer>
          {loading && !hasMoreTeams ? (
            <Loading />
          ) : teamsWithGoals.length > 0 ? (
            <React.Fragment>
              <GoalsTableHeader key={"table-header"} goalType={"team"} />
              {teamsWithGoals.map(team => {
                const teamSelected = team.id === selectedTeamId
                return (
                  <TableCard
                    key={team.id}
                    expanded={teamSelected}
                    onClick={
                      teamSelected
                        ? undefined
                        : () => setSelectedTeamId(team.id)
                    }
                  >
                    {teamSelected ? (
                      <React.Fragment>
                        <div className={styles.topSpace} />
                        <TeamGoalsList
                          team={team}
                          filters={filters}
                          onSelectTeam={() => setSelectedTeamId(undefined)}
                        />
                      </React.Fragment>
                    ) : (
                      <div className={styles.team}>
                        <GoalsSummaryCell
                          ownerName={team.name}
                          progress={Math.floor(team.overallProgress * 100)}
                          goalsCount={team.goalsCount}
                          expandable
                          open={false}
                        />
                      </div>
                    )}
                  </TableCard>
                )
              })}
              {loading && hasMoreTeams && <Loading />}
            </React.Fragment>
          ) : (
            <GoalsEmptyState
              headingText={strings.teamSummariesList.emptyStateHeadingText}
              bodyText={strings.teamSummariesList.emptyStateBodyText}
            />
          )}
        </TableContainer>
      </ScrollContainer>
    </div>
  )
}

export default injectIntl(TeamSummariesList)
