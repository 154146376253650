import _ from "lodash"
import { Department, JobTitle } from "../models/EmployeeGrouping"

export default {
  init() {
    this.data = {
      departments: [],
      jobTitlesByParentId: {}
    }
  },

  getInitialState() {
    return this.data
  },

  onListEmployeeGroupings(employeeGroupings) {
    this.data = {
      ...this.data,
      departments: employeeGroupings.filter(eg => eg instanceof Department),
      jobTitlesByParentId: _(employeeGroupings)
        .filter(eg => eg instanceof JobTitle)
        .groupBy("parent_job_title_id")
        .value()
    }

    this.trigger(this.data)
  },

  onGetDepartments(departments) {
    this.data = {
      ...this.data,
      departments
    }

    this.trigger(this.data)
  }
}
