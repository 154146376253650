import { useCallback } from "react"
import useDataAPI from "./useDataAPI"

const useArchiveTeam = (teamId: string) => {
  const archiveAPI = useDataAPI(`/teams/${teamId}/archive`, "PUT")

  const archiveTeam = useCallback(() => {
    archiveAPI.fire()
  }, [archiveAPI])

  return {
    archiveTeam,
    loading: archiveAPI.loading,
    success: !!archiveAPI.data && !archiveAPI.error
  }
}

export default useArchiveTeam
