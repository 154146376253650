import _ from "lodash"
import promiseFinally from "../../util/promiseFinally"
import strings from "../../locale/strings"

// NB: To avoid potential circular dependencies, this should probably be the
// only actions module imported.
import UIActions from "../UIActions"

export function addLoadingState(loadingArgs, promise) {
  if (!(Array.isArray(loadingArgs) || _.isArguments(loadingArgs))) {
    loadingArgs = [loadingArgs]
  }

  if (loadingArgs[0] === false) {
    return promise
  }

  // eslint-disable-next-line prefer-spread
  UIActions.setLoadingState.apply(UIActions, loadingArgs)

  return promiseFinally(promise, () =>
    UIActions.setLoadingState(false, ...loadingArgs.slice(1))
  )
}

export function addToasts(
  { success, defaultError = strings.toast.genericErrorMessage },
  promise,
  hideSuccessToast
) {
  return promise.then(
    result => {
      if (success) {
        !hideSuccessToast && UIActions.success(success)
      }
      return result
    },
    err => {
      const status = _.get(err, "response.status")
      const msg = _.get(err, "response.body.error")
      UIActions.error(msg && status !== 500 ? msg : defaultError)
      return Promise.reject(err)
    }
  )
}

/**
 * Given a promise for a SupremeAgent request, returns a promise that
 * tries to extract the parsed body from the response, failing if
 * the response failed.
 */
export function extractResponseBody(requestPromise) {
  return requestPromise.then(resp => resp.body)
}

/**
 * Given a promise for a SupremeAgent request, returns a promise that
 * tries to extract a given key from the response JSON, failing if
 * the key is not present.
 *
 * @param {string} key
 * @param {Function=} modelWrapper
 * @param {Promise} requestPromise
 */
export function extractResponseKey(key, modelWrapper = null, requestPromise) {
  if (!requestPromise) {
    requestPromise = modelWrapper
    modelWrapper = null
  }

  return requestPromise.then(response => {
    const result = _.get(response, ["body", key])

    return result === null || result === undefined
      ? // Reject it in this way to have a similar shape to superagent errors.
        Promise.reject({ response })
      : modelWrapper
      ? Array.isArray(result)
        ? result.map(modelWrapper)
        : modelWrapper(result)
      : result
  })
}
