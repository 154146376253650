import Reflux from "reflux-core"
import Actions from "../actions"
import BaseIncompleteSurveyResponseStore from "./BaseIncompleteSurveyResponseStore"
import { SurveyKind } from "../constants/survey"

const IncompleteSurveyResponseStore = Reflux.createStore({
  ...BaseIncompleteSurveyResponseStore,

  init() {
    BaseIncompleteSurveyResponseStore.init.call(this, {
      actions: Actions.IncompleteSurveyResponse
    })

    this.data = {
      ...this.data,
      incompleteMRF: []
    }

    this.listenTo(
      Actions.SurveyResponse.searchSurveyResponses.completed,
      this.onSearchSurveys
    )
    this.listenTo(
      Actions.IncompleteSurveyResponse.submitSurveyResponse.completed,
      this.onSurveyFinished
    )
    this.listenTo(
      Actions.IncompleteSurveyResponse.rejectIncompleteSurveyResponse.completed,
      this.onSurveyFinished
    )
  },

  onSearchSurveys({ results: surveyResponses }) {
    this.data = {
      ...this.data,
      incompleteMRF: [
        ...surveyResponses.filter(survey => survey.kind === SurveyKind.MRF)
      ]
    }

    this.trigger(this.data)
  },

  onSurveyFinished(surveyResponse) {
    this.data = {
      ...this.data,
      incompleteMRF: this.data.incompleteMRF.filter(
        survey => survey.id !== surveyResponse.id
      )
    }

    this.trigger(this.data)
  }
})

export default IncompleteSurveyResponseStore
