import Reflux from "reflux-core"
import * as AdminActions from "../actions"

export const createAdminUserUploadsStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        importRequests: null,
        pagination: {}
      }

      this.listenTo(
        AdminActions.AdminUserActions.listImportRequests.completed,
        ({ userUploads, meta }) => {
          this.data = {
            ...this.data,
            importRequests:
              meta.pagination && meta.pagination.current_page > 1
                ? [...this.data.importRequests, ...userUploads]
                : userUploads,
            pagination: meta.pagination
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminActions.AdminUserActions.importUserCsv.completed,
        newImportRequest => {
          this.data = {
            ...this.data,
            importRequests: [newImportRequest, ...this.data.importRequests]
          }

          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminActions.AdminUserActions.cancelImportUserCsv.completed,
        this.onGetImportRequest
      )

      this.listenTo(
        AdminActions.AdminUserActions.continueImportUserCsv.completed,
        this.onGetImportRequest
      )
    },

    getInitialState() {
      return this.data
    },

    onGetImportRequest(newImportRequest) {
      this.transformImportRequests(importRequest =>
        importRequest.id === newImportRequest.id
          ? newImportRequest
          : importRequest
      )

      this.trigger(this.data)
    },

    transformImportRequests(transformImportRequest) {
      const importRequests = this.data.importRequests
      const updatedImportRequests =
        importRequests && importRequests.map(transformImportRequest)

      this.data = {
        ...this.data,
        importRequests: updatedImportRequests
      }
    }
  })

export default createAdminUserUploadsStore()
