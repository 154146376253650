import { useContext, useEffect } from "react"
import { getPersonalGoalsResponseDecoder } from "../decoders/GetPersonalGoalsResponseDecoder/getPersonalGoalsResponseDecoder"
import { GoalsContext } from "../state/GoalsState"
import { PersonalGoalPreview } from "../types/Goals"
import useDataAPI from "./useDataAPI"

type Filters = {
  from?: string
  to?: string
}

const usePersonalGoals = (filters: Filters) => {
  const { state, dispatch } = useContext(GoalsContext)
  const { fire, data, loading } = useDataAPI("/goals?type=my_goal", "GET")

  useEffect(() => {
    fire({ page: 1, ...filters })
  }, [filters, fire])

  useEffect(() => {
    if (data) {
      getPersonalGoalsResponseDecoder
        .decodePromise(data)
        .then(response => {
          dispatch({ type: "FETCH_PERSONAL", payload: response })
        })
        .catch(error => {
          throw error
        })
    }
  }, [data, dispatch])

  const fetchMore = () => {
    const nextPage = state.personalGoals.nextPage
    if (nextPage) {
      fire({ page: nextPage, ...filters })
    }
  }

  const personalGoals = Object.values(state.personalGoals.pages)
    .reduce((res, goals) => [...res, ...goals], [])
    .map(goalId => state.goalsPreviewsById[goalId] as PersonalGoalPreview)

  const hasMore = state.personalGoals.totalCount > personalGoals.length

  return {
    hasMore,
    fetchMore,
    personalGoals,
    goalStats: state.goalStats,
    loading
  }
}

export default usePersonalGoals
