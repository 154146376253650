import { JsonDecoder } from "ts.data.json"
import { TeamGoal } from "../../types/Goals"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  goalKeyResultDecoder,
  goalPriorityDecoder
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

export const teamGoalDecoder: JsonDecoder.Decoder<TeamGoal> = JsonDecoder.object<
  TeamGoal
>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
    priority: goalPriorityDecoder,
    type: JsonDecoder.isExactly("team_goal").map((): "team" => "team"),
    owners: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalOwnerDecoder, "goalOwners")
    ),
    teamId: JsonDecoder.failover("", JsonDecoder.string),
    teamName: JsonDecoder.failover("", JsonDecoder.string),
    description: JsonDecoder.failover(undefined, JsonDecoder.string),
    status: JsonDecoder.string,
    keyResults: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalKeyResultDecoder, "keyResults")
    ),
    visibility: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    ),
    outgoingAlignmentAssociations: JsonDecoder.failover(
      undefined,
      JsonDecoder.array(
        JsonDecoder.object<{
          id: number
          alignedGoalId: number
        }>(
          {
            id: JsonDecoder.number,
            alignedGoalId: JsonDecoder.number
          },
          "outgoing alignment association",
          {
            alignedGoalId: "incoming_alignment_goal_id"
          }
        ),
        "outgoing alignment associations "
      )
    )
  },
  "teamGoal",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    owners: "goal_owners",
    teamId: "team_id",
    teamName: "team_name",
    keyResults: "key_results",
    outgoingAlignedGoals: "outgoing_aligned_goals",
    outgoingAlignmentAssociations: "outgoing_alignment_associations"
  }
)
