import React, { useState, useMemo, useEffect, useCallback } from "react"
import { FormattedMessage, InjectedIntl } from "react-intl"
import { Location } from "history"
import { Text, Button } from "@kaizen/component-library"
import { List } from "immutable"
import { InjectedRouter } from "react-router/lib/Router"
import { WithRouterProps } from "react-router"
import useTeam from "../../../hooks/useTeam"
import UserSelector from "../../elements/UserSelector/UserSelector"
import Avatar from "../../Avatar/Avatar"
import strings from "../../../locale/strings"
// @ts-ignore
import MarkdownFormattedMessage from "../../elements/MarkdownFormattedMessage/MarkdownFormattedMessage"
// @ts-ignore
import Typeahead from "../../elements/Typeahead/Typeahead"
// @ts-ignore
import TypeaheadRowGroup from "../../widgets/TypeaheadRowGroup/TypeaheadRowGroup"
// @ts-ignore
import TypeaheadRowWithAvatar from "../../widgets/TypeaheadRowWithAvatar/TypeaheadRowWithAvatar"
// @ts-ignore
import SurveyQuestionsManager from "../../elements/SurveyQuestionsManager/SurveyQuestionsManager"
import styles from "./TeamFeedbackRequest.scss"
import useTeamFeedbackReviewersSuggestions from "../../../hooks/useTeamFeedbackReviewersSuggestions"
import useRequestTeamBasedFeedback from "../../../hooks/useRequestTeamBasedFeedback"
import useTeamBasedFeedbackQuestionTemplate from "../../../hooks/useTeamBasedFeedbackQuestionTemplates"
import SuccessConfirmationModal from "./SuccessConfirmationModal"
import UnsavedChangesModal from "./UnsavedChangesConfirmationModal"
import useNotifications from "../../../hooks/useNotifications"
import { injectIntl } from "../../../lib/react-intl-with-custom-terms"
import Aid from "../../../constants/automationId"

type TeamFeedbackRequest = React.FC<
  {
    router: Pick<InjectedRouter, "push">
    intl: InjectedIntl
    location: Location
  } & WithRouterProps
>

type Question = {
  type: string
  title: string
  description?: string
  required: boolean
}

type FeedbackOptionUser = {
  id: number
  name: string
  email: string
  avatar: string
  group: false
}

type FeedbackOptionGroup = {
  group: true
  count: number
  title: string
  users: FeedbackOptionUser[]
}

type FeedbackOption = FeedbackOptionUser | FeedbackOptionGroup
type TeamMemberId = string | undefined

const TeamFeedbackRequest: TeamFeedbackRequest = ({
  router,
  intl,
  location,
  params
}) => {
  const { formatMessage } = intl
  const { teamId } = params

  // re-route if no team provided to /teams
  if (!teamId) {
    router.push("teams")
  }

  const { team, loading } = useTeam(teamId)

  const [teamMemberId, setTeamMemberId] = useState<TeamMemberId>(
    team?.details?.teamMembers[0]?.aggregateId
  )
  const [reviewers, setReviewers] = useState<FeedbackOptionUser[]>([])
  const [
    showSuccessConfirmationModal,
    setShowSuccessConfirmationModal
  ] = useState(false)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const { showNotification } = useNotifications()

  const teamMember =
    team?.details?.teamMembers.find(t => t.aggregateId === teamMemberId) ||
    team?.details?.teamLeads.find(t => t.aggregateId === teamMemberId) ||
    team?.details?.teamMembers[0]

  const { suggestions } = useTeamFeedbackReviewersSuggestions(
    teamMemberId,
    teamId
  )

  const { template } = useTeamBasedFeedbackQuestionTemplate(
    useMemo(() => reviewers.map(r => r.id), [reviewers]),
    teamMember && teamMember.id
  )

  const [questions, setQuestions] = useState<Question[]>(
    (template && template.questions) || []
  )

  // Update the state for the selected team member
  const handleSelectUser = (id: string) => {
    setTeamMemberId(id)
  }

  const { requestFeedback } = useRequestTeamBasedFeedback(
    teamId,
    questions,
    reviewers,
    teamMember
  )

  useEffect(() => {
    if (template) {
      setQuestions(template.questions)
    }
  }, [template])

  const usingCustomQuestions = template?.questions !== questions

  // isDiff returns true if there are unsaved changes on the form
  const isDiff = usingCustomQuestions || reviewers.length > 0

  const handleResetQuestions = useCallback(() => {
    if (template) {
      setQuestions(template.questions)
    }
  }, [template])

  const handleRequestTbf = () => {
    setReviewers([])
    setShowSuccessConfirmationModal(false)
  }

  if (!team || !teamMember) return null
  if (loading) return <div className={styles.content}>Loading ...</div>

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        {showUnsavedChangesModal && (
          <UnsavedChangesModal
            onLeavePage={() => router.push(`/teams/${teamId}`)}
            onCancel={() => setShowUnsavedChangesModal(false)}
          />
        )}
        {showSuccessConfirmationModal && (
          <SuccessConfirmationModal
            onLeavePage={() => router.push(`/teams/${teamId}`)}
            onRequestTbf={handleRequestTbf}
          />
        )}

        <div className={styles.titleContainer}>
          <Text tag="h2" style="zen-heading-2">
            <FormattedMessage
              {...strings.teamLeadRequestFeedback.requestFeedback}
            />
          </Text>
        </div>
        <div
          className={styles.titleContainer}
          data-automation-id={Aid.teamBasedFeedbackReviewee}
        >
          <div className={styles.avatarContainer}>
            <Avatar
              imageURL={
                teamMember.profileImage
                  ? teamMember.profileImage
                  : teamMember.avatar
              }
              automationId={Aid.teamBasedFeedbackAvatar}
            />
          </div>
          <Text tag="h3" inline={true} inheritBaseline style="zen-heading-3">
            <MarkdownFormattedMessage
              {...strings.teamLeadRequestFeedback.feedbackAbout}
              values={{ name: teamMember.name }}
            />
          </Text>
        </div>
        <div className={styles.reviewersSelector}>
          <Text tag="p" style="body-bold">
            <FormattedMessage
              {...strings.teamLeadRequestFeedback.requestFrom}
            />
          </Text>
        </div>
        <div className={styles.typeaheadContainer}>
          <Typeahead
            automationId={Aid.teamBasedFeedbackTypeAhead}
            className="SurveyFeedbackRequests--reviewer-widget-dropdown flex"
            zugataStyle={true}
            //key={`${reviewers ? reviewers.length : 0}`}
            value={reviewers}
            placeholder={
              <FormattedMessage
                {...strings.teamLeadRequestFeedback.selectFromDropdown}
              />
            }
            // @ts-ignore
            valueComponent={config => {
              const { value, onRemove } = config
              return (
                <TypeaheadRowWithAvatar
                  user={{
                    full_name: value.name,
                    email: value.email,
                    avatar_images: {
                      thumb_url: value.avatar
                    },
                    profile_image_url: value.avatar
                  }}
                  showEmail={false}
                  clearable={true}
                  onClear={() => onRemove(value)}
                />
              )
            }}
            // @ts-ignore
            optionRenderer={option =>
              option.group ? (
                <TypeaheadRowGroup count={option.count} title={option.title} />
              ) : (
                <TypeaheadRowWithAvatar
                  user={{
                    full_name: option.name,
                    email: option.email,
                    avatar_images: {
                      thumb_url: option.avatar
                    },
                    profile_image_url: option.avatar
                  }}
                />
              )
            }
            loadOptions={() => {
              const shouldIncludeGroupOption = suggestions.some(
                s => !reviewers.find(r => r.id === s.id)
              )

              const results = shouldIncludeGroupOption
                ? [
                    {
                      group: true,
                      count: suggestions.length,
                      title: formatMessage(
                        strings.teamLeadRequestFeedback
                          .requestFromOtherTeamMembers,
                        { team: team?.name }
                      ),
                      users: suggestions
                    },
                    ...suggestions
                  ]
                : suggestions

              return Promise.resolve({
                results
              })
            }}
            refreshOptionsOnFocus={true}
            clearable={false}
            allowCreate={false}
            searchable={true}
            multi={true}
            onChange={(options: FeedbackOption[]) => {
              const reviewers = options.reduce<FeedbackOptionUser[]>(
                (users, option) =>
                  option.group ? [...option.users] : [...users, option],
                []
              )
              setReviewers(reviewers)
            }}
            labelKey="name"
            valueKey="email"
          />
        </div>
        <div className={styles.questions}>
          <Text tag="p" style="body-bold">
            <FormattedMessage
              {...strings.teamLeadRequestFeedback.questionsWillBeAsked}
            />
          </Text>
          {template && !usingCustomQuestions && (
            <MarkdownFormattedMessage
              {...strings.teamLeadRequestFeedback.templates[template.type]
                .title}
            />
          )}
          {usingCustomQuestions && (
            <Button
              onClick={handleResetQuestions}
              label={formatMessage(strings.requestFeedback.resetToDefault)}
              secondary
            />
          )}
          <div className={styles.surveyQuestions}>
            <SurveyQuestionsManager
              className="SurveyFeedbackRequests--template-wrapper-questions"
              key={(!usingCustomQuestions && template?.type) || "custom"}
              initialQuestions={List(questions)}
              tooltipsEnabled={false}
              onUpdateQuestion={(questions: List<Question>) =>
                setQuestions(questions.toArray())
              }
            />
          </div>
          <div className={styles.actionButtonsContaienr}>
            <div className={styles.cancelButtonContainer}>
              <Button
                onClick={
                  isDiff
                    ? () => setShowUnsavedChangesModal(true)
                    : () => router.push(`/teams/${teamId}`)
                }
                label={formatMessage(strings.feedbackRequests.cancelRequest)}
                automationId={Aid.teamBasedFeedbackCancelRequestButton}
                secondary
              />
            </div>
            <Button
              disabled={questions.length === 0 || reviewers.length === 0}
              onClick={async () => {
                try {
                  const res = await requestFeedback()
                  if (res.status !== 200) {
                    throw new Error()
                  }
                  setShowSuccessConfirmationModal(true)
                } catch (ex) {
                  showNotification({
                    type: "negative",
                    title: "Error",
                    message: formatMessage(
                      strings.teamLeadRequestFeedback.feedbackRequestError
                    )
                  })
                }
              }}
              label={formatMessage(strings.feedbackRequests.sendRequest)}
              automationId={Aid.teamBasedFeedbackSendRequestButton}
              primary
            />
          </div>
        </div>
        <UserSelector
          title="Team Members"
          users={
            [...team.details?.teamMembers, ...team.details?.teamLeads].map(
              user => ({
                id: user.aggregateId,
                name: user.name,
                avatar: user.avatar
              })
            ) || []
          }
          selectedUser={teamMemberId || teamMember?.aggregateId}
          onSelectUser={handleSelectUser}
        />
      </div>
    </div>
  )
}

export default injectIntl(TeamFeedbackRequest)
