import * as React from "react"
import { useCallback, useState } from "react"
import { WithRouterProps } from "react-router"
import { InlineNotification } from "@kaizen/component-library"
import Loading from "../../elements/Loading/Loading"
import useGoalDossier from "../../../hooks/useGoalDossier"
import usePersonalGoals from "../../../hooks/usePersonalGoals"
import ScrollContainer from "../../widgets/ScrollContainer/ScrollContainer"
import useCurrentUser from "../../../hooks/useCurrentUser"
import GoalsTableHeader from "../../elements/GoalsTableHeader/GoalsTableHeader"
import GoalsTable from "../../widgets/GoalsTable/GoalsTable"
import strings from "../../../locale/strings"
const { emptyState } = strings.personalGoalsList
import GoalsEmptyState from "../../widgets/GoalsEmptyState/GoalsEmptyState"
import FiltersBar, { Filters } from "../../widgets/FiltersBar/FiltersBar"
import styles from "./PersonalGoalsList.scss"
import { useIntl } from "../../../hooks/useIntl"

export type PersonalGoalsListProps = React.FunctionComponent<WithRouterProps>

const datePickerFilterName = (index: number) => (index === 0 ? "from" : "to")

const PersonalGoalsList: PersonalGoalsListProps = ({ router, location }) => {
  const currentUser = useCurrentUser()
  const { open: openGoalDossier } = useGoalDossier(router, location)
  const { formatMessage } = useIntl()
  const defaultFilters = {
    from: currentUser.company.goalCycle.start,
    to: currentUser.company.goalCycle.end
  }
  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const {
    personalGoals,
    loading,
    goalStats,
    fetchMore: fetchMorePersonalGoals,
    hasMore: hasMorePersonalGoals
  } = usePersonalGoals(filters)

  const handleCreateButtonClick = useCallback(() => {
    router.push("/new_goals/personal/new")
  }, [router])

  return (
    <>
      <InlineNotification
        title={formatMessage(strings.personalGoalsList.lookingForDirectReports)}
        type="informative"
        persistent={true}
      >
        {formatMessage(strings.personalGoalsList.youCanView)}{" "}
        <a href="/performance/goals/team">
          {formatMessage(strings.personalGoalsList.viewGoals)}
        </a>
      </InlineNotification>
      <FiltersBar onFiltersChange={setFilters} defaultFilters={defaultFilters}>
        <div className={styles.filter}>
          <FiltersBar.DatePicker name={datePickerFilterName} allowDateRange />
        </div>
      </FiltersBar>
      <ScrollContainer
        onScrolledToBottom={() =>
          hasMorePersonalGoals && fetchMorePersonalGoals()
        }
      >
        {loading && !hasMorePersonalGoals ? (
          <Loading />
        ) : personalGoals.length > 0 ? (
          <React.Fragment>
            <GoalsTableHeader goalType={"personal"} />
            <GoalsTable
              titleName={currentUser.name}
              goals={personalGoals}
              goalsCount={goalStats.goalCount}
              goalProgress={Math.floor(goalStats.overallProgress * 100)}
              openGoal={goalId => openGoalDossier(goalId)}
              loading={loading && hasMorePersonalGoals}
            />
          </React.Fragment>
        ) : (
          <GoalsEmptyState
            headingText={emptyState.headingText}
            bodyText={emptyState.bodyText}
            createGoalAction={handleCreateButtonClick}
          />
        )}
      </ScrollContainer>
    </>
  )
}

export default PersonalGoalsList
