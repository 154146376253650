import Cookies from "universal-cookie"

const REENTRY_SUBDOMAIN_COOKIE_NAME = "reentry_subdomain"

export class SubdomainRedirector {
  /**
   * Initializes a new instance.
   * @param {*} store The underlying store. It must implement `get`, `set` and `remove` methods. Defaults to a cookie store.
   */
  constructor(store = new Cookies()) {
    this.store = store
  }


  /**
   * Generates a set of options to limits the visibility of the stored subdomain to a specific domain and path.
   * @param {string} hostname Used to limit the visibility of the stored subdomain to this particular hostname domain.
   * @param {string} path Used to limit the visibility of the stored subdomain to this particular path.
   */
  _storageOptionsForLocation(hostname, path) {
    return {
      // We need to set the cookie to be available for the whole domain,
      // otherwise we cannot retrieve its value from the "wrong" subdomain
      domain: "." + this._naiveExtractDomain(hostname),
      // However, in order to avoid unintended redirections, we only allow
      // the cookie to be retrieved if we are in the same path as we were
      // when it was stored.
      path: path
    }
  }

  /**
   * Naively extracts the subdomain from the given hostname, assuming the format foo.bar.com.
   * @param {string} hostname The hostname which subdomain is to be extracted.
   * @returns {string} The subdomain-part of the given hostname.
   */
  _naiveExtractSubdomain(hostname) {
    return hostname.split(".")[0]
  }

  /**
   * Naively extracts the domain from the given hostname assuming the format foo.bar.com.
   * @param {string} hostname The hostname which domain is to be extracted.
   * @returns {string} The domain-part of the given hostname.
   */
  _naiveExtractDomain(hostname) {
    return hostname.split(".").splice(1).join(".")
  }

  /**
   * Gets the stored subdomain.
   * @returns {?string} The stored subdomain.
   */
  _getStoredSubdomain() {
    return this.store.get(REENTRY_SUBDOMAIN_COOKIE_NAME)
  }

  /**
   * Clears the stored subdomain, if any.
   * @param {Location} location The location object to use for locating the domain- and path-specific subdomain to delete.
   * @returns {void}
   */
  _clearStoredSubdomain({ hostname, pathname }) {
    this.store.remove(REENTRY_SUBDOMAIN_COOKIE_NAME, this._storageOptionsForLocation(hostname, pathname))
  }

  /**
   * Stores the subdomain of the given location hostname.
   * @param {Location} location The location which hostname subdomain is to be stored.
   * Defaults to `window.location`.
   */
  storeSubdomain(location = window.location) {
    const { hostname, pathname } = location
    this.store.set(
      REENTRY_SUBDOMAIN_COOKIE_NAME,
      this._naiveExtractSubdomain(hostname),
      this._storageOptionsForLocation(hostname, pathname)
    )
  }

  /**
   * Navigates to the stored subdomain if it exists and is different from the current location.
   * @param {Location} location - A location object that represents the current
   * location, used for checking if it already corresponds with the stored
   * subdomain. Defaults to `window.location`.
   * @param {function} navigator - A function called to perform the actual
   * navigation. Defaults to setting the `window.location.hostname` to the new
   * `hostname`, causing navigation automatically.
   */
  navigateToStoredSubdomain(
    location = window.location,
    navigator = newHostname => { window.location.hostname = newHostname }
  ) {
    const reentrySubdomain = this._getStoredSubdomain()
    const { hostname } = location

    if (reentrySubdomain) {
      this._clearStoredSubdomain(location)
      if (this._naiveExtractSubdomain(hostname) !== reentrySubdomain) {
        const hostnameWithSubdomain = hostname.replace(/[^\\.]+/, reentrySubdomain)
        navigator(hostnameWithSubdomain)
      }
    }
  }
}

/**
 * A SubdomainRedirector instance suitable for browsers.
 */
const browserSubdomainRedirector = new SubdomainRedirector()
export default browserSubdomainRedirector
