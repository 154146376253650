import uuid from "uuid/v4"
import useDataAPI from "./useDataAPI"
import { User } from "../types/User"

const membershipData = (teamId: string) => (user: User) => ({
  team_membership_id: uuid(),
  member_id: user.aggregateId,
  team_id: teamId
})

type Team = {
  name: string
  description: string
  teamLeads: Array<User>
  teamMembers: Array<User>
}

const useCreateTeam = (teamId: string, accountId: string) => {
  const fetchAPI = useDataAPI("/teams", "POST")
  const createTeam = ({ name, description, teamLeads, teamMembers }: Team) => {
    const membershipForTeamData = membershipData(teamId)
    const teamData = {
      team: {
        team_id: teamId,
        name: name,
        description: description,
        account_id: accountId
      },
      team_leads: teamLeads.map(membershipForTeamData),
      team_members: teamMembers.map(membershipForTeamData)
    }
    fetchAPI.fire(teamData)
  }
  return {
    createTeam,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading
  }
}

export default useCreateTeam
