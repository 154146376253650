import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import strings from "../../../locale/strings"
import Loading from "../../elements/Loading/Loading"
import useGoalDetails from "../../../hooks/useGoalDetails"
import useNotifications from "../../../hooks/useNotifications"
import { User } from "../../../types/User"
import DepartmentGoalForm, {
  DepartmentGoalFields,
  Visibility
} from "../DepartmentGoalForm/DepartmentGoalForm"
import useUpdateDepartmentGoal from "../../../hooks/useUpdateDepartmentGoal"
import { DepartmentGoal } from "../../../types/Goals"
import useDepartments from "../../../hooks/useDepartments"
import { formDisabled } from "../NewDepartmentGoal/NewDepartmentGoal"

type EditDepartmentGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    goalId: string
    departmentId: string
  }
}>

const EditDepartmentGoal: EditDepartmentGoal = ({ intl, router, params }) => {
  const { goalId, departmentId } = params
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const { goal } = useGoalDetails(Number(goalId))
  const {
    updateDepartmentGoal,
    loading,
    data,
    error
  } = useUpdateDepartmentGoal(goalId, departmentId)
  const { departments } = useDepartments()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.editGoalNotifications.successTitle),
        message: formatMessage(
          strings.editGoalNotifications.successNotification
        )
      })
      router.push({
        pathname: `/new_goals/department`,
        query: {
          goalId
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.editGoalNotifications.errorTitle),
        message: formatMessage(strings.editGoalNotifications.errorNotification)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  if (!goal) return <Loading />
  let priority
  switch (goal.priority) {
    case "low":
      priority = 0
      break
    case "medium":
      priority = 1
      break
    case "high":
      priority = 2
      break
  }

  const initOwners: User[] = (goal as DepartmentGoal).owners
  const goalFields: DepartmentGoalFields = {
    name: goal.name,
    departmentId: departmentId,
    description: goal.description ? goal.description : "",
    dueDate: goal.dueDate,
    priority: priority as 0 | 1 | 2,
    visibility: goal.visibility as Visibility,
    keyResults: goal.keyResults,
    alignedGoalIds: goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.map(goal => goal.id)
      : [],
    owners: initOwners
  }

  const initDepartment = () => {
    const department = departments.filter(
      department => department.id === departmentId
    )
    return department.length > 0
      ? { value: department[0].id, label: department[0].name }
      : undefined
  }

  return (
    <DepartmentGoalForm
      title={formatMessage(strings.editDepartmentGoal.title)}
      submitLabel={formatMessage(strings.editDepartmentGoal.submitLabel)}
      defaultFields={goalFields}
      departments={departments}
      initDepartment={departmentId ? initDepartment() : undefined}
      onSubmit={fields => updateDepartmentGoal(fields, goal)}
      onLeavePage={() => router.push("/new_goals/department")}
      disabled={formDisabled(loading, departments)}
      initOwners={initOwners}
      goalId={goalId}
    />
  )
}

export default injectIntl(EditDepartmentGoal)
