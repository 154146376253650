import "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar.less"
import PropTypes from "prop-types"
import React from "react"
import "./TypeaheadRowGroup.less"

export default class TypeaheadRowGroup extends React.Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }

  static defaultProps = {
    showEmail: true
  }

  render() {
    const { title, count } = this.props

    return (
      <div>
        <div className="TypeaheadRowGroup flex left-align layout horizontal center truncate needsclick">
          <div className="TypeaheadRowGroup--avatar layout vertical center-justified">
            {count}
          </div>
          <div className="TypeaheadRowGroup--title layout vertical center-justified">
            {title}
          </div>
        </div>
      </div>
    )
  }
}
