import { Button, Text } from "@kaizen/component-library"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import restoreIcon from "@kaizen/component-library/icons/restore.icon.svg"
import deleteIcon from "@kaizen/component-library/icons/trash.icon.svg"
import { Location } from "history"
import * as React from "react"
import { useState, useEffect } from "react"
import { InjectedRouter } from "react-router"
import { injectIntl, InjectedIntl } from "react-intl"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useArchivedTeam from "../../../hooks/useArchivedTeam"
import Loading from "../../elements/Loading/Loading"
import styles from "./ArchivedTeam.scss"
import TeamDeleteConfirmation from "../../widgets/TeamDeleteConfirmation/TeamDeleteConfirmation"
import TeamMemberCard from "../../widgets/TeamMemberCard/TeamMemberCard"
import useRestoreTeam from "../../../hooks/useRestoreTeam"
import useNotifications from "../../../hooks/useNotifications"
import strings from "../../../locale/strings"
const { restoredNotification } = strings.teams.archivedTeamCard

type ArchivedTeam = React.FunctionComponent<{
  router: InjectedRouter
  params: { teamId: string }
  location: Location
  intl: InjectedIntl
}>

/**
 * Returns Archived Teams page
 *
 * @param {Team} props { router, params, location }
 * @returns {Team}
 */
const ArchivedTeam: ArchivedTeam = ({ router, params, location, intl }) => {
  const { teamId } = params
  const { team } = useArchivedTeam(teamId)
  const currentUser = useCurrentUser()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { showNotification } = useNotifications()
  const [useNotification, setUseNotification] = useState<boolean>(false)
  const [teamRestored, setTeamRestored] = useState<boolean>(false)
  const { restoreTeam, data, error } = useRestoreTeam(teamId)
  const { formatMessage } = intl

  const restoreTeamAction = () => {
    restoreTeam()
    setUseNotification(true)
  }

  useEffect(() => {
    if (useNotification && !error) {
      showNotification({
        type: "affirmative",
        title: formatMessage(restoredNotification.title),
        message: formatMessage(restoredNotification.message)
      })

      setTeamRestored(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  if (!team) return <Loading />
  if (team.details && team.details.archivedAt === "") {
    router.push("/teams/archived")
  }

  const currentUserIsTeamLead =
    team.details &&
    team.details.teamLeads
      .map(({ aggregateId }) => aggregateId)
      .includes(currentUser.aggregateId)
  const isEditable = currentUser.isAdmin || currentUserIsTeamLead

  // This needs to be updated through reflux
  if (teamRestored) {
    router.push(`teams/archived`)
  }

  return (
    <div>
      {showDeleteConfirmation && (
        <TeamDeleteConfirmation
          teamId={teamId}
          onTeamDeleted={() => router.push("/teams/archived")}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
      <div className={styles.navigation}>
        <Button
          secondary
          label={formatMessage(strings.teams.archivingTeams.back)}
          icon={leftIcon}
          onClick={() => router.push(`teams/archived`)}
        />
      </div>
      <div className={styles.header}>
        <Text tag="h1" style="zen-heading-2" inline>
          {team.name}
        </Text>
        <div className={styles.options}>
          {isEditable && (
            <>
              <Button
                secondary
                label={formatMessage(strings.teams.archivingTeams.delete)}
                icon={deleteIcon}
                onClick={() => setShowDeleteConfirmation(true)}
              />
              <Button
                secondary
                label={formatMessage(strings.teams.archivingTeams.restore)}
                icon={restoreIcon}
                onClick={() => restoreTeamAction()}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.description}>
        <Text tag="p">{team.description}</Text>
      </div>
      {team.details && (
        <div className={styles.cards}>
          {team.details.teamLeads.map(teamMember => (
            <TeamMemberCard
              key={teamMember.aggregateId}
              lead={true}
              teamMember={teamMember}
            />
          ))}
          {team.details.teamMembers.map(teamMember => (
            <TeamMemberCard
              key={teamMember.aggregateId}
              teamMember={teamMember}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default injectIntl(ArchivedTeam)
