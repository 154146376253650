import Reflux from "reflux-core"
import LocaleBundles from "../locale/LocaleBundles"

const LocaleActions = Reflux.createActions({
  loadLocaleData: { asyncResult: true }
})

LocaleActions.loadLocaleData.listenAndPromise(locale => {
  const bundle = LocaleBundles[locale]
  const shortLocaleCode = locale.split("-")[0]
  const fallbackBundle = LocaleBundles[shortLocaleCode]
  if (bundle) {
    return bundle()
  } else if (fallbackBundle) {
    return fallbackBundle()
  } else {
    return Promise.reject()
  }
})

export default LocaleActions
