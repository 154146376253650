import * as React from "react"
import { createContext, useContext, useReducer } from "react"
import { Pagination } from "../decoders/PaginationDecoder/paginationDecoder"
import { getPaginationState, PaginatedResults } from "./GoalsState"

export interface Department {
  id: string
  name: string
}

interface State {
  departmentsById: Record<string, Department>
  allDepartments: PaginatedResults<string>
}

type Action =
  | { type: "FETCH_DEPARTMENT"; payload: Department }
  | {
      type: "FETCH_ALL_DEPARTMENTS"
      payload: { departments: Department[]; pagination: Pagination }
    }

const INITIAL_STATE: State = {
  departmentsById: {},
  allDepartments: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  }
}

export const departmentsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FETCH_DEPARTMENT": {
      const department = action.payload
      return {
        ...state,
        departmentsById: {
          ...state.departmentsById,
          [department.id]: department
        }
      }
    }
    case "FETCH_ALL_DEPARTMENTS": {
      const departmentsById: Record<string, Department> = {}
      const allDepartments: string[] = []
      const { departments, pagination } = action.payload
      departments.map(department => {
        departmentsById[department.id] = department
        allDepartments.push(department.id)
      })
      return {
        ...state,
        departmentsById: {
          ...state.departmentsById,
          ...departmentsById
        },
        allDepartments: getPaginationState(
          state.allDepartments,
          pagination,
          allDepartments
        )
      }
    }
  }
}

export const DepartmentsContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: INITIAL_STATE,
  dispatch: state => state
})

export const DepartmentsProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const departmentsContext = useContext(DepartmentsContext)
  const [state, dispatch] = useReducer(
    departmentsReducer,
    departmentsContext.state
  )

  return (
    <DepartmentsContext.Provider value={{ state, dispatch }}>
      {children}
    </DepartmentsContext.Provider>
  )
}
