/**
 * @fileoverview Constants for use with the remark markdown parser
 */

/**
 * Represents the block and inline syntax elements supported by Zugata's rich-text implementation.
 */
export const SYNTAX_WHITELIST = {
  block: ["newline", "list", "paragraph"],
  inline: ["escape", "link", "strong", "break", "text"]
}

/**
 * Represents a subset of the syntax in `SYNTAX_WHITELIST` for rendering inline Markdown
 */
export const SYNTAX_WHITELIST_INLINE = {
  block: ["newline", "paragraph"], // Remark parser seems to break if either of these is disabled
  inline: ["escape", "link", "strong", "break", "text"]
}

export const REMARK_PING_CONFIG = {
  pingUsername: _username => true,
  userURL: _username => "#", // Not used
  // eslint-disable-next-line no-control-regex
  usernameRegex: /@<(([\w.-]+)+@[\w.-]+)>/
}
