import React from "react"
import { IntlProvider, addLocaleData } from "react-intl"
import moment from "moment"
import en from "react-intl/locale-data/en"
import connect from "./decorators/connect"
import LocaleStore from "../stores/LocaleStore"
import LocaleActions from "../actions/LocaleActions"

// Load english locale info by default
addLocaleData(en)

const setLocale = locale => {
  switch (locale) {
    case "zh":
      return "zh-cn"
    default:
      return locale
  }
}

@connect(LocaleStore, "localeData")
export default class ZugataIntlProvider extends React.Component {
  componentDidMount() {
    const { locale } = this.props.localeData
    moment.locale(setLocale(locale))
    LocaleActions.loadLocaleData(locale)
  }

  componentDidUpdate(prevProps) {
    const {
      localeData: { locale }
    } = this.props
    const {
      localeData: { locale: prevLocale }
    } = prevProps

    if (locale !== prevLocale) {
      // Locale changed, trigger load
      LocaleActions.loadLocaleData(locale)
    }
  }

  render() {
    const {
      localeData: { locale, messages }
    } = this.props

    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {this.props.children}
      </IntlProvider>
    )
  }
}
