import * as React from "react"
import { createContext, useReducer } from "react"
import { Pagination } from "../decoders/PaginationDecoder/paginationDecoder"
import { getPaginationState, PaginatedResults } from "./GoalsState"

export type TeamPreview = {
  id: string
  name: string
  description?: string
  goalsCount: number
  membersCount: number
  overallProgress: number
}

export type TeamSummary = Pick<
  TeamPreview,
  "id" | "name" | "goalsCount" | "overallProgress"
>

const INITIAL_STATE: TeamSummariesState = {
  teamSummariesById: {},
  myTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  },
  allTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  },
  searchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0
  }
}

type TeamSummariesState = {
  teamSummariesById: Record<string, TeamSummary>
  myTeams: PaginatedResults<string>
  allTeams: PaginatedResults<string>
  searchResults: PaginatedResults<string>
}

type TeamSummariesAction =
  | FetchMySummariesAction
  | UpdateMySummariesAction
  | FetchAllSummariesAction
  | FetchSummariesSearchAction
  | FetchSummaryAction
  | UpdateTeamOverallProgress

type FetchMySummariesAction = {
  type: "FETCH_MY_TEAM_SUMMARIES"
  payload: {
    teamSummaries: TeamSummary[]
    pagination: Pagination
  }
}

type UpdateMySummariesAction = {
  type: "UPDATE_TEAM_SUMMARIES"
  payload: {
    teamId: string
  }
}

type FetchAllSummariesAction = {
  type: "FETCH_ALL_TEAM_SUMMARIES"
  payload: {
    teamSummaries: TeamSummary[]
    pagination: Pagination
  }
}

type FetchSummariesSearchAction = {
  type: "FETCH_TEAM_SUMMARIES_SEARCH"
  payload: {
    teamSummaries: TeamSummary[]
    pagination: Pagination
  }
}

type FetchSummaryAction = {
  type: "FETCH_TEAM_SUMMARY"
  payload: {
    teamSummary: TeamSummary
    pagination: Pagination
  }
}

type UpdateTeamOverallProgress = {
  type: "UPDATE_TEAM_OVERALL_PROGRESS"
  payload: {
    teamId: string
    progress: number
  }
}

export const teamsReducer = (
  state: TeamSummariesState,
  action: TeamSummariesAction
): TeamSummariesState => {
  switch (action.type) {
    case "FETCH_MY_TEAM_SUMMARIES": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const myTeams: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map(teamSummary => {
        teamSummariesById[teamSummary.id] = teamSummary
        myTeams.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById
        },
        myTeams: getPaginationState(state.myTeams, pagination, myTeams)
      }
    }

    case "UPDATE_TEAM_SUMMARIES": {
      // NOTE: Used for updating the Team Goal Summaries UI
      // does not address pagination state issues for myTeams
      // or allTeams key
      const { teamId } = action.payload
      const teamSummariesById: Record<string, TeamSummary> = {}
      // find current state, filter out the removed
      Object.keys(state.teamSummariesById).map(key => {
        const teamSummary = state.teamSummariesById[key]
        if (key === teamId) {
          teamSummary.goalsCount--
        }
        teamSummariesById[key] = teamSummary
      })

      return {
        ...state,
        teamSummariesById: teamSummariesById
      }
    }

    case "FETCH_ALL_TEAM_SUMMARIES": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const allTeams: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map(teamSummary => {
        teamSummariesById[teamSummary.id] = teamSummary
        allTeams.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById
        },
        allTeams: getPaginationState(state.allTeams, pagination, allTeams)
      }
    }

    case "FETCH_TEAM_SUMMARIES_SEARCH": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const searchResults: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map(teamSummary => {
        teamSummariesById[teamSummary.id] = teamSummary
        searchResults.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById
        },
        searchResults: getPaginationState(
          state.searchResults,
          pagination,
          searchResults
        )
      }
    }

    case "FETCH_TEAM_SUMMARY": {
      const { teamSummary } = action.payload
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          [teamSummary.id]: teamSummary
        }
      }
    }

    case "UPDATE_TEAM_OVERALL_PROGRESS": {
      const { teamId, progress } = action.payload
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          [teamId]: {
            ...state.teamSummariesById[teamId],
            overallProgress: progress
          }
        }
      }
    }
  }
  return { ...state }
}

export const TeamSummariesContext: React.Context<{
  state: TeamSummariesState
  dispatch: React.Dispatch<TeamSummariesAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: TeamSummariesAction) => {}
})

export const TeamSummariesProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(teamsReducer, INITIAL_STATE)

  return (
    <TeamSummariesContext.Provider value={{ state, dispatch }}>
      {children}
    </TeamSummariesContext.Provider>
  )
}
