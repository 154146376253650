import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { FormattedRelative } from "react-intl"
import ZugataFormattedDate from "./ZugataFormattedDate"

/**
 * Takes a timestamp string that can be passed to MomentJS's moment() method
 * and returns a string representation in one of two formats depending on how long ago the
 * moment was.
 *
 * For moments that are over a week old it formats them like so:
 * "Abbreviated month. day, year"
 * e.g.: "Feb. 23, 2017"
 *
 * Moments that are a week or less old will be formatted according to 'react-intl'
 * <FormattedRelative> method:
 * Something like, "N units ago"
 * e.g.: "5 days ago"
 *
 */
const ZugataFormattedRelativeDate = ({ dayCountCutoff, value, ...props }) => {
  const momentizedTimestamp = moment(value)
  if (moment().diff(momentizedTimestamp, "days") > dayCountCutoff) {
    return <ZugataFormattedDate value={value} month="short" />
  } else {
    return <FormattedRelative value={value} updateInterval={30000} {...props} />
  }
}

ZugataFormattedRelativeDate.propTypes = {
  dayCountCutoff: PropTypes.number,
  value: PropTypes.string.isRequired
}

ZugataFormattedRelativeDate.defaultProps = {
  dayCountCutoff: 7
}

export default ZugataFormattedRelativeDate
