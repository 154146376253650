import { Text } from "@kaizen/component-library"
import * as React from "react"
import { useState } from "react"
import { InjectedRouter } from "react-router"
import { injectIntl, InjectedIntl } from "react-intl"
import _ from "lodash"
import uuid from "uuid/v4"
import { useCallback } from "react"
import archivedIcon from "@kaizen/component-library/icons/archived.icon.svg"
import exportIcon from "@kaizen/component-library/icons/export.icon.svg"
// @ts-ignore
import SearchField from "../SearchField/SearchField.jsx"
import useDebounce from "../../../hooks/useDebounce"
import useTeams from "../../../hooks/useTeams"
import ScrollContainer from "../ScrollContainer/ScrollContainer"
import Tiles from "../Tiles/Tiles"
import TeamCard from "./TeamCard"
import styles from "./TeamsList.scss"
import strings from "../../../locale/strings"
import useDataAPI from "../../../hooks/useDataAPI"
import useNotifications from "../../../hooks/useNotifications"
import useCurrentUser from "../../../hooks/useCurrentUser"
import { isUserHRBP } from "../../../util/users"
import BreadcrumbAndMenuHeader from "../BreadcrumbAndMenuHeader/BreadcrumbAndMenuHeader"
import { ActionItems } from "../ActionItems/ActionItems"
import EmptyState from "../EmptyState/EmptyState"
import emptyTeamGoalsImg from "../../../static/images-2017/drawings/emptystates/goals.png"

import { MenuItem } from "../../../../types/MenuItem"
import Aid from "../../../constants/automationId"

type TeamsList = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
}>

/**
 * Returns active teams list
 *
 * @param {*} prop { router }
 * @returns {TeamsList} TeamsList component
 */
const TeamsList: TeamsList = ({ router, intl }) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const { formatMessage } = intl
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const user = useCurrentUser()

  const {
    teams: myTeams,
    hasMore: myTeamsHasMore,
    fetchMore: myTeamsFetchMore
  } = useTeams({ all_teams: false, q: debouncedSearchTerm })
  const {
    teams: allTeams,
    hasMore: allTeamsHasMore,
    fetchMore: allTeamsFetchMore
  } = useTeams({ all_teams: true, q: debouncedSearchTerm })

  const { fire } = useDataAPI("/dashboard/team_export_requests", "POST")
  const { showNotification } = useNotifications()
  const handleClick = useCallback(async () => {
    try {
      await fire({
        team_export_request: { options: {} }
      })
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.teams.exportStatsCsv.successTitle),
        message: formatMessage(strings.teams.exportStatsCsv.successNotification)
      })
    } catch (ex) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.teams.exportStatsCsv.errorTitle),
        message:
          ex.response?.data?.error ||
          formatMessage(strings.teams.exportStatsCsv.errorNotification)
      })
    }
  }, [fire, formatMessage, showNotification])

  const menuItems: MenuItem[] = _.compact([
    {
      text: strings.teams.teamsList.createTeam.actionLabel,
      onClick: () => {
        router.push(`teams/new/${uuid()}`)
      },
      priorityAction: true,
      primary: true,
      automationId: Aid.teamsListCreateNewTeam
    },
    {
      icon: archivedIcon,
      text: strings.teams.teamsList.archivedButton,
      onClick: () => {
        router.push(`teams/archived`)
      },
      priorityAction: true
    }
  ])

  if (user.isAdmin || isUserHRBP(user)) {
    menuItems.push({
      icon: exportIcon,
      text: strings.teams.exportStatsCsv.button,
      onClick: () => handleClick(),
      priorityAction: true
    })
  }

  function renderPageContent() {
    if (myTeams.length > 0 || allTeams.length > 0) {
      return (
        <>
          <SearchField
            onQueryChange={setSearchTerm}
            fullWidth
            className={styles.search}
            query={searchTerm}
            placeholder={formatMessage(
              strings.teams.teamsList.searchField.placeholder
            )}
          />
          <div></div>
          <div>
            <Text tag="h2" style="zen-heading-2">
              {formatMessage(strings.teams.teamsList.myTeams)}
            </Text>
          </div>
          {myTeams.length > 0 ? (
            <ScrollContainer
              onScrolledToBottom={() => myTeamsHasMore && myTeamsFetchMore()}
            >
              <Tiles.Tiles>
                {myTeams.map(team => (
                  <TeamCard
                    key={team.id}
                    team={team}
                    onActionClick={() => router.push(`teams/${team.id}`)}
                  />
                ))}
              </Tiles.Tiles>
            </ScrollContainer>
          ) : (
            <Text style="paragraph" tag="p">
              {formatMessage(strings.teams.teamsList.noMyTeams)}
            </Text>
          )}

          <div>
            <Text tag="h2" style="zen-heading-2">
              {formatMessage(strings.teams.teamsList.allTeams)}
            </Text>
          </div>
          {allTeams.length > 0 ? (
            <ScrollContainer
              onScrolledToBottom={() => allTeamsHasMore && allTeamsFetchMore()}
            >
              <Tiles.Tiles>
                {allTeams.map(team => (
                  <TeamCard
                    key={team.id}
                    team={team}
                    onActionClick={() => router.push(`teams/${team.id}`)}
                  />
                ))}
              </Tiles.Tiles>
            </ScrollContainer>
          ) : (
            <Text style="paragraph" tag="p">
              {formatMessage(strings.teams.teamsList.noAllTeams)}
            </Text>
          )}
        </>
      )
    } else {
      return (
        <EmptyState
          imgSrc={emptyTeamGoalsImg}
          header={formatMessage(strings.teams.teamsList.createTeam.title)}
          body={formatMessage(strings.teams.teamsList.createTeam.body)}
        ></EmptyState>
      )
    }
  }

  return (
    <div>
      <div className={styles.heading}>
        <BreadcrumbAndMenuHeader
          headerText={formatMessage(strings.teams.teamsList.title)}
          leftAligned={true}
          hasMenu={menuItems.length > 0}
        >
          <ActionItems menuItems={menuItems} />
        </BreadcrumbAndMenuHeader>
      </div>
      {renderPageContent()}
    </div>
  )
}

export default injectIntl(TeamsList)
