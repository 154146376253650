import moment from "moment"
import { JsonDecoder } from "ts.data.json"
import { CurrentUser } from "../../state/CurrentUser"
import { JobTitle } from "../../types/JobTitle"
import ConfigurationOptions from "../../constants/configurationOptions"

const {
  object,
  number,
  failover,
  array,
  string,
  oneOf,
  isExactly,
  dictionary,
  boolean
} = JsonDecoder

const getGoalCycle = (month: number, day: number, length: number) => {
  // Note: subtract one from months because Date and moment's months are zero-based
  const cycleStart = moment({
    year: moment().year(),
    month: month - 1,
    date: day
  })

  const monthsFromStart = moment().diff(cycleStart, "months", true)
  const periodOffset = Math.floor(monthsFromStart / length)

  const startDate = moment(cycleStart)
    .add(periodOffset * length, "months")
    .startOf("day")

  const endDate = moment(startDate)
    .add(length, "months")
    .subtract(1, "day")
    .endOf("day")

  return {
    start: startDate.toDate(),
    end: endDate.toDate()
  }
}

const jobTitleDecoder = JsonDecoder.object<JobTitle>(
  {
    id: JsonDecoder.number,
    title: JsonDecoder.string,
    type: JsonDecoder.string,
    parentJobTitle: failover(
      undefined,
      object<JobTitle>(
        {
          id: JsonDecoder.number,
          title: JsonDecoder.string,
          type: JsonDecoder.string
        },
        "parent jobTitle decoder",
        {
          type: "job_title_type"
        }
      )
    )
  },
  "Jobtitle decoder decoder",
  {
    type: "job_title_type",
    parentJobTitle: "parent_job_title"
  }
)

const currentUserDecoder = object(
  {
    user: object(
      {
        id: number,
        email: failover("", string),
        employee_aggregate_id: failover("", string),
        best_name: string,
        job_title_name: failover("", string),
        avatar_images: object(
          {
            thumb_url: string
          },
          "currentUserDecoder avatar_images"
        ),
        profile_image_url: failover("", string),
        company: object(
          {
            account_id: string,
            goal_cycle_day: number,
            goal_cycle_month: number,
            goal_cycle_length: number,
            allow_reviews: boolean,
            allow_team_based_feedback: failover(false, boolean),
            name: failover("", string),
            id: failover(undefined, number),
            allow_everyone_to_see_department_goals: failover(false, boolean),
            subdomain: failover("", string)
          },
          "currentUserDecoder company"
        ),
        role_names: array(
          oneOf(
            [
              isExactly("admin"),
              isExactly("staff"),
              isExactly("super_staff"),
              isExactly("mystique"),
              isExactly("death_star"),
              isExactly("department_manager"),
              isExactly("hr_business_partner"),
              isExactly("hris_admin")
            ],
            "curentUserDecoder role"
          ),
          "curentUserDecoder roles"
        ),
        configuration_options: failover(
          [],
          array(string, "surveyItemDecoder comments")
        ),
        hr_business_partners: failover(
          [],
          array(
            object(
              {
                department: jobTitleDecoder
              },
              "currentUserDecoder hrbpartners object"
            ),
            "currentUserDecoder hrbpartners array"
          )
        ),
        department_managers: failover(
          [],
          array(
            object(
              {
                department: jobTitleDecoder
              },
              "currentUserDecoder departmentManager object"
            ),
            "currentUserDecoder departmentManager array"
          )
        ),
        custom_terms: dictionary(string, "curentUserDecoder custom_terms"),
        job_title: failover(undefined, jobTitleDecoder)
      },
      "currentUserDecoder user"
    )
  },
  "currentUserDecoder"
).map<CurrentUser>(({ user }) => ({
  id: user.id,
  email: user.email,
  aggregateId: user.employee_aggregate_id,
  name: user.best_name,
  role: user.job_title_name,
  avatar: user.avatar_images.thumb_url,
  company: {
    accountId: user.company.account_id,
    allowReviews: user.company.allow_reviews,
    allowTeamBasedFeedback: user.company.allow_team_based_feedback,
    allowEveryoneToSeeDepartmentGoas:
      user.company.allow_everyone_to_see_department_goals,
    goalCycle: getGoalCycle(
      user.company.goal_cycle_month,
      user.company.goal_cycle_day,
      user.company.goal_cycle_length
    ),
    name: user.company.name,
    id: user.company.id,
    subdomain: user.company.subdomain
  },
  isAdmin: user.role_names.includes("admin"),
  profileImage: user.profile_image_url,
  customTerms: user.custom_terms,
  jobTitle: user.job_title,
  configurationOptions: user.configuration_options.map(option => {
    // I was block on how to do it better.
    // The API is returning the strings of the ConfigurationOptions enum.
    // Need to convert the array of string on array of enum, improves super welcome!
    switch (option) {
      case "team_goals":
        return ConfigurationOptions.teamGoals
      case "team_based_feedback":
        return ConfigurationOptions.teamBasedFeedback
      default:
        return ConfigurationOptions.teams
    }
  }),
  department: user.job_title ? user.job_title.parentJobTitle : undefined,
  hrbPartners: user.hr_business_partners.map(({ department }) => department),
  managedDepartments: user.department_managers.map(
    ({ department }) => department
  )
}))

export default currentUserDecoder
