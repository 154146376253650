import PropTypes from "prop-types"
import React from "react"
import { Select, AsyncSelect } from "@kaizen/component-library/draft"
import _ from "lodash"
import { injectIntl } from "react-intl"
import selectedValueLoader from "../../decorators/selectedValueLoader"
import Settings from "../../../settings"
import strings from "../../../locale/strings"
import { debounce } from "../../../decorators/timers.js"

// A wrapper for react-select v3 that is compatible with the react-select v1 API
// In react-select v1 values were passed as plain strings.
// In react-select v3 values are passed as { value, label } objects.
// This implementation will cache loaded values so that the label can be saved and retrieved later
const CompatibleAsyncSelect = ({
  value,
  defaultValue,
  loadOptions,
  ...props
}) => {
  const [cachedOptions, setCachedOptions] = React.useState(null)

  const findCachedValue = value => {
    if (value && typeof value !== "object" && cachedOptions) {
      return cachedOptions.find(o => o.value === value)
    } else {
      return value || null
    }
  }

  const loadOptionsWrapper = (query, callback) => {
    loadOptions(query, options => {
      setCachedOptions(options)
      callback(options)
    })
  }

  const valueWrapper = findCachedValue(defaultValue || value)

  return (
    <AsyncSelect
      loadOptions={loadOptionsWrapper}
      value={valueWrapper}
      defaultValue={valueWrapper}
      {...props}
    />
  )
}

const MINIMUM_OPTIONS_FOR_SEARCH = 5
const { SEARCHFIELD_DEBOUNCE } = Settings

class TypeaheadKaizen extends React.Component {
  static propTypes = {
    labelKey: PropTypes.string,

    onChange: PropTypes.func,

    loadOptions: PropTypes.func,
    loadOptionsField: PropTypes.string,
    loadOptionsForValues: PropTypes.func,

    automationId: PropTypes.string,
    isClearable: PropTypes.bool
  }

  static defaultProps = {
    labelKey: "label",
    onChange: _.noop,
    loadOptionsField: "results",
    isClearable: true
  }

  constructor(props) {
    super(props)
    this.state = {
      focused: false
    }
  }

  handleChange(value) {
    const {
      loadOptionsForValues,
      onChange,
      selectedValueLoader: { cacheObjects }
    } = this.props

    this.setState({ focused: false })

    // Cache selected options so we don't have to refetch them
    if (loadOptionsForValues) {
      cacheObjects(getValueAsArray({ value }))
    }

    onChange(value)
  }

  loadOptionsIntoInputField = (query, callback) => {
    const {
      loadOptions,
      loadOptionsField,
      isClearable,
      intl: { formatMessage }
    } = this.props

    return loadOptions(query).then(result => {
      if (result[loadOptionsField] !== undefined) {
        const options = [...result[loadOptionsField]]
        const optionsWithAll = [
          { value: null, label: formatMessage(strings.general.all) },
          ...options
        ]
        return callback(isClearable ? optionsWithAll : options)
      } else {
        const resultWithAll = [
          { value: null, label: formatMessage(strings.general.all) },
          ...result
        ]
        // Fallback for legacy react-select loadOptions
        return callback(isClearable ? resultWithAll : result)
      }
    })
  }

  @debounce(SEARCHFIELD_DEBOUNCE)
  debouncedLoadOptions(inputValue, callback) {
    return this.loadOptionsIntoInputField(inputValue, callback)
  }

  getLoadValues = (inputValue, callback) => {
    this.debouncedLoadOptions(inputValue, callback)
  }

  render() {
    const {
      value,
      defaultValue,
      allowCreate,
      loadOptions,
      loadOptionsForValues,
      selectedValueLoader,
      searchable,
      options,
      automationId,
      placeholder,
      multi,
      ...props
    } = this.props
    const { focused } = this.state
    const isSearchable = options
      ? options.length > MINIMUM_OPTIONS_FOR_SEARCH
      : // The searchable prop in react-select defaults to `true`, so we should only consider
        // explicit `false` to mean not searchable.
        searchable !== false

    const correctedValue =
      focused && isSearchable
        ? // Normally, react-select keeps the selected value around even when you click into it. For
          // single-value selects, this means you have to type "over" the selected value to search
          // for a new value. Setting the value to null while the control is focused fixed this. This
          // also avoids a related issue, where typing backspace tries to clear the value.
          null
        : defaultValue && loadOptionsForValues
        ? selectedValueLoader.values
        : defaultValue
    return React.createElement(loadOptions ? CompatibleAsyncSelect : Select, {
      ...props,

      searchable: isSearchable,
      autoBlur: true,
      autoload: false,
      options,
      loadOptions: loadOptions ? this.getLoadValues : null,
      defaultValue: correctedValue,
      placeholder: placeholder,
      defaultOptions: true,

      onFocus: () => this.setState({ focused: true }),
      onChange: this.handleChange.bind(this),
      onBlur: () => this.setState({ focused: false })
    })
  }
}

function getValueAsArray({ value }) {
  // REVIEW: react-select itself has more complex logic and supports different formats for
  // `value`, but this should suit our use cases
  if (!value) {
    return []
  } else {
    return [value]
  }
}

export default selectedValueLoader({
  getValues: ({ value }) => getValueAsArray({ value }),
  getObjectId: ({ valueKey }, option) => option[valueKey],
  loadObjectsForIds: ({ loadOptionsForValues }, values) =>
    loadOptionsForValues && loadOptionsForValues(values)
})(injectIntl(TypeaheadKaizen, { withRef: true }))

export { TypeaheadKaizen as RawTypeaheadKaizen }
