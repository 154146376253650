import "./LongAnswer.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import TextEditor from "../../elements/TextEditor"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import strings from "../../../locale/strings"
import Aid from "../../../constants/automationId"

export default class LongAnswer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    question: PropTypes.object.isRequired,
    onTypeAnswer: PropTypes.func,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    answer: PropTypes.object,
    index: PropTypes.number,
    highlightedInitialValue: PropTypes.string,
    hideAsterisk: PropTypes.bool,
    hideQuestion: PropTypes.bool,
    automationId: PropTypes.string
  }

  static defaultProps = {
    className: "",
    hideQuestion: false,
    onBlur: _.noop,
    onTypeAnswer: _.noop
  }

  render() {
    const {
      className,
      question,
      index,
      answer,
      onTypeAnswer,
      readOnly,
      onBlur,
      highlightedInitialValue,
      hideAsterisk,
      hideQuestion,
      automationId
    } = this.props

    const { can_share: canShare, title, description, id } = question
    const editorRef = `LongAnswer-${id}`
    const shareableIndicator = canShare && !hideAsterisk ? "*" : ""
    const requiredIndicator = do {
      if (question.required === false) {
        ;<FormattedMessage {...strings.customizedQuestions.optionalIndicator} />
      }
    }

    return (
      <div
        className={`LongAnswer ${className}`}
        data-automation-id={automationId}
      >
        <div className="LongAnswer--question layout horizontal">
          {index && (
            <div className="LongAnswer--question-number flex none">
              {index + ". "}
            </div>
          )}
          {!hideQuestion && (
            <div className="LongAnswer--skill-name layout vertical flex one">
              <div className="LongAnswer--skill-name-title">
                <MarkdownText text={title} inline={true} /> {shareableIndicator}
                <span className="LongAnswer--skill-name-optional">
                  {" "}
                  {requiredIndicator}
                </span>
              </div>
              {description && (
                <div>
                  <MarkdownText
                    className="LongAnswer--skill-name-description"
                    text={description}
                    inline={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {!readOnly ? (
          <TextEditor
            forwardedRef={editor => (this[editorRef] = editor)}
            className="LongAnswer--text-editor flex one"
            initialValue={_.get(answer, "text")}
            saveState={_.get(answer, "saveState")}
            onChange={value => onTypeAnswer(value)}
            onBlur={(ev, value) => onBlur(ev, value)}
            hasEmojiPicker={false}
            highlightedInitialValue={highlightedInitialValue}
            automationId={Aid.inputFeedback}
          />
        ) : (
          <MarkdownText
            className="LongAnswer--read-only-text"
            text={_.get(answer, "text")}
          />
        )}
      </div>
    )
  }
}
