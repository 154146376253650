import { Comment } from "../../state/Comments"
import { User } from "../../types/User"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userDecoder: (data: any) => User = data => ({
  id: data.id,
  aggregateId: "",
  name: data.best_name,
  avatar: data.avatar_images.thumb_url,
  role: data.job_title_name,
  profileImage: data.profile_image_url
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCommentsDecoder: (data: any) => Comment[] = data => {
  const { comments } = data

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const decodeCommentData = (commentData: any): Comment => ({
    id: commentData.id,
    body: commentData.body,
    dateSent: new Date(commentData.created_at),
    author: userDecoder(commentData.author.user),
    replies: commentData.child_comments
      ? commentData.child_comments.map(decodeCommentData)
      : undefined
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (comments as Array<any>).map<Comment>(decodeCommentData)
}

export default getCommentsDecoder
