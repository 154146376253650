import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import * as Sentry from "@sentry/browser"
import ErrorPage from "./ErrorPage"

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    })
  }

  constructor(props) {
    super(props)
    this.state = { errorJustHappened: false }
  }

  static getDerivedStateFromError() {
    return { errorJustHappened: true }
  }

  componentDidUpdate(prevProps) {
    const { location: previousLocation } = prevProps
    const { location } = this.props
    const locationChanged =
      previousLocation.pathname !== location.pathname ||
      previousLocation.search !== location.search

    if (locationChanged) {
      this.setState({ errorJustHappened: false })
    }
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error(error, info)
    Sentry.captureException(error)
  }

  render() {
    const { errorJustHappened } = this.state
    const { children } = this.props

    if (errorJustHappened) {
      return <ErrorPage />
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
