import { useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import { SurveyResponsesContext, SurveyItem } from "../state/SurveyState"
import { surveyItemDecoder } from "../decoders/SurveyItemDecoder/surveyItemDecoder"
import useDataAPI from "./useDataAPI"

const useUpdateSurveyItems = (
  surveyResponseId?: number,
  surveyItemId?: number
) => {
  const { dispatch } = useContext(SurveyResponsesContext)
  const updateItemAPI = useDataAPI(`/survey_items/${surveyItemId}`, "PUT")

  const updateSurveyItem = (
    surveyItemUpdated: SurveyItem,
    answerObject: object
  ) => {
    if (surveyItemId && answerObject) {
      updateItemAPI.fire(answerObject)
    }
    if (surveyItemUpdated && surveyResponseId) {
      surveyItemUpdated.saveState = "saving"
      dispatch({
        type: "UPDATE_SURVEY_ITEM",
        payload: { updatedSurveyItem: surveyItemUpdated, surveyResponseId }
      })
    }
  }

  useEffect(() => {
    if (updateItemAPI.data && surveyResponseId) {
      surveyItemDecoder
        .decodePromise(updateItemAPI.data)
        .then(response => {
          response.saveState = "saved"
          dispatch({
            type: "UPDATE_SURVEY_ITEM",
            payload: { updatedSurveyItem: response, surveyResponseId }
          })
        })
        .catch(error => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemAPI.data])

  return {
    updateSurveyItem
  }
}

export default useUpdateSurveyItems
