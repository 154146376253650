import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage, FormattedNumber } from "react-intl"
import cx from "classnames"
import Tooltip from "rc-tooltip"
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"
import strings from "../../../locale/strings"
import PerformanceReview from "../../../models/PerformanceReview"
import DataTable from "../DataTable/DataTable"
import LabeledEmployeePic from "../LabeledEmployeePic/LabeledEmployeePic"
import User from "../../../models/User"
import slugify from "../../../util/slugify"
import FadeoutTruncated from "../../elements/FadeoutTruncated/FadeoutTruncated"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import Select from "../../elements/Select/Select"
import NoManagerText from "../NoManagerText/NoManagerText"
import MoreButtonsMenu from "../MoreButtonsMenu/MoreButtonsMenu"
import FormattedListSentence from "../../elements/FormattedListSentence/FormattedListSentence"
import Icon from "../../elements/Icon/Icon"
import ReviewStatus from "../ReviewStatus/ReviewStatus"
import "./EvaluationsTable.less"

export const HEADER_CELLS = {
  flag: {
    type: "flag",
    title: "",
    sortBy: "flag",
    toggleable: false,
    fixed: true
  },
  share: {
    type: "share",
    title: "",
    headerCell: true,
    toggleable: false,
    fixed: true
  },
  employee: {
    type: "employee",
    title: strings.general.employee,
    sortBy: "name",
    toggleable: false,
    fixed: true
  },
  cycleName: {
    type: "cycleName",
    title: strings.general.evaluationCycle,
    toggleable: true
  },
  cycleWithDate: {
    type: "cycleWithDate",
    title: strings.general.evaluationCycle,
    toggleable: true
  },
  status: {
    type: "status",
    title: strings.performanceReviews.status,
    sortBy: "status",
    toggleable: true
  },
  manager: {
    type: "manager",
    title: strings.general.reportsTo,
    sortBy: "manager_name",
    toggleable: true
  },
  average: {
    type: "average",
    title: strings.performanceReviews.averageOfRatings,
    sortBy: "average",
    toggleable: true
  },
  profile: {
    type: "profile",
    title: "",
    toggleable: false
  },
  bucket: {
    type: "bucket",
    title: strings.general.assessmentGroup,
    sortBy: "performance_bucket",
    toggleable: true
  }
}

export default class EvaluationsTable extends React.Component {
  static propTypes = {
    reviews: PropTypes.arrayOf(PropTypes.instanceOf(PerformanceReview))
      .isRequired,
    getCycleForReview: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    sortable: PropTypes.bool,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.oneOf(["asc", "desc"]),
    isReviewSelectable: PropTypes.func.isRequired,
    reviewLinkPrefix: PropTypes.string,
    reviewLinkQuery: PropTypes.object,
    hasMore: PropTypes.bool,
    questionIdForQuestionColumnSort: PropTypes.number,
    onSortUpdate: PropTypes.func,
    onSelectReview: PropTypes.func,
    onNextPageNeeded: PropTypes.func,
    highlightedReviewId: PropTypes.number
  }

  static contextTypes = {
    user: PropTypes.object
  }

  static defaultProps = {
    sortable: true,
    hasMore: false,
    onSortUpdate: _.noop,
    onSelectReview: _.noop
  }

  getDataTableColumns() {
    const { columns } = this.props
    return _(columns)
      .filter(c => !c.toggleable || c.on)
      .map(column => ({
        ...column,
        headerCell:
          column.type === "share" ? this.renderShareHeader(column) : undefined,
        renderCell: this.cellRenderers[column.type]
      }))
      .value()
  }

  renderShareHeader(column) {
    const { reviews } = this.props
    const imageName = "reply"
    const completedReviews = _.filter(
      reviews,
      review => review.complete === true
    )
    const canShareAllReviews =
      completedReviews.length > 0
        ? _.some(completedReviews, r => !r.shared_at)
        : false
    const tooltip = canShareAllReviews ? (
      <FormattedMessage {...strings.performanceReviews.shareAllTooltip} />
    ) : null

    return (
      <div
        className="EvaluationsTable--share-cell"
        onClick={canShareAllReviews ? column.onHeaderClick : null}
      >
        {this.renderShareTooltip(tooltip, imageName)}
      </div>
    )
  }

  renderShareTooltip(tooltip, imageName) {
    const className = cx("icon-flip-horizontal", {
      "EvaluationsTable--share-cell-inactive": !tooltip
    })
    const icon = (
      <div className={className}>
        <Icon iconName="sharing" />
      </div>
    )
    if (!tooltip) {
      return icon
    }

    return (
      <Tooltip
        placement="bottom"
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        overlay={<div className="DataTable--header-tooltip">{tooltip}</div>}
      >
        {icon}
      </Tooltip>
    )
  }

  getLinkLocationForReview = review => {
    const { reviewLinkPrefix, reviewLinkQuery, getCycleForReview } = this.props
    const cycle = getCycleForReview(review)

    if (!reviewLinkPrefix) {
      return null
    }

    return {
      pathname: `${reviewLinkPrefix}/${cycle.id}/${slugify(
        cycle.name
      )}/review/${review.id}`,
      query: reviewLinkQuery
    }
  }

  canEditCell = (review, column) =>
    column.canEdit ? column.canEdit(review) : false

  handleSort = ({ sortBy, sortId, sortOrder }) => {
    const { onSortUpdate } = this.props

    onSortUpdate({
      sortBy,
      questionId: sortBy === "performance_answer" ? sortId : undefined,
      sortOrder
    })
  }

  renderDropdownCell = ({ value, options, onChange }) => (
    <div className="EvaluationsTable--editable-cell">
      <div onClick={e => e.preventDefault()}>
        <Select
          className="EvaluationsTable--dropdown-field"
          value={value}
          options={options}
          onChange={onChange}
        />
      </div>
    </div>
  )

  renderQuestionCell = (review, column) => {
    const answerToRender = _.find(
      review.performance_answers,
      answer => answer.performance_question_in_cycle.id === column.id
    )

    const textAnswer = _.get(answerToRender, "text")
    const ratingAnswer = _.get(answerToRender, "rating")

    const cycle = this.props.getCycleForReview(review)
    const question = cycle.performance_question_in_cycles.find(
      ({ id }) => id === column.id
    )

    if (
      question.question_type === "multiple-choice" &&
      this.canEditCell(review, column)
    ) {
      return this.renderDropdownCell({
        review,
        column,
        value: ratingAnswer,
        options: _(question.answer_titles_hash)
          .map((title, value) => ({ text: title, value: Number(value) }))
          .sortBy("value")
          .value(),
        onChange: ({ value }) => column.onChange({ review, question, value })
      })
    } else {
      return (
        <div className="EvaluationsTable--question-cell">
          <FadeoutTruncated>
            {do {
              if (textAnswer) {
                ;<MarkdownText text={textAnswer} />
              } else if (ratingAnswer) {
                question.answer_titles_hash[ratingAnswer]
              } else {
                ;("-")
              }
            }}
          </FadeoutTruncated>
        </div>
      )
    }
  }

  renderBucketCell = (review, column) => {
    const cycle = this.props.getCycleForReview(review)
    const { complete, assignedBucket } = review

    if (this.canEditCell(review, column)) {
      return this.renderDropdownCell({
        review,
        column,
        value: _.get(assignedBucket, "id"),
        options: cycle.performance_bucket_in_cycles.map(({ id, title }) => ({
          text: title,
          value: id
        })),
        onChange: ({ value: bucketId }) => column.onChange({ review, bucketId })
      })
    } else {
      return (
        <div className="EvaluationsTable--bucket-cell">
          {do {
            if (complete) {
              if (assignedBucket) {
                assignedBucket.title
              } else {
                ;<FormattedMessage {...strings.general.completed} />
              }
            } else {
              ;<div className="EvaluationsTable--incomplete-bucket">-</div>
            }
          }}
        </div>
      )
    }
  }

  reviewIsShareable(review) {
    const { user } = this.context
    const { complete, manager } = review
    const managerId = manager && manager.id
    const userId = user && user.id
    const userCanShare =
      managerId === userId || User.isAdmin(user) || User.isHrbp(user)
    return complete && userCanShare
  }

  renderShareIconColumn(review, column, iconName, className) {
    const {
      shared_at: sharedAt,
      user: { best_name: firstName }
    } = review
    const cellActive = this.reviewIsShareable(review) && !sharedAt
    const tooltip = cellActive ? (
      <FormattedMessage
        {...strings.performanceReviews.shareWithTooltip}
        values={{ name: firstName }}
      />
    ) : null
    const iconImage = this.reviewIsShareable(review)
      ? this.renderShareTooltip(tooltip, iconName)
      : null
    const onClick = cellActive
      ? e => {
          e.preventDefault()
          column.onCellClick(review)
        }
      : null

    return (
      <div className={className} onClick={onClick}>
        {iconImage}
      </div>
    )
  }

  renderFlagIconColumn(review, column, iconName, className) {
    return (
      <div
        className={className}
        onClick={e => {
          e.preventDefault()
          column.onCellClick(review)
        }}
      >
        <Icon iconName={iconName} />
      </div>
    )
  }

  cellRenderers = {
    employee: ({ user }) => (
      <div className="EvaluationsTable--employee-cell">
        <LabeledEmployeePic user={user} />
      </div>
    ),

    flag: (review, column) =>
      this.renderFlagIconColumn(
        review,
        column,
        review.flag === "flagged" ? "flagOn" : "flagOff",
        cx("EvaluationsTable--flag-cell", {
          "EvaluationsTable--flag-cell-faded":
            review.flag !== "flagged" && !this.canEditCell(review, column)
        })
      ),

    share: (review, column) =>
      this.renderShareIconColumn(
        review,
        column,
        "reply",
        cx("EvaluationsTable--share-cell", {
          "EvaluationsTable--share-cell-inactive": review.shared_at
        })
      ),

    cycleName: review => <div>{this.props.getCycleForReview(review).name}</div>,

    cycleWithDate: review => {
      const cycle = this.props.getCycleForReview(review)
      return (
        <div>
          <div className="EvaluationsTable--cycle-date">
            <ZugataFormattedDate value={cycle.end_date} />
          </div>
          <div className="EvaluationsTable--cycle-name">{cycle.name}</div>
        </div>
      )
    },

    manager: ({ manager }) => (
      <div className="EvaluationsTable--manager-cell">
        {manager ? User.getDisplayName(manager) : <NoManagerText />}
      </div>
    ),

    status: ({ status }) => (
      <div className={"EvaluationsTable--status-cell"}>
        <div>
          <ReviewStatus
            status={status}
            statusStrings={strings.evaluations.status}
            startedFlag={true}
          />
        </div>
      </div>
    ),

    average: ({ average_question_rating: averageRating }) => (
      <div className="EvaluationsTable--rating-cell">
        {do {
          if (averageRating || averageRating === 0) {
            ;<FormattedNumber
              value={averageRating}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          } else {
            ;<FormattedMessage {...strings.performanceReviews.noAverage} />
          }
        }}
      </div>
    ),

    profile: (review, column) => (
      <div
        className="EvaluationsTable--profile-cell"
        onClick={e => {
          e.preventDefault()
          column.onCellClick(review)
        }}
      >
        <Icon iconName="user" />
      </div>
    ),

    menuItems: (review, column, selectable) => (
      <div className="EvaluationsTable--menuItems">
        {selectable && (
          <MoreButtonsMenu
            showTip={false}
            menuAlignment={"right"}
            onMouseLeave={true}
            options={column.renderOptions(review)}
          />
        )}
      </div>
    ),

    bucket: this.renderBucketCell,

    question: this.renderQuestionCell,

    collaborators: review => (
      <div className="EvaluationsTable--collaborator-icon">
        {review.access_permissions && review.access_permissions.length > 0 && (
          <Tooltip
            placement="bottom"
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            overlay={
              <div className="EvaluationsTable--collaborator-tooltip">
                <FormattedListSentence
                  base={strings.review.collaboratorSubtitle}
                  items={review.access_permissions}
                  options={{
                    itemRenderer: ({ user }) => user.full_name
                  }}
                />
              </div>
            }
          >
            <div className="EvaluationsTable--collaborator-icon-container">
              <Icon iconName="user" />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  render() {
    const {
      reviews,
      sortable,
      sortBy,
      sortOrder,
      hasMore,
      isReviewSelectable,
      questionIdForQuestionColumnSort,
      onSelectReview,
      onNextPageNeeded,
      highlightedReviewId
    } = this.props

    return (
      <DataTable
        className="EvaluationsTable"
        items={reviews}
        columns={this.getDataTableColumns()}
        sortBy={sortBy}
        sortId={questionIdForQuestionColumnSort}
        sortOrder={sortOrder}
        sortable={sortable}
        hasMore={hasMore}
        getLinkLocationForItem={this.getLinkLocationForReview}
        isItemSelectable={isReviewSelectable}
        onSort={this.handleSort}
        onSelectItem={onSelectReview}
        onNextPageNeeded={onNextPageNeeded}
        highlightedItemId={highlightedReviewId}
      />
    )
  }
}
