import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import strings from "../../../locale/strings"
import ZugataFormattedDate from "../../elements/ZugataFormattedDate/ZugataFormattedDate"

export default class UserInfo extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  renderLine(label, value) {
    return (
      <p>
        <strong>
          <FormattedMessage {...label} />
        </strong>{" "}
        {typeof value !== "object" ? (
          <span className="truncate">{value}</span>
        ) : (
          value
        )}
      </p>
    )
  }

  render() {
    const { user } = this.props
    const now = moment(new Date())

    const tenureYears = now.diff(user.hire_date, "year")
    const tenureMonths = now.diff(user.hire_date, "month") % 12

    return (
      <div className="UserInfo layout vertical">
        {this.renderLine(strings.userInfo.department, user.department_name)}
        {this.renderLine(strings.userInfo.pos, user.job_title.best_title)}
        {this.renderLine(strings.userInfo.level, user.job_title_level)}
        {this.renderLine(strings.userInfo.manager, user.manager_name)}
        {this.renderLine(
          strings.userInfo.hireDate,
          user.hire_date ? <ZugataFormattedDate value={user.hire_date} /> : ""
        )}
        {this.renderLine(
          strings.userInfo.tenure,
          do {
            if (!user.hire_date) {
              null
            } else if (tenureYears === 0 && tenureMonths === 0) {
              ;<FormattedMessage {...strings.userInfo.lessThanAMonth} />
            } else {
              ;<FormattedMessage
                {...strings.userInfo.dateTemplate}
                values={{
                  yearNum: tenureYears,
                  monthNum: tenureMonths
                }}
              />
            }
          }
        )}
        {this.renderLine(strings.userInfo.location, user.location)}
        {this.renderLine(strings.userInfo.id, user.employee_id)}
      </div>
    )
  }
}
