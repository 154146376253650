import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import _ from "lodash"
import { Department } from "../../../state/DepartmentsState"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useNotifications from "../../../hooks/useNotifications"
import DepartmentGoalForm, {
  DepartmentGoalFields
} from "../DepartmentGoalForm/DepartmentGoalForm"
import useCreateDepartmentGoal from "../../../hooks/useCreateDepartmentGoal"
import useDepartments from "../../../hooks/useDepartments"
import strings from "../../../locale/strings"
import { User } from "../../../types/User"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"

type NewDepartmentGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    departmentId: string
  }
}>

export const formDisabled = (loading: boolean, departments: Department[]) =>
  loading || departments.length === 0

const NewDepartmentGoal: NewDepartmentGoal = ({ intl, router, params }) => {
  const { departmentId } = params
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const currentUser = useCurrentUser()
  // NOTE: Do we need to have a search term here?
  const { departments } = useDepartments()
  const {
    createDepartmentGoal,
    loading,
    data,
    error
  } = useCreateDepartmentGoal()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalSuccessTitle
        ),
        message: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalSuccessNotification
        )
      })
      router.push({
        pathname: `/new_goals/department`,
        query: {
          // @ts-ignore
          goalId: data.goal.id
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalErrorTitle
        ),
        message: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalErrorNotification
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const initOwners: User[] = [currentUser]
  const newGoal: DepartmentGoalFields = {
    name: "",
    departmentId: "",
    description: "",
    dueDate: currentUser.company.goalCycle.end,
    priority: 1,
    visibility: "everyone",
    owners: initOwners,
    keyResults: [newKeyResult()],
    alignedGoalIds: []
  }

  const initDepartment = () => {
    const department = departments.filter(
      department => department.id === departmentId
    )
    return department.length > 0
      ? { value: department[0].id, label: department[0].name }
      : undefined
  }

  /**
   * To have access to create a department goal means that the user is admin or is hrb department or manage a department.
   * If the user is an admin we allow to create a goal for any department.
   * If the user is non an admin we display hrbp departments and user's managed departments.
   * https://zugata.atlassian.net/browse/FOW-621
   * Should this be done in the API?
   */

  const currentUserHrbPartners = currentUser.hrbPartners.map(department => {
    return { name: department.title, id: department.id.toString() }
  })

  const currentUserManagedDepartments = currentUser.managedDepartments.map(
    department => {
      return { name: department.title, id: department.id.toString() }
    }
  )
  const currentUserDepartments = currentUserHrbPartners.concat(
    currentUserManagedDepartments
  )

  const unique = _.uniq(currentUserDepartments, department => department.id)

  return (
    <DepartmentGoalForm
      title={formatMessage(strings.newDepartmentGoal.title)}
      submitLabel={formatMessage(strings.newDepartmentGoal.submitLabel)}
      defaultFields={newGoal}
      departments={currentUser.isAdmin ? departments : unique}
      initDepartment={departmentId ? initDepartment() : undefined}
      onSubmit={createDepartmentGoal}
      onLeavePage={() => router.push("/new_goals/department")}
      disabled={formDisabled(loading, departments)}
      initOwners={initOwners}
    />
  )
}

export default injectIntl(NewDepartmentGoal)
