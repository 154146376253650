import { debounce } from "../decorators/timers"

/**
 * Given an element or window, notifies about scroll position and size changes
 * to facilitate elements that track its scrolling (e.g., sticky headers).
 *
 * Takes two arguments: the scrolling element/window and a callback. For convenience,
 * the callback is called with an object of the form
 * `{scrollLeft, scrollTop, clientLeft, clientTop, width, height}`, which
 * describes the position and dimensions of the scrollable area.
 *
 * Must be explicitly started and stopped with `start()` and `stop()`.
 *
 * The following convenience methods are also available:
 *
 * - `getScrollbox()`: returns an object of the form
 *   `{scrollLeft, scrollTop, clientLeft, clientTop, width, height}`, describing
 *   the position and dimensions of the scrollable area
 * - `notifyScrollChange()`: triggers the passed-in callback as if a scroll
 *   or resize event had just occurred.
 */
export default class ScrollTracker {
  constructor(nodeOrWindow, callback) {
    this._target = nodeOrWindow
    this._callback = callback
    this._scrollHandler = this.handleScroll.bind(this)
    this._resizeHandler = this.handleResize.bind(this)
  }

  getTarget() {
    // If document or body, return window instead
    return (
      ((this._target.nodeName === "#document" ||
        this._target.nodeName === "BODY") &&
        window) ||
      this._target
    )
  }

  getScrollDimensions() {
    const target = this.getTarget()
    const isWindow = target.window === target // adapted from jQuery

    if (isWindow) {
      return {
        scrollLeft: target.pageXOffset,
        scrollTop: target.pageYOffset,

        clientLeft: 0,
        clientTop: 0,

        // By reporting width using window.innerWidth & window.innerHeight instead
        // of documentElement.clientWidth etc., we can account for Mobile Safari's
        // address bar compression effect. The tradeoff is that any scroll bars
        // will be included as well. That shouldn't be a problem as this utility
        // is usually used for vertical scroll tracking, and there shouldn't normally be
        // a horizontal scroll bar on the window.
        //
        width: target.innerWidth,
        height: target.innerHeight
      }
    } else {
      const clientRect = target.getBoundingClientRect()
      const parentWindow = target.ownerDocument.defaultView
      const style = parentWindow.getComputedStyle(target, null)

      return {
        scrollLeft: target.scrollLeft,
        scrollTop: target.scrollTop,

        // NB: clientRect represents a border box, but scrolling occurs within
        // borders
        clientLeft: clientRect.left + parseInt(style.borderLeftWidth, 10),
        clientTop: clientRect.top + parseInt(style.borderTopWidth, 10),

        width: target.clientWidth,
        height: target.clientHeight
      }
    }
  }

  start() {
    const target = this.getTarget()

    this.stop()

    target.addEventListener("scroll", this._scrollHandler)
    target.addEventListener("resize", this._resizeHandler)
    target.addEventListener("orientationchange", this._resizeHandler)

    this.notifyScrollChange()
  }

  stop() {
    const target = this.getTarget()

    target.removeEventListener("scroll", this._scrollHandler)
    target.removeEventListener("resize", this._resizeHandler)
    target.removeEventListener("orientationchange", this._resizeHandler)
  }

  notifyScrollChange() {
    this._callback(this.getScrollDimensions())
  }

  handleScroll() {
    this.notifyScrollChange()
  }

  handleResize() {
    this.notifyScrollChange()
    this.handleResizeAfterTransitions()
  }

  @debounce(500)
  handleResizeAfterTransitions() {
    this.notifyScrollChange()
  }
}
