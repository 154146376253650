import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { FormattedMessage } from "react-intl"

const getNumericCase = index => {
  const numbers = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten"
  }

  return `${numbers[index]}Item${index > 1 ? "s" : ""}`
}

const findLocaleString = ({ base, count, includesSelf }) => {
  const person = includesSelf ? "secondPerson" : "thirdPerson"
  const baseWithPerson = _.get(base, person, _.get(base, "thirdPerson"))
  return _.get(
    baseWithPerson,
    getNumericCase(count),
    _.get(baseWithPerson, "nItems")
  )
}

const FormattedListSentence = ({
  base,
  items = [],
  options: {
    isItemSelf = () => false,
    itemRenderer = item => item,
    totalCount = items.length
  } = {}
}) => {
  const itemsToShow = _.reject(items, isItemSelf)
  const includesSelf = itemsToShow.length < items.length
  const localeString = findLocaleString({
    base,
    count: totalCount,
    includesSelf
  })

  const values = {
    num: totalCount,
    ..._(itemsToShow)
      .map((item, i) => [`noun${i + 1}`, itemRenderer(item)])
      .zipObject()
      .value()
  }

  return <FormattedMessage {...localeString} values={values} />
}

FormattedListSentence.propTypes = {
  base: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  options: PropTypes.shape({
    totalCount: PropTypes.number,
    isItemSelf: PropTypes.func,
    itemRenderer: PropTypes.func
  })
}

export default FormattedListSentence
