import _ from "lodash"
import Reflux from "./ActionsInitializer"
import SessionActions from "./SessionActions"
import UIActions, { loadingTypes } from "./UIActions"
// NOTE: import SteadyfootAgent after SessionActions
import SteadyfootAgent from "./lib/SteadyfootAgent"
import settings from "../settings"
import strings from "../locale/strings"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "./lib/apiActionHelpers"
import { GENERAL_URLS, USERS_URLS } from "../api/endpoints.json"

const { AUTH_PASSWORDS_URL, NOTES_URL } = GENERAL_URLS
const {
  USERS_URL,
  SIGNUP_REQUEST_URL,
  SIGNUP_INVITE_URL,
  CURRENT_USER_URL
} = USERS_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}

const UserActions = Reflux.createActions({
  loadCurrent: ASYNC_SETTINGS,
  reloadCurrent: ASYNC_SETTINGS,
  searchForManager: ASYNC_SETTINGS,
  list: ASYNC_SETTINGS,
  create: ASYNC_SETTINGS,
  signup: ASYNC_SETTINGS,
  confirm: ASYNC_SETTINGS,
  forgotPassword: ASYNC_SETTINGS,

  invitePeep: ASYNC_SETTINGS,
  acceptInvite: ASYNC_SETTINGS,

  update: ASYNC_SETTINGS,
  updatePassword: ASYNC_SETTINGS,
  updateFirstName: ASYNC_SETTINGS,
  updateLastName: ASYNC_SETTINGS,
  updateJobTitle: ASYNC_SETTINGS,
  updateAvatar: ASYNC_SETTINGS,
  updateCompanyName: ASYNC_SETTINGS,
  updateAutoEmailSetting: ASYNC_SETTINGS,

  loadById: ASYNC_SETTINGS,
  loadUserByEmail: ASYNC_SETTINGS,

  getNote: ASYNC_SETTINGS,
  saveNote: ASYNC_SETTINGS,
  notifyNoteEditing: {},

  exportCurrentUserData: { asyncResult: true }
})

/* User Actions
===============================*/

UserActions.loadCurrent.listenAndPromise(() =>
  addToasts(
    { defaultError: strings.toast.app.unableToConnect },
    extractResponseKey(
      "user",
      augmentSteadyfootUser,
      agent.get(CURRENT_USER_URL)
    )
  )
)

UserActions.list.listenAndPromise(
  ({ page = 1, per_page = 15, ...search } = {}) => {
    return new Promise((resolve, reject) => {
      agent
        .get(USERS_URL)
        .query({
          page,
          per_page,
          ...search
        })
        .end((err, res) => {
          if (err || !res.body.users) {
            reject(err)
          } else {
            resolve({
              users: res.body.users.map(augmentSteadyfootUser),
              meta: res.body.meta
            })
          }
        })
    })
  }
)

UserActions.create.listenAndPromise(credentials =>
  addLoadingState(
    [true, { light: true, type: loadingTypes.AUTH }],
    addToasts(
      { defaultError: strings.signin.genericSignupError },
      extractResponseBody(agent.post(USERS_URL).send(credentials))
    )
  ).then(user => {
    SessionActions.autoLogin(credentials)
  })
)

UserActions.signup.listenAndPromise(({ email }) =>
  addLoadingState(
    [true, { light: true, type: loadingTypes.AUTH }],
    addToasts(
      { defaultError: strings.signin.genericSignupError },
      extractResponseBody(agent.post(SIGNUP_REQUEST_URL).send({ email }))
    )
  )
)

UserActions.forgotPassword.listenAndPromise(credentials =>
  addLoadingState(
    [true, { light: true, type: loadingTypes.AUTH }],
    addToasts(
      { defaultError: strings.signin.genericResetPasswordError },
      extractResponseBody(
        agent
          .post(AUTH_PASSWORDS_URL)
          .send({ user: { email: credentials.user.email } })
      )
    )
  )
)

UserActions.invitePeep.listenAndPromise((email, formatMessage) =>
  addLoadingState(
    true,
    extractResponseBody(
      agent
        .post(SIGNUP_INVITE_URL)
        .send({ signup_invite: { email_address: email } })
    )
  ).then(
    () => {
      UIActions.success(
        formatMessage(strings.feedbackRequests.successMessageSingleRequest, {
          user: email
        })
      )
    },
    err => {
      const msg =
        (err && err.response && err.response.body && err.response.body.error) ||
        formatMessage(strings.feedbackRequests.errorMessageSendInviteToPeep, {
          email
        })
      UIActions.error(msg)
    }
  )
)

UserActions.acceptInvite.listenAndPromise(invitationToken =>
  addLoadingState(
    [true, { light: true }],
    extractResponseKey(
      "signup_invite",
      agent.get(`${SIGNUP_INVITE_URL}/${invitationToken}`)
    )
  )
)

UserActions.reloadCurrent.listenAndPromise(() =>
  extractResponseKey("user", augmentSteadyfootUser, agent.get(CURRENT_USER_URL))
)

UserActions.update.preEmit = function({ user }, { silent = false } = {}) {
  return updateUser(user, "update", { silent })
}

UserActions.updatePassword.preEmit = function(credentials) {
  credentials = credentials.user || credentials

  UIActions.setLoadingState(true, { light: true })

  agent
    .put(
      credentials.reset_password_token || credentials.signup_token
        ? AUTH_PASSWORDS_URL
        : CURRENT_USER_URL
    )
    .send({ user: credentials })
    .end((err, res) => {
      UIActions.setLoadingState(false)

      if (err || !res) {
        UserActions.updatePassword.failed(res && res.body, err)
        UserActions.update.failed(res && res.body, err)
      } else {
        UIActions.success(strings.signin.passwordChanged)
        UserActions.updatePassword.completed(res.body, credentials.password)
      }
    })
}

UserActions.updateFirstName.preEmit = function(val) {
  return updateUser({ best_name: val }, "updateFirstName")
}

UserActions.updateLastName.preEmit = function(val) {
  return updateUser({ last_name: val }, "updateLastName")
}

UserActions.updateJobTitle.preEmit = function(val) {
  return updateUser({ job_title_id: val }, "updateJobTitle")
}

UserActions.updateCompanyName.preEmit = function(val) {
  return updateUser({ company: { name: val } }, "updateCompanyName")
}

UserActions.updateAutoEmailSetting.preEmit = function(val) {
  return updateUser({ can_email_contacts: val }, "updateAutoEmailSetting")
}

UserActions.updateAvatar.preEmit = function(avatar) {
  UIActions.setLoadingState(true)

  return updateUser({ avatar }, "updateAvatar")
}

UserActions.loadById.listenAndPromise((id, { showLoadingState = false } = {}) =>
  addLoadingState(
    showLoadingState,
    extractResponseKey("user", agent.get(`${USERS_URL}/${id}`))
  )
)

UserActions.getNote.listenAndPromise(({ userId }) =>
  extractResponseKey(
    "note",
    agent.get(NOTES_URL).query({ about_user_id: userId })
  ).catch(() => Promise.reject({ userId }))
)

UserActions.saveNote.listenAndPromise(({ noteBody, userId }) =>
  extractResponseKey(
    "note",
    agent
      .post(NOTES_URL)
      .send({ note: { body: noteBody, about_user_id: userId } })
  ).catch(() => Promise.reject({ noteBody, userId }))
)

UserActions.exportCurrentUserData.listenAndPromise(() =>
  addToasts(
    { success: strings.user.exportDataSuccess },
    addLoadingState([true, { light: true }], agent.post(`${USERS_URL}/export`))
  )
)

UserActions.loadUserByEmail.listenAndPromise(email =>
  addToasts({}, loadUserByEmail(email))
)

export function loadUserByEmail(email, { url = USERS_URL } = {}) {
  return extractResponseKey(
    "users",
    agent.get(url).query({ q: email })
  ).then(users => _.find(users, { email }))
}

function updateUser(updates, actionName, { silent = false } = {}) {
  agent
    .put(CURRENT_USER_URL)
    .send({ user: updates })
    .end((err, res) => {
      UIActions.setLoadingState(false)

      if (err || !res) {
        UserActions[actionName].failed(res && res.body, err)
        UserActions.update.failed(res && res.body, err)
      } else {
        const user = augmentSteadyfootUser(res.body.user)
        UserActions[actionName].completed(user)
        UserActions.update.completed(user)

        if (!silent) {
          UIActions.notify(strings.profile.updatedProfile)
        }
      }
    })
}

/**
 * Transforms the user object returned from the Steadyfoot API, adding or
 * modifying properties to better suit Beaconstar.
 */
export function augmentSteadyfootUser(user) {
  if (!user) {
    return null
  }

  /**
   * Prepend the URL with the Steadyfoot host if it's server-relative.
   */
  function resolveUrl(url) {
    return _.startsWith(url, "/") && !_.startsWith(url, "//")
      ? _.trimRight(settings.STEADYFOOT_URL, "/") + url
      : url
  }

  // TODO: consider ImmutableJS to make non-modifying object updates easier
  return _.mapValues(user, (value, key) => {
    switch (key) {
      case "avatar_images":
        return _.mapValues(value, resolveUrl)
      case "company":
        return _.mapValues(value, (compValue, compKey) =>
          compKey === "avatar_images"
            ? _.mapValues(compValue, resolveUrl)
            : compValue
        )
      // TODO remove when we deprecate full_name
      case "full_name":
        return (
          value ||
          (user.best_name && user.last_name
            ? `${user.best_name} ${user.last_name}`
            : value)
        )
      default:
        return value
    }
  })
}

export default UserActions
