import React from "react"
import FlatPickr from "react-flatpickr"
// @ts-ignore
import connect from "../../decorators/connect"
// @ts-ignore
import LocaleStore from "../../../stores/LocaleStore"
import Aid from "../../../constants/automationId"

type Props = {
  localeData: { flatpickr: { flatpickrLocale: string } }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
  displayTime: boolean
  automationId: Aid
}

function IntlFlatPickr({
  localeData: { flatpickr: flatpickrLocale },
  options,
  displayTime = false,
  automationId,
  ...props
}: Props) {
  return (
    <FlatPickr
      data-enable-time={displayTime}
      options={{
        locale: flatpickrLocale, // English is the implicit default
        ...options
      }}
      data-automation-id={automationId}
      {...props}
    />
  )
}

export default connect(LocaleStore, "localeData")(IntlFlatPickr)
