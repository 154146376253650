import { useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import teamDecoder from "../decoders/TeamDecoder/teamDecoder"
import { TeamsContext } from "../state/TeamsState"
import useDataAPI from "./useDataAPI"

const useTeam = (id?: string) => {
  const { state: teams, dispatch } = useContext(TeamsContext)
  const fetchAPI = useDataAPI(`/teams/${id}`, "GET")

  useEffect(() => {
    if (id) {
      fetchAPI.fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (fetchAPI.data) {
      teamDecoder
        .decodePromise(fetchAPI.data)
        .then(team => {
          dispatch({ type: "FETCH_TEAM", payload: team })
        })
        .catch(error => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAPI.data])

  const team = id ? teams.selectedTeam : undefined

  return { team, loading: fetchAPI.loading }
}

export default useTeam
