import React from "react"
import PropTypes from "prop-types"
import User from "../../../models/User"
import FeatureFlags from "../../../constants/featureFlags"

/**
 * A hoc version of feature flagging. Handy to use with the routes.jsx config.
 */
export const featureToggle = ({
  flag,
  On,
  Off
}: {
  flag: FeatureFlags
  On: React.ComponentType
  Off: React.ComponentType
}) => {
  class AdminEeListingPageFeatureToggled extends React.PureComponent {
    static contextTypes = {
      user: PropTypes.object.isRequired
    }

    render() {
      return User.hasFlag(this.context.user, flag) ? (
        <On {...this.props} />
      ) : (
        <Off {...this.props} />
      )
    }
  }

  return AdminEeListingPageFeatureToggled
}
