import _ from "lodash"
import BaseModel from "./BaseModel"

export default class SurveyTemplate extends BaseModel {
  constructor(props) {
    super(props)

    /**
     * This constructor transforms the questions array from the shape the backend expects
     * to the shape they are interpreted by the UI components:
     */
    this.questions = props.questions.map(question => {
      const { choices, ...restOfProperties } = question
      const transformedQuestion = { ..._.omit(restOfProperties, "idx") }

      if (choices) {
        transformedQuestion.choices = _.sortBy(choices, "value")
      }

      return transformedQuestion
    })
  }

  /* This function transforms the questions array to the shape the backend expects: */
  static transformToBackendTemplate(template) {
    return {
      ...template,
      questions: template.questions.map((question, index) => {
        const transformedQuestion = {
          ..._.omit(question, "clientId"),
          idx: index,
          // eslint-disable-next-line camelcase
          discussion_prompt: question.discussion_prompt || undefined
        }

        return transformedQuestion
      })
    }
  }
}
