import PropTypes from "prop-types"
import React from "react"
import emojify from "../../util/emojify"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import QuestionsAndAnswersComment from "../QuestionsAndAnswersComment/QuestionsAndAnswersComment"

export default class ReviewNotesComment extends React.Component {
  static propTypes = {
    review: PropTypes.object.isRequired
  }

  render() {
    const { review } = this.props

    const notesContent =
      // Skill-based reviews are not currently rich-text-ified, so don't use MarkdownText for them.
      // HACK - SF currently doesn't expose the review type, so we check if there aren't any
      // rated skills. Unfortunately, this would also catch reviews where all the skills were
      // skipped.
      review.ratings.every(r => r.rating === 0) ? (
        <MarkdownText text={review.notes} />
      ) : (
        <div>{emojify(review.notes)}</div>
      )

    return review.question ? (
      <QuestionsAndAnswersComment
        questionsAndAnswers={[
          { question: review.question, answerContent: notesContent }
        ]}
      />
    ) : (
      notesContent
    )
  }
}
