import {
  TableCard,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderRowCell
} from "@kaizen/component-library/draft/Kaizen/Table"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import GoalsSummaryCell from "../../elements/GoalsSummaryCell/GoalsSummaryCell"
import GoalRow from "../../elements/GoalRow/GoalRow"
import strings from "../../../locale/strings"
import { GoalPreview } from "../../../types/Goals"
import Loading from "../../elements/Loading/Loading"
import styles from "./GoalsTable.scss"

type GoalsTableProps = React.FunctionComponent<{
  intl: InjectedIntl
  goals: GoalPreview[]
  titleName: string
  displayOwners?: boolean
  goalProgress: number
  goalsCount?: number
  openGoal: (goalId: string) => void
  onClose?: () => void
  loading?: boolean
}>

const GoalsTable: GoalsTableProps = ({
  goals,
  goalsCount,
  openGoal,
  displayOwners,
  titleName,
  goalProgress,
  onClose,
  loading = false,
  intl
}) => {
  const smallerCellWidth = displayOwners ? 2 / 12 : 2 / 10
  const mediumCellWidth = displayOwners ? 3 / 12 : 3 / 10
  const largerCellWidth = displayOwners ? 5 / 12 : 5 / 10
  return (
    <div>
      <TableContainer>
        <TableCard onClick={onClose ? onClose : undefined}>
          <GoalsSummaryCell
            ownerName={titleName}
            progress={goalProgress}
            goalsCount={goalsCount || goals.length}
            expandable={!!onClose}
            open
          />
        </TableCard>
        <div className={styles.tableHeader}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderRowCell
                labelText={intl.formatMessage(
                  strings.goalsPage.teamGoals.goalHeader
                )}
                width={largerCellWidth}
              />
              {displayOwners && (
                <TableHeaderRowCell
                  labelText={intl.formatMessage(
                    strings.goalsPage.teamGoals.ownerHeader
                  )}
                  width={smallerCellWidth}
                />
              )}
              <TableHeaderRowCell
                labelText={intl.formatMessage(
                  strings.goalsPage.teamGoals.dueDateHeader
                )}
                width={smallerCellWidth}
              />
              <TableHeaderRowCell
                labelText={intl.formatMessage(
                  strings.goalsPage.teamGoals.percentageHeader
                )}
                width={mediumCellWidth}
              />
            </TableHeaderRow>
          </TableHeader>
        </div>

        {goals.length > 0 &&
          goals.map(goal => {
            const owners =
              displayOwners && goal.type !== "personal"
                ? goal.owners
                : undefined
            return (
              <TableCard
                key={goal.id}
                onClick={() => openGoal(goal.id.toString())}
              >
                <GoalRow goal={goal} owners={owners} />
              </TableCard>
            )
          })}
      </TableContainer>
      {loading && <Loading />}
    </div>
  )
}

export default injectIntl(GoalsTable)
