import "./GoalKeyResultList.less"
import React from "react"
import PropTypes from "prop-types"
import d3 from "d3"
import { FormattedMessage } from "react-intl"
import { List } from "immutable"
import strings from "../../../locale/strings"
import GoalProgressSlider from "./GoalProgressSlider"
import GoalProgressBar from "./GoalProgressBar"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"

export default class GoalKeyResultList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    keyResults: PropTypes.instanceOf(List).isRequired,
    readOnly: PropTypes.bool,
    onCompletionChange: PropTypes.func,
    onAfterCompletionChange: PropTypes.func
  }

  static defaultProps = {
    className: "",
    readOnly: false,
    onCompletionChange: (index, completion) => {}, // eslint-disable-line no-unused-vars
    onAfterCompletionChange: (index, completion) => {} // eslint-disable-line no-unused-vars
  }

  render() {
    const {
      className,
      keyResults,
      readOnly,
      onCompletionChange,
      onAfterCompletionChange
    } = this.props

    const overallCompletion = d3.mean(keyResults.toArray(), kr => kr.completion)

    return (
      <div className={`GoalKeyResultList ${className}`}>
        <div className="GoalKeyResultList--header layout horizontal center-items justified">
          <FormattedMessage {...strings.goals.keyResults} />
          <FormattedMessage
            {...strings.general.percentComplete}
            values={{ value: overallCompletion }}
          />
        </div>
        <ol>
          {keyResults.map(({ title, completion }, i) => (
            <li
              key={i}
              className="GoalKeyResultList--key-result-container layout horizontal center-items wrap"
            >
              <div className="GoalKeyResultList--key-result-title flex three">
                {/* Number the list manually rather than letting the browser do it.
                 * The interplay between HTML lists and flex is totally messed, at least
                 * in Chrome. This also simplifies the responsive layout, where the number
                 * is visually part of the text. */}
                {i + 1}. <MarkdownText inline={true} text={title} />
              </div>
              {readOnly ? (
                <GoalProgressBar
                  className="GoalKeyResultList--key-result-completion flex one"
                  value={completion}
                />
              ) : (
                <GoalProgressSlider
                  className="GoalKeyResultList--key-result-completion flex one"
                  value={completion}
                  onChange={onCompletionChange.bind(null, i)}
                  onAfterChange={onAfterCompletionChange.bind(null, i)}
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    )
  }
}
