import AdminEmployeeGrouping from "../admin/stores/AdminEmployeeGroupingStore"
import AdminFeedbackStats from "../admin/stores/AdminFeedbackStatsStore"
import AdminGoal from "../admin/stores/AdminGoalStore"
import AdminIncompleteSurveyResponse from "../admin/stores/AdminIncompleteSurveyResponseStore"
import AdminManagerFeedbackStats from "../admin/stores/AdminManagerFeedbackStatsStore"
import AdminSavedView from "../admin/stores/AdminSavedViewStore"
import AdminSurveyResponse from "../admin/stores/AdminSurveyResponseStore"
import AdminSurvey from "../admin/stores/AdminSurveyStore"
import AdminTemplate from "../admin/stores/AdminTemplateStore"
import AdminUserUploads from "../admin/stores/AdminUserUploadsStore"
import Directory from "../admin/stores/DirectoryStore"
import ManageUsers from "../admin/stores/ManageUsersStore"
import PerformanceReviewAdmin from "../admin/stores/PerformanceReviewAdminStore"
import BaseEmployeeGrouping from "./BaseEmployeeGroupingStore"
import BaseIncompleteSurveyResponse from "./BaseIncompleteSurveyResponseStore"
import BaseSurveyResponse from "./BaseSurveyResponseStore"
import BaseSurvey from "./BaseSurveyStore"
import Contact from "./ContactStore"
import Conversation from "./ConversationStore"
import CurrentUser from "./CurrentUserStore"
import DevelopmentResource from "./DevelopmentResourceStore"
import Draft from "./DraftStore"
import EmployeeGrouping from "./EmployeeGroupingStore"
import Experiment from "./ExperimentStore"
import FeedbackReply from "./FeedbackReplyStore"
import FeedbackRequests from "./FeedbackRequestsStore"
import Feedback from "./FeedbackStore"
import Filters from "./FiltersStore"
import GivenFeedback from "./GivenFeedbackStore"
import Goal from "./GoalStore"
import IncompleteSurveyResponse from "./IncompleteSurveyResponseStore"
import LoadingState from "./LoadingStateStore"
import Locale from "./LocaleStore"
import Notification from "./NotificationStore"
import Ontology from "./OntologyStore"
import PerformanceReviewHack from "./PerformanceReviewHackStore"
import PerformanceReview from "./PerformanceReviewStore"
import PreviousUser from "./PreviousUserStore"
import PublicPraise from "./PublicPraiseStore"
import Review from "./ReviewStore"
import SavedView from "./SavedViewStore"
import Search from "./SearchStore"
import Session from "./SessionStore"
import SurveyResponse from "./SurveyResponseStore"
import Survey from "./SurveyStore"
import Team from "./TeamStore"
import Template from "./TemplateStore"
import User from "./UserStore"
import UserSummary from "./UserSummaryStore"
import ZugiPoints from "./ZugiPointsStore"

// Includes a list of every reflux store available.
// It's sole purpose is for debugging purposes, so we can log store changes
// in the console.
export const refluxStores = {
  AdminEmployeeGrouping,
  AdminFeedbackStats,
  AdminGoal,
  AdminIncompleteSurveyResponse,
  AdminManagerFeedbackStats,
  AdminSavedView,
  AdminSurveyResponse,
  AdminSurvey,
  AdminTemplate,
  AdminUserUploads,
  Directory,
  ManageUsers,
  PerformanceReviewAdmin,
  BaseEmployeeGrouping,
  BaseIncompleteSurveyResponse,
  BaseSurveyResponse,
  BaseSurvey,
  Contact,
  Conversation,
  CurrentUser,
  DevelopmentResource,
  Draft,
  EmployeeGrouping,
  Experiment,
  FeedbackReply,
  FeedbackRequests,
  Feedback,
  Filters,
  GivenFeedback,
  Goal,
  IncompleteSurveyResponse,
  LoadingState,
  Locale,
  Notification,
  Ontology,
  PerformanceReviewHack,
  PerformanceReview,
  PreviousUser,
  PublicPraise,
  Review,
  SavedView,
  Search,
  Session,
  SurveyResponse,
  Survey,
  Team,
  Template,
  User,
  UserSummary,
  ZugiPoints
}
