import _ from "lodash"
import CurrentUserStore from "../../../stores/CurrentUserStore"
import Actions from "../../../actions/index"
import strings from "../../../locale/strings"

const AUTHED_LANDING = "notifications"
const UNAUTHED_LANDING = "signin"

function Authenticate({
  authorizedRoles,
  predicate = user => true,
  routeOnDeny
} = {}) {
  return (nextState, replaceWith) => {
    const user = CurrentUserStore.getInitialState()

    if (!predicate(user)) {
      Authenticate.deny(nextState, replaceWith, AUTHED_LANDING)
    } else if (
      !_.isEmpty(authorizedRoles) &&
      !_.some(authorizedRoles, r => _.contains(user.role_names, r))
    ) {
      if (!routeOnDeny) {
        Actions.UI.error(strings.toast.authenticate.accessDenied)
      }
      Authenticate.deny(
        nextState,
        replaceWith,
        routeOnDeny ? routeOnDeny : AUTHED_LANDING
      )
    }
  }
}

Authenticate.allow = function(router, query) {
  const intendedPath = query._intent
  if (intendedPath) {
    router.replace({ pathname: intendedPath, query: _.omit(query, "_intent") })
  } else {
    router.replace(AUTHED_LANDING)
  }
}

Authenticate.deny = function(nextState, replaceWith, redirectTo) {
  const intendedPath = nextState.location.pathname
  const query =
    redirectTo === UNAUTHED_LANDING
      ? { ...nextState.location.query, _intent: intendedPath }
      : {}
  replaceWith({ pathname: redirectTo, query })
}

export default Authenticate
