import usersDecoder from "../decoders/UsersDecoder/usersDecoder"
import { User } from "../types/User"
import useCurrentUser from "./useCurrentUser"
import useSearch from "./useSearch"

const useUserSearch = (query: string, includeCurrentUser: boolean) => {
  const currentUser = useCurrentUser()
  const { results, loading, error } = useSearch<User>(
    "/users",
    usersDecoder,
    query
  )

  return {
    results: includeCurrentUser ? [currentUser, ...results] : results,
    loading,
    error
  }
}

export default useUserSearch
