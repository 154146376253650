import * as React from "react"
import { useState } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import useUserSearch from "../../../hooks/useUserSearch"
import useDebounce from "../../../hooks/useDebounce"
import { User } from "../../../types/User"
import SearchField from "../../elements/SearchField/SearchField"
import UserAvatarNameAndRole from "../UserAvatarNameAndRole/UserAvatarNameAndRole"
import styles from "./UserSearch.scss"
import Settings from "../../../settings"
import strings from "../../../locale/strings"

interface UserSearchProps {
  id: string
  labelText: string
  description?: string
  onUserSelect: (user: User) => void
  disabled: boolean
  filter?: (user: User) => boolean
  includeCurrentUser?: boolean
  intl: InjectedIntl
  onBlur?: () => void
}

const { SEARCHFIELD_DEBOUNCE } = Settings

const UserSearch = ({
  id,
  labelText,
  description,
  onUserSelect,
  disabled,
  includeCurrentUser = true,
  filter = () => true,
  intl,
  onBlur
}: UserSearchProps) => {
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCHFIELD_DEBOUNCE)
  const { results: searchResults } = useUserSearch(
    debouncedSearchTerm,
    includeCurrentUser
  )
  const filteredResults = searchResults.filter(filter)

  return (
    <SearchField
      id={id}
      labelText={labelText}
      description={description}
      placeholder={intl.formatMessage(strings.teams.userSearch.placeholder)}
      inputValue={searchTerm}
      onChange={evt => {
        setSearchTerm(evt.target.value)
      }}
      emptyText={intl.formatMessage(strings.teams.userSearch.emptyText)}
      selectResult={index => {
        onUserSelect(filteredResults[index])
        setSearchTerm("")
      }}
      disabled={disabled}
      onBlur={onBlur}
    >
      {filteredResults.map(user => (
        <div key={user.id} className={styles.result}>
          <UserAvatarNameAndRole user={user} small />
        </div>
      ))}
    </SearchField>
  )
}

export default injectIntl(UserSearch)
