import { Button, Text } from "@kaizen/component-library"
import { Select } from "@kaizen/component-library/draft"
import editIcon from "@kaizen/component-library/icons/edit.icon.svg"
import deleteIcon from "@kaizen/component-library/icons/trash.icon.svg"
import moment from "moment"
import * as React from "react"
import { useCallback, useEffect, useState, useContext } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { WithRouterProps } from "react-router"
// @ts-ignore
import FormattedListSentence from "../../elements/FormattedListSentence/FormattedListSentence"
// @ts-ignore
import ProgressBar from "../../elements/ProgressBar/ProgressBar"
import strings from "../../../locale/strings"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useDebounce from "../../../hooks/useDebounce"
import useGoalDossier from "../../../hooks/useGoalDossier"
import useNotifications from "../../../hooks/useNotifications"
import useTeam from "../../../hooks/useTeam"
import useUpdateGoal from "../../../hooks/useUpdateGoal"
import { userBelongsToTeam } from "../../../state/TeamsState"
import { Goal, GoalKeyResult, UpdatingGoal } from "../../../types/Goals"
import { JobTitle } from "../../../types/JobTitle"
import { CurrentUser as User } from "../../../state/CurrentUser"
import GoalKeyResults from "../../elements/GoalKeyResults/GoalKeyResults"
import ProgressSlider from "../../elements/ProgressSlider/ProgressSlider"
import GoalDeletionModal from "./GoalDeletionModal"
import GoalOwnersDetails from "./GoalOwnersDetails"
import OutgoingAlignedGoals from "../AlignedGoals/OutgoingAlignedGoals"
import { CommentsProvider } from "../../../state/Comments"
import GoalComments from "../../elements/GoalComments/GoalComments"
import styles from "./GoalDetails.scss"
import { TeamSummariesContext } from "../../../state/TeamSummariesState"

interface GoalDetailsProps extends WithRouterProps {
  goal: Goal
  intl: InjectedIntl
}

type Option = {
  value: string
  label: string
}

const GoalDetails = ({ goal, intl, ...routerProps }: GoalDetailsProps) => {
  const { formatMessage } = intl
  const goalId = goal.id
  const user = useCurrentUser()
  const { team } = useTeam((goal.type === "team" && goal.teamId) || undefined)
  const {
    updateGoal,
    success: updateSuccess,
    error: updateError
  } = useUpdateGoal(goalId)
  const { close: closeDossier } = useGoalDossier(
    routerProps.router,
    routerProps.location
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { showNotification } = useNotifications()
  const [progress, setProgress] = useState<number | null>(null)
  const [keyResultsProgress, setKeyResultsProgress] = useState<
    Record<string, number>
  >({})

  const statusLabels = {
    // Goals with a status of 'Created' should be displayed as being 'In progress' in the UI
    ongoing: strings.goals.status.ongoing,
    created: strings.goals.status.ongoing,
    blocked: strings.goals.status.blocked,
    accomplished: strings.goals.status.accomplished
  }

  const [selectedStatus, setSelectedStatus] = useState<Option>({
    value: goal.status,
    label: formatMessage(statusLabels[goal.status as keyof typeof statusLabels])
  })

  const debouncedProgress = useDebounce(progress, 500)
  const debouncedKeyResultsProgress = useDebounce(keyResultsProgress, 500)
  const { dispatch } = useContext(TeamSummariesContext)

  const getKeyResults = useCallback(
    (): GoalKeyResult[] | undefined =>
      goal.keyResults.map(kr => ({
        ...kr,
        completion:
          keyResultsProgress[kr.id] !== undefined
            ? keyResultsProgress[kr.id]
            : kr.completion
      })),
    [goal, keyResultsProgress]
  )

  const keyResults = getKeyResults()

  const shouldShowGoalComments = (goal: Goal) => {
    if (goal.type === "department" || goal.type === "company") {
      return false
    } else if (goal.visibility === "owner") {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (debouncedProgress !== null) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        completion: debouncedProgress / 100
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProgress])

  useEffect(() => {
    if (Object.keys(debouncedKeyResultsProgress).length > 0) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        keyResults: getKeyResults()
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyResultsProgress])

  useEffect(() => {
    if (selectedStatus.value !== goal.status) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        status: selectedStatus.value
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus])

  useEffect(() => {
    // update status in UI to 'accomplished' if progress is %100
    if (goal.completion === 1) {
      setSelectedStatus({
        value: "accomplished",
        label: formatMessage(
          statusLabels["accomplished" as keyof typeof statusLabels]
        )
      })
    }
  }, [formatMessage, goal.completion, statusLabels])

  useEffect(() => {
    if (updateSuccess) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.goalsPage.goalsDossier.successTitle),
        message: formatMessage(
          strings.goalsPage.goalsDossier.successNotification
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess])

  useEffect(() => {
    if (updateError) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.goalsPage.goalsDossier.errorTitle),
        message: formatMessage(strings.goalsPage.goalsDossier.errorNotification)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError])

  useEffect(() => {
    setProgress(null)
    setKeyResultsProgress({})
  }, [goalId])

  const handleDeleteGoal = useCallback(() => {
    setShowDeleteConfirmation(true)
  }, [])

  const handleKeyResultChange = useCallback(
    (keyResultId: string, progress: number) => {
      setKeyResultsProgress(kr => ({
        ...kr,
        [keyResultId]: progress / 100
      }))
    },
    []
  )

  const renderVisibilityText = (goal: Goal) => {
    switch (goal.type) {
      case "personal":
        if (goal.visibility === "individuals") {
          return (
            <FormattedListSentence
              base={strings.goals.individualsVisibility}
              items={goal.individuals}
              options={{
                itemRenderer: ({ name }: { name: string }) => name
              }}
            />
          )
        } else {
          return formatMessage({
            ...strings.goals.visibilityLabel[
              goal.visibility === "manager"
                ? "myManager"
                : goal.visibility === "owner"
                ? "onlyMe"
                : "everyone"
            ]
          })
        }
      case "team":
        return formatMessage({
          ...strings.goals.visibilityLabel[
            goal.visibility === "team_only" ? "teamOnly" : "everyone"
          ]
        })
      default:
        return ""
    }
  }

  // User department permissions ---------------------------------------------

  const userHrbPartners = (user: User) =>
    user.hrbPartners
      ? user.hrbPartners.map((department: JobTitle) => department.id)
      : []

  const userManagedDepartments = (user: User) =>
    user.managedDepartments
      ? user.managedDepartments.map((department: JobTitle) => department.id)
      : []

  const userCanEditDepartmentGoal = (user: User, goal: Goal) =>
    user.isAdmin ||
    (goal.type === "department" &&
      userHrbPartners(user).includes(goal.departmentId)) ||
    (goal.type === "department" &&
      userManagedDepartments(user).includes(goal.departmentId))

  // ------------------------------------------------------------------------

  const userCanEdit =
    goal.type === "personal" ||
    (goal.type === "team" && team && userBelongsToTeam(user, team)) ||
    (goal.type === "department" && userCanEditDepartmentGoal(user, goal)) ||
    (goal.type === "company" && user.isAdmin)

  return (
    <div className={styles.container}>
      <React.Fragment>
        {showDeleteConfirmation && (
          <GoalDeletionModal
            goalId={goalId}
            onGoalDeleted={() => {
              if (team && team.id) {
                dispatch({
                  type: "UPDATE_TEAM_SUMMARIES",
                  payload: { teamId: team.id }
                })
              }
              closeDossier()
            }}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
        <Text tag="h3" style="zen-heading-3">
          {goal.name}
        </Text>
        <Text tag="p">{goal.description}</Text>
        <div className={styles.statusInfoContainer}>
          <div>
            <Text tag="h5" style="label">
              {formatMessage(strings.goalsPage.goalsDossier.dueDate)}
            </Text>
            <Text tag="p">{`${moment(goal.dueDate).format(
              "MMMM DD, YYYY"
            )}`}</Text>
          </div>
          <div className={styles.status}>
            <Text tag="h5" style="label">
              {formatMessage(strings.goalsPage.goalsDossier.status)}
            </Text>
            {userCanEdit ? (
              <Select
                id="status"
                key="status"
                value={selectedStatus}
                options={[
                  {
                    value: "ongoing",
                    label: formatMessage(strings.goals.status.ongoing)
                  },
                  {
                    value: "blocked",
                    label: formatMessage(strings.goals.status.blocked)
                  },
                  {
                    value: "accomplished",
                    label: formatMessage(strings.goals.status.accomplished)
                  }
                ]}
                // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
                onChange={(value: Option) => {
                  setSelectedStatus(value)
                }}
                isSearchable={false}
                disabled={true}
              />
            ) : (
              <Text tag="p">{selectedStatus.label}</Text>
            )}
          </div>
        </div>
        {goal.type === "team" && goal.owners && (
          <div className={styles.owners}>
            <GoalOwnersDetails owners={goal.owners} />
          </div>
        )}
        {keyResults && keyResults.length > 0 ? (
          <div
            data-automation-id="goal-details-progress-label"
            className={styles.progress}
          >
            <Text tag="h6">
              {formatMessage(strings.goalsPage.goalsDossier.goalProgress)}
            </Text>
            <Text tag="h6">
              {`${Math.round(goal.completion * 100)}% ${formatMessage(
                strings.goalsPage.goalsDossier.complete
              )}`}
            </Text>
          </div>
        ) : (
          <React.Fragment>
            {userCanEdit ? (
              <div className={styles.progressSlider}>
                <Text tag="h6">
                  {formatMessage(strings.goalsPage.goalsDossier.goalProgress)}
                </Text>
                <div
                  data-automation-id="goal-details-progress-slider"
                  className={styles.slider}
                >
                  <ProgressSlider
                    step={5}
                    progress={
                      progress !== null ? progress : goal.completion * 100
                    }
                    onProgressChange={setProgress}
                  />
                </div>
              </div>
            ) : (
              <div data-automation-id="goal-details-progress-bar">
                <ProgressBar value={goal.completion} />
              </div>
            )}
          </React.Fragment>
        )}
        <GoalKeyResults
          keyResults={keyResults || []}
          onProgressChange={handleKeyResultChange}
          readOnly={!userCanEdit}
        />
        <OutgoingAlignedGoals goal={goal} {...routerProps} />

        {shouldShowGoalComments(goal) && (
          <div className={styles.comments}>
            <Text tag="h6" inheritBaseline inline>
              {formatMessage(strings.goalsPage.goalsDossier.comments)}
            </Text>
            <CommentsProvider>
              <GoalComments
                goalId={String(goalId)}
                currentUserCanComment={goal.visibility !== "owner"}
              />
            </CommentsProvider>
          </div>
        )}
        <div
          data-automation-id="goal-details-action-buttons"
          className={styles.actionsContainer}
        >
          <Text tag="p" inheritBaseline inline>
            {renderVisibilityText(goal)}
          </Text>
          {userCanEdit && (
            <div className={styles.actions}>
              <Button
                label={formatMessage(strings.teamGoals.delete)}
                icon={deleteIcon}
                secondary
                onClick={handleDeleteGoal}
              />
              <Button
                label={formatMessage(strings.general.edit)} // replace with strings.teamGoals.edit when translations arrive
                icon={editIcon}
                secondary
                onClick={() => {
                  const redirectTo =
                    goal.type === "personal"
                      ? `/new_goals/personal/edit/${goalId}`
                      : goal.type === "team"
                      ? `/new_goals/team/${goal.teamId}/edit/${goalId}`
                      : goal.type === "department"
                      ? `/new_goals/department/${goal.departmentId}/edit/${goalId}`
                      : goal.type === "company"
                      ? `/new_goals/company/edit/${goalId}`
                      : ""
                  routerProps.router.push(redirectTo)
                }}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  )
}

export default React.memo(injectIntl(GoalDetails))
