import { JsonDecoder } from "ts.data.json"

export interface GoalStats {
  goalCount: number
  overallProgress: number
}

const goalStatsDecoder = JsonDecoder.object<GoalStats>(
  {
    goalCount: JsonDecoder.number,
    overallProgress: JsonDecoder.number
  },
  "goalStats",
  {
    goalCount: "goal_count",
    overallProgress: "overall_progress"
  }
)

export default goalStatsDecoder
