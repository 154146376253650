import Reflux from "reflux-core"
// Don't import steadyfoot-related actions; if they import SteadyfootAgent, a circular dependency happens
import SessionActions from "../actions/SessionActions"
import LocalStorage from "./lib/LocalStorage"

const db = new LocalStorage({ key: "SessionStore" })

const SessionStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function() {
    this.listenTo(SessionActions.login.completed, this.onLoginCompleted)
    this.listenTo(SessionActions.refresh.failed, this.onRefreshFailed)
    this.listenTo(SessionActions.logout.completed, this.onLogoutCompleted)

    // Purge the session on mount as we rely on
    // ensureCultureAmpAuthed to fetch the initial session
    this.purge()
  },

  getInitialState: function() {
    return db.loadData()
  },

  isLoggedIn: function() {
    return !!db.loadData()
  },

  // Only different from isLoggedIn when another tab logs out
  isTabLoggedIn: function() {
    return !!db.getData()
  },

  get: function() {
    return db.loadData()
  },

  purge: function(purgeAll) {
    //LocalStorage.destroySessionData(purgeAll);
    db.destroyData()
  },

  setSession(session) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sk_mode } = session

    db.destroyData()
    //db.updateData({sk_mode});

    this.trigger(db.getData())
  },

  onLoginCompleted: function(session) {
    this.setSession(session)
  },

  onLogoutCompleted() {
    this.purge(true)
  },

  onRefreshFailed: function() {
    this.purge(false)
    this.trigger(db.loadData())
  }
})

export default SessionStore
