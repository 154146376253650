/**
 * A list of the configuration options added to the
 * performance-api ConfigurationOptions table via migration.
 * Note, these are unrelated to the feature flag system that uses flipper,
 * and are a first step in moving away from using Account settings
 * (where we access database entries from the companies table).
 */
enum ConfigurationOptions {
  teams = "teams",
  teamGoals = "team_goals",
  teamBasedFeedback = "team_based_feedback"
}

export default ConfigurationOptions
