import * as React from "react"
import { withRouter, WithRouterProps } from "react-router"
import { Tabs as KaizenTabs } from "@kaizen/component-library/draft"
import { memo } from "react"
import { isRegularMouseClick } from "../../../util/domEvent"

export type Tab = {
  label: string
  active?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  href?: string
}

type Props = WithRouterProps & {
  tabs: (Tab | false)[] // false values in the array will be filtered out
  renderTab?: (renderProps: {
    tab: Tab
    tabClassName: string
    activeTabClassName: string
    disabledTabClassName: string
  }) => React.ReactNode
}

/**
 * A wrapper around the kaizen tabs component.
 *
 * It adds the following functionality:
 *   - Links the `href` property of a tab to react router. As in, prevent
 *     a page reload, in the same way that the `Link` component does.
 *   - Add convenience functionality around the tabs array, so if false is passed,
 *     the tab will not get rendered.
 */
const Tabs = ({ router, tabs, ...rest }: Props) => {
  const filteredTabs: Tab[] = tabs.filter(t => t) as Tab[]
  const modifiedTabs = filteredTabs.map((t: Tab) => {
    if (t.href) {
      return {
        ...t,
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          if (t.onClick) {
            t.onClick(e)
          }

          if (!e.defaultPrevented && isRegularMouseClick(e)) {
            e.preventDefault()
            // Should a tab click be a push, or a replace? 🤔
            router.replace(t.href as string)
          }
        }
      }
    }
    return t
  })

  return <KaizenTabs tabs={modifiedTabs} {...rest} />
}

export default withRouter(memo(Tabs))
