import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import KaizenSplitButton from "@kaizen/component-library/draft/Kaizen/SplitButton/SplitButton"
import { SplitButtonProps } from "@kaizen/component-library/draft/Kaizen/SplitButton/SplitButton"
import { useCallback } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../../locale/strings"
import { isRegularMouseClick } from "../../../util/domEvent"

export type RawProps = Omit<SplitButtonProps, "dropdownAltText">

export type Props = RawProps &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RouteComponentProps<any, any, any> & {
    intl: InjectedIntl
  }

// Wrapper around the Kaizen SplitButton component.
//   https://cultureamp.design/components/split-button/
// This wrapper adds integration with react-router, so if we specify a `href`,
// it will use `router.push`, instead of doing a fresh page load.
// It also sends down the `dropdownAltText`, so you don't need to specify it
// every time.
const SplitButton: React.FC<Props> = ({
  router,
  intl,
  href,
  onClick,
  ...rest
}) => {
  // @ts-ignore
  const basedHref = href != null ? `${router.location.basename}/${href}` : href
  const handleClick = useCallback(
    event => {
      // Helpful code was copy/pasted from react-router:
      //  https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
      try {
        if (onClick) onClick(event)
      } catch (ex) {
        event.preventDefault()
        throw ex
      }

      if (basedHref != null) {
        if (
          !event.defaultPrevented && // onClick prevented default
          isRegularMouseClick(event)
        ) {
          event.preventDefault()
          if (href) router.push(href)
        }
      }
    },
    [basedHref, href, onClick, router]
  )

  return (
    <KaizenSplitButton
      dropdownAltText={intl.formatMessage(strings.general.openMenu)}
      {...rest}
      onClick={handleClick}
      href={basedHref}
    />
  )
}

// @ts-ignore
const WrappedComponent: React.FC<RawProps> = withRouter(injectIntl(SplitButton))

export default WrappedComponent
