import "./MarkdownText.less"
import PropTypes from "prop-types"
import React from "react"
import remarkPing from "remark-ping"
import { shortnameToUnicode } from "emojione"
import cx from "classnames"
import ReactMarkdown from "../../../util/react-markdown"
import remarkSyntaxWhitelist from "../../../util/remarkSyntaxWhitelist"
import {
  REMARK_PING_CONFIG,
  SYNTAX_WHITELIST,
  SYNTAX_WHITELIST_INLINE
} from "../../../util/remarkConstants"

export const MARKDOWN_NODE_RENDERERS = {
  // eslint-disable-next-line react/display-name
  paragraph: ({ children }) => (
    <p className="MarkdownText--content-block">{children}</p>
  ),

  // eslint-disable-next-line react/display-name
  link: ({ href, title, children }) => (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ),

  // eslint-disable-next-line react/display-name
  ping: ({ children }) => (
    <span className="MarkdownText--mention">{children}</span>
  ),

  // Unfortunately, the remark parser parses both 'image' and 'link' from the 'link' tokenizer. So
  // we have to block images at the render phase rather than the parse phase. Also, using
  // react-markdown's disallowedTypes + unwrapDisallowed for this seems to throw an error (as of
  // react-markdown 3.1.4).
  // eslint-disable-next-line react/display-name
  image: ({ alt }) => <span>{alt}</span>,

  // eslint-disable-next-line react/display-name
  list: ({ ordered, start, children }) =>
    React.createElement(
      ordered ? "ol" : "ul",
      {
        className: "MarkdownText--content-block",
        start
      },
      ...children
    )
}

export const MARKDOWN_NODE_RENDERERS_INLINE = {
  ...MARKDOWN_NODE_RENDERERS,

  // For the inline case, lists are blocked at the syntax level in SYNTAX_WHITELIST_INLINE. But
  // the remark parser doesn't seem to work if you disable the paragraph syntax, so they're rendered
  // as inline elements here
  // eslint-disable-next-line react/display-name
  paragraph: ({ children }) => (
    <span className="MarkdownText--inline-paragraph">{children}</span>
  )
}

export default class MarkdownText extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    inline: PropTypes.bool
  }

  render() {
    const { className, text, inline } = this.props

    return (
      <ReactMarkdown
        source={shortnameToUnicode(text || "")}
        className={cx("MarkdownText", className, {
          "MarkdownText--inline": inline
        })}
        plugins={[
          [
            remarkSyntaxWhitelist,
            inline ? SYNTAX_WHITELIST_INLINE : SYNTAX_WHITELIST
          ],
          [remarkPing, REMARK_PING_CONFIG]
        ]}
        escapeHtml={true}
        renderers={
          inline ? MARKDOWN_NODE_RENDERERS_INLINE : MARKDOWN_NODE_RENDERERS
        }
      />
    )
  }
}
