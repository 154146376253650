import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/component-library/draft"
import * as React from "react"
import { useEffect } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import strings from "../../../locale/strings"
import useDeleteTeam from "../../../hooks/useDeleteTeam"
import styles from "./TeamDeleteConfirmation.scss"

type TeamDeleteConfirmation = React.FunctionComponent<{
  teamId: string
  onTeamDeleted: () => void
  onCancel: () => void
  intl: InjectedIntl
}>

const TeamDeleteConfirmation: TeamDeleteConfirmation = ({
  teamId,
  onTeamDeleted,
  onCancel,
  intl
}) => {
  const { formatMessage } = intl
  const {
    deleteGoal,
    success: teamWasDeleted,
    loading: deleteLoading
  } = useDeleteTeam(teamId)

  useEffect(() => {
    if (teamWasDeleted) {
      onTeamDeleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamWasDeleted])

  return (
    <ConfirmationModal
      isOpen
      type="negative"
      title={deleteLoading ? "Deleting team ..." : "Delete team"}
      confirmLabel={formatMessage(strings.teamGoals.delete)}
      dismissLabel={formatMessage(strings.teamGoals.cancel)}
      onDismiss={() => {
        onCancel()
      }}
      onConfirm={() => {
        deleteGoal()
      }}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          Are you sure you want to delete this team?
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(TeamDeleteConfirmation)
