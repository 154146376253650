import { Popover, TextField } from "@kaizen/component-library/draft"
import { Button } from "@kaizen/component-library"
import moment, { Moment } from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import Calendar from "./primitives/Calendar"
import styles from "./styles.scss"
import strings from "../../../locale/strings"

const dateIcon = require("@kaizen/component-library/icons/date-start.icon.svg")
  .default
const dateRangeIcon = require("@kaizen/component-library/icons/date-range.icon.svg")
  .default

export type DatePickerProps = {
  id: string
  allowDateRange?: boolean
  initialDate?: Date[]
  onChange: (dates: Date[]) => void
  label?: string
  intl: InjectedIntl
}

type DatePicker = React.FunctionComponent<DatePickerProps>

const DatePicker: DatePicker = ({
  id,
  allowDateRange = false,
  onChange,
  label,
  initialDate = [],
  intl
}) => {
  const { formatMessage } = intl
  const [calendarOpened, setCalendarOpened] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<HTMLDivElement>(null)
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDate)
  const [savedDates, setSavedDates] = useState<Date[]>(initialDate)
  const dates = selectedDates.map(date => moment(date))

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const clickInsideTextField =
        containerRef.current && containerRef.current.contains(target)
      const clickInsideCalendar =
        calendarRef.current && calendarRef.current.contains(target)

      if (!clickInsideCalendar && !clickInsideTextField) {
        setCalendarOpened(false)
        allowDateRange && setSelectedDates(savedDates)
      }
    },
    [
      setCalendarOpened,
      containerRef,
      calendarRef,
      setSelectedDates,
      allowDateRange,
      savedDates
    ]
  )

  useEffect(() => {
    window.addEventListener("click", handleClick)
    return () => window.removeEventListener("click", handleClick)
  }, [handleClick])

  const datesString = useMemo(() => {
    const format = "ll"
    const startDateString = dates[0] && dates[0].format(format)
    const endDateString =
      (dates[1] && dates[1].format(format)) ||
      (dates[0] && formatMessage(strings.filters.datePicker.endDate))
    return allowDateRange
      ? startDateString && `${startDateString} - ${endDateString}`
      : startDateString
  }, [dates, allowDateRange, formatMessage])

  const handleDateChange = useCallback(
    (dates: Moment[]) => {
      setSelectedDates(
        dates.sort((a, b) => (a.isBefore(b) ? -1 : 1)).map(m => m.toDate())
      )
      !allowDateRange && onChange(dates.map(m => m.toDate()))
    },
    [setSelectedDates, allowDateRange, onChange]
  )

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onClick={() => setCalendarOpened(true)}
    >
      <TextField
        id={id}
        inputType="text"
        labelText={label}
        inputValue={datesString || ""}
        icon={allowDateRange ? dateRangeIcon : dateIcon}
        onChange={() => {}}
      />
      {calendarOpened && (
        <Popover position="center" side="top" size="large">
          <div ref={calendarRef}>
            <Calendar
              selectedDates={dates}
              onChange={handleDateChange}
              allowDateRange={allowDateRange}
            />
          </div>
          <div className={styles.actions}>
            <Button
              label={formatMessage(strings.filters.datePicker.clear)}
              // @ts-ignore: React.MouseEvent / MouseEvent incompatibility
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setCalendarOpened(false)
                setSelectedDates([])
                setSavedDates([])
                onChange([])
              }}
              disabled={selectedDates.length === 0}
              secondary
            />
            {allowDateRange && (
              <Button
                label={formatMessage(strings.filters.datePicker.save)}
                // @ts-ignore: React.MouseEvent / MouseEvent incompatibility
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setCalendarOpened(false)
                  setSavedDates(selectedDates)
                  onChange(selectedDates)
                }}
                disabled={selectedDates.length < 2}
                secondary
              />
            )}
          </div>
        </Popover>
      )}
    </div>
  )
}

export default injectIntl(DatePicker)
