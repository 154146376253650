import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import cx from "classnames"
import Tooltip from "rc-tooltip"
import ProfileContent from "../ProfileContent/ProfileContent"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import "./ToggleableProfileContent.less"

@injectIntl
export default class ToggleableProfileContent extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    selfReflectionToExcludeId: PropTypes.number,
    hideActionButtons: PropTypes.bool,
    panelStyle: PropTypes.bool,
    className: PropTypes.string,
    onToggle: PropTypes.func,
    savedViewId: PropTypes.number,
    isAdmin: PropTypes.bool
  }

  static defaultProps = {
    hideActionButtons: false,
    panelStyle: false,
    className: "",
    onToggle: null,
    isAdmin: false
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: true
    }
  }

  handleToggle = () => {
    this.setState(state => ({
      visible: !state.visible
    }))
  }

  render() {
    const {
      className,
      intl: { formatMessage },
      onToggle,
      panelStyle,
      ...otherProps
    } = this.props
    const { visible } = this.state

    const toggleClasses = cx(
      { "ToggleableProfileContent--toggle-closed": !visible },
      "ToggleableProfileContent--toggle"
    )

    const toggleableProfileContentClasses = cx(
      {
        "ToggleableProfileContent--closed": !visible,
        "ToggleableProfileContent--panel-style": panelStyle
      },
      "ToggleableProfileContent",
      visible ? className : null
    )

    return (
      <div className={toggleableProfileContentClasses}>
        <Tooltip
          placement="left"
          mouseEnterDelay={visible ? 100 : 1} //Hack to don't display the tooltip if the profile is open.
          overlay={
            <div className="ToggleableProfileContent--tooltip">
              {formatMessage(strings.profile.showProfile)}
            </div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
        >
          <div
            className={toggleClasses}
            onClick={onToggle ? onToggle : this.handleToggle}
          >
            <Icon iconName={visible ? "close" : "user"} />
          </div>
        </Tooltip>

        <ProfileContent
          {...otherProps}
          iconTabStyle={true}
          dossierDrawer={true}
        />
      </div>
    )
  }
}
