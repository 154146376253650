import Reflux from "reflux-core"
import { extractResponseKey } from "./lib/apiActionHelpers"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import UIActions from "./UIActions"
import { GENERAL_URLS } from "../api/endpoints.json"

const { CONTACTS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}
const POLL_TOP_INTERVAL = 10 * 1000 // 10 seconds

const ContactActions = Reflux.createActions({
  loadTop: ASYNC_SETTINGS,
  startPollingTop: {},
  stopPollingTop: {}
})

let pollTopTimer = null

/* Feedback Actions
===============================*/

ContactActions.loadTop.listenAndPromise((startMoment, finishMoment) =>
  extractResponseKey(
    "contacts",
    agent.get(CONTACTS_URL).query({
      "contacts[start_date]": startMoment.format(),
      "contacts[end_date]": finishMoment.format()
    })
  )
)

ContactActions.startPollingTop.preEmit = function(startMoment, finishMoment) {
  UIActions.setLoadingState(false)
  UIActions.setLoadingState(true, { light: true })

  clearInterval(pollTopTimer)
  pollTopTimer = setInterval(() => {
    ContactActions.loadTop(startMoment, finishMoment)
      // eslint-disable-next-line no-console
      .then(contactsPoll => console.log({ contactsPoll }))
      .catch(contactsPoll => {
        // eslint-disable-next-line no-console
        console.error({ contactsPoll })
        clearInterval(pollTopTimer)
      })
  }, POLL_TOP_INTERVAL)
}

ContactActions.stopPollingTop.preEmit = function() {
  UIActions.setLoadingState(false)
  clearInterval(pollTopTimer)
}

export default ContactActions
