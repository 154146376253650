import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import strings from "../../../locale/strings"
import Icon from "../../elements/Icon/Icon"
import AnimatedEllipsis from "../../elements/AnimatedEllipsis/AnimatedEllipsis"
import "./SavingIndicator.less"

export default class SavingIndicator extends React.Component {
  static propTypes = {
    saveState: PropTypes.oneOf(["saving", "saved", "failed"])
  }

  render() {
    const { saveState } = this.props

    switch (saveState) {
      case "saving":
        return (
          <div className="SavingIndicator">
            <FormattedMessage {...strings.general.saving} />
            <AnimatedEllipsis />
          </div>
        )
      case "saved":
        return (
          <div className="SavingIndicator SavingIndicator--saved layout horizontal center">
            <Icon iconName="check" />
            <FormattedMessage {...strings.general.saved} />
          </div>
        )
      case "failed":
        return (
          <div className="SavingIndicator layout horizontal center">
            <Icon iconName="information" />
            <FormattedMessage {...strings.general.saveFailed} />
          </div>
        )
      default:
        return null
    }
  }
}
