import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import strings from "../../../locale/strings"
import useCurrentUser from "../../../hooks/useCurrentUser"
import useNotifications from "../../../hooks/useNotifications"
import PersonalGoalForm, {
  PersonalGoalFields
} from "../PersonalGoalForm/PersonalGoalForm"
import useCreatePersonalGoal from "../../../hooks/useCreatePersonalGoal"
import { newKeyResult } from "../../../hooks/utils/goalKeyResults"
import { trackIndividualGoalCreated } from "../../../lib/analytics/events"

type NewPersonalGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
}>

const NewPersonalGoal: NewPersonalGoal = ({ router, intl }) => {
  const { showNotification } = useNotifications()
  const { formatMessage } = intl
  const currentUser = useCurrentUser()
  const newGoal: PersonalGoalFields = {
    name: "",
    description: "",
    dueDate: currentUser.company.goalCycle.end,
    priority: 1,
    visibility: "everyone",
    keyResults: [newKeyResult()],
    alignedGoalIds: [],
    viewers: []
  }
  const { createPersonalGoal, loading, data, error } = useCreatePersonalGoal()
  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(
          strings.newIndividualGoal.newIndividualGoalSuccessTitle
        ),
        message: formatMessage(
          strings.newIndividualGoal.newIndividualGoalSuccessNotification
        )
      })

      trackIndividualGoalCreated()

      router.push({
        pathname: `/new_goals/personal`,
        query: {
          // @ts-ignore
          goalId: data.goal.id
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(
          strings.newIndividualGoal.newIndividualGoalErrorTitle
        ),
        message: formatMessage(
          strings.newIndividualGoal.newIndividualGoalErrorNotification
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  return (
    <PersonalGoalForm
      title={formatMessage(strings.newIndividualGoal.title)}
      submitLabel={formatMessage(strings.newIndividualGoal.submitLabel)}
      defaultFields={newGoal}
      onSubmit={createPersonalGoal}
      onCancel={() => router.push("/new_goals/personal")}
      disabled={loading}
    />
  )
}

export default injectIntl(NewPersonalGoal)
