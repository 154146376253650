import { Text, Button } from "@kaizen/component-library"

import cx from "classnames"
import * as React from "react"
import styles from "./Tiles.scss"
import Aid from "../../../constants/automationId"

interface TilesProps {
  children: React.ReactNode
}

const Tiles = ({ children }: TilesProps) => (
  <div className={styles.tiles}>{children}</div>
)

export interface TileProps {
  title: string
  subtitle?: string
  description?: string
  illustration?: string
  background: "seedling" | "lapis" | "wisteria"
  actionLabel: string
  secondaryActionLabel?: string
  onActionClick: () => void
  onSecondaryActionClick?: () => void
  automationId?: Aid
  secondaryAutomationId?: string
}

const Tile = ({
  title,
  subtitle,
  description,
  background,
  illustration,
  actionLabel,
  secondaryActionLabel,
  onActionClick,
  onSecondaryActionClick,
  automationId,
  secondaryAutomationId
}: TileProps) => (
  <article className={styles.tile}>
    <div className={cx(styles.content, styles[background])}>
      <div className={cx(styles.header, { [styles.short]: !!illustration })}>
        <div className={styles.heading}>
          <Text tag="h1" inline style="zen-heading-3">
            {title}
          </Text>
          {subtitle && (
            <div>
              <Text tag="h2" style="small" inline>
                {subtitle}
              </Text>
            </div>
          )}
        </div>
        {illustration && (
          <img src={illustration} className={styles.illustration} />
        )}
      </div>
      {description && <p className={styles.description}>{description}</p>}
    </div>
    <div className={styles.actions}>
      {onSecondaryActionClick && (
        <div className={styles.buttonMargin}>
          <Button
            label={secondaryActionLabel || ""}
            onClick={onSecondaryActionClick}
            automationId={secondaryAutomationId}
            secondary
          />
        </div>
      )}
      <Button
        label={actionLabel}
        onClick={onActionClick}
        automationId={automationId}
      />
    </div>
  </article>
)

export default {
  Tiles,
  Tile
}
