import { useEffect, useState } from "react"
// @ts-ignore
import Actions from "../actions"

type Question = {
  type: string
  title: string
  description?: string
  required: boolean
}

export type QuestionsTemplate = {
  type: "default" | "directs"
  questions: Question[]
}

const useTeamBasedFeedbackQuestionTemplate = (
  reviewersIds: number[],
  revieweeId?: number
) => {
  const [templates, setTemplates] = useState<QuestionsTemplate[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!revieweeId) return

    const fetchTemplates = async () => {
      setLoading(true)
      const data = await Actions.Template.getTemplate({
        subjectId: revieweeId,
        authorIds: reviewersIds.length > 0 ? reviewersIds : [revieweeId],
        policyTypes: "MRF"
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const templates: QuestionsTemplate[] = data.map((templateData: any) => ({
        type: "default",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        questions: templateData.questions.map((questionData: any) => ({
          type: questionData.type,
          title: questionData.title,
          choices: questionData.choices,
          required: questionData.required
        }))
      }))

      setLoading(false)
      setTemplates(templates)
    }

    fetchTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revieweeId])

  return {
    loading,
    template: templates.length ? templates[0] : undefined
  }
}

export default useTeamBasedFeedbackQuestionTemplate
