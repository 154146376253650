import Reflux from "reflux-core"
import _ from "lodash"
import * as AdminActions from "../../admin/actions"
import BaseEmployeeGroupingStore from "../../stores/BaseEmployeeGroupingStore"

export default Reflux.createStore(
  _.assign({}, BaseEmployeeGroupingStore, {
    init() {
      BaseEmployeeGroupingStore.init.call(this)

      this.listenTo(
        AdminActions.Company.listEmployeeGroupings.completed,
        this.onListEmployeeGroupings
      )

      this.listenTo(AdminActions.Company.createEmployeeGrouping.completed, () =>
        AdminActions.Company.listEmployeeGroupings()
      )

      this.listenTo(
        AdminActions.Company.deleteEmployeeGrouping.completed,
        employeeGrouping => {
          const { id } = employeeGrouping
          const { departments, jobTitlesByParentId } = this.data

          this.data = {
            ...this.data,
            departments: _.reject(departments, { id }),
            jobTitlesByParentId: _.mapValues(jobTitlesByParentId, jobTitles =>
              _.reject(jobTitles, { id })
            )
          }

          this.trigger(this.data)
        }
      )
    }
  })
)
