import observeSearchActions from "./lib/observeSearchActions"

export default {
  init({
    actions: {
      searchSurveyResponses,
      pageSurveyResponses,
      clearSurveyResponses
    }
  }) {
    this.data = {
      surveyResponseSearchResults: null,
      surveyResponseSearchState: null
    }

    observeSearchActions(this, {
      getResults: () => this.data.surveyResponseSearchResults,
      searchAction: searchSurveyResponses,
      pageAction: pageSurveyResponses,
      clearAction: clearSurveyResponses,
      onChange: (surveyResponseSearchResults, surveyResponseSearchState) => {
        this.data = {
          ...this.data,
          surveyResponseSearchResults,
          surveyResponseSearchState
        }

        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  }
}
