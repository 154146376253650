/* eslint-disable import/no-named-as-default-member, import/default */
import qs from "query-string"
import { useEffect, useState } from "react"
import { JsonDecoder } from "ts.data.json"
import useDataAPI from "./useDataAPI"

const useSearch = <T>(
  endpoint: string,
  decoder: JsonDecoder.Decoder<T[]>,
  search: string
) => {
  const { url, query } = qs.parseUrl(endpoint)
  const params = {
    ...query,
    q: search
  }
  const { fire, loading, data, error } = useDataAPI(
    `${url}?${qs.stringify(params)}`,
    "GET"
  )
  const [results, setResults] = useState<T[]>([])

  useEffect(() => {
    fire()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, endpoint])

  useEffect(() => {
    if (data) {
      decoder.decodePromise(data).then(setResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { results, loading, error }
}

export default useSearch
