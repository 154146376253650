import "./InputField.less"
import PropTypes from "prop-types"
import React from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import cx from "classnames"
import $ from "jquery"
import { throttle } from "../../../decorators/timers"
import InputClearButton from "../InputClearButton/InputClearButton"

const TRANSITION_DURATION = 250

let nextId = 0

export default class InputField extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    labelContent: PropTypes.node,
    name: PropTypes.string,
    className: PropTypes.string,
    inputClass: PropTypes.string,
    multiline: PropTypes.bool,
    clearable: PropTypes.bool,

    // REVIEW - only used for certain types like "date" and will be specific
    // to the underlying UI library (e.g., pickadate.js for type "date"). We
    // should probably split different input types into different components.
    format: PropTypes.string,

    span: PropTypes.number,
    beforeContent: PropTypes.node,
    afterContent: PropTypes.node,
    type: PropTypes.string, // TODO

    borderlessStyle: PropTypes.bool,
    borderedStyle: PropTypes.bool,

    onChange: PropTypes.func,
    onClear: PropTypes.func,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    autoValidate: PropTypes.bool,
    valid: PropTypes.bool,

    automationId: PropTypes.string
  }

  static defaultProps = {
    className: "",
    inputClass: "",
    multiline: false,
    type: "text",
    span: 12,
    beforeContent: null,
    afterContent: null,
    onChange: _.noop,
    onClear: _.noop,
    borderedStyle: false,
    autoValidate: true
  }

  constructor(props) {
    super(props)

    this.state = {
      focused: false
    }
  }

  componentDidMount() {
    const { value, defaultValue } = this.props

    if (value || defaultValue) {
      this.resizeToContent()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.resizeToContent()
    }
  }

  getGeneratedId() {
    if (!this._generatedId) {
      this._generatedId = `InputField--${nextId++}`
    }
    return this._generatedId
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  @throttle(TRANSITION_DURATION)
  resizeToContent() {
    if (!this.props.multiline) {
      return
    }

    // eslint-disable-next-line react/no-find-dom-node
    const containerNode = ReactDOM.findDOMNode(this)
    const inputNode = this.getTextFieldNode()

    if (!inputNode) {
      return
    }

    const oldStyleHeight = inputNode.style.height

    containerNode.style.minHeight = `${getComputedHeight(containerNode)}px`
    inputNode.style.transition = "0s"
    inputNode.style.height = "2px"

    const overflowHeight = inputNode.scrollHeight - inputNode.clientHeight
    const targetHeight = getComputedHeight(inputNode) + overflowHeight

    inputNode.style.height = oldStyleHeight
    containerNode.style.minHeight = ""
    inputNode.offsetHeight // force a relayout

    inputNode.style.transition = ""
    inputNode.style.height = `${targetHeight}px`
  }

  initDatePicker(ref) {
    if (ref && !ref._initedDatePicker) {
      ref._initedDatePicker = true

      const { format } = this.props

      $(() => {
        $(ref)
          .pickadate({
            format,
            close: "Select",
            clear: null,
            selectMonths: true,
            selectYears: 15
          })
          .on("change", event => {
            // materialize's datepicker sets readonly on the <input>,
            // which blocks natural onchange events, so we have to manually
            // tirgger it
            if (this.props.onChange) {
              this.props.onChange(event)
            }
          })
      })
    }
  }

  getTextFieldNode() {
    return this.inputRef
  }

  handleChange(e) {
    this.resizeToContent()
    this.props.onChange(e)
  }

  handleFocus(e) {
    this.setState({
      focused: true
    })

    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e)
    }
  }

  handleBlur(e) {
    this.setState({
      focused: false
    })

    const { onBlur } = this.props
    if (onBlur) {
      onBlur(e)
    }
  }

  render() {
    const {
      labelContent,
      className,
      multiline,
      span,
      type,
      borderedStyle,
      borderlessStyle,
      inputClass,
      beforeContent,
      afterContent,
      clearable,
      onClear,
      valid,
      autoValidate,
      automationId,
      ...inputProps
    } = this.props
    const { focused } = this.state
    const { value, defaultValue } = inputProps

    const id = inputProps.id || this.getGeneratedId()

    const isControlled = value !== undefined && value !== null
    const hasDefaultValue = !isControlled && !!defaultValue

    // If controlled input, render label className based on value. Otherwise,
    // leave it alone.
    const labelProps = isControlled ? { className: value ? "active" : "" } : {}

    const classes = cx(className, `InputField col s${span} layout horizontal`, {
      "input-field": !!labelContent,
      "InputField--bordered": borderedStyle,
      "InputField--borderless": borderlessStyle,
      "InputField--clearable": clearable,
      "InputField--focused": focused
    })

    // TODO: materialize's input-field seems to depend on being laid out
    // in its grid system (in particular, for the label to be laid out properly);
    // this should not be a requirement.
    //
    return (
      <div className={classes}>
        <div className="InputField--before-content">{beforeContent}</div>
        {React.createElement(multiline ? "textarea" : "input", {
          ...inputProps,

          rows: multiline ? 1 : undefined, // Provide minimum one line for auto-height inputs
          // TODO materialize doesn't appear to remove the browser default crap
          // for type=date, so we convert that back to text
          type: type === "date" ? "text" : type,

          ref: ref => {
            if (type === "date") {
              this.initDatePicker(ref)
            }

            this.inputRef = ref
          },

          // HACK materialize's datepicker doesn't seem to play nice with validate
          className: cx(inputClass, {
            validate: autoValidate && type !== "date",
            valid: valid === true,
            invalid: !focused && valid === false
          }),

          onChange: this.handleChange.bind(this),
          onFocus: this.handleFocus.bind(this),
          onBlur: this.handleBlur.bind(this),
          ["data-automation-id"]: automationId
        })}
        {labelContent ? (
          <label
            {...labelProps}
            htmlFor={id}
            ref={
              // Use a ref callback to only set the active class on initial mount
              hasDefaultValue ? setActive : null
            }
          >
            {labelContent}
          </label>
        ) : null}
        {afterContent}
        {clearable && <InputClearButton onClick={onClear} />}
      </div>
    )
  }
}

function setActive(ref) {
  if (ref) {
    ref.className = "active"
  }
}

function getComputedHeight(node) {
  const style = node.ownerDocument.defaultView.getComputedStyle(node, null)

  // IE 11 and older don't account for box-sizing in the height returned from getComputedStyle
  return style.boxSizing === "border-box"
    ? node.offsetHeight
    : parseInt(style.height)
}
