import { JsonDecoder } from "ts.data.json"
import { DepartmentSummary } from "../../state/DepartmentSummariesState"
import paginationDecoder, {
  Pagination
} from "../PaginationDecoder/paginationDecoder"

type ResponseType = {
  departments: DepartmentSummary[]
  meta: {
    pagination: Pagination
  }
}

const departmentSummaryDecoder = JsonDecoder.object<DepartmentSummary>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    overallProgress: JsonDecoder.failover(0, JsonDecoder.number),
    goalsCount: JsonDecoder.failover(0, JsonDecoder.number)
  },
  "DepartmentSummary",
  {
    id: "department_id",
    name: "department_name",
    overallProgress: "department_overall_progress",
    goalsCount: "goal_count"
  }
)

export const getDepartmentSummariesResponseDecoder = JsonDecoder.object<
  ResponseType
>(
  {
    departments: JsonDecoder.array(
      departmentSummaryDecoder,
      "departmentSummaries"
    ),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder
      },
      "meta"
    )
  },
  "getDepartmentsResponse"
).map(({ departments, meta: { pagination } }) => ({
  departmentSummaries: departments,
  pagination
}))
