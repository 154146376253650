import "./ConfirmationModal.less"
import PropTypes from "prop-types"
import React from "react"
import modalDeprecated from "../../decorators/modalDeprecated"
import Button from "../../elements/Button/Button"
import Aid from "../../../constants/automationId"

@modalDeprecated({
  className: "ConfirmationModal",
  hasDefaultExitButton: false,
  allowEscToClose: false // Prevent the admin to press ESC to exit
  // There is a situation, in the pages that contain the multiStepModal and ConfirmationModal,
  // It will two keyboard listeners in the pages, and press ESC the modal will close directly without showing the ConfirmationModal
})
export default class ConfirmationModal extends React.Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    imgSrc: PropTypes.string,
    headerContent: PropTypes.node.isRequired,
    messageContent: PropTypes.node,
    yesButtonText: PropTypes.node.isRequired,
    noButtonText: PropTypes.node,
    yesButtonEnabled: PropTypes.bool
  }

  handleConfirmClick() {
    const { onConfirmClick, onClose } = this.props
    onConfirmClick()
    onClose()
  }

  render() {
    const {
      onClose,
      imgSrc,
      headerContent,
      messageContent,
      yesButtonText,
      noButtonText,
      yesButtonEnabled
    } = this.props

    return (
      <div
        className="ConfirmationModal center-align"
        data-automation-id={Aid.confirmationModal}
      >
        {imgSrc && <img className="ConfirmationModal--img" src={imgSrc} />}
        <h2>{headerContent}</h2>
        {messageContent}
        <div className="layout horizontal center-center">
          {noButtonText ? (
            <Button actionType="plain" onClick={onClose}>
              {noButtonText}
            </Button>
          ) : null}
          <Button
            actionType="primary"
            destructive={true}
            disabled={
              yesButtonEnabled === undefined ? false : !yesButtonEnabled
            }
            onClick={this.handleConfirmClick.bind(this)}
          >
            {yesButtonText}
          </Button>
        </div>
      </div>
    )
  }
}
