import classNames from "classnames"
import * as React from "react"
import Avatar from "../../Avatar/Avatar"
import styles from "./GoalOwnersCell.scss"

type GoalOwnersCellProps = {
  avatars: string[]
  limit?: number
}

const GoalOwnersCell = ({ avatars, limit = 2 }: GoalOwnersCellProps) => {
  const othersCount = Math.max(avatars.length - limit, 0)
  return (
    <div
      className={classNames({
        [styles.container]: true
      })}
    >
      {avatars.slice(0, limit).map((avatar, index) => (
        <div className={styles.avatar} key={`avatar-${index}`}>
          <Avatar size="small" imageURL={avatar} />
        </div>
      ))}
      {othersCount > 0 && (
        <div className={styles.avatar}>
          <Avatar
            size="small"
            imageURL={`https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/+${othersCount}?size=100`}
          />
        </div>
      )}
    </div>
  )
}

export default GoalOwnersCell
