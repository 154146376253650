import "./ProfileHeader.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import strings from "../../../locale/strings"
import ProfilePic from "../ProfilePic/ProfilePic"
import User from "../../../models/User"

export default class ProfileHeader extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    extraSubtitle: PropTypes.node
  }

  render() {
    const { user, extraSubtitle } = this.props

    return (
      <div className="ProfileHeader layout vertical center-center">
        <div className="ProfileHeader--title">
          {user.full_name || user.email}
        </div>
        {User.isDeactivated(user) && (
          <div className="ProfileHeader--deactivated">
            <FormattedMessage {...strings.user.deactivated} />
          </div>
        )}
        <div className="ProfileHeader--subtitle center-align">
          <div>{user.job_title_name}</div>
          {extraSubtitle ? <div>{extraSubtitle}</div> : null}
        </div>
        <ProfilePic
          className="ProfileHeader--profile-pic"
          showHorizontalRule={true}
          user={user}
        />
      </div>
    )
  }
}
