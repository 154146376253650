import _ from "lodash"

const { localStorage, JSON, alert } = window

let shouldWarnIncognito = true

class LocalStorage {
  constructor({ key, jsonReviver }) {
    this._storageKey = key
    this._jsonReviver = jsonReviver
    this.loadData()
  }

  // Use loadData when cache isn't trusted
  loadData() {
    const serializedData = localStorage.getItem(this._storageKey)

    if (serializedData) {
      try {
        this._data = JSON.parse(serializedData, this._jsonReviver)
      } catch (e) {
        this.destroyData()
      }
    } else {
      this._data = null
    }

    return this._data
  }

  updateData(update, tries = 0) {
    this._data = _.assign({}, this._data, update)
    try {
      localStorage.setItem(this._storageKey, JSON.stringify(this._data))
    } catch (e) {
      // might be incognito! or something dumb
      // console.warn(e);
      if (shouldWarnIncognito) {
        if (!tries) {
          localStorage.clear()
          this.updateData(null, tries + 1)
        } else {
          alert(
            [
              "Looks like you're browsing privately!",
              "This app is modern and snappy,",
              "so it's much better if it can save your session.",
              "Open a normal window on your browser, or allow",
              "local storage."
            ].join(" ")
          )
          shouldWarnIncognito = false
        }
      }
    }
  }

  destroyData() {
    this._data = null
    localStorage.removeItem(this._storageKey)
  }

  // Use getData when cache is trusted
  getData() {
    // TODO cache causes store problems when destroySessionData is called
    return this._data
  }

  // ReactJS transform doesn't support getters/setters... wut
  // get data() {
  //   return this._data;
  // }
  // set data(newData) {
  //   this._data = newData;
  //   localStorage.setItem(this._storageKey, JSON.stringify(this._data));
  // }
}

LocalStorage.destroySessionData = (signOut = false) => {
  // Destroys all local data keys except those exempted above.
  // See http://stackoverflow.com/questions/19844750
  let i = localStorage.length
  while (i--) {
    const key = localStorage.key(i)

    if (key === "PreviousUserStore") {
      // Destroy PreviousUserStore on signout
      if (signOut) {
        localStorage.removeItem(key)
      }
    } else if (key !== "LocaleData") {
      // Destroy other every key except LocaleData - we want to remember the user's locale
      localStorage.removeItem(key)
    }
  }
}

export default LocalStorage
