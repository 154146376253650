import { FormattedMessage } from "react-intl"
import _ from "lodash"

type ObjType = string | object | object[] | string[]

type StringsToMessageDescriptor<T> = {
  [P in keyof T]: T[P] extends string
    ? FormattedMessage.MessageDescriptor
    : StringsToMessageDescriptor<T[P]>
}

/**
 * Function used by locale/strings.ts
 * Takes a nested object, and converts all of the strings to a MessageDescriptor
 * object that is accepted by react-intl,
 */
export const convertToMessageDescriptorsFn = <T>(
  obj: object | object[],
  id = ""
): object | object[] => {
  const mapper = (value: ObjType, key: string | number | undefined) => {
    const jointKey = [id, key].filter(s => s !== "").join("-")
    if (typeof value === "string") {
      return {
        id: jointKey,
        defaultMessage: value
      }
    }
    return convertToMessageDescriptorsFn(value, jointKey)
  }

  return Array.isArray(obj)
    ? (obj as object[]).map(mapper)
    : _.mapValues(obj, mapper)
}

export const convertToMessageDescriptors = <T extends object>(
  obj: T
): StringsToMessageDescriptor<T> => {
  return convertToMessageDescriptorsFn(obj, "") as StringsToMessageDescriptor<T>
}
