import { useCallback } from "react"
import useDataAPI from "./useDataAPI"

/**
 * Hook to help restore a team and return the success
 *
 * @param {string} teamId
 * @returns
 */
const useRestoreTeam = (teamId: string) => {
  const restoreAPI = useDataAPI(`/teams/${teamId}/restore`, "PUT")

  const restoreTeam = useCallback(() => {
    restoreAPI.fire()
  }, [restoreAPI])

  return {
    restoreTeam,
    loading: restoreAPI.loading,
    data: restoreAPI.data,
    error: restoreAPI.error
  }
}

export default useRestoreTeam
