import React from "react"
// @ts-ignore
import ScrollableSideDrawer from "../../widgets/ScrollableSideDrawer/ScrollableSideDrawer"
import Avatar from "../../Avatar/Avatar"
import Aid from "../../../constants/automationId"

type User = {
  id: string
  name: string
  avatar: string
}

type UserSelector = React.FC<{
  title?: string | React.ReactNode
  users: User[]
  positionRelative?: boolean
  onSelectUser: (userId: string) => void
  selectedUser?: string
}>

const UserSelector: UserSelector = ({
  title,
  users,
  onSelectUser,
  positionRelative = false,
  selectedUser
}) => {
  return (
    <ScrollableSideDrawer
      sections={[
        {
          id: "users",
          items: users.map(({ name, avatar, id }) => ({
            key: id,
            notSelectable: false,

            peekSection: (
              <div
                className="ReviewsProgressDrawerWrapper--avatar-holder
                        layout vertical center-center"
                data-automation-id={`${Aid.userSelector}-${id}`}
              >
                <Avatar imageURL={avatar} />
              </div>
            ),

            retractedSection: (
              <div className="layout horizontal center left-align">
                <div
                  className="truncate"
                  data-automation-id={`${Aid.userSelectorName}-${id}`}
                >
                  {name}
                </div>
              </div>
            ),

            onClick: (e: React.SyntheticEvent) => {
              e.preventDefault()
              onSelectUser(id)
            }
          }))
        }
      ]}
      selectedItemKey={selectedUser}
      scrollToSelectedItem={true}
      header={title}
      positionRelativeMode={positionRelative}
    />
  )
}

export default UserSelector
