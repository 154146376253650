import Reflux from "reflux-core"
import * as AdminActions from "../actions"
import BaseSurveyStore from "../../stores/BaseSurveyStore"
import { setAtIndex } from "../../util/array"

export const createAdminSurveyStore = () =>
  Reflux.createStore({
    ...BaseSurveyStore,

    init() {
      BaseSurveyStore.init.call(this, { actions: AdminActions.Survey })

      this.listenTo(
        AdminActions.Survey.createSurvey.completed,
        this.addOrReplaceSurvey
      )
      this.listenTo(AdminActions.Survey.updateSurvey.completed, survey => {
        // Update the survey in "surveysById"
        this.addOrReplaceSurvey(survey)

        // Also...if the user is on the survey listing page, and they update the
        // name/schedule of an individual cycle, then we want to also update
        // the survey in the list.
        // Ideally, `surveySearchResults` should just be an array of IDs, and
        // then all surveys are stored in the one `surveysById` key/value store.
        // But, I've spent too much time refactoring...
        // Also note, the surveys in this array slightly differ in shape to the
        // one returned from the updated survey. The updated survey has an
        // extra `survey_audiences` property, and the `templates` property
        // is an array instead of an object. Hopefully this doesn't confuse
        // anyone...
        if (this.data.surveySearchResults) {
          const index = this.data.surveySearchResults.findIndex(
            r => r.id === survey.id
          )
          if (index !== -1) {
            this.data = {
              ...this.data,
              surveySearchResults: setAtIndex(
                this.data.surveySearchResults,
                survey,
                index
              )
            }
            this.trigger(this.data)
          }
        }
      })
      this.listenTo(
        AdminActions.Survey.deleteSurvey.completed,
        this.onDeleteSurvey
      )
      this.listenTo(
        AdminActions.Survey.createSurveyTemplate.completed,
        this.replaceSurveyTemplate
      )
      this.listenTo(
        AdminActions.Survey.updateSurveyTemplate.completed,
        this.replaceSurveyTemplate
      )
      this.listenTo(
        AdminActions.Survey.createAudience.completed,
        this.replaceSurveyAudience
      )
      this.listenTo(
        AdminActions.Survey.updateAudience.completed,
        this.replaceSurveyAudience
      )
      this.listenTo(
        AdminActions.Survey.getSurveyAudienceUsers.completed,
        ({ survey }) => this.addOrReplaceSurvey(survey)
      )
    },

    addOrReplaceSurvey(newSurvey) {
      this.data = {
        ...this.data,
        surveysById: this.data.surveysById.set(newSurvey.id, newSurvey)
      }

      this.trigger(this.data)
    },

    updateSurvey(surveyId, updater) {
      this.data = {
        ...this.data,
        surveysById: this.data.surveysById.update(
          surveyId,
          survey => survey && updater(survey)
        )
      }

      this.trigger(this.data)
    },

    replaceSurveyTemplate({ surveyId, template }) {
      this.updateSurvey(surveyId, survey =>
        survey.with({ templates: [template] })
      )
    },

    replaceSurveyAudience(surveyAudience) {
      this.updateSurvey(
        surveyAudience.survey_id,
        survey =>
          /* eslint-disable camelcase */
          survey.with({
            survey_audiences: [surveyAudience],

            // If the survey audiences of an active survey are updated, we can assume that SF has
            // turned on updating_survey_responses_in_progress. Any changes to audiences of an active
            // survey trigger a background job to update survey responses.
            updating_survey_responses_in_progress:
              survey.updating_survey_responses_in_progress ||
              survey.state === "active"
          })
        /* eslint-enable camelcase */
      )
    },

    onDeleteSurvey({ id }) {
      this.data = {
        ...this.data,
        surveysById: this.data.surveysById.delete(id),
        surveySearchResults: this.data.surveySearchResults?.filter(
          s => s.id !== id
        )
      }

      this.trigger(this.data)
    }
  })

export default createAdminSurveyStore()
