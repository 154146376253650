import { JsonDecoder } from "ts.data.json"
import { OutgoingAlignedGoal } from "../../types/Goals"
import { goalTypeDecoder } from "../BaseGoalDecoder/baseGoalDecoder"

export const outgoingAlignedGoalDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    goal_type: goalTypeDecoder,
    team_name: JsonDecoder.failover("", JsonDecoder.string),
    department: JsonDecoder.failover(
      { title: "" },
      JsonDecoder.object(
        { title: JsonDecoder.string },
        "OutgoingAlignedGoal.departmentDecoder"
      )
    )
  },
  "outgoingAlignedGoal"
).map<OutgoingAlignedGoal>(({ goal_type, team_name, department, ...keys }) => ({
  goalType: goal_type,
  teamName: team_name,
  departmentName: department.title,
  ...keys
}))
