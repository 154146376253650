import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions/index"
import * as AdminActions from "../admin/actions"
import { Category, Skill } from "../models/OntologyItem"
import LocalStorage from "./lib/LocalStorage"
import SessionStore from "./SessionStore"

const CATEGORY_TYPE = "category"
const SKILL_TYPE = "attribute"

const db = new LocalStorage({
  key: "OntologyStore",

  jsonReviver(key, value) {
    switch (value && value.skill_type) {
      case CATEGORY_TYPE:
        return new Category(value)
      case SKILL_TYPE:
        return new Skill(value)
      default:
        return value
    }
  }
})

const SKILL_STAGED_CHANGE_ACTIONS = _.at(Actions.Ontology, [
  "createSkill",
  "updateSkill",
  "createCategory",
  "publishCategory",
  "archiveCategory",
  "updateCategory"
])

const OntologyStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function() {
    if (!db.getData()) {
      db.updateData({
        categories: [],
        skills: [],
        defaultCategories: [],
        defaultSkills: []
      })
    }

    this.state = {
      ...db.getData(),
      stagedCategories: null,
      stagedSkills: null
    }

    this.listenTo(SessionStore, s => !!s && this.fetchOntology())
    this.listenTo(Actions.Ontology.loadSkills.completed, this.onLoadSkills)
    this.listenTo(
      Actions.Ontology.loadDefaultSkills.completed,
      this.onLoadDefaultSkills
    )
    this.listenTo(
      Actions.Ontology.loadStagedSkills.completed,
      this.onLoadStagedSkills
    )
    this.listenTo(
      Actions.Ontology.loadStagedSkill.completed,
      this.onLoadStagedSkill
    )
    this.listenTo(
      Actions.Ontology.publishCategory.completed,
      this.onPublishCategory
    )

    for (const action of SKILL_STAGED_CHANGE_ACTIONS) {
      this.listenTo(action.completed, this.onUpdateStagedSkill)
    }

    this.listenTo(
      AdminActions.SkillDefault.create.completed,
      this.onSkillDefaultCreate
    )
    this.listenTo(
      AdminActions.SkillDefault.destroy.completed,
      this.onSkillDefaultDestroy
    )

    // Refreshes ontology data each load
    if (SessionStore.getInitialState()) {
      this.fetchOntology()
    }
  },

  getInitialState: function() {
    return this.state
  },

  fetchOntology: function() {
    // Gets called on load AND when Session changes/refreshes
    // eslint-disable-next-line no-console
    Actions.Ontology.loadSkills().catch(ontology => console.error({ ontology }))
  },

  onLoadSkills: function(incomingSkills) {
    const [categories, skills] = _.partition(
      incomingSkills,
      s => s.skill_type === CATEGORY_TYPE
    )

    db.updateData({ categories, skills })
    _.assign(this.state, db.getData())

    this.trigger(this.state)
  },

  onLoadDefaultSkills: function(incomingSkills) {
    const [defaultCategories, defaultSkills] = _.partition(
      incomingSkills,
      s => s.skill_type === CATEGORY_TYPE
    )

    db.updateData({ defaultCategories, defaultSkills })
    _.assign(this.state, db.getData())

    this.trigger(this.state)
  },

  onLoadStagedSkills: function(incomingSkills) {
    const [stagedCategories, stagedSkills] = _.partition(
      incomingSkills,
      s => s.skill_type === CATEGORY_TYPE
    )

    _.assign(this.state, { stagedCategories, stagedSkills })

    this.trigger(this.state)
  },

  refreshStagedBySubstitutingSkill: function(
    skillId,
    skillTransformer,
    { refreshParent = true } = {}
  ) {
    if (!this.state.stagedCategories) {
      return
    }

    // Refresh staged ontology with the new skill substituted in
    const { stagedCategories, stagedSkills } = this.state
    const nextOntology = stagedCategories.concat(stagedSkills)
    const substIndex = _.findIndex(nextOntology, { id: skillId })
    const newSkill = skillTransformer(nextOntology[substIndex])

    if (newSkill) {
      nextOntology[
        substIndex > -1 ? substIndex : nextOntology.length
      ] = newSkill
      this.onLoadStagedSkills(nextOntology)

      // Refresh parent as well
      if (refreshParent && newSkill.parent_skill_id) {
        Actions.Ontology.loadStagedSkill({ id: newSkill.parent_skill_id })
      }
    }
  },

  onLoadStagedSkill: function(newSkill) {
    this.refreshStagedBySubstitutingSkill(newSkill.id, _.constant(newSkill), {
      refreshParent: false
    })
  },

  onUpdateStagedSkill: function(newSkill) {
    this.refreshStagedBySubstitutingSkill(newSkill.id, _.constant(newSkill))
  },

  onPublishCategory: function() {
    this.fetchOntology()
    if (this.state.stagedCategories) {
      Actions.Ontology.loadStagedSkills()
    }
  },

  onSkillDefaultCreate: function(skillDefault) {
    this.refreshStagedBySubstitutingSkill(
      skillDefault.skill_id,
      skill =>
        skill &&
        skill.with({
          skill_defaults: [...skill.skill_defaults, skillDefault]
        })
    )
  },

  onSkillDefaultDestroy: function(skillDefault) {
    const { skill_id: skillId, job_title: jobTitle } = skillDefault

    this.refreshStagedBySubstitutingSkill(
      skillId,
      skill =>
        skill &&
        skill.with({
          skill_defaults: _.reject(
            skill.skill_defaults, // eslint-disable-line camelcase
            sd => sd.job_title.id === jobTitle.id
          )
        })
    )
  }
})

export default OntologyStore
