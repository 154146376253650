import "./ActivityList.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import ZugataFormattedRelativeDate from "../../elements/ZugataFormattedDate/ZugataFormattedRelativeDate"
import strings from "../../../locale/strings"
import User from "../../../models/User"
import Avatar from "../../Avatar/Avatar"

@injectIntl
export default class ActivityList extends React.Component {
  static propTypes = {
    cycle: PropTypes.object,
    review: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    bucketPosition: PropTypes.number
  }

  getRevieweeName() {
    const { review } = this.props
    return review.user ? review.user.full_name || review.user.best_name : ""
  }

  getActivityUserName(activityUser) {
    const {
      currentUser,
      intl: { formatMessage }
    } = this.props

    if (!activityUser) {
      return {
        person: "third",
        name: <i>{formatMessage(strings.user.deleted)}</i>
      }
    }

    return activityUser.id === currentUser.id
      ? { person: "second", name: formatMessage(strings.general.you) }
      : { person: "third", name: User.getFullName(activityUser) }
  }

  getQuestionIndex(auditableId) {
    const { review, cycle, bucketPosition } = this.props
    const questionId = review.performance_answers.find(
      answer => answer.id === auditableId
    ).performance_question_in_cycle.id
    const questionIndex = cycle.performance_question_in_cycles.findIndex(
      question => question.id === questionId
    )
    const indexWithBuckets =
      bucketPosition <= questionIndex ? questionIndex + 2 : questionIndex + 1
    return indexWithBuckets
  }

  renderAuditChange(activityItem) {
    const {
      audited_changes: auditedChanges,
      auditable_type: auditableType,
      auditable_id: auditableId,
      user
    } = activityItem

    const reviewer = this.getActivityUserName(user)
    const reviewee = this.getRevieweeName()

    let formattedMessageProps
    if (auditableType === "PerformanceBucketAssignment") {
      formattedMessageProps = this.renderBucketActivityItem(
        auditableId,
        auditedChanges,
        reviewer,
        reviewee
      )
    } else if (auditableType === "PerformanceReview") {
      formattedMessageProps = auditedChanges.manager_completed_at
        ? this.renderReviewCompletedItem(auditedChanges, reviewer)
        : this.renderReviewShareItem(reviewer, reviewee)
    } else if (auditableType === "PerformanceCycle") {
      formattedMessageProps = this.renderSharingUpdateItem(
        auditedChanges,
        reviewer
      )
    } else {
      formattedMessageProps = auditedChanges.choice
        ? this.renderChoiceUpdateItem(auditableId, auditedChanges, reviewer)
        : this.renderResponseUpdateItem(auditableId, reviewer)
    }
    const { values, ...restOfProps } = formattedMessageProps

    return (
      <div className="ActivityList--activity-audit-text">
        <FormattedMessage
          {...restOfProps}
          values={_.mapValues(values, value => (
            <strong>{value}</strong>
          ))}
        />
      </div>
    )
  }

  renderBucketActivityItem(auditableId, auditedChanges, reviewer, reviewee) {
    const { cycle, review } = this.props
    const editedBucket = cycle.performance_bucket_in_cycles.find(
      bucket => bucket.id === auditedChanges.performance_bucket_in_cycle_id
    )
    // The object review.performance_bucket_assignments has the list of assignments bucket updates.
    // First we find the index of the current assignment using the auditableId.
    // After getting the index we get the previous assignment to render it on the screen.
    const bucketIndex = review.performance_bucket_assignments.findIndex(
      bucket => bucket.id === auditableId
    )
    const previousBucket =
      review.performance_bucket_assignments[bucketIndex - 1]
    const previousAssignment = previousBucket.performance_bucket_in_cycle
    return {
      ...strings.performanceReviews.audits.fields.PerformanceBucketAssignment
        .template,
      values: {
        person: reviewer.person,
        reviewer: reviewer.name,
        reviewee,
        bucketName: _.get(editedBucket, "title"),
        previousBucketName: _.get(previousAssignment, "title")
      }
    }
  }

  renderReviewCompletedItem(auditedChanges, reviewer) {
    const completedDate = auditedChanges.manager_completed_at[1]

    return {
      ...strings.performanceReviews.audits.fields.PerformanceReview
        .templateManagerCompleted,
      values: {
        person: reviewer.person,
        reviewer: reviewer.name,
        date: <ZugataFormattedRelativeDate value={completedDate} />
      }
    }
  }

  renderReviewShareItem(reviewer, reviewee) {
    return {
      ...strings.performanceReviews.audits.fields.PerformanceReview
        .templateManagerShared,
      values: {
        person: reviewer.person,
        reviewer: reviewer.name,
        reviewee
      }
    }
  }

  renderSharingUpdateItem(auditedChanges, user) {
    const shareChange = auditedChanges.can_share_performance_review
    const enabledAction = Array.isArray(shareChange)
      ? shareChange[1]
      : shareChange

    return {
      ...strings.performanceReviews.audits.fields.PerformanceCycle[
        enabledAction ? "templateEnabled" : "templateDisabled"
      ],
      values: {
        person: user.person,
        user: user.name
      }
    }
  }

  renderChoiceUpdateItem(auditableId, auditedChanges, reviewer) {
    const { review } = this.props
    const question = review.performance_answers.find(
      answer => answer.id === auditableId
    ).performance_question_in_cycle
    const previousAnswer = question.answer_titles_hash[auditedChanges.choice[0]]
    const newAnswer = question.answer_titles_hash[auditedChanges.choice[1]]

    return {
      ...strings.performanceReviews.audits.fields.PerformanceAnswer
        .templateChoice,
      values: {
        person: reviewer.person,
        reviewer: reviewer.name,
        index: this.getQuestionIndex(auditableId),
        previousAnswer,
        newAnswer
      }
    }
  }

  renderResponseUpdateItem(auditableId, reviewer) {
    return {
      ...strings.performanceReviews.audits.fields.PerformanceAnswer
        .templateResponse,
      values: {
        person: reviewer.person,
        reviewer: reviewer.name,
        index: this.getQuestionIndex(auditableId)
      }
    }
  }

  render() {
    const { review } = this.props
    const activities = review.activities
    return (
      <ul className="ActivityList">
        {_(activities)
          .reject(
            (item, i, items) =>
              // Remove duplicate assignments
              items[i - 1] && items[i - 1].id === item.id
          )
          .sortBy(item => -new Date(item.created_at))
          .map(item => {
            const { id, user, created_at: createdAt } = item
            return (
              <li
                key={id}
                className="ActivityList--activity-item layout horizontal"
              >
                <div className="ActivityList--avatar-container">
                  <Avatar imageURL={user.profile_image_url} />
                </div>
                <div className="ActivityList--text-container flex left-align">
                  <div className="ActivityList--activity-timestamp">
                    <ZugataFormattedRelativeDate value={createdAt} />
                  </div>
                  {this.renderAuditChange(item)}
                </div>
              </li>
            )
          })
          .value()}
      </ul>
    )
  }
}
