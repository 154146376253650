import classNames from "classnames"
import * as React from "react"
import DEFAULT_IMAGE_URL from "../../static/images-2017/avatars/manager.png"
import styles from "./Avatar.scss"

type AvatarProps = {
  size?: "small" | "medium" | "large" | "xlarge"
  automationId?: string
  imageURL: string
}

const Avatar = ({
  size = "medium",
  imageURL,
  automationId,
  ...props
}: AvatarProps) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
        [styles.xlarge]: size === "xlarge"
      })}
    >
      <img
        src={imageURL}
        data-automation-id={automationId}
        onError={ev => {
          ;(ev.target as HTMLImageElement).src = DEFAULT_IMAGE_URL
          return false
        }}
      />
    </div>
  )
}

export default Avatar
