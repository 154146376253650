import { Text, Icon } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import chevronDownIcon from "@kaizen/component-library/icons/chevron-down.icon.svg"
import chevronUpIcon from "@kaizen/component-library/icons/chevron-up.icon.svg"
import { TableRowCell } from "@kaizen/component-library/draft/Kaizen/Table"
import strings from "../../../locale/strings"
import TeamGoalProgressCell from "../TeamGoalProgressCell/TeamGoalProgressCell"
import useGetResponsiveValue from "../../../hooks/useGetResponsiveValue"
import styles from "./GoalsSummaryCell.scss"

type GoalsSummaryCellProps = {
  ownerName: string
  goalsCount: number
  progress: number
  expandable?: boolean
  open?: boolean
  intl: InjectedIntl
}

const GoalsSummaryCell = ({
  ownerName,
  goalsCount,
  progress,
  expandable = false,
  open,
  intl
}: GoalsSummaryCellProps) => {
  const { formatMessage } = intl
  const getResponsiveValue = useGetResponsiveValue()
  const isMobile = getResponsiveValue({ 0: true, 768: false })
  return (
    <div className={styles.container}>
      <TableRowCell width={getResponsiveValue({ 0: 1, 768: 3 / 12 })}>
        <div className={styles.teamName}>
          <Text tag="div" style="body-bold" inheritBaseline>
            {ownerName}
          </Text>
        </div>
      </TableRowCell>
      <TableRowCell width={getResponsiveValue({ 0: 1, 768: 7 / 12 })}>
        <div className={styles.progress}>
          <Text tag="div" style="body-bold" inheritBaseline>
            {`${progress}%`}
          </Text>
          <TeamGoalProgressCell progress={progress} />
        </div>
      </TableRowCell>
      <TableRowCell width={getResponsiveValue({ 0: 1, 768: 1 / 12 })}>
        <div className={styles.goalsCount}>
          <Text tag="div" style="body-bold" inheritBaseline>
            {formatMessage(strings.goals.numOfGoals, { count: goalsCount })}
          </Text>
        </div>
      </TableRowCell>
      {isMobile ? (
        <div className={styles.chevronIcon}>
          {expandable && (
            <Icon
              title={
                open
                  ? formatMessage(strings.goalsPage.goalSummaryCell.close)
                  : formatMessage(strings.goalsPage.goalSummaryCell.open)
              }
              icon={open ? chevronUpIcon : chevronDownIcon}
            />
          )}
        </div>
      ) : (
        <TableRowCell width={1 / 12}>
          <div className={styles.chevronIcon}>
            {expandable && (
              <Icon
                title={
                  open
                    ? formatMessage(strings.goalsPage.goalSummaryCell.close)
                    : formatMessage(strings.goalsPage.goalSummaryCell.open)
                }
                icon={open ? chevronUpIcon : chevronDownIcon}
              />
            )}
          </div>
        </TableRowCell>
      )}
    </div>
  )
}

export default injectIntl(GoalsSummaryCell)
