import * as React from "react"
import { createContext, useReducer } from "react"
import { v4 as uuid } from "uuid"
import { Omit } from "yargs"

type LocalNotificationType =
  | "affirmative"
  | "informative"
  | "cautionary"
  | "negative"
export type LocalNotification = {
  id: string
  type: LocalNotificationType
  title: string
  message: string
}

type NotificationsState = {
  notificationsById: Record<string, LocalNotification>
}
type NotificationsAction = ShowNotificationAction | HideNotificationAction

type ShowNotificationAction = {
  type: "SHOW"
  payload: Omit<LocalNotification, "id">
}

type HideNotificationAction = {
  type: "HIDE"
  payload: string
}

const INITIAL_STATE: NotificationsState = {
  notificationsById: {}
}

const notificationsReducer = (
  state: NotificationsState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case "SHOW": {
      const { payload } = action
      const notification = {
        ...payload,
        // @ts-ignore
        id: payload.id || uuid()
      }
      return {
        ...state,
        notificationsById: {
          ...state.notificationsById,
          [notification.id]: notification
        }
      }
    }
    case "HIDE": {
      const { payload: notificationId } = action
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [notificationId]: notif,
        ...notifications
      } = state.notificationsById
      return {
        ...state,
        notificationsById: notifications
      }
    }
  }
}

export const NotificationsContext: React.Context<{
  state: NotificationsState
  dispatch: React.Dispatch<NotificationsAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: NotificationsAction) => {}
})

export const NotificationsProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(notificationsReducer, INITIAL_STATE)

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationsContext.Provider>
  )
}
