import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import _ from "lodash"
import strings from "../../../locale/strings"
import connect from "../../decorators/connect"
import modalDeprecated from "../../decorators/modalDeprecated"
import Actions from "../../../actions"
import ConversationStore from "../../../stores/ConversationStore"
import ConversationModalWrapper from "../conversations/ConversationModalWrapper"
import Conversation from "../conversations/Conversation"
import ConversationMessage from "../conversations/ConversationMessage"
import Loader from "../Loader/Loader"
import ProfilePic from "../ProfilePic/ProfilePic"
import PastFeedbackItem from "../PastFeedbackItem/PastFeedbackItem"
import Aid from "../../../constants/automationId"
import "./CompletedPeerFeedbackModal.less"

class CompletedPeerFeedbackModal extends React.Component {
  static defaultProps = {
    // When sharedMRF is set to true, it means the reviewee is viewing the completed MRF
    sharedMRF: false
  }

  static propTypes = {
    reviewId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    sharedMRF: PropTypes.bool
  }

  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  componentDidMount() {
    this.loadSurveyContent({ closeOnError: true })
  }

  getReview() {
    const { reviewId, conversationData } = this.props
    return _.get(
      conversationData,
      `sourceObjectsByType["survey_response"][${reviewId}]`
    )
  }

  loadSurveyContent({ closeOnError = false } = {}) {
    Actions.SurveyResponse.getSurveyResponse(this.props.reviewId).catch(
      () => closeOnError && this.props.onClose()
    )
  }

  loadReplies() {
    this.loadSurveyContent()
  }

  handleReply(body) {
    Actions.Comments.comment({
      sourceObject: this.getReview(),
      body,
      type: "survey_response"
    })
  }

  getTitle() {
    const review = this.getReview()

    const reviewer = review.author
    const reviewee = review.subject

    const revieweeName = reviewee.full_name || reviewee.email

    return reviewer && this.context.user.email === reviewer.email ? (
      <FormattedMessage
        {...strings.completedPeerFeedbackModal.youGaveFeedback}
        values={{ revieweeName }}
      />
    ) : (
      [
        <div key="title" style={{ padding: "0 0 15px 0" }}>
          <FormattedMessage
            {...strings.completedPeerFeedbackModal.feedbackOn}
            values={{ revieweeName }}
          />
        </div>,
        <ProfilePic
          key="profilePic"
          showHorizontalRule={true}
          user={reviewee}
        />
      ]
    )
  }

  getInputPlaceholder(reviewerEmail) {
    const {
      intl: { formatMessage }
    } = this.props

    return reviewerEmail === this.context.user.email
      ? formatMessage(strings.completedPeerFeedbackModal.anythingElse)
      : formatMessage(strings.completedPeerFeedbackModal.ideasForCoaching)
  }

  canComment(review) {
    const { email } = this.context.user

    const reviewer = review.author
    const reviewee = review.subject

    return (
      email === (reviewer && reviewer.email) || email === reviewee.manager_email
    )
  }

  transformReview(review) {
    return review.comments.map(comment => {
      const {
        id,
        body,
        author: { user },
        created_at: createdAt
      } = comment
      return (
        <ConversationMessage
          key={id}
          body={body}
          timestamp={createdAt}
          id={id}
          sender={user}
        />
      )
    })
  }

  render() {
    const review = this.getReview()
    const { sharedMRF } = this.props

    return review ? (
      <div data-automation-id={Aid.completedPeerFeedbackModal}>
        <ConversationModalWrapper title={this.getTitle()}>
          <div className="CompletedPeerFeedbackModal--feedback-container">
            <PastFeedbackItem
              review={review}
              type={"survey_response"}
              enableCopyToClipboard={false}
              showProfileItemVisibilityIndicator={false}
              sharedMRF={sharedMRF}
            />
          </div>
          <Conversation
            sourceType={"survey_response"}
            sourceId={review.id}
            messages={this.transformReview(review)}
            inputPlaceholder={this.getInputPlaceholder(
              review.reviewer ? review.reviewer.email : ""
            )}
            loading={review.loadingComments}
            lastSavedReplyId={review.lastSavedCommentId}
            onRepliesNeeded={this.loadReplies.bind(this)}
            onReply={this.handleReply.bind(this)}
            readOnly={!this.canComment(review)}
          />
        </ConversationModalWrapper>
      </div>
    ) : (
      <Loader />
    )
  }
}

export default _.compose(
  injectIntl,
  connect(ConversationStore, "conversationData"),
  modalDeprecated({
    className: "CompletedPeerFeedbackModal",
    opened: props => !!props.reviewId,
    key: props => props.reviewId
  })
)(CompletedPeerFeedbackModal)

export { CompletedPeerFeedbackModal as RawCompletedPeerFeedbackModal }
