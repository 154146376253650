import { JsonDecoder } from "ts.data.json"
import { Omit } from "yargs"
import {
  BaseGoal,
  BaseGoalPreview,
  GoalIndividual,
  GoalKeyResult,
  GoalPriority,
  GoalType
} from "../../types/Goals"

type Difference<T, S extends T> = Omit<S, keyof T>

export const goalTypeDecoder = JsonDecoder.oneOf<GoalType>(
  [
    JsonDecoder.isExactly("my_goal").map((): "personal" => "personal"),
    JsonDecoder.isExactly("team_goal").map((): "team" => "team"),
    JsonDecoder.isExactly("department_goal").map(
      (): "department" => "department"
    ),
    JsonDecoder.isExactly("company_goal").map((): "company" => "company")
  ],
  "goalType"
)

export const goalPriorityDecoder = JsonDecoder.oneOf<GoalPriority>(
  [
    JsonDecoder.isExactly("none").map((): "low" => "low"),
    JsonDecoder.isExactly("medium"),
    JsonDecoder.isExactly("high")
  ],
  "goalPriority"
)

export const goalKeyResultDecoder: JsonDecoder.Decoder<GoalKeyResult> = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    title: JsonDecoder.string,
    completion: JsonDecoder.number
  },
  "keyResult"
)

export const goalIndividualsDecoder: JsonDecoder.Decoder<GoalIndividual> = JsonDecoder.object(
  {
    user: JsonDecoder.object<GoalIndividual>(
      {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        avatar: JsonDecoder.object(
          {
            thumb_url: JsonDecoder.string
          },
          "avatar"
        ).map(avatarObj => avatarObj.thumb_url),
        profileImage: JsonDecoder.failover("", JsonDecoder.string),
        role: JsonDecoder.failover("", JsonDecoder.string),
        aggregateId: JsonDecoder.failover("", JsonDecoder.string)
      },
      "user",
      {
        name: "best_name",
        avatar: "avatar_images",
        role: "job_title_name",
        aggregateId: "employee_aggregate_id",
        profileImage: "profile_image_url"
      }
    )
  },
  "individuals"
).map(({ user }) => user)

const baseGoalDecoders = {
  id: JsonDecoder.number,
  name: JsonDecoder.string,
  completion: JsonDecoder.number,
  commentsCount: JsonDecoder.number,
  dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
  priority: goalPriorityDecoder,
  type: goalTypeDecoder
}

const baseGoalKeyMap = {
  commentsCount: "number_of_comments",
  dueDate: "due_at",
  priority: "priority_name",
  type: "goal_type"
}

export const extendGoalPreviewDecoder = <T extends BaseGoalPreview>(
  decoders: JsonDecoder.DecoderObject<
    Difference<BaseGoalPreview, T> & Partial<BaseGoalPreview>
  >,
  decoderName: string,
  keyMap?: JsonDecoder.DecoderObjectKeyMap<Difference<BaseGoalPreview, T>>
): JsonDecoder.Decoder<T> => {
  const mergedDecoders = {
    ...baseGoalDecoders,
    ...decoders
  } as JsonDecoder.DecoderObject<T>

  const mergedKeyMaps: JsonDecoder.DecoderObjectKeyMap<T> = {
    ...baseGoalKeyMap,
    ...keyMap
  } as JsonDecoder.DecoderObjectKeyMap<T>

  return JsonDecoder.object<T>(mergedDecoders, decoderName, mergedKeyMaps)
}

export const extendGoalDecoder = <T extends BaseGoal>(
  decoders: JsonDecoder.DecoderObject<
    Difference<BaseGoal, T> & Partial<BaseGoal>
  >,
  decoderName: string,
  keyMap?: JsonDecoder.DecoderObjectKeyMap<Difference<BaseGoal, T>>
): JsonDecoder.Decoder<T> => {
  const mergedDecoders = {
    ...baseGoalDecoders,
    description: JsonDecoder.failover(undefined, JsonDecoder.string),
    status: JsonDecoder.failover(undefined, JsonDecoder.string),
    keyResults: JsonDecoder.array(goalKeyResultDecoder, "keyResults"),
    visibility: JsonDecoder.string,
    ...decoders
  } as JsonDecoder.DecoderObject<T>

  const mergedKeyMaps: JsonDecoder.DecoderObjectKeyMap<T> = {
    ...baseGoalKeyMap,
    keyResults: "key_results",
    ...keyMap
  } as JsonDecoder.DecoderObjectKeyMap<T>

  return JsonDecoder.object<T>(mergedDecoders, decoderName, mergedKeyMaps)
}
