import Reflux from "./ActionsInitializer"

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}

export const loadingTypes = {
  OTHER: "other",
  AUTH: "auth",
  USER_IMPORT: "user_import"
}

const UIActions = Reflux.createActions({
  setLoadingState: {},
  setLightboxState: {},
  setRequestState: {},
  notify: {},
  error: {},
  success: {},
  offline: {},
  confirm: ASYNC_SETTINGS,
  popup: { sync: true },
  destroyPopup: {}
})

const openedWindows = {}

UIActions.confirm.preEmit = function(message) {
  if (confirm(message)) {
    this.completed()
  } else {
    this.failed()
  }
}

UIActions.popup.listen(({ url, name = "popup", width = 400, height = 600 }) => {
  // EJ - this isn't exact in the presence of scroll bars, address bars, etc., but it
  // should be good enough
  const left = window.screenX + Math.max(0, (window.innerWidth - width) / 2)
  const top = window.screenY + Math.max(0, (window.innerHeight - height) / 2)

  openedWindows[name] = window.open(
    url,
    name,
    `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`
  )
})

UIActions.destroyPopup.preEmit = function(name) {
  const win = openedWindows[name]
  if (win) {
    win.close()
  }
}

export default UIActions
