import _ from "lodash"
import * as logging from "./constants/logging"

const CHROME_EXTENSION_ID_DEVELOPMENT = "beldojngkknecoijbmeeboebiolebiag"
const CHROME_EXTENSION_ID = "pjlfgiceggnffgieobmgpmibpcidmadf"

const CONSTANTS = {
  AUTH: {
    PROVIDERS: {
      GOOGLE: "google",
      BASIC: "basic",
      SAML: "saml"
    },
    GOOGLE_SCOPES: [
      "profile",
      "email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email"
    ],
    DEFAULT_INTEGRATIONS: [
      {
        provider: "google"
      },
      {
        provider: "basic"
      }
    ],
    PROVIDER_ORDERING: _.invert(["saml", "google", "basic"])
  },

  IMPORT: {
    PROVIDERS: {
      GOOGLE: "google",
      EXCHANGE: "exchange",
      SLACK: "slack",
      BASIC: "basic" // TODO deprecate in Steadyfoot
    }
  },

  PROVIDER_COLORS: {
    // Keys match provider *values* above
    // absent implies default color
    google: "#3F85F4"
  },

  SLACK_REDIRECT_URI: `https://${window.location.host}/performance/profile?to=setup&redirect=true`,
  SLACK_SCOPES: [
    "im:read",
    "groups:read",
    "team:read",
    "users:read",
    "users:read.email",
    "search:read"
  ],

  GOOGLE_SCOPES: [
    "https://www.googleapis.com/auth/calendar.readonly",
    "profile",
    "email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email"
    // 'https://www.googleapis.com/auth/contacts.readonly',
    //"https://www.googleapis.com/auth/gmail.metadata"
  ],

  EXCHANGE_SCOPES: ["EWS.AccessAsUser.All"],

  EXCHANGE_AUTHORITY: "https://login.microsoftonline.com/common",
  EXCHANGE_GRAPH_ENDPOINT: "https://graph.microsoft.com/v1.0/me",

  // Colors for arbitrary values are determined by scaling this scale proportionally
  // and interpolating values
  RATING_COLOR_SCALE: {
    0: "rgba(231, 136, 143)",
    1: "rgba(227, 167, 175)",
    2: "rgba(218, 216, 222)",
    3: "rgba(148, 157, 175)",
    4: "rgba(105, 118, 144)"
  },

  // Keep in sync with CSS
  MOBILE_WIDTH: 500,
  SMALL_SCREEN_WIDTH: 800,
  GMAIL_FRAME_WIDTH: 300,

  COLORS: {
    MANATEE: "#9898AD",
    LIGHT_BLUE: "#229793",
    GREEN_BRIGHT: "#4CAF50",
    SEEDLING: "#21A38B",
    TEXT_COLOR: "#31315C",
    TEXT_COLOR_FADED: "rgba(49, 49, 92, 0.45)",
    // TEXT_COLOR_SECONDARY: '#9898AD',
    BLOCKED_CONTENT_COLOR: "#E9EBEC",
    STATS_PROGRESS_COLOR: "#5176C9",
    COMPLETED_COLOR: "#7EA763",

    CREATED: "#9898AD",
    IN_PROGRESS: "#E1AA11",
    BLOCKED: "#DF5A49",
    ACCOMPLISHED: "#7EA763",

    TOOLTIP_BACKGROUND: "#FFFFFF",
    TOOLTIP_FONT: "#31315C",
    TOOLTIP_BORDER: "#35374A",

    FEEDBACK_STATS: {
      SENT: "#5176C9",
      READ: "#E1AA11",
      LIKED: "#C95191",
      COMMENTS: "#7EA763",
      REQUESTS: "#6E41CF"
    }
  },

  EXPERIMENTS: {
    DEFAULT_GROUP: "0",
    FIRST_VARIATION: "1"
  },

  POINTS: {
    FEEDBACK_COMPLETE: 10,
    FEEDBACK_OPENED: -10
  },

  CSV_EXPORT_POLL_INTERVAL: 1000,
  USER_CSV_IMPORT_POLL_INTERVAL: 3000,
  CYCLE_POLL_TIMER_INTERVAL: 60000,
  NOTIFICATION_POLL_INTERVAL: 1000 * 60,
  AUTOSAVE_DELAY: 1500,
  SEARCHFIELD_DEBOUNCE: 300,

  GOALS: {
    DUE_AT_HOUR: 17
  },

  GLOBAL_EVENTS: {
    AUTH2: "zugata:loadedGoogleAuth"
  },

  TOS_LINK: "https://www.cultureamp.com/terms/",
  PRIVACY_POLICY_LINK: "https://www.cultureamp.com/privacy.html",
  FAQ_LINK:
    "https://academy.cultureamp.com/hc/en-us/sections/360001253640-Performance",
  PERMISSIONS_LINK:
    "https://academy.cultureamp.com/hc/en-us/articles/360004126480",
  GMAIL_PLUGIN_LINK:
    "https://chrome.google.com/webstore/detail/zugata/" +
    CHROME_EXTENSION_ID_DEVELOPMENT +
    "?hl=en",
  OUTLOOK_ARTICLE_LINK:
    "https://help.zugata.com/workflow-integrations/give-real-time-feedback-with-microsoft-outlook-add-in-beta",
  CHANGE_PASSWORD_LINK: "/my/password",
  CHANGE_PROFILE_PIC_LINK: "/my/profile",
  GMAIL_ORIGIN: "https://mail.google.com",

  NUM_MONTHS_OF_PROGRESS: 6,
  IMPORT_CONTACTS_INTEGRATIONS: true,

  FEATURE_FLAGS: {
    ADMIN_USER_UPLOAD: true,
    UPDATE_MANAGER_REVIEWER: false
  },

  BEACONSTAR_URL: "/performance",
  STEADYFOOT_URL: "/performance/api/v1",
  PERFORMANCE_UI_URL: "/performance",
  CULTUREAMP_JURISDICTION_DOMAIN: "cultureamp.com",

  LOG_TAGS: [],

  USE_SUBDOMAINS: false,

  TREASURE_DATA_DATABASE: "incoming_frontend",
  TREASURE_DATA_PATHNAME: "/",
  TREASURE_DATA_DEVELOPMENT: false,
  TREASURE_DATA_HOST: "analytics.usw2.dev-us.cultureamp.io",
  TREASURE_DATA_WRITE_KEY: "zymfzj68Ubr4c7qA8r5qVFVzZXNY639BYKthqUhc",

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID_DEVELOPMENT,
  SENTRY_ENABLED: false,
  // default to main US Sentry project (overriden by EU)
  SENTRY_URL: "https://be0953292126452b928c7602a5d8a3e7@sentry.io/2255089",

  WHY_DID_YOU_RENDER_DEBUGGER: false
}
/**
 * DEVELOPMENT SETTINGS
 */
const development = _.assign({}, CONSTANTS, {
  EXCHANGE_OAUTH_CLIENT_ID: "dff99cea-2831-4ffd-8b1e-c42b648ec4a4",

  GOOGLE_CLIENT_ID:
    "542265184555-ito2ljbi9kp97gnft094ob552t4sv3tg.apps.googleusercontent.com",
  GOOGLE_AUTH_CLIENT_ID:
    "52402875477-l1inmlreefitiut9d7jrmu9evtc7ois2.apps.googleusercontent.com",

  GOOGLE_ANALYTICS_OPTIONS: {
    cookieDomain: "none"
  },
  GOOGLE_ANALYTICS_ID: "UA-57828100-8",

  SLACK_CLIENT_ID: "32704674261.844084224612",

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,
  PERFORMANCE_UI_URL: "http://localhost:4000/performance",

  LOG_TAGS: [
    logging.REFLUX_ACTIONS,
    logging.SENTRY_EVENTS,
    logging.CURRENT_USER_STORE
  ],
  TREASURE_DATA_DEVELOPMENT: process.env.LOG_TREASURE_DATA
})

/**
 * SANDBOX SETTINGS extends DEVELOPMENT
 */
const sandbox = _.assign({}, development, {
  EXCHANGE_OAUTH_CLIENT_ID: "49557ef9-f8de-4af7-b925-a576693ae84e",

  GOOGLE_CLIENT_ID:
    "542265184555-ito2ljbi9kp97gnft094ob552t4sv3tg.apps.googleusercontent.com",
  GOOGLE_AUTH_CLIENT_ID:
    "52402875477-6et80bo55t9v2jc4r5j15lf0a8r06g3c.apps.googleusercontent.com",

  GOOGLE_ANALYTICS_OPTIONS: null,

  SLACK_CLIENT_ID: "32704674261.844084224612",

  ZUGATA_CLIENT_ID:
    "14632d28940f60b2cb6f308acbfd5c040295365b634576d8c574f6f1945ffbe4",

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  PERFORMANCE_UI_URL: "http://elk.development.cultureamp.net/performance",

  SENTRY_ENABLED: true
})

/**
 * TEST SETTINGS extends DEVELOPMENT
 */
const test = _.assign({}, development, {
  // Don't hit actual server!
  STEADYFOOT_URL: "http://steadyfoot_url/",
  BEACONSTAR_URL: "http://beaconstar_url/",
  TREASURE_DATA_DEVELOPMENT: true
})

/**
 * DEMO SETTINGS extends DEVELOPMENT
 */
const demo = _.assign({}, development, {
  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  IMPORT_CONTACTS_INTEGRATIONS: false
})

const dogfood = {
  ...demo,
  BEACONSTAR_URL: "https://zugata.zugata.me/"
}

/**
 * PRODUCTION SETTINGS
 */
const production = _.assign({}, CONSTANTS, {
  // TODO: Need to update after we create one for production
  EXCHANGE_OAUTH_CLIENT_ID: "",

  GOOGLE_ANALYTICS_ID: "UA-57828100-10",

  GMAIL_PLUGIN_LINK:
    "https://chrome.google.com/webstore/detail/zugata/" +
    CHROME_EXTENSION_ID +
    "?hl=en",

  GOOGLE_CLIENT_ID:
    "659653689069-td81n5ieiq09h8iajhrlop2669b1ncg7.apps.googleusercontent.com",
  GOOGLE_AUTH_CLIENT_ID:
    "52402875477-lphrl1kck5t5648o42gecosqomr6v961.apps.googleusercontent.com",

  SENTRY_ENABLED: true,

  SLACK_REDIRECT_URI:
    "https://identity.cultureamp.com/performance/profile?to=setup&redirect=true",
  SLACK_CLIENT_ID: "2891252727.582446413717",

  ZUGATA_CLIENT_ID:
    "6da3fb928c72ea8ff9d94475a86c1f63ae75e972a245430758b1d52b09899a30",

  INTERCOM_ID: "m6btd41m",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,
  PERFORMANCE_UI_URL: "https://cultureamp.cultureamp.com/performance",
  USE_SUBDOMAINS: true,

  TREASURE_DATA_HOST: "in.analytics.cultureamp.com",
  TREASURE_DATA_WRITE_KEY: "hbq3mkhC9MP7jkuCf6e9nhF2CYzrKpzdjCDK3MEy",

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID
})

/**
 * PRODUCTION-EU SETTINGS
 */
const productionEu = _.assign({}, production, {
  LOGO_PATH: "/images-2017/CA-logo.svg",
  SLACK_REDIRECT_URI:
    "https://identity.eu.cultureamp.com/performance/profile?to=setup&redirect=true",
  PERFORMANCE_UI_URL: "https://cultureamp.eu.cultureamp.com/performance",
  SENTRY_URL: "https://240a983a37e6470cba9a4184cbbab6c6@sentry.io/1851539"
})

// Use process.env to select the environment
let Settings = {
  development,
  test,
  sandbox,
  production,
  demo,
  dogfood,
  production_eu: productionEu // eslint-disable-line camelcase
  // @ts-ignore
}[process.env.NODE_ENV]

if (!Settings) {
  throw new Error(
    "no Settings found for node environment: " + process.env.NODE_ENV
  )
}

// Support settings overrides from env. Don't do this for the test env, though,
// because that is meant to run in a fixed environment.
if (process.env.NODE_ENV !== "test") {
  Settings = _.defaults(
    {
      BEACONSTAR_URL:
        process.env.BEACONSTAR_EXTERNAL_URL || CONSTANTS.BEACONSTAR_URL,
      STEADYFOOT_URL:
        process.env.STEADYFOOT_EXTERNAL_URL || CONSTANTS.STEADYFOOT_URL,
      // Comma separated string, which gets split into an array by webpack.config.js
      // For a list of possible log tags, see src/constants/logging.ts
      LOG_TAGS: process.env.LOG_TAGS,
      WHY_DID_YOU_RENDER_DEBUGGER: process.env.WHY_DID_YOU_RENDER_DEBUGGER,
      GOOGLE_ANALYTICS_ID: process.env.BEACONSTAR_GOOGLE_ANALYTICS_ID,
      GOOGLE_CLIENT_ID: process.env.BEACONSTAR_GOOGLE_CLIENT_ID,
      GOOGLE_AUTH_CLIENT_ID: process.env.BEACONSTAR_GOOGLE_AUTH_CLIENT_ID,
      SLACK_CLIENT_ID: process.env.BEACONSTAR_SLACK_CLIENT_ID,
      CULTUREAMP_JURISDICTION_DOMAIN:
        process.env.CULTUREAMP_JURISDICTION_DOMAIN,
      TREASURE_DATA_DEVELOPMENT: process.env.LOG_TREASURE_DATA
        ? process.env.LOG_TREASURE_DATA === "1"
        : CONSTANTS.TREASURE_DATA_DEVELOPMENT
    },
    Settings
  )
}

export default Settings
