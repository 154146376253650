import Reflux from "reflux-core"
import Actions from "../actions/index"

const ContactStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function() {
    this.listenTo(Actions.Contact.loadTop.completed, this.onLoadCompleted)
  },

  getInitialState: function() {
    return {}
  },

  onLoadCompleted: function(resp) {
    this.trigger(resp)
  }
})

export default ContactStore
