import _ from "lodash"
import moment from "moment"
import strings from "../locale/strings"
import { Department, UserRaw } from "../../types/User"
import FeatureFlags from "../constants/featureFlags"
import ConfigurationOptions from "../constants/configurationOptions"

export default class User {
  static getDisplayName(user: UserRaw) {
    return user.best_name || user.full_name || user.email
  }

  static getFirstName(user: UserRaw, { emailOk = true } = {}) {
    return (
      user.best_name ||
      (user.full_name && user.full_name.trim().split(" ")[0]) ||
      (emailOk ? user.email : null)
    )
  }

  static getLastName(user: UserRaw) {
    return (
      // @ts-ignore: I don't think we have a last_name field do we?
      user.last_name || (user.full_name && user.full_name.trim().split(" ")[1])
    )
  }

  static getFullName(user: UserRaw) {
    return user.full_name || user.email
  }

  // https://zugata.atlassian.net/browse/ZUG-8015
  // This should just be job_title_name after SF addresses the issue
  static getJobTitle(user: UserRaw) {
    return (
      user.job_title_name ||
      (typeof user.job_title === "string" ? user.job_title : null)
    )
  }

  static getDomain(user: UserRaw) {
    // @ts-ignore: Not sure if this is correct. I see `domains`, but not `domain`
    return user.company.domain || user.email.split("@")[1]
  }

  static isAdmin(user: UserRaw | null) {
    return Boolean(
      user && user.role_names && user.role_names.indexOf("admin") > -1
    )
  }

  static isHrbp(user: UserRaw | null) {
    return Boolean(
      user && user.hr_business_partners && user.hr_business_partners.length > 0
    )
  }

  static isHrisAdmin(user: UserRaw | null) {
    return Boolean(
      user && user.role_names && user.role_names.indexOf("hris_admin") > -1
    )
  }

  static isDeptManager(user: UserRaw) {
    return user.department_managers.length > 0
  }

  static managesDepartment(user: UserRaw, department: Department) {
    const { department_managers: departmentManagers } = user

    return (
      !!departmentManagers &&
      departmentManagers.some(
        departmentManager => departmentManager.department_id === department.id
      )
    )
  }

  static canImport(user: UserRaw) {
    return _.get(user.company, "import_integrations.length", 0) > 0
  }

  static equals(a: UserRaw, b: UserRaw) {
    return a.id === b.id
  }

  static isManagerOf(manager: UserRaw, user: UserRaw) {
    return user.manager_email && user.manager_email === manager.email
  }

  static getDisplayAccountType(user: UserRaw) {
    return User.isAdmin(user)
      ? strings.general.admin
      : User.isHrbp(user)
      ? strings.general.hrbp
      : User.isDeptManager(user)
      ? strings.general.deptManager
      : strings.general.employee
  }

  static isDeactivated(user: UserRaw) {
    return !!user.deleted_at || moment().isAfter(user.deactivated_at || "")
  }

  static hasFlag(user: UserRaw, flag: FeatureFlags) {
    return Boolean(user?.flags?.[flag])
  }

  static hasConfigOption(user: UserRaw, option: ConfigurationOptions) {
    return Boolean(user?.configuration_options?.includes(option))
  }
}
