import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Icon from "../Icon/Icon"
import "./SortableCell.less"

export default class SortableCell extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    sortState: PropTypes.oneOf(["asc", "desc", null]),
    disabled: PropTypes.bool,
    children: PropTypes.node,
    onSort: PropTypes.func
  }

  static defaultProps = {
    className: "",
    disabled: false,
    sortState: null
  }

  handleClick = () => {
    const { sortState, onSort } = this.props
    onSort(sortState === "asc" ? "desc" : "asc")
  }

  render() {
    const { className, disabled, sortState, children } = this.props

    const sortIndicatorClass = cx("SortableCell--sort-indicator", {
      "SortableCell--sort-indicator-active": !!sortState
    })

    return (
      <div
        className={`SortableCell ${className}`}
        onClick={!disabled ? this.handleClick : null}
      >
        <div className="SortableCell--inner layout horizontal center">
          {children}
          {!disabled && (
            <div className={sortIndicatorClass}>
              <Icon
                iconName={
                  sortState === "asc" ? "sortAscending" : "sortDescending"
                }
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
