export default {
  TIMELY: "timely",
  ACTIONABLE: "actionable",
  SPECIFIC: "specific"
}

const LIKE_REACTION = "like"

export function discardLikeReactions(reactions) {
  return reactions.filter(reaction => reaction.reaction_type !== LIKE_REACTION)
}

export function getLikeReaction(reactions) {
  return reactions.find(reaction => reaction.reaction_type === LIKE_REACTION)
}
