import _ from "lodash"
import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey
} from "./lib/apiActionHelpers"
import PerformanceReview from "../models/PerformanceReview"
import PerformanceCycle from "../models/PerformanceCycle"
import strings from "../locale/strings"
import { CycleSearchAgent, getCycles } from "./lib/cycleActionHelpers"
import { loadUserFilterOptions } from "./lib/userFilterHelpers"
import { collapseGroupTypes } from "./lib/teamHelpers"
import endpoints from "../api/endpoints.json"

const {
  GENERAL_URLS,
  PERF_REVIEWS_URLS,
  PERF_REVIEW_FILTER_URLS,
  SAVED_VIEW_URLS
} = endpoints
const { BASE_URL } = GENERAL_URLS
const {
  PERF_REVIEW_URL,
  PERF_ANSWERS_URL,
  PERF_BUCKET_URL,
  PERF_CYCLES_URL,
  PAST_REVIEWS_URL,
  SHARED_REVIEWS_URL,
  CREATE_COLLABORATOR_COMMENTS_URL,
  ADD_COLLABORATOR_URL
} = PERF_REVIEWS_URLS
const { SAVED_VIEW_URL } = SAVED_VIEW_URLS

const agent = SteadyfootAgent.defaultInstance

const searchAgent = new CycleSearchAgent({
  cycleItemType: PerformanceReview,
  cycleKey: "performance_cycles",
  cycleKeySingle: "performance_cycle",
  itemKey: "performance_reviews",
  itemKeySingle: "performance_review"
})

const PerformanceReviewActions = Reflux.createActions({
  getCycles: { asyncResult: true },
  getTeamReviews: { asyncResult: true },
  searchReviews: { asyncResult: true },
  pageSearchResults: { asyncResult: true },

  getReview: { asyncResult: true },
  updateReview: { asyncResult: true },
  localUpdateReview: { sync: true },
  clearReviews: {},

  updateAnswer: { asyncResult: true },
  notifyAnswerEditing: {},

  setNextReview: { sync: true },

  shareAllReviews: { asyncResult: true },
  addBucketAssignment: { asyncResult: true },
  getPastReviewsByUserId: { asyncResult: true },
  loadSharedReviews: { asyncResult: true },
  loadSharedReviewById: { asyncResult: true },

  shareReviewInCycle: { asyncResult: true },
  loadFilterOptions: { asyncResult: true },
  createComments: { asyncResult: true },
  getCollaboratorReview: { asyncResult: true },
  addCollaborators: { asyncResult: true },
  removeCollaborator: { asyncResult: true }
})

export function updateReview({
  review,
  perfReviewUrl,
  perfAnswersUrl,
  perfBucketUrl,
  loadingState
}) {
  // No API yet to update a review along with its answers and bucket assignment, so need multiple
  // calls
  const {
    id,
    performance_answers: answers,
    performance_bucket_in_cycle_id: bucketId,
    ...otherReviewProps
  } = review

  const isFlagChange = !!review.flag

  const answersPromise = !_.isEmpty(answers)
    ? agent.post(perfAnswersUrl).send({
        performance_review_id: id,

        // Filter out unanswered questions
        performance_answers: _.pick(answers, choice => choice !== null)
      })
    : null

  const bucketPromise = bucketId
    ? agent.post(perfBucketUrl).send({
        performance_bucket_assignment: {
          performance_bucket_in_cycle_id: bucketId,
          performance_review_id: id
        }
      })
    : null

  const reviewPromise = Promise.all(
    _.compact([answersPromise, bucketPromise])
  ).then(() =>
    !_.isEmpty(otherReviewProps)
      ? agent
          .put(`${perfReviewUrl}/${id}`)
          .send({ performance_review: otherReviewProps })
      : agent.get(`${perfReviewUrl}/${id}`)
  )

  return addLoadingState(
    loadingState,
    do {
      if (!isFlagChange) {
        addToasts(
          {
            defaultError: strings.performanceReviews.updateReview.defaultError,
            success: strings.performanceReviews.updateReview.success
          },
          extractResponseKey(
            "performance_review",
            PerformanceReview.of,
            reviewPromise
          )
        )
      } else {
        extractResponseKey(
          "performance_review",
          PerformanceReview.of,
          reviewPromise
        )
      }
    }
  )
}

export function getTeamReviews(
  {
    cycleId,
    query: { only_my_team = true, ...query } = {}, // eslint-disable-line
    loadingState = [true, { light: true }]
  },
  url
) {
  return addLoadingState(
    loadingState,
    extractResponseKey(
      "performance_reviews",
      PerformanceReview.of,
      agent.get(url).query({
        ...query,
        only_my_team,
        per_page: 1e9,
        performance_cycle_id: cycleId
      })
    )
  )
}

export function addBucketAssignment(
  { review, bucketId, revertOnFail = false },
  url
) {
  return addToasts(
    {},
    extractResponseKey(
      "performance_bucket_assignment",
      agent.post(url).send({
        performance_bucket_assignment: {
          performance_bucket_in_cycle_id: bucketId,
          performance_review_id: review.id
        }
      })
    )
  ).catch(() => Promise.reject({ review, bucketId, revert: revertOnFail }))
}

export function updateAnswer(
  { review, question, value, revertOnFail = false },
  url
) {
  return addToasts(
    {},
    extractResponseKey(
      "performance_answers",
      agent.post(`${url}`).send({
        performance_review_id: review.id,
        performance_answers: { [question.id]: value }
      })
    )
  ).then(
    () => ({ review, question }),
    () => Promise.reject({ review, question, revert: revertOnFail })
  )
}

PerformanceReviewActions.getCycles.listenAndPromise(
  ({ savedViewId = null } = {}) =>
    getCycles({
      baseUrl: savedViewId ? `${SAVED_VIEW_URL}/${savedViewId}` : BASE_URL,
      cycleKey: "performance_cycles",
      cycleType: PerformanceCycle
    })
)

PerformanceReviewActions.getTeamReviews.listenAndPromise(params =>
  getTeamReviews(params, PERF_REVIEW_URL)
)

function adaptSearchParams(searchParams) {
  const {
    performance_bucket_in_cycle_ids: bucketIds,
    department_ids: departmentIds,
    manager_ids: managerIds,
    job_title_ids: jobTitleIds,
    user_ids: userIds,
    group_types: groupTypes,
    status,
    levels,
    ...params
  } = searchParams

  return {
    ...params,
    performance_bucket_in_cycle_ids: bucketIds && bucketIds.join(","),
    status: Array.isArray(status) ? status.join(",") : status,
    department_ids: departmentIds && departmentIds.join(","),
    manager_ids: managerIds && managerIds.join(","),
    job_title_ids: jobTitleIds && jobTitleIds.join(","),
    levels: levels && levels.join(","),
    user_ids: userIds && userIds.join(","),
    group_type: collapseGroupTypes(groupTypes)
  }
}

PerformanceReviewActions.searchReviews.listenAndPromise(
  ({ cycleId, savedViewId, searchParams }) =>
    addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        cycleId,
        searchParams: adaptSearchParams(searchParams),
        ...do {
          if (savedViewId) {
            ;({
              fullResourcePath: `${SAVED_VIEW_URL}/${savedViewId}/performance_reviews`
            })
          }
        }
      })
    )
)

PerformanceReviewActions.pageSearchResults.listenAndPromise(
  ({ cycleId, savedViewId, page, searchParams }) =>
    addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        cycleId,
        page,
        searchParams: adaptSearchParams(searchParams),
        ...do {
          if (savedViewId) {
            ;({
              fullResourcePath: `${SAVED_VIEW_URL}/${savedViewId}/performance_reviews`
            })
          }
        }
      })
    )
)

PerformanceReviewActions.addBucketAssignment.listenAndPromise(params =>
  addBucketAssignment(params, PERF_BUCKET_URL)
)

PerformanceReviewActions.updateAnswer.listenAndPromise(params =>
  updateAnswer(params, PERF_ANSWERS_URL)
)

PerformanceReviewActions.getReview.listenAndPromise(
  ({ cycleId, reviewId, savedViewId, loadingState = true } = {}) =>
    addLoadingState(
      loadingState,
      do {
        if (savedViewId) {
          extractResponseKey(
            "performance_review",
            PerformanceReview.of,
            agent.get(
              `${SAVED_VIEW_URL}/${savedViewId}/performance_reviews/${reviewId}`
            )
          )
        } else {
          searchAgent.get({ cycleId, itemId: reviewId })
        }
      }
    )
)

PerformanceReviewActions.updateReview.listenAndPromise(
  (review, { loadingState } = {}) =>
    updateReview({
      review,
      perfReviewUrl: PERF_REVIEW_URL,
      perfAnswersUrl: PERF_ANSWERS_URL,
      perfBucketUrl: PERF_BUCKET_URL,
      loadingState
    })
)

PerformanceReviewActions.shareReviewInCycle.listenAndPromise(
  ({ id }, cycleId) =>
    addLoadingState(
      true,
      addToasts(
        {
          success: strings.performanceReviews.toasts.evaluationShared,
          defaultError: strings.performanceReviews.toasts.errorSharing
        },
        agent
          .post(`${PERF_CYCLES_URL}/${cycleId}/performance_reviews/share`)
          .query({
            id: id
          })
      )
    ).then(() => {
      // SF doesn't return the updated review after sharing
      return extractResponseKey(
        "performance_review",
        PerformanceReview.of,
        agent.get(`${PERF_REVIEW_URL}/${id}`)
      )
    })
)

PerformanceReviewActions.shareAllReviews.listenAndPromise(
  ({
    cycleId,
    filters: {
      departmentIds = null,
      groupTypes = null,
      jobTitleIds = null,
      managerIds = null,
      userIds = null,
      bucketId = null
    }
  }) =>
    addToasts(
      {
        defaultError: strings.performanceReviews.toasts.errorSharingAll,
        success: strings.performanceReviews.toasts.sharedAll
      },
      addLoadingState(
        true,
        agent
          .post(`${PERF_CYCLES_URL}/${cycleId}/performance_reviews/share`)
          .query({
            department_ids: departmentIds ? departmentIds.join(",") : undefined,
            manager_ids: managerIds ? managerIds.join(",") : undefined,
            job_title_ids: jobTitleIds ? jobTitleIds.join(",") : undefined,
            group_types: collapseGroupTypes(groupTypes),
            [typeof bucketId === "string"
              ? "status"
              : "performance_bucket_in_cycle_id"]: bucketId,
            user_ids: userIds ? userIds.join(",") : undefined
          })
      )
    )
)

PerformanceReviewActions.getPastReviewsByUserId.listenAndPromise(
  ({ userId, savedViewId = null }) =>
    addToasts(
      { defaultError: strings.performanceReviews.toasts.errorLoading },
      extractResponseKey(
        "performance_reviews",
        PerformanceReview.of,
        agent
          .get(
            savedViewId
              ? `${SAVED_VIEW_URL}/${savedViewId}/performance_reviews/past`
              : PAST_REVIEWS_URL
          )
          .query({ user_id: userId })
      ).then(results => ({
        results,
        clientMeta: { userId }
      }))
    )
)

PerformanceReviewActions.loadSharedReviews.listenAndPromise(() =>
  addLoadingState(true, extractResponseBody(agent.get(SHARED_REVIEWS_URL)))
)

PerformanceReviewActions.loadSharedReviewById.listenAndPromise(id =>
  extractResponseKey(
    "shared_performance_review",
    agent.get(`${SHARED_REVIEWS_URL}/${id}`)
  )
)

/**
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} cycleIds - performance cycle IDs
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
PerformanceReviewActions.loadFilterOptions.listenAndPromise(
  ({ userFilterType, cycleIds, currentFilters, query, ids, page = 1 }) =>
    loadUserFilterOptions({
      urls: PERF_REVIEW_FILTER_URLS,
      userFilterType,
      cycleIds,
      cycleParamName: "performance_cycle_ids",
      currentFilters,
      query,
      ids,
      page
    })
)

PerformanceReviewActions.createComments.listenAndPromise(comments =>
  addLoadingState(
    true,
    addToasts(
      {
        success: strings.performanceReviews.toasts.commentsShared,
        defaultError: strings.performanceReviews.toasts.commentsErrorSharing
      },
      agent.post(CREATE_COLLABORATOR_COMMENTS_URL).send({ comments: comments })
    )
  )
)

PerformanceReviewActions.getCollaboratorReview.listenAndPromise(reviewId =>
  extractResponseKey(
    "performance_review",
    PerformanceReview.of,
    agent.get(`${PERF_REVIEW_URL}/${reviewId}`)
  )
)

PerformanceReviewActions.addCollaborators.listenAndPromise(
  ({ collaborators, performanceReviewId, notes }) =>
    addLoadingState(
      true,
      agent.post(`${ADD_COLLABORATOR_URL}`).send({
        access_permission: {
          user_ids: collaborators,
          source_id: performanceReviewId,
          source_type: "performance_review",
          permission: "all",
          notes
        }
      })
    )
)

PerformanceReviewActions.removeCollaborator.listenAndPromise(
  ({ permissionId }) =>
    addLoadingState(true, agent.del(`${ADD_COLLABORATOR_URL}/${permissionId}`))
)

export default PerformanceReviewActions
