import _ from "lodash"

export default {
  init({ actions: { list: listAction, load: loadAction } }) {
    this.data = {
      savedViewsByType: {},
      savedViewsById: {}
    }

    this.listenTo(listAction.completed, ({ savedViews, type }) => {
      this.data = {
        ...this.data,
        savedViewsByType: {
          ...this.data.savedViewsByType,
          [type]: savedViews
        },
        savedViewsById: {
          ...this.data.savedViewsById,
          ..._.indexBy(savedViews, "id")
        }
      }

      this.trigger(this.data)
    })

    this.listenTo(loadAction.completed, savedView => {
      this.addOrReplaceSavedView(savedView)
      this.trigger(this.data)
    })
  },

  getInitialState() {
    return this.data
  },

  addOrReplaceSavedView(savedView) {
    const { savedViewsByType, savedViewsById } = this.data
    const { id, type } = savedView
    const savedViewsOfType = savedViewsByType[type]

    this.data = {
      ...this.data,
      savedViewsByType: {
        ...savedViewsByType,
        [type]:
          savedViewsOfType &&
          savedViewsOfType.map(s => (s.id === savedView.id ? savedView : s))
      },
      savedViewsById: {
        ...savedViewsById,
        [id]: savedView
      }
    }
  }
}
