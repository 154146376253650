import * as React from "react"
import { hot } from "react-hot-loader/root"
import { IndexRoute, Route } from "react-router"
// @ts-ignore
import Authenticate from "../../pages/lib/Authenticate"
import { SurveyResponsesProvider } from "../../../state/SurveyState"
import IncompleteTBFPage from "../../pages/IncompleteTBFPage/IncompleteTBFPage"
import TeamFeedbackRequest from "../../pages/TeamFeedbackRequest/TeamFeedbackRequest"

type SurveysResponsesRoutes = React.FunctionComponent

const SurveysResponsesRoutes: SurveysResponsesRoutes = ({ children }) => (
  <SurveyResponsesProvider>{children}</SurveyResponsesProvider>
)

export const routes = (
  <Route
    path="team_based_feedback"
    component={hot(SurveysResponsesRoutes)}
    onEnter={Authenticate({
      // @ts-ignore
      predicate: user => user.company.allow_team_based_feedback
    })}
  >
    <IndexRoute component={hot(IncompleteTBFPage)} />
    <Route
      path="team_based_feedback/:teamId"
      component={TeamFeedbackRequest}
      /*onEnter={Authenticate({
        predicate: user => user.is_a_manager
      })} - TODO - add team lead authentication*/
    />
  </Route>
)
