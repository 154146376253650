import "./TabContent.less"
import * as React from "react"
import * as ReactIntl from "react-intl"
import { FormattedMessage } from "react-intl"

export type Props = {
  content: React.ReactNode
  iconTabStyle?: boolean
  icon?: string
  id: string
  name: ReactIntl.FormattedMessage.MessageDescriptor
}

const TabContent = (props: Props) => (
  <section key={props.id} className={"Tabs--content cf"}>
    {props?.iconTabStyle && (
      <h2 className="Tabs--content-header">
        <FormattedMessage {...props.name} />
      </h2>
    )}
    {props.content}
  </section>
)

export default TabContent
