import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions/index"

const ReviewStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init() {
    this.data = {
      feedbackTemplates: [],
      review: null,
      reviewee: null,
      reviewLoaded: false,
      reviewSkipped: false,
      queue: []
    }

    this.listenTo(Actions.Review.load.completed, this.onLoadCompleted)
    this.listenTo(Actions.Review.load.failed, this.onLoadFailed)
    this.listenTo(Actions.Review.confirmSkill, this.rateNextSkill.bind(this, 1))
    this.listenTo(Actions.Review.denySkill, this.rateNextSkill.bind(this, -1))
    this.listenTo(Actions.Review.skipSkill, this.rateNextSkill.bind(this, 0))
    this.listenTo(
      Actions.Review.loadQueue.completed,
      this.onLoadQueue.bind(this)
    )
    this.listenTo(
      Actions.Review.acceptInvite.completed,
      this.acceptInviteCompleted
    )

    // TODO put this in Actions using promise.then(...)
    this.listenTo(Actions.Review.complete.completed, review => {
      this.data = {
        ...this.data,
        review
      }

      this.removeUserFromQueue(review.reviewee)
      this.trigger(this.data)
    })

    this.listenTo(Actions.Review.skipAll.completed, review => {
      this.data = {
        ...this.data,
        review,
        reviewSkipped: true
      }

      this.removeUserFromQueue(review.reviewee)
      this.trigger(this.data)
    })

    this.listenTo(Actions.Review.loadRevieweeByEmail.completed, reviewee => {
      this.data = {
        ...this.data,
        reviewee
      }

      this.trigger(this.data)
    })

    this.listenTo(
      Actions.Review.getFeedbackTemplates.completed,
      feedbackTemplates => {
        this.data = {
          ...this.data,
          feedbackTemplates
        }

        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Review.setReview, review => {
      this.data = {
        ...this.data,
        review,
        reviewLoaded: true
      }

      this.trigger(this.data)
    })
  },

  getInitialState() {
    return this.data
  },

  onLoadFailed() {
    this.data = {
      ...this.data,
      review: null,
      reviewLoaded: true,
      reviewSkipped: false
    }

    this.trigger(this.data)
  },

  onLoadCompleted(review) {
    this.data = {
      ...this.data,
      review,
      feedbackTemplates: [],
      reviewLoaded: true,
      reviewSkipped: false
    }

    this.trigger(this.data)
  },

  onLoadQueue(queue) {
    this.data = {
      ...this.data,
      queue
    }

    this.trigger(this.data)
  },

  rateNextSkill: function(value) {
    const { review } = this.data

    if (!review) {
      return
    }

    const nextRating = _.find(review.ratings, r => !r.rating && r.rating !== 0)

    this.data = {
      ...this.data,
      review: {
        ...review,
        ratings: review.ratings.map(r =>
          r === nextRating ? { ...nextRating, rating: value } : r
        )
      }
    }

    this.trigger(this.data)
  },

  removeUserFromQueue(user) {
    this.data = {
      ...this.data,
      queue: this.data.queue.filter(queueItem => queueItem.user.id !== user.id)
    }
  },

  acceptInviteCompleted(reviewInvitation) {
    const { review } = reviewInvitation
    if (
      !reviewInvitation.completed_at &&
      reviewInvitation.review_status !== "skipped"
    ) {
      this.onLoadCompleted(review)
    }
  }
})

export default ReviewStore
