const NON_SPACE_REGEX = /\S/
const EMAIL_DOMAIN_REGEX = /@(.+\.[^.]{2,})$/

export function isNonBlank(s) {
  return s && NON_SPACE_REGEX.test(s)
}

export function getDomainFromEmail(email) {
  return (EMAIL_DOMAIN_REGEX.exec(email) || [])[1]
}

export function isEmail(email) {
  return EMAIL_DOMAIN_REGEX.test(email)
}

export const checkIfIncludesLongWord = (str, maxWordLength) =>
  Boolean(str.split(/\s+/).find(item => item.length > maxWordLength))
