import PropTypes from "prop-types"

export const UserByEmailType = PropTypes.shape({
  full_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
})

export const UserByIdType = PropTypes.shape({
  full_name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
})
