// Automation IDs, used to identify dom elements via the cypress e2e tests.
// Abbreviated because of how common these ids are to be used.
// To be synced with:
//   performance-test-services/cypress/constants/automationId.ts
//   performance-ui/src/constants/automationId.ts
enum Aid {
  addQuestionButton = "add-question-button",
  confirmationModal = "confirmation-modal",
  alignGoalSearchFieldInput = "align-goal-search",
  cancelIndividualGoalButton = "cancel-individual-goal-button",
  createIndividualGoalButton = "create-individual-goal-button",
  cycleCreationEndDate = "cycle-creation-end-date",
  cycleCreationName = "cycle-creation-name",
  cycleCreationStartDate = "cycle-creation-start-date",
  cycleCreationTimezone = "cycle-creation-timezone",
  cycleStepPreviewQuestions = "cycle-step-preview-questions",
  cycleStepManageQuestions = "cycle-step-manage-questions",
  editableAnswerChoice = "editable-answer-choice",
  employeeEvaluationCycleItem = "employee-evaluation-cycle-item",
  goalNameFieldInput = "goal-name",
  goalDatePickerFieldInput = "goal-date-picker",
  goalDescriptionFieldInput = "goal-description",
  inputClearButton = "input-clear-button",
  inputFeedback = "input-feedback",
  loader = "loader",
  modal = "modal",
  moreMenu = "more-menu",
  multiStepModalExitButton = "multi-step-modal-exit-button",
  multiStepModalNextButton = "multi-step-modal-next-button",
  multiStepModalPreviousButton = "multi-step-modal-previous-button",
  notificationsList = "notifications-list",
  notificationsListLegacy = "notifications-list-legacy", // The old notifications list that relies on reflux
  provideTeamBasedFeedbackTask = "provide-team-based-feedback-task",
  questionClearButton = "question-clear-button",
  questionDescription = "question-description",
  questionTitle = "question-title",
  requestTeamBasedFeedback = "request-team-based-feedback",
  selfReflectionCreateCycleButton = "self-reflection-create-cycle-button",
  selfReflectionCreationModal = "self-reflection-creation-modal",
  selfReflectionCycleNameAndDateModal = "self-reflection-cycle-name-and-date-modal",
  selfReflectionCycleQuestionsModal = "self-reflection-cycle-questions-modal",
  selfReflectionCycleManageUsersModal = "self-reflection-cycle-manage-users-modal",
  selfReflectionEndCycleModal = "self-reflection-end-cycle-modal",
  selfReflectionCyclesTable = "self-reflection-cycles-table",
  selfReflectionEmptyState = "self-reflection-empty-state",
  selfReflectionEmptyStateCta = "self-reflection-empty-state-cta",
  selfReflectionCycleActionMenu = "self-reflection-cycle-action-menu",
  selfReflectionCycleItem = "self-reflection-cycle-item",
  sharedQuestionListItem = "shared-question-list-item",
  shareQuestionCheckbox = "share-question-checkbox",
  shareFeedbackCheckbox = "share-feedback-checkbox",
  submitFeedbackForm = "submit-feedback-form",
  surveyQuestionsManager = "survey-questions-manager",
  surveyQuestion = "survey-question",
  teamNameInput = "team-name",
  teamDescriptionInput = "team-description",
  teamsListCreateNewTeam = "teams-list-create-new-team",
  teamLeadsSearchInput = "team-leads-search",
  teamMembersSearchInput = "team-members-search",
  teamFormCreateNewTeamButton = "team-form-create-new-team-button",
  toggleSharing = "toggle-sharing",
  userItem = "user-item",
  userProfileCell = "user-profile-cell",
  userSelector = "us-user-selection",
  userSelectorName = "us-user-selection-name",
  viewTeamButton = "view-team-button",
  teamBasedFeedbackTypeAhead = "tbf-reviewers-selector",
  teamBasedFeedbackSendRequestButton = "tbf-send-request-button",
  teamBasedFeedbackCancelRequestButton = "tbf-cancel-request-button",
  teamBasedFeedbackAvatar = "tbf-avatar",
  teamBasedFeedbackReviewee = "tbf-reviewee-name",
  teamBasedFeedbackQuickActionButton = "quick-button-action",
  // notification modals
  feedbackModal = "feedback-modal",
  completedPeerFeedbackModal = "completed-peer-feedback-modal"
}

export default Aid
