import Reflux from "../../actions/ActionsInitializer"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey
} from "../../actions/lib/apiActionHelpers"
import SurveyTemplate from "../../models/SurveyTemplate"
import strings from "../../locale/strings"
import { ADMIN_TEMPLATES_URLS } from "../../api/endpoints.json"

const { ADMIN_TEMPLATES_URL, ADMIN_POLICIES_URL } = ADMIN_TEMPLATES_URLS
const agent = SteadyfootAgent.defaultInstance

const AdminTemplateActions = Reflux.createActions({
  getPolicies: { asyncResult: true },
  getTemplates: { asyncResult: true },
  createMRFTemplate: { asyncResult: true },
  updateMRFTemplate: { asyncResult: true },
  deleteTemplate: { asyncResult: true }
})

AdminTemplateActions.deleteTemplate.listenAndPromise(({ templateId }) =>
  addLoadingState(
    [true, { light: false }],
    addToasts(
      {
        success:
          strings.admin.managerRequestedFeedback.settings.toasts.templateDeleted
      },
      extractResponseKey(
        "template",
        agent.del(`${ADMIN_TEMPLATES_URL}/${templateId}`)
      )
    )
  )
)

AdminTemplateActions.createMRFTemplate.listenAndPromise(
  ({ policyId, questions }) => {
    const template = SurveyTemplate.transformToBackendTemplate({
      name: "MRF Template",
      locale: "en",
      user_policies_templates_attributes: [
        {
          user_policy_id: policyId
        }
      ],
      questions
    })

    return addLoadingState(
      [true, { light: false }],
      addToasts(
        {
          success:
            strings.admin.managerRequestedFeedback.settings.toasts
              .templateCreated
        },
        extractResponseKey(
          "template",
          agent.post(ADMIN_TEMPLATES_URL).send({ template })
        )
      )
    )
  }
)

AdminTemplateActions.updateMRFTemplate.listenAndPromise(
  ({ templateId, questions }) =>
    addLoadingState(
      [true, { light: false }],
      addToasts(
        {
          success:
            strings.admin.managerRequestedFeedback.settings.toasts
              .templateUpdated
        },
        extractResponseKey(
          "template",
          agent
            .put(`${ADMIN_TEMPLATES_URL}/${templateId}`)
            .send(SurveyTemplate.transformToBackendTemplate({ questions }))
        )
      )
    )
)

AdminTemplateActions.getPolicies.listenAndPromise(() =>
  addLoadingState(
    [true, { light: true }],
    extractResponseKey("user_policies", agent.get(ADMIN_POLICIES_URL))
  )
)

AdminTemplateActions.getTemplates.listenAndPromise(() =>
  addLoadingState(
    [true, { light: true }],
    extractResponseKey(
      "templates",
      agent.get(ADMIN_TEMPLATES_URL).query({
        default_for: "MRF"
      })
    )
  )
)

export default AdminTemplateActions
