import Reflux from "reflux-core"
import Actions from "../actions"

export default Reflux.createStore({
  init() {
    this.initializeData()
    this.listenTo(Actions.UI.setLoadingState, this.onSetLoadingState)
  },

  initializeData() {
    this.data = {
      loadCount: 0
    }
  },

  getInitialState() {
    return this.data
  },

  onSetLoadingState(loading) {
    const loadDelta = loading ? 1 : -1
    this.data.loadCount + loadDelta

    this.trigger(this.data)
  }
})
