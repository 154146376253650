import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import PastFeedbackItem from "../PastFeedbackItem/PastFeedbackItem"
import User from "../../../models/User"
import Select from "../../elements/Select/Select"
import Button from "../../elements/Button/Button"
import FeedbackModal from "../FeedbackModal/FeedbackModal"
import SharedFeedbackModal from "../SharedFeedbackModal/SharedFeedbackModal"
import CompletedPeerFeedbackModal from "../CompletedPeerFeedbackModal/CompletedPeerFeedbackModal"
import CompletedSelfReviewModal from "../CompletedSelfReviewModal/CompletedSelfReviewModal"
import Actions from "../../../actions"
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll"
import strings from "../../../locale/strings"
import "./PastFeedback.less"

export default class PastFeedback extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    reviewee: PropTypes.shape({
      email: PropTypes.string.isRequired,
      manager_email: PropTypes.string,
      best_name: PropTypes.string,
      full_name: PropTypes.string
    }).isRequired,
    reviews: PropTypes.array,
    selfReflectionsOnly: PropTypes.bool,
    optionFilters: PropTypes.array,
    onFilterChange: PropTypes.func,
    selectedFeedbackFilter: PropTypes.string,
    emptyState: PropTypes.node,
    hideManagerHeader: PropTypes.bool,
    onNextPageNeeded: PropTypes.func,
    hasMore: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool
  }

  static defaultProps = {
    onNextPageNeeded: _.noop,
    isAdmin: false
  }

  constructor(props) {
    super(props)

    this.state = {
      feedbackId: null,
      peerFeedbackId: null,
      selfReflectionId: null,
      reviewShareId: null,
      hasMRF: false
    }
  }
  componentWillMount() {
    const { user } = this.context
    const {
      reviewee: { email: revieweeEmail },
      isAdmin
    } = this.props
    Actions.Feedback.loadingMRF({
      userId: user.id,
      revieweeEmail,
      type: "manager_requested_feedbacks",
      isAdmin: isAdmin
    }).then(manager_requested_feedbacks => {
      this.setState({
        hasMRF: manager_requested_feedbacks
          ? manager_requested_feedbacks.length > 0
          : false
      })
    })
  }

  handleClickExportFeedback = () => {
    const { reviewee, selectedFeedbackFilter, isAdmin } = this.props
    const { router } = this.context

    // Note: in this context, "isAdmin" relates to if we're in the admin section of the app. This prop is unrelated to User.isAdmin().
    // Whilst a user can change this url param manually, it will not authenticate on the backend if they don't have the admin role.
    // To clarify this, from here onwards the variable is used as onAdminDashboard, passing "true" or "false" as a string.
    window.open(
      `${
        router.location.basename
      }/printer_friendly_peer_review?email=${encodeURIComponent(
        reviewee.email
      )}&feedbackType=${encodeURIComponent(
        selectedFeedbackFilter
      )}&onAdminDashboard=${isAdmin}`,
      "_blank"
    )
  }

  renderSurveyResponseMessageText(peerReview) {
    if (peerReview.quick_comment) {
      return null
    }

    if (peerReview.kind === "TBF") {
      return <FormattedMessage {...strings.pastFeedback.teamBasedFeedback} />
    }

    return (
      <FormattedMessage {...strings.pastFeedback.managerRequestedFeedback} />
    )
  }

  renderFeedbackItem(review) {
    const { selfReflectionsOnly } = this.props
    const { type } = review.source_obj

    switch (type) {
      case "praise_review":
      case "review": {
        const reviewObject = review.source_obj.review
        return (
          <PastFeedbackItem
            key={`Review-${review.id}`}
            review={reviewObject}
            onClick={() => this.setState({ feedbackId: reviewObject.id })}
            type={type}
            typeText={
              reviewObject.type === "praise_review" && (
                <FormattedMessage {...strings.praise.title} />
              )
            }
          />
        )
      }
      case "review_share": {
        const reviewShare = review.source_obj.review_share
        return (
          <PastFeedbackItem
            key={`Review-share-${review.id}`}
            review={reviewShare.review}
            onClick={() => this.setState({ reviewShareId: reviewShare.id })}
            type={type}
            typeText={<FormattedMessage {...strings.general.sharedFeedback} />}
          />
        )
      }
      case "survey_response": {
        const peerReview = review.source_obj[type]
        return (
          <PastFeedbackItem
            key={`Peer-feedback-${review.id}`}
            review={peerReview}
            answers={
              peerReview.peer_feedback_answers /* NB: only applies to non-survey (old) MRF */
            }
            onClick={() => {
              this.setState({
                peerFeedbackId: peerReview.id
              })
            }}
            type={type}
            typeText={this.renderSurveyResponseMessageText(peerReview)}
            sharedMRF={peerReview.quick_comment}
          />
        )
      }
      case "self_reflection": {
        const selfReview = review.source_obj.self_reflection
        return (
          <PastFeedbackItem
            key={`Self-reflection-${review.id}`}
            review={selfReview}
            answers={selfReview.self_reflection_answers}
            onClick={() => this.setState({ selfReflectionId: selfReview.id })}
            type={type}
            typeText={
              !selfReflectionsOnly && (
                <FormattedMessage {...strings.general.selfReflection} />
              )
            }
          />
        )
      }
      default:
        return
    }
  }

  render() {
    const {
      reviews,
      selfReflectionsOnly,
      optionFilters,
      onFilterChange,
      selectedFeedbackFilter,
      emptyState,
      hideManagerHeader,
      hasMore,
      onNextPageNeeded
    } = this.props
    const { reviewShareId, selfReflectionId, hasMRF } = this.state
    const { user } = this.context

    return (
      <InfiniteScroll
        className="PastFeedback"
        items={reviews}
        hasMore={hasMore}
        onNextPageNeeded={onNextPageNeeded}
      >
        {(User.isAdmin(user) || User.isHrbp(user) || user.is_a_manager) &&
          !selfReflectionsOnly &&
          !hideManagerHeader && (
            <div className="PastFeedback--manager-header layout horizontal center justified">
              {optionFilters && (
                <Select
                  className="PastFeedback--filter"
                  options={optionFilters}
                  value={selectedFeedbackFilter}
                  onChange={option => onFilterChange(option.value)}
                />
              )}
              {hasMRF && (
                <Button
                  className="PastFeedback--export-link"
                  actionType="secondary"
                  onClick={this.handleClickExportFeedback}
                >
                  <FormattedMessage {...strings.general.exportPeerFeedback} />
                </Button>
              )}
            </div>
          )}
        {reviews
          ? reviews.length > 0
            ? reviews.map(review => this.renderFeedbackItem(review))
            : emptyState
          : null}

        <FeedbackModal
          reviewId={this.state.feedbackId}
          onClose={() => this.setState({ feedbackId: null })}
        />
        <SharedFeedbackModal
          shareId={reviewShareId}
          onClose={() => this.setState({ reviewShareId: null })}
        />
        <CompletedSelfReviewModal
          opened={!!selfReflectionId}
          loadReview={() =>
            Actions.SelfReview.loadReviewById({ id: selfReflectionId })
          }
          modalKey={selfReflectionId}
          readOnly={true}
          onClose={() => this.setState({ selfReflectionId: null })}
        />
        <CompletedPeerFeedbackModal
          reviewId={this.state.peerFeedbackId}
          onClose={() => this.setState({ peerFeedbackId: null })}
        />
      </InfiniteScroll>
    )
  }
}
