import { useCallback } from "react"
import useDataAPI from "./useDataAPI"

const useDeleteTeam = (teamId: string) => {
  const deleteAPI = useDataAPI(`/teams/${teamId}`, "DELETE")

  const deleteGoal = useCallback(() => {
    deleteAPI.fire()
  }, [deleteAPI])

  return {
    deleteGoal,
    loading: deleteAPI.loading,
    success: !!deleteAPI.data && !deleteAPI.error
  }
}

export default useDeleteTeam
