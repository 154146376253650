import classnames from "classnames"
import * as React from "react"
import { Label } from "@kaizen/component-library/draft"
import styles from "./RadioGroupRow.scss"

export type RadioGroupProps = {
  automationId?: string
  labelText: string | React.ReactNode
  noBottomMargin?: boolean
}

type RadioGroup = React.FunctionComponent<RadioGroupProps>

const RadioGroupRow: RadioGroup = ({
  automationId = "",
  children,
  labelText = "",
  noBottomMargin = false
}) => (
  <span
    data-automation-id={automationId}
    className={classnames(styles.radioGroupContainer, {
      [styles.noBottomMargin]: noBottomMargin
    })}
  >
    <div className={styles.radioGroupLabel}>
      <Label
        automationId={`${automationId}-field-label`}
        labelText={labelText}
        labelType="text"
      />
    </div>
    {children}
  </span>
)

export default RadioGroupRow
