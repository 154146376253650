import axios from "axios"
import { useReducer, useCallback } from "react"

type DataFetchState = {
  loading: boolean
  error?: Error
  data?: JSON
}

type DataFetchAction = SuccessAction | ErrorAction | FetchAction

type FetchAction = {
  type: "FETCH"
}

type SuccessAction = {
  type: "SUCCESS"
  payload: JSON
}

type ErrorAction = {
  type: "ERROR"
  payload: Error
}

type DataAPIMethod = "POST" | "GET" | "PUT" | "DELETE"

const INITIAL_STATE: DataFetchState = {
  loading: false
}

const dataFetchReducer = (
  state: DataFetchState,
  action: DataFetchAction
): DataFetchState => {
  switch (action.type) {
    case "FETCH":
      return {
        ...INITIAL_STATE,
        data: undefined,
        error: undefined,
        loading: true
      }
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        data: undefined,
        loading: false
      }
    case "SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: undefined,
        loading: false
      }
  }
}

axios.defaults.baseURL = "/performance/api/v1/"

const httpMethods = ["POST", "PUT", "PATCH"]

const useDataAPI = (url: string, method: DataAPIMethod) => {
  const [state, dispatch] = useReducer(dataFetchReducer, INITIAL_STATE)

  const fire = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data?: any) => {
      dispatch({ type: "FETCH" })

      try {
        const result = await axios({
          method,
          url,
          data: httpMethods.includes(method) ? data : undefined,
          params: method === "GET" ? data : undefined
        })
        dispatch({ type: "SUCCESS", payload: result.data })

        return result
      } catch (error) {
        dispatch({ type: "ERROR", payload: error })
        throw error
      }
    },
    [method, url]
  )

  return { ...state, fire }
}

export default useDataAPI
