import { JsonDecoder } from "ts.data.json"
import { GoalType } from "../../types/Goals"
import { goalTypeDecoder } from "../BaseGoalDecoder/baseGoalDecoder"
const { array, object, string, number, failover } = JsonDecoder

export interface AlignableGoal {
  id: number
  name: string
  description?: string
  type: GoalType
}

export const alignableGoalsDecoder = object(
  {
    goals: array(
      object<{
        id: number
        name: string
        team_name?: string
        type: GoalType
        department_name?: string
      }>(
        {
          id: number,
          name: string,
          team_name: failover(undefined, string),
          type: goalTypeDecoder,
          department_name: failover(undefined, string)
        },
        "alignableGoal",
        {
          type: "goal_type"
        }
      ),
      "alignableGoals"
    )
  },
  "alignableGoalsResponse"
).map<AlignableGoal[]>(({ goals }) =>
  goals.map(goal => ({
    ...goal,
    description:
      goal.type === "department" ? goal.department_name : goal.team_name
  }))
)
