import "./RepositionableItemNumber.less"
import React from "react"
import cx from "classnames"

type Props = {
  className?: string
  itemIndex: number
  itemCount: number
  onMove: (delta: number) => void
}

export default class RepositionableItemNumber extends React.Component<Props> {
  static defaultProps = {
    className: ""
  }

  renderArrow({
    delta,
    icon,
    isHidden
  }: {
    delta: number
    icon: React.ReactNode
    isHidden: boolean
  }) {
    const { onMove } = this.props

    return (
      <div
        onClick={() => onMove(delta)}
        className={cx("RepositionableItemNumber--move material-icons", {
          "RepositionableItemNumber--move-hidden": isHidden
        })}
      >
        {icon}
      </div>
    )
  }

  render() {
    const { className, itemIndex, itemCount } = this.props

    return (
      <div
        className={`RepositionableItemNumber layout vertical center-center ${className}`}
      >
        {this.renderArrow({
          delta: -1,
          icon: "keyboard_arrow_up",
          isHidden: itemIndex < 1
        })}
        <div>{itemIndex + 1}.</div>
        {this.renderArrow({
          delta: 1,
          icon: "keyboard_arrow_down",
          isHidden: itemIndex >= itemCount - 1
        })}
      </div>
    )
  }
}
