import { Text } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { TeamMember } from "../../../state/TeamsState"
import Avatar from "../../Avatar/Avatar"
import styles from "./TeamMemberCard.scss"
import strings from "../../../locale/strings"

/**
 * Returns Team member card
 *
 * @param {*} props {
 *   lead,
 *   teamMember,
 * }
 * @returns {TeamMemberCard} component
 */

type TeamMemberCardType = React.FunctionComponent<{
  lead?: boolean
  teamMember: TeamMember
  intl: InjectedIntl
}>

const TeamMemberCard: TeamMemberCardType = ({
  lead = false,
  teamMember,
  intl
}) => {
  const { formatMessage } = intl
  return (
    <section className={styles.card}>
      {lead && (
        <div className={styles.pill} data-testid={"lead-pill"}>
          <Text tag="div" style="small-bold" inheritBaseline inline>
            {formatMessage(strings.teamGoals.teamLead)}
          </Text>
        </div>
      )}
      <div className={styles.avatar}>
        <Avatar size="large" imageURL={teamMember.profileImage} />
      </div>
      <div>
        <Text tag="h1" style="zen-heading-3" inline inheritBaseline>
          {teamMember.name}
        </Text>
      </div>
      <div>
        <Text tag="h2" style="body" inline>
          {teamMember.role}
        </Text>
      </div>
    </section>
  )
}

export default injectIntl(TeamMemberCard)
