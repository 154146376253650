/**
 * A list of every feature flag used in the app.
 * Note, these are for the new feature flag system that uses flipper. Not
 * the old feature flag system where we access database entries from the
 * companies table.
 */
enum FeatureFlags {
  mrfCycles = "mrf_cycles",
  hideLeaderboard = "hide_leaderboard"
}

export default FeatureFlags
