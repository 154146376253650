import moment from "moment"
import { Seq } from "immutable"

export default class Company {
  static CURRENT_COMPANY_ID = -1

  static shouldShowGoals(company) {
    return company.allow_goals
  }

  static areDepartmentGoalsAllowedForEveryone(company) {
    return company.allow_everyone_to_see_department_goals
  }

  static isGoalsEnabled(company) {
    return company.premium && Company.shouldShowGoals(company)
  }

  static isNewGoalsInterfaceEnabled(company) {
    return company.allow_new_goals_interface && Company.isGoalsEnabled(company)
  }

  static publicPraiseEnable(company) {
    return company.allow_praise
  }

  /**
   * @param {Company} company
   * @param {(number|Date|moment)=} targetDate - optional. Date that determines the "current"
   *    period. Defaults to now.
   * @returns {[Date, Date]} a pair of dates representing the start and end dates, respectively, of
   *    the period.
   */
  static getEnclosingGoalPeriod(company, targetDate = new Date()) {
    const {
      goal_cycle_month: cycleStartMonth,
      goal_cycle_day: cycleStartDay,
      goal_cycle_length: periodMonthLength
    } = company

    const cycleStart =
      // Note: subtract one from months because Date and moment's months are zero-based
      moment({
        year: moment(targetDate).year(),
        month: cycleStartMonth - 1,
        date: cycleStartDay
      })

    const monthsFromStart = moment(targetDate).diff(cycleStart, "months", true)
    const periodOffset = Math.floor(monthsFromStart / periodMonthLength)

    const periodStart = moment(cycleStart)
      .add(periodOffset * periodMonthLength, "months")
      .startOf("day")

    const periodEnd = moment(periodStart)
      .add(periodMonthLength, "months")
      .subtract(1, "day")
      .endOf("day")

    return [periodStart.toDate(), periodEnd.toDate()]
  }

  /**
   * @param {Company} company
   * @param {(number|Date|moment)=} initialDate - optional. Date that determines the initial period
   * in the sequence. Defaults to now.
   * @param {(number|Date|moment)=} finalDate - optional. If provided, date at which to terminate
   *    the sequence. Specifically, the sequence will not include the goal period that the finalDate
   *    is in. If it is not provided (the default), an infinite sequence will be returned.
   * @param {number=} step - the number of periods to between each member of the sequence. May be
   *    negative, in which case periods will be iterated in reverse chronological order. Defaults to
   *    1.
   * @returns {Seq<[Date, Date]>} a Seq of [Date, Date] pairs representing the start and end dates,
   *    respectively, of each period
   */
  static generateGoalPeriods(
    company,
    { initialDate = new Date(), finalDate = null, step = 1 } = {}
  ) {
    const periodMonthLength = company.goal_cycle_length
    const initialPeriod = Company.getEnclosingGoalPeriod(company, initialDate)
    const periodStart = moment(initialPeriod[0])

    return Seq({
      next() {
        const periodEnd = moment(periodStart)
          .add(periodMonthLength, "months")
          .subtract(1, "day")
          .endOf("day")

        // REVIEW
        const terminal =
          finalDate &&
          (step > 0
            ? periodEnd.isAfter(finalDate)
            : periodStart.isBefore(finalDate))

        if (terminal) {
          return { done: true }
        }

        const nextPeriod = [periodStart.toDate(), periodEnd.toDate()]
        periodStart.add(step * periodMonthLength, "months")

        return { value: nextPeriod, done: false }
      }
    })
  }
}
