import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/component-library/draft"
import * as React from "react"
import { useEffect } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import useArchiveTeam from "../../../hooks/useArchiveTeam"
import styles from "./ArchiveTeamModal.scss"
import useNotifications from "../../../hooks/useNotifications"
import strings from "../../../locale/strings"
const {
  successNotification,
  archiveTeamModal,
  archive,
  cancel
} = strings.teams.archivingTeams
type ArchiveTeamModal = React.FunctionComponent<{
  teamId: string
  onArchiveTeam: () => void
  onCancel: () => void
  intl: InjectedIntl
}>

/**
 * TODO: Update text with i18n friendliness
 *
 * @param {*} props {
 *   teamId,
 *   onArchiveTeam,
 *   onCancel
 * }
 * @returns {ArchiveTeamModal} Modal component
 */
const ArchiveTeamModal: ArchiveTeamModal = ({
  teamId,
  onArchiveTeam,
  onCancel,
  intl
}) => {
  const { showNotification } = useNotifications()
  const {
    archiveTeam,
    success: teamWasArchived,
    loading: archiving
  } = useArchiveTeam(teamId)

  useEffect(() => {
    if (teamWasArchived) {
      onArchiveTeam()
      showNotification({
        type: "affirmative",
        title: intl.formatMessage(successNotification.title),
        message: intl.formatMessage(successNotification.message)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamWasArchived])

  return (
    <ConfirmationModal
      isOpen
      type="informative"
      title={
        archiving
          ? intl.formatMessage(archiveTeamModal.loading)
          : intl.formatMessage(archiveTeamModal.title)
      }
      confirmLabel={intl.formatMessage(archive)}
      dismissLabel={intl.formatMessage(cancel)}
      onDismiss={() => onCancel()}
      onConfirm={() => archiveTeam()}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {intl.formatMessage(archiveTeamModal.body)}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(ArchiveTeamModal)
