import React from "react"
import ResponsiveWrapper from "../elements/ResponsiveWrapper/ResponsiveWrapper"
import EmptyState from "../widgets/EmptyState/EmptyState"
import negativeImage from "../../static/images-2017/drawings/emptystates/negative.png"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/useIntl"

type Props = {
  errorMessage?: string
  ctaText?: string
  ctaOnClick?: () => void
}

const ErrorPage = ({ errorMessage, ctaText, ctaOnClick }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <ResponsiveWrapper className="ErrorPage">
      <div className="ErrorPage--notification-container flex layout vertical center-center">
        <EmptyState
          imgSrc={negativeImage}
          header={formatMessage(strings.errorPage.header)}
          body={errorMessage || formatMessage(strings.errorPage.body)}
          ctaText={ctaText}
          ctaProps={ctaOnClick ? { onClick: ctaOnClick } : undefined}
        />
      </div>
    </ResponsiveWrapper>
  )
}

export default ErrorPage
