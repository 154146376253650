import React from "react"
import { Range, Seq } from "immutable"

/*
 * Takes an array of elements and interleaves a given separator element
 * between them.
 *
 * @param arr Array of elements
 * @param elem Seperator element to be interleaved
 * @returns {Array} A new array, constructed as above.
 */
export function intersperseElement(arr, elem) {
  return Seq(arr)
    .interleave(
      Range(0, arr.length).map(i =>
        React.cloneElement(elem, { key: `intersperse-${i}` })
      )
    )
    .skipLast(1)
    .toJS()
}
