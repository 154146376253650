import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import Tooltip from "rc-tooltip"
import strings from "../../../locale/strings"
import ZugataFormattedRelativeDate from "../../elements/ZugataFormattedDate/ZugataFormattedRelativeDate"
import MarkdownText from "../../elements/MarkdownText/MarkdownText"
import "./ConversationMessage.less"

export const genericMessageProps = {
  // These props are used outside of this component
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  sender: PropTypes.shape({
    email: PropTypes.string.isRequired,
    full_name: PropTypes.string
  }),
  isPrivate: PropTypes.bool,
  comments: PropTypes.array
}
export default class ConversationMessage extends React.Component {
  static propTypes = {
    ...genericMessageProps,
    tooltip: PropTypes.node,
    body: PropTypes.node.isRequired
  }

  static defaultProps = {
    isPrivate: false,
    comments: []
  }

  render() {
    const { body, timestamp, tooltip } = this.props
    const message = do {
      if (body) {
        ;<div
          className="ConversationMessage"
          ref={ref => (this.container = ref)}
        >
          <div className="ConversationMessage--timestamp">
            <ZugataFormattedRelativeDate value={timestamp} />
          </div>
          <div className="ConversationMessage--message">
            {typeof body === "string" ? <MarkdownText text={body} /> : body}
          </div>
        </div>
      } else {
        ;<div className="faded">
          <em>
            <FormattedMessage {...strings.general.noFeedback} />
          </em>
        </div>
      }
    }

    if (tooltip) {
      return (
        <Tooltip
          placement="top"
          overlay={
            <div className="ConversationMessage--tooltip">{tooltip}</div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          getTooltipContainer={() => this.container}
        >
          {message}
        </Tooltip>
      )
    } else {
      return message
    }
  }
}
