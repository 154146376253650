import "./ScrollableSideDrawer.less"
import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import cx from "classnames"
import Tooltip from "rc-tooltip"
import ProfilePic from "../ProfilePic/ProfilePic"
import Icon from "../../elements/Icon/Icon"

@injectIntl
export default class ScrollableSideDrawer extends React.PureComponent {
  static propTypes = {
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            notSelectable: PropTypes.bool,
            peekSection: PropTypes.element,
            peekSectionUser: PropTypes.object,
            retractedSection: PropTypes.element.isRequired,
            onClick: PropTypes.func
          })
        )
      })
    ).isRequired,
    selectedItemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    header: PropTypes.node,
    scrollToSelectedItem: PropTypes.bool,
    className: PropTypes.string,
    openByDefault: PropTypes.bool,
    footer: PropTypes.element,
    positionRelativeMode: PropTypes.bool,
    notSelectableTooltip: PropTypes.node
  }

  static defaultProps = {
    className: "",
    scrollToSelectedItem: false
  }

  constructor(props) {
    super(props)

    this.state = {
      opened: props.openByDefault
    }

    this.containerNode = null
    this.itemNodesByItemKey = {}
  }

  componentDidMount() {
    if (this.props.scrollToSelectedItem) {
      this.focusSelectedItem()
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollToSelectedItem, selectedItemKey } = this.props

    if (scrollToSelectedItem && selectedItemKey !== prevProps.selectedItemKey) {
      this.focusSelectedItem()
    }
  }

  focusSelectedItem() {
    const {
      props: { selectedItemKey },
      containerNode,
      itemNodesByItemKey
    } = this
    const selectedItemNode = itemNodesByItemKey[selectedItemKey]

    if (containerNode && selectedItemNode) {
      containerNode.scrollTop = selectedItemNode.offsetTop
    }
  }

  render() {
    const {
      sections,
      selectedItemKey,
      header,
      className,
      footer,
      positionRelativeMode,
      notSelectableTooltip
    } = this.props
    const { opened } = this.state

    return (
      <div
        className={cx("ScrollableSideDrawer layout vertical", className, {
          "ScrollableSideDrawer--opened": opened,
          "ScrollableSideDrawer--closed": !opened,
          "ScrollableSideDrawer--relative-mode": positionRelativeMode
        })}
        ref={node => (this.containerNode = node)}
      >
        <div className="ScrollableSideDrawer--items flex">
          <div
            className="ScrollableSideDrawer--close layout horizontal center flex none"
            onClick={() => this.setState({ opened: !opened })}
          >
            <div className="ScrollableSideDrawer--peek-section layout horizontal flex none center-center">
              <Icon
                className="ScrollableSideDrawer--peek-section-icon"
                iconName={opened ? "arrowForward" : "arrowBackward"}
              />
            </div>
            <div className="ScrollableSideDrawer--header">{header}</div>
          </div>

          {sections.map(
            ({ items, id }) =>
              items.length > 0 && (
                <ScrollableSideDrawerSection
                  key={id}
                  items={items}
                  selectedItemKey={selectedItemKey}
                  notSelectableTooltip={notSelectableTooltip}
                  openDrawer={() => this.setState({ opened: true })}
                />
              )
          )}
        </div>

        {footer && opened && (
          <div className="ScrollableSideDrawer--footer flex none self-end">
            {footer}
          </div>
        )}
      </div>
    )
  }
}

@injectIntl
class ScrollableSideDrawerSection extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        notSelectable: PropTypes.bool.isRequired,
        peekSection: PropTypes.element,
        peekSectionUser: PropTypes.object,
        retractedSection: PropTypes.element.isRequired,
        onClick: PropTypes.func
      })
    ),
    header: PropTypes.object,
    showHeader: PropTypes.bool,
    selectedItemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    openDrawer: PropTypes.func,
    notSelectableTooltip: PropTypes.node
  }

  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  renderItemContent = item => {
    const { selectedItemKey } = this.props
    const {
      key,
      notSelectable,
      peekSection,
      peekSectionUser,
      retractedSection,
      onClick
    } = item

    return (
      <div
        key={`${key}-item-container`}
        className={cx("ScrollableSideDrawer--item layout horizontal", {
          "ScrollableSideDrawer--item-not-selectable": notSelectable,
          "ScrollableSideDrawer--item-selected": key === selectedItemKey
        })}
        onClick={e => !notSelectable && onClick(e)}
      >
        {peekSectionUser ? (
          <div
            key={`${key}-item-peek-section`}
            className="ScrollableSideDrawer--peek-section
                        layout vertical center-center flex none"
          >
            <ProfilePic
              key={`${key}-avatar`}
              className="ScrollableSideDrawer--avatar"
              user={peekSectionUser}
            />
          </div>
        ) : (
          React.cloneElement(peekSection, {
            className: cx(
              "ScrollableSideDrawer--peek-section flex none",
              peekSection.props.className
            ),
            key: `${key}peek-section`
          })
        )}
        {React.cloneElement(retractedSection, {
          className: cx(
            "ScrollableSideDrawer--retracted-section flex",
            retractedSection.props.className
          ),
          key: `${key}-retracted-section`
        })}
      </div>
    )
  }

  render() {
    const { items, notSelectableTooltip } = this.props

    return (
      <div className="ScrollableSideDrawerSection">
        {items.map(item => [
          notSelectableTooltip && item.notSelectable ? (
            <Tooltip
              key={item.key}
              placement="left"
              overlay={
                <div
                  key={`${item.key}-tooltip`}
                  className="ScrollableSideDrawerSection--tooltip layout vertical center"
                >
                  {notSelectableTooltip}
                </div>
              }
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              {this.renderItemContent(item)}
            </Tooltip>
          ) : (
            this.renderItemContent(item)
          ),
          <hr key={`hr-${item.key}`} />
        ])}
      </div>
    )
  }
}
