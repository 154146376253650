// NOTE: don't import SteadyfootAgent, it depends on Session
import Reflux from "./ActionsInitializer"

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}

const SessionActions = Reflux.createActions({
  login: ASYNC_SETTINGS,
  autoLogin: ASYNC_SETTINGS,
  confirmAndLogin: ASYNC_SETTINGS,
  startProviderLogin: { sync: true },
  logout: ASYNC_SETTINGS, // async to allow router to act before data purge
  refresh: ASYNC_SETTINGS,
  exchangeAuthCode: ASYNC_SETTINGS
})

SessionActions.Google = Reflux.createActions({
  // 'init': ASYNC_SETTINGS,
  getAuthCode: ASYNC_SETTINGS,
  exchangeAuthCode: ASYNC_SETTINGS
})

SessionActions.Saml = Reflux.createActions({
  startLogin: { sync: true }
})

/* Session Actions not allowed here because SteadyfootAgent depends on Session
 * See SessionActionsImpl for them
===============================*/

export default SessionActions
