import { JsonDecoder } from "ts.data.json"
import { PersonalGoalPreview } from "../../types/Goals"
import paginationDecoder, {
  Pagination
} from "../PaginationDecoder/paginationDecoder"
import { personalGoalPreviewDecoder } from "../PersonalGoalPreviewDecoder/personalGoalPreviewDecoder"
import goalStatsDecoder, {
  GoalStats
} from "../GoalStatsDecoder/goalStatsDecoder"

type ResponseType = {
  goals: PersonalGoalPreview[]
  meta: {
    goals_stats: GoalStats
    pagination: Pagination
  }
}

export const getPersonalGoalsResponseDecoder = JsonDecoder.object<ResponseType>(
  {
    goals: JsonDecoder.array(personalGoalPreviewDecoder, "personalGoals"),
    meta: JsonDecoder.object(
      {
        goals_stats: goalStatsDecoder,
        pagination: paginationDecoder
      },
      "meta"
    )
  },
  "getPersonalGoalsResponse"
).map(({ goals, meta: { goals_stats: goalStats, pagination } }) => ({
  goals,
  goalStats,
  pagination
}))
