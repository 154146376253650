import { JsonDecoder } from "ts.data.json"
import paginationDecoder from "../PaginationDecoder/paginationDecoder"
const { array, object, number, string } = JsonDecoder

const departmentDecoder = object(
  {
    id: number,
    name: string
  },
  "departmentsDecoder department"
)

export const departmentsDecoder = object(
  {
    departments: array(departmentDecoder, "departmentsDecoder departments"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder
      },
      "meta"
    )
  },
  "departmentsDecoder"
).map(({ departments, meta }) => ({
  departments: departments.map(department => ({
    id: department.id.toString(),
    name: department.name
  })),
  pagination: meta.pagination
}))

export default departmentsDecoder
