import Reflux from "reflux-core"
import _ from "lodash"
import moment from "moment"
import Immutable, { List, Map, Record } from "immutable"
import Actions from "../actions"
import Company from "../models/Company"
import observeSearchActions from "./lib/observeSearchActions"

/**
 * A GoalSource identifies a unique entity that has goals, for instance, a particular department
 * or a particular user. It can be identified by the goal type and the id of that entity.
 */
export const GoalSource = Record({ goalType: "my_goal", sourceId: -1 })

export const sourceForGoal = goal => {
  return GoalSource({
    goalType: goal.goal_type,
    sourceId:
      goal.goal_type === "company_goal"
        ? Company.CURRENT_COMPANY_ID
        : goal.department_id || goal.user_id || null
  })
}

export default Reflux.createStore({
  init() {
    this.data = {
      goalsBySource: Map(), // { GoalSource -> List<Goal> }
      accomplishedGoals: null,
      teamGoalSearchResults: null,
      teamGoalPagination: null,
      lastSelectedDeptId: null
    }

    this.listenTo(
      Actions.Goal.list.completed,
      ({ goals, departmentId, userId, couldIncludeCompanyGoals }) => {
        this.setGoalsForSources({
          goals,
          sources: _.compact([
            userId && GoalSource({ goalType: "my_goal", sourceId: userId }),

            departmentId &&
              GoalSource({
                goalType: "department_goal",
                sourceId: departmentId
              }),

            couldIncludeCompanyGoals &&
              GoalSource({
                goalType: "company_goal",
                sourceId: Company.CURRENT_COMPANY_ID
              })
          ])
        })
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.Goal.load.completed, updatedGoal => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(Actions.Goal.loadAccomplishedGoals.completed, ({ goals }) => {
      this.data = {
        ...this.data,
        accomplishedGoals: goals
      }
      this.trigger(this.data)
    })

    observeSearchActions(this, {
      getResults: () => this.data.teamGoalSearchResults,
      searchAction: Actions.Goal.searchTeamGoals,
      pageAction: Actions.Goal.pageTeamGoals,
      onChange: (teamGoalSearchResults, { pagination, stats }) => {
        this.data = {
          ...this.data,
          teamGoalSearchResults,
          teamGoalPagination: pagination,
          teamGoalStats: stats
        }

        this.trigger(this.data)
      }
    })

    this.listenTo(Actions.Goal.create.completed, ({ newGoal, targetRange }) => {
      if (
        targetRange &&
        _.isArray(targetRange) &&
        moment(newGoal.due_at).isBetween(targetRange[0], targetRange[1])
      ) {
        this.data = {
          ...this.data,
          goalsBySource: this.data.goalsBySource.update(
            sourceForGoal(newGoal),
            goals =>
              goals && goals.push(newGoal).sortBy(({ due_at: dueAt }) => dueAt)
          )
        }

        this.trigger(this.data)
      }
    })

    this.listenTo(Actions.Goal.update.completed, updatedGoal => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(Actions.Goal.markAsManagerSeen.completed, updatedGoal => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(Actions.Goal.delete.completed, deletedGoal => {
      this.data = {
        ...this.data,
        goalsBySource: this.data.goalsBySource.update(
          sourceForGoal(deletedGoal),
          goals => goals.filterNot(g => g.id === deletedGoal.id)
        )
      }

      this.trigger(this.data)
    })

    this.listenTo(Actions.Goal.setLastSelectedDeptId, lastSelectedDeptId => {
      this.data = {
        ...this.data,
        lastSelectedDeptId
      }

      this.trigger(this.data)
    })

    this.listenTo(Actions.Goal.clearGoals, () => {
      this.initializeState()
      this.trigger(this.data)
    })
  },

  initializeState() {
    this.data = {
      goalsBySource: Map()
    }
  },

  getInitialState() {
    return this.data
  },

  /**
   * @param {Array<Object>} goals - the list of goals to set in goalsBySource. Should contain all
   *                                the goals for the goal sources listed in `sources`.
   *
   * @param {Array<GoalSource>} sources - the sources represented in the `goals` array. If a given
   *                                      source has no goals in the `goals` array, it will be
   *                                      understood that this source has no goals (an empty array
   *                                      will be assigned to it in the goalsBySource Map).
   */
  setGoalsForSources({ goals, sources }) {
    const newGoalsBySourceEntries = sources.map(source => [
      source,
      List(goals.filter(g => Immutable.is(sourceForGoal(g), source)))
    ])

    this.data = {
      ...this.data,
      goalsBySource: this.data.goalsBySource.merge(newGoalsBySourceEntries)
    }
  },

  updateGoal(updatedGoal) {
    const { goalsBySource, teamGoalSearchResults } = this.data

    this.data = {
      ...this.data,
      goalsBySource:
        goalsBySource &&
        goalsBySource.update(sourceForGoal(updatedGoal), goals => {
          const goalIndex = goals
            ? goals.findIndex(g => g.id === updatedGoal.id)
            : -1
          return goalIndex > -1
            ? goals.set(goalIndex, updatedGoal)
            : goals
            ? goals.push(updatedGoal)
            : List.of(updatedGoal)
        }),
      teamGoalSearchResults:
        teamGoalSearchResults &&
        teamGoalSearchResults.map(goal =>
          goal.id === updatedGoal.id ? updatedGoal : goal
        )
    }

    this.trigger(this.data)
  }
})
