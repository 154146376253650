import "./Toggle.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import _ from "lodash"
import strings from "../../../locale/strings"
import messageDescriptorType from "../../prop-types/messageDescriptorType"
import Aid from "../../../constants/automationId"

export default class Toggle extends React.Component {
  static propTypes = {
    label: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    endLabel: messageDescriptorType,
    startLabel: messageDescriptorType,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    className: "",
    onChange: _.noop,
    startLabel: strings.general.slider.no
  }

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
      active: _.get(props, "checkboxProps.checked") || false
    }
  }

  notifyChange(checked) {
    const { onChange } = this.props
    onChange(checked)
  }

  handleFocus = () => this.setState({ focused: true })
  handleBlur = () => this.setState({ focused: false })

  handleChange = e => {
    this.notifyChange(e.target.checked)
  }

  handleClick = () => {
    this.checkbox.checked = !this.checkbox.checked
    this.notifyChange(this.checkbox.checked)
  }

  render() {
    const {
      id,
      name,
      className,
      label,
      disabled,
      checked,
      endLabel,
      startLabel
    } = this.props
    const { focused } = this.state

    const classes = cx({
      focused,
      active: checked,
      Toggle: true,
      [className]: true
    })

    const containerClasses = cx({
      "Toggle--container": true,
      active: checked,
      focused
    })

    const handleClasses = cx({
      "Toggle--handle": true,
      active: checked,
      focused
    })

    return (
      <div
        className={classes}
        onClick={this.handleClick}
        data-automation-id={Aid.toggleSharing}
      >
        <label>{startLabel && <FormattedMessage {...startLabel} />}</label>
        <span className={containerClasses}>
          <span className={handleClasses} />
          <input
            id={id}
            name={name}
            checked={checked}
            ref={ref => (this.checkbox = ref)}
            type="checkbox"
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            disabled={disabled}
            style={{ opacity: 0 }}
          />
        </span>
        <label className={`${checked ? "active" : ""}`}>
          {endLabel && <FormattedMessage {...endLabel} />}
        </label>
      </div>
    )
  }
}
