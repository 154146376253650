import Reflux from "reflux-core"
import { Map } from "immutable"
import * as AdminActions from "../actions"
import observeSearchActions from "../../stores/lib/observeSearchActions"

const AdminGoalStore = Reflux.createStore({
  init() {
    this.data = {
      ...this.data,
      goalSearchResults: null,
      goalPagination: null,
      goalStats: null,
      statisticsForCompany: null,
      statisticsByDepartmentIds: Map(),
      teamStatisticsByDepartmentIds: Map(),
      departmentStats: null
    }

    this.listenTo(AdminActions.Goal.load.completed, this.updateGoal)

    this.listenTo(
      AdminActions.Goal.loadStatsOverview.completed,
      this.onStatsOverviewLoaded
    )
    this.listenTo(
      AdminActions.Goal.loadDepartmentStats.completed,
      this.onDepartmentStatsLoaded
    )
    this.listenTo(
      AdminActions.Goal.loadTeamStats.completed,
      this.onTeamStatsLoaded
    )

    observeSearchActions(this, {
      getResults: () => this.data.goalSearchResults,
      searchAction: AdminActions.Goal.searchGoals,
      pageAction: AdminActions.Goal.pageGoals,
      onChange: (goalSearchResults, { pagination, stats }) => {
        this.data = {
          ...this.data,
          goalSearchResults,
          goalPagination: pagination,
          goalStats: stats
        }

        this.trigger(this.data)
      }
    })
  },

  getInitialState() {
    return this.data
  },

  updateGoal(updatedGoal) {
    const { goalSearchResults } = this.data

    this.data = {
      ...this.data,
      goalSearchResults:
        goalSearchResults &&
        goalSearchResults.map(goal =>
          goal.id === updatedGoal.id ? updatedGoal : goal
        )
    }

    this.trigger(this.data)
  },

  onStatsOverviewLoaded({ stats, departmentId }) {
    const { statisticsForCompany, statisticsByDepartmentIds } = this.data

    this.data = {
      ...this.data,
      statisticsForCompany: !departmentId ? stats : statisticsForCompany,
      statisticsByDepartmentIds: departmentId
        ? this.data.statisticsByDepartmentIds.set(departmentId, stats)
        : statisticsByDepartmentIds
    }

    this.trigger(this.data)
  },

  onDepartmentStatsLoaded(departmentStats) {
    this.data = { ...this.data, departmentStats }
    this.trigger(this.data)
  },

  onTeamStatsLoaded({ stats, departmentId }) {
    this.data = {
      ...this.data,
      teamStatisticsByDepartmentIds: this.data.teamStatisticsByDepartmentIds.set(
        departmentId,
        stats
      )
    }

    this.trigger(this.data)
  }
})

export default AdminGoalStore
