import Reflux from "reflux-core"

import SteadyfootAgent from "./lib/SteadyfootAgent"
import UIActions from "./UIActions"
import { GENERAL_URLS } from "../api/endpoints.json"

const { STATS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const StatsActions = Reflux.createActions({
  // 'rankings': ASYNC_SETTINGS,
  // 'totals': ASYNC_SETTINGS,
})

StatsActions.totals = function(path = "") {
  return new Promise((resolve, reject) => {
    UIActions.setLoadingState(true, { light: true })

    agent.get(`${STATS_URL}/totals/${path}`).end((err, res) => {
      UIActions.setLoadingState(false)

      if (err) {
        reject(res && res.body, err)
      } else {
        resolve(res.body)
      }
    })
  })
}

StatsActions.rankings = function(path = "", { around_me = false } = {}) {
  return new Promise((resolve, reject) => {
    UIActions.setLoadingState(true, { light: true })

    agent
      .get(`${STATS_URL}/rankings/${path}`)
      .query({ around_me })
      .end((err, res) => {
        UIActions.setLoadingState(false)

        if (err || !res.body.ranking) {
          reject(res && res.body, err)
        } else {
          resolve({ ranking: res.body.ranking, meta: res.body.meta })
        }
      })
  })
}

export default StatsActions
