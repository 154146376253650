import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import strings from "../../../locale/strings"
import "./ReviewStatus.less"

export const COMPLETED_STATUS = "completed"
export const INCOMPLETE_STATUS = "incomplete"
export const NOT_STARTED = "incomplete"
export const EXPIRED_STATUS = "expired"
export const ERROR_STATUS = "error"
export const CREATING_STATUS = "creating"
// NOTE: This shows up as "Draft" in the translations for SR and EE cycles
export const CREATED_STATUS = "created"
export const SCHEDULED_STATUS = "scheduled"
export const READY_STATUS = "ready"
export const ACTIVE_STATUS = "active"
export const CLOSED_STATUS = "closed"
export const DRAFT_STATUS = "draft"
export const SHARED_STATUS = "shared"
export const DISMISSED_STATUS = "dismissed"

export default class ReviewStatus extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    statusStrings: PropTypes.object,
    startedFlag: PropTypes.bool
  }

  static defaultProps = {
    statusStrings: strings.reviewStatus,
    startedFlag: false
  }

  render() {
    const { status, statusStrings, startedFlag } = this.props
    return (
      <div
        className={cx("ReviewStatus", {
          "ReviewStatus--complete":
            status === COMPLETED_STATUS || status === ACTIVE_STATUS,
          "ReviewStatus--incomplete":
            status === INCOMPLETE_STATUS ||
            status === SCHEDULED_STATUS ||
            status === READY_STATUS ||
            status === CREATING_STATUS ||
            status === DISMISSED_STATUS ||
            status === CREATED_STATUS ||
            status === DRAFT_STATUS,
          "ReviewStatus--expired":
            status === EXPIRED_STATUS ||
            status === CLOSED_STATUS ||
            status === SHARED_STATUS,
          "ReviewStatus--not-started": startedFlag && status === NOT_STARTED
        })}
      >
        {statusStrings[status] ? (
          <FormattedMessage {...statusStrings[status]} />
        ) : null}
      </div>
    )
  }
}
