import Reflux from "reflux-core"
import moment from "moment-timezone"
import Actions from "../actions/index"
import CompanyActions from "../admin/actions/CompanyActions"
import LocalStorage from "./lib/LocalStorage"
import SessionStore from "./SessionStore"
// Enforcing dev mode only for logging.
// We may not even want the breadcrumbs, since I found
// a PII issue before. Let's consider removing all
// the redundant Promises.
import { isLogTagEnabled } from "../util/logging"
import { CURRENT_USER_STORE } from "../constants/logging"

const db = new LocalStorage({ key: "UserStore" })

const CurrentUserStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function() {
    this.listenTo(SessionStore, this.onSessionChanged)
    this.listenTo(Actions.Import.collect.completed, this.silentlyFetchUser)

    this.listenTo(Actions.User.loadCurrent.completed, this.onLoadCompleted)
    this.listenTo(Actions.User.reloadCurrent.completed, this.onUpdateCompleted)
    this.listenTo(Actions.User.update.completed, this.onUpdateCompleted)

    this.listenTo(CompanyActions.updateUser.completed, this.silentlyFetchUser)

    // This if statement is a quick fix to get the tests to pass. I could
    // not figure out how to properly fix this.
    // But this raises a question, why are we dispatching actions from our store?
    if (process.env.NODE_ENV !== "test") {
      // Force the time_zone to be updated.
      this.fetchUser()
    }
    // Refreshes user data each time
    if (!SessionStore.getInitialState()) {
      // If we have a user, then the data is inconsistent
      // and we should remove the current user
      if (this.getInitialState()) {
        this.onSessionChanged(null)
      }
    }
  },

  getInitialState: function() {
    return db.loadData()
  },

  fetchUser: function() {
    // Gets called on load
    Actions.User.update(
      { user: { time_zone: moment.tz.guess(true) } },
      { silent: true }
    ).catch(user => {
      if (isLogTagEnabled(CURRENT_USER_STORE)) {
        // eslint-disable-next-line no-console
        console.error({ user })
      }
    })
  },

  silentlyFetchUser: function() {
    // Gets called when Session changes/refreshes
    Actions.User.reloadCurrent()
      .then(user => {
        if (isLogTagEnabled(CURRENT_USER_STORE)) {
          // eslint-disable-next-line no-console
          console.log({ user })
        }
      })
      .catch(user => {
        if (isLogTagEnabled(CURRENT_USER_STORE)) {
          // eslint-disable-next-line no-console
          console.error({ user })
        }
      })
  },

  purge: function() {
    db.destroyData()
  },

  onSessionChanged: function(session) {
    if (session) {
      if (this.getInitialState()) {
        // Refreshing session token
        this.silentlyFetchUser()
      } else {
        // Logging in
        this.fetchUser()
      }
    } else {
      // Logging out
      this.purge()

      this.trigger(db.loadData())
    }
  },

  onLoadCompleted: function(resp) {
    if (!SessionStore.getInitialState()) {
      return
    }

    db.updateData(resp)
    const user = db.getData()
    this.trigger(user)
  },

  onUpdateCompleted: function(userUpdate) {
    db.updateData(userUpdate)
    this.trigger(db.getData())
  }
})

export default CurrentUserStore
