import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/component-library/draft"
import * as React from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../../locale/strings"
import styles from "./GoalUnsavedChangesModal.scss"

type GoalUnsavedChangesModal = React.FunctionComponent<{
  onLeavePage: () => void
  onCancel: () => void
  intl: InjectedIntl
}>

const GoalUnsavedChangesModal: GoalUnsavedChangesModal = ({
  onLeavePage,
  onCancel,
  intl
}) => {
  const { formatMessage } = intl

  return (
    <ConfirmationModal
      isOpen
      type="cautionary"
      title={formatMessage(strings.teamGoals.unsavedChangesModal.title)}
      confirmLabel={formatMessage(
        strings.teamGoals.unsavedChangesModal.confirmLabel
      )}
      dismissLabel={formatMessage(strings.teamGoals.cancel)}
      onDismiss={() => {
        onCancel()
      }}
      onConfirm={() => {
        onLeavePage()
      }}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {formatMessage(strings.teamGoals.unsavedChangesModal.modalContent)}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(GoalUnsavedChangesModal)
