import { Draft } from "../../state/Drafts"

/**
 * Response format:
 * {
 *  draft: {
 *    body: "Draft body"
 *  }
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveDraftDecoder = (data: any): Draft => {
  const { draft } = data
  return {
    body: draft.body || ""
  }
}

export default saveDraftDecoder
