import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Team } from "../../../state/TeamsState"
import Tiles, { TileProps } from "../Tiles/Tiles"
import useNotifications from "../../../hooks/useNotifications"
import useRestoreTeam from "../../../hooks/useRestoreTeam"
import strings from "../../../locale/strings"
const { tile, restoredNotification } = strings.teams.archivedTeamCard

type ArchivedTeamCard = React.FunctionComponent<{
  team: Team
  onActionClick: () => void
  updateTeamsList: () => void
  intl: InjectedIntl
}>

const ArchivedTeamCard: ArchivedTeamCard = ({
  team,
  onActionClick,
  updateTeamsList,
  intl
}) => {
  const { name, preview, description, currentUserCanEdit } = team
  const { showNotification } = useNotifications()
  const { restoreTeam, error } = useRestoreTeam(team.id)
  const { formatMessage } = intl

  const restoreTeamAction = () => {
    restoreTeam()
    if (!error) {
      showNotification({
        type: "affirmative",
        title: formatMessage(restoredNotification.title),
        message: formatMessage(restoredNotification.message)
      })
      updateTeamsList()
    }
  }

  let subtitle = formatMessage(tile.loading)
  if (preview) {
    const { goalCount, memberCount } = preview
    const goalStr =
      goalCount === 1
        ? formatMessage(tile.goalSingular)
        : formatMessage(tile.goalPlural)
    const memberStr =
      memberCount === 1
        ? formatMessage(tile.memberSingular)
        : formatMessage(tile.memberPlural)
    subtitle = `${goalCount} ${goalStr} | ${memberCount} ${memberStr}`
  }

  const tileProps: TileProps = {
    title: name,
    subtitle: subtitle,
    description: description,
    background: "seedling",
    actionLabel: formatMessage(tile.actionLabel),
    onActionClick: onActionClick
  }

  if (currentUserCanEdit) {
    tileProps.secondaryActionLabel = formatMessage(tile.secondaryActionLabel)
    tileProps.onSecondaryActionClick = restoreTeamAction
  }

  return <Tiles.Tile {...tileProps} />
}

export default injectIntl(ArchivedTeamCard)
