import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import "./Checkbox.less"

export default class Checkbox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    filled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    indeterminate: undefined,
    filled: false,
    disabled: false
  }

  constructor(props) {
    super(props)
  }

  render() {
    const {
      id,
      text,
      disabled,
      checked,
      filled,
      onChange,
      ...inputProps
    } = this.props
    const labelClassName = cx(
      "Checkbox--label",
      { "Checkbox--label-disabled": disabled },
      { "Checkbox--label-selected": checked }
    )

    return (
      <div className="Checkbox">
        <input
          type="checkbox"
          className={filled ? "Checkbox--filled" : ""}
          id={id}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          {...inputProps}
        />
        <label htmlFor={id} className={labelClassName}>
          {text}
        </label>
      </div>
    )
  }
}
