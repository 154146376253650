import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../actions"
import BaseCycleStore from "./lib/BaseCycleStore"

const PerformanceReviewStore = Reflux.createStore(
  _.assign({}, BaseCycleStore, {
    init() {
      BaseCycleStore.init.call(this, { actions: Actions.PerformanceReview })

      this.data = {
        ...this.data,
        collaboratorReview: null,
        pastReviewsByUserId: {},
        sharedReviews: null,
        currentSharedPerformanceReview: null
      }

      this.listenTo(
        Actions.PerformanceReview.getTeamReviews.completed,
        this.onGetTeamReviews
      )

      this.listenTo(
        Actions.PerformanceReview.getCycles.completed,
        this.setCycles
      )

      this.listenTo(
        Actions.PerformanceReview.getReview.completed,
        this.onGetReview
      )

      this.listenTo(
        Actions.PerformanceReview.updateReview.completed,
        this.onGetReview
      )
      this.listenTo(
        Actions.PerformanceReview.shareReviewInCycle.completed,
        this.onShareReview
      )
      this.listenTo(
        Actions.PerformanceReview.setNextReview,
        this.onSetNextReview
      )
      this.listenTo(
        Actions.PerformanceReview.addBucketAssignment.completed,
        this.onAddBucketAssignment
      )

      this.listenTo(
        Actions.PerformanceReview.notifyAnswerEditing,
        this.onAnswerEdit
      )
      this.listenTo(
        Actions.PerformanceReview.updateAnswer.completed,
        this.onUpdateAnswer.bind(this, "saved")
      )
      this.listenTo(
        Actions.PerformanceReview.updateAnswer.failed,
        this.onUpdateAnswer.bind(this, "failed")
      )

      this.listenTo(Actions.PerformanceReview.clearReviews, this.clearReviews)

      this.listenTo(
        Actions.PerformanceReview.getPastReviewsByUserId.completed,
        ({ results, clientMeta: { userId } }) => {
          this.data = {
            ...this.data,
            pastReviewsByUserId: {
              ...this.data.pastReviewsByUserId,
              [userId]: results
            }
          }

          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.PerformanceReview.loadSharedReviews.completed,
        ({ shared_performance_reviews: sharedReviews }) => {
          this.data = {
            ...this.data,
            sharedReviews
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.PerformanceReview.loadSharedReviewById.completed,
        review => {
          this.data = {
            ...this.data,
            currentSharedPerformanceReview: review
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.PerformanceReview.getCollaboratorReview.completed,
        review => {
          this.data = {
            ...this.data,
            collaboratorReview: review
          }
          this.trigger(this.data)
        }
      )
    },

    onGetReview(updatedReview) {
      this.data = {
        ...this.data,
        pastReviewsByUserId: _.mapValues(
          this.data.pastReviewsByUserId,
          reviews =>
            reviews.map(review =>
              // Past reviews have additional fields, so merge in the updates rather than replace
              review.id === updatedReview.id
                ? review.with(updatedReview)
                : review
            )
        )
      }

      BaseCycleStore.onGetReview.call(this, updatedReview)
    }
  })
)

export default PerformanceReviewStore
