import "./GoalDateRangePicker.less"
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { injectIntl } from "react-intl"
import _ from "lodash"
import Select from "../../elements/Select/Select"
import IntlFlatPickr from "../IntlFlatPickr/IntlFlatPickr"
import Company from "../../../models/Company"
import strings from "../../../locale/strings"

const DATE_FORMAT = { day: "numeric", month: "short", year: "numeric" }
const DEFAULT_CUSTOM_RANGE_START_DATE = moment()
  .subtract(1, "year")
  .startOf("day")
  .toDate()
const DEFAULT_CUSTOM_RANGE_END_DATE = moment()
  .add(1, "year")
  .endOf("day")
  .toDate()
export const CUSTOM_RANGE_VALUE = 0

class GoalDateRangePicker extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    targetRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    onTargetRangeChange: PropTypes.func.isRequired,
    enableCustomDates: PropTypes.bool
  }

  static defaultProps = {
    enableCustomDates: true
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      customRangeSelected: this.targetRangeIsCustom()
    }
  }

  targetRangeIsCustom() {
    const [startDate, endDate] = this.props.targetRange
    const { user } = this.context
    const goalPeriod = Company.getEnclosingGoalPeriod(user.company, startDate)

    return !_.isEqual(goalPeriod, [startDate, endDate])
  }

  componentDidUpdate(prevProps, prevState) {
    const { onTargetRangeChange } = this.props
    const { customRangeSelected } = this.state

    // User has switched to custom range selection, set default value for the range
    if (
      prevState.customRangeSelected !== customRangeSelected &&
      customRangeSelected
    ) {
      onTargetRangeChange([
        DEFAULT_CUSTOM_RANGE_START_DATE,
        DEFAULT_CUSTOM_RANGE_END_DATE
      ])
    }
  }

  getSelectedCompanyPeriod(props = this.props) {
    const { targetRange } = props
    const { company } = this.context.user

    return Company.getEnclosingGoalPeriod(company, targetRange[0])
  }

  getPeriodOptions() {
    const {
      intl: { formatMessage, formatDate }
    } = this.props
    const { company } = this.context.user
    const { enableCustomDates } = this.props

    // Get all periods that fit into preceeding year
    const finalDate = moment().subtract(1, "year")
    const goalPeriods = Company.generateGoalPeriods(company, {
      step: -1,
      finalDate
    }).toArray()

    const goalPeriodOptions = goalPeriods.map(([startDate, endDate]) => {
      const text = formatMessage(strings.time.dateRange, {
        startDate: formatDate(startDate, DATE_FORMAT),
        endDate: formatDate(endDate, DATE_FORMAT)
      })

      // Value must be string-able for <Select />. Use the start date of a period to identify it.
      const value = startDate.getTime()

      return { value, text }
    })
    // Adding value to select custom range
    if (enableCustomDates) {
      return goalPeriodOptions.concat([
        {
          value: CUSTOM_RANGE_VALUE,
          text: formatMessage(strings.time.pickDateRange)
        }
      ])
    }

    return goalPeriodOptions
  }

  handlePeriodChange = option => {
    const { onTargetRangeChange } = this.props
    const newValue = Number(option.value)

    if (newValue === CUSTOM_RANGE_VALUE) {
      this.setState({ customRangeSelected: true })
    } else {
      // Value was stringified for <Select /> and represents the start of the period - see
      // getPeriodOptions
      const startDate = new Date(newValue)
      const newRange = this.getSelectedCompanyPeriod({
        targetRange: [startDate, null]
      })

      this.setState({ customRangeSelected: false }, () =>
        onTargetRangeChange(newRange)
      )
    }
  }

  handleCustomRangeChange = dateRange => {
    if (!dateRange || dateRange.length < 2) {
      return
    }

    const { onTargetRangeChange } = this.props
    const [startDate, endDate] = dateRange

    onTargetRangeChange([
      moment(startDate)
        .startOf("day")
        .toDate(),
      moment(endDate)
        .endOf("day")
        .toDate()
    ])
  }

  render() {
    const { customRangeSelected } = this.state
    const periodOptions = this.getPeriodOptions()
    const { targetRange } = this.props

    return (
      <div className="GoalDateRangePicker layout horizontal">
        <Select
          className={`GoalDateRangePicker--dropdown${
            customRangeSelected ? "-customDate" : ""
          } flex one`}
          value={
            customRangeSelected
              ? CUSTOM_RANGE_VALUE
              : // In the options, goal periods are identified by the numeric representation of
                // the start date - see getPeriodOptions
                this.getSelectedCompanyPeriod()[0].getTime()
          }
          options={periodOptions}
          onChange={this.handlePeriodChange}
        />

        {customRangeSelected && (
          <IntlFlatPickr
            className="GoalDateRangePicker--flatpickr flex one"
            options={{
              mode: "range",
              dateFormat: "M j, Y", // ToDo: use i18n formatting, see ZUG-8494
              altFormat: "M j, Y",
              altInput: true
            }}
            value={targetRange}
            onChange={this.handleCustomRangeChange}
          />
        )}
      </div>
    )
  }
}

export default injectIntl(GoalDateRangePicker)
export { GoalDateRangePicker as RawGoalDateRangePicker }
