import * as React from "react"
import { useState, useEffect } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Text } from "@kaizen/component-library"
import { Select } from "@kaizen/component-library/draft"
import Avatar from "../../Avatar/Avatar"
import useTeam from "../../../hooks/useTeam"
import { Team } from "../../../state/TeamsState"
import styles from "./GoalOwnerSelect.scss"
import { User } from "../../../types/User"
import strings from "../../../locale/strings"

export type GoalOwnerOption = {
  avatar: string
  avatarFallback: string
  label: string
  value: User
}

type GoalOwnerSelect = React.FunctionComponent<{
  title: string
  disabled: boolean
  placeholder: string
  teamId: string
  owners: User[]
  setFields: (users: User[]) => void
  onUserAdded: (user: User) => void
  intl: InjectedIntl
}>

const shapeOption = (member: User) => ({
  value: member,
  label: member.name,
  avatar: member.profileImage,
  avatarFallback: member.avatar
})

export const shapeOptions = (team: Team) => {
  if (team && team.details) {
    const { teamMembers, teamLeads } = team.details
    const shapedTeamLeads = teamLeads.map(shapeOption)
    const shapedTeamMembers = teamMembers.map(shapeOption)
    return [...shapedTeamLeads, ...shapedTeamMembers]
  } else {
    return []
  }
}

type ActionEvent = {
  action: string
  option: GoalOwnerOption
  name?: string
}

/**
 * Note that we are using !important in this component to override
 * React Select styles. Not the greatest idea, but this is that 0.1%
 * time we should use !important.
 */
const GoalOwnerSelect: GoalOwnerSelect = ({
  title,
  disabled,
  placeholder,
  teamId,
  owners,
  setFields,
  onUserAdded,
  intl
}) => {
  const [selectedMembers, setSelectedMembers] = useState<GoalOwnerOption[]>([])
  const [options, setOptions] = useState<GoalOwnerOption[]>([])
  const { team } = useTeam(teamId)
  const { formatMessage } = intl

  useEffect(() => {
    if (team) {
      const newOptions = shapeOptions(team)
      const baseOwners = newOptions.filter(option =>
        owners.some(owner => owner.id === option.value.id)
      )

      setSelectedMembers(baseOwners)
      setOptions(newOptions)
    } else {
      setSelectedMembers([])
      setOptions([])
    }
  }, [team, owners])

  return (
    <>
      <div className={styles.titleContainer}>
        <Text tag="h4" style="label">
          {title}
        </Text>
      </div>
      <Select
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        value={selectedMembers}
        styles={{
          // @ts-ignore
          multiValue: (provided, state) => ({
            ...provided,
            padding: "0px !important",
            borderRadius: "24px !important"
          }),
          // @ts-ignore
          multiValueLabel: (provided, state) => ({
            ...provided,
            padding: "3px !important"
          }),
          // @ts-ignore
          multiValueRemove: (provided, state) => ({
            ...provided,
            borderRadius: "12px !important",
            height: "24px !important",
            alignSelf: "center !important",
            marginRight: "6px !important"
          })
        }}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        onChange={(values: GoalOwnerOption[], event: ActionEvent) => {
          setSelectedMembers(values)
          values ? setFields(values.map(option => option.value)) : setFields([])

          switch (event.action) {
            case "select-option":
              onUserAdded(event.option.value)
              break
            default:
              break
          }
        }}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        isMulti={true}
        noOptionsMessage={() =>
          formatMessage(strings.teamGoalForm.noOptionsMessage)
        }
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        formatOptionLabel={({
          label,
          avatar,
          avatarFallback
        }: GoalOwnerOption) => (
          <div className={styles.goalOwnerSelect}>
            <Avatar size="small" imageURL={avatar} />
            <div className={styles.labelContainer}>{label}</div>
          </div>
        )}
      />
    </>
  )
}

export default injectIntl(GoalOwnerSelect)
