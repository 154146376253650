import Reflux from "reflux-core"
import SteadyfootAgent from "../../actions/lib/SteadyfootAgent"
import { ADMIN_SKILLS_URLS } from "../../api/endpoints.json"
import { extractResponseKey } from "../../actions/lib/apiActionHelpers"
const { ADMIN_SKILLS_DEFAULT_URL } = ADMIN_SKILLS_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"]
}

const SkillDefaultActions = Reflux.createActions({
  create: ASYNC_SETTINGS,
  destroy: ASYNC_SETTINGS
})

SkillDefaultActions.create.listenAndPromise(({ skill_id, job_title_id }) =>
  extractResponseKey(
    "skill_default",
    agent.post(ADMIN_SKILLS_DEFAULT_URL).send({ skill_id, job_title_id })
  )
)

SkillDefaultActions.destroy.listenAndPromise(skillDefault =>
  extractResponseKey(
    "skill_default",
    agent.del(`${ADMIN_SKILLS_DEFAULT_URL}/${skillDefault.id}`)
  )
)

export default SkillDefaultActions
