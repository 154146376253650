import { JsonDecoder } from "ts.data.json"

export type ReviewerSuggestion = {
  id: number
  name: string
  email: string
  avatar: string
}

const singleSuggestionDecoder = JsonDecoder.object<ReviewerSuggestion>(
  {
    id: JsonDecoder.number,
    email: JsonDecoder.string,
    name: JsonDecoder.string,
    avatar: JsonDecoder.string
  },
  "reviewersSuggestions > singleReviewerSuggestion",
  {
    name: "best_name",
    avatar: "profile_image_url"
  }
)

const suggestionsDecoder = JsonDecoder.object(
  {
    users: JsonDecoder.array(singleSuggestionDecoder, "")
  },
  "reviewersSuggestions"
).map(({ users }) => users)

export default suggestionsDecoder
