import _ from "lodash"
import React from "react"
import { Dropdown, MenuList, MenuItem, Button } from "@kaizen/component-library"
import Media from "react-media"
import { injectIntl, InjectedIntl } from "react-intl"
import {
  MenuItem as MenuItemType,
  Item as ItemType
} from "../../../../types/MenuItem"
import { DESKTOP_QUERY } from "../../../constants/styles"
import "./ActionItems.less"
import strings from "../../../locale/strings"

interface Props {
  menuItems: MenuItemType[]
  intl: InjectedIntl
}

export const RawActionItems = ({ menuItems, intl }: Props) => {
  function renderItemOrGroup(item: MenuItemType, i: number) {
    if (item.type === "group") {
      return item.menuItems.map((item, i) => renderItem(item, i))
    } else {
      return renderItem(item, i)
    }
  }

  function renderItem(item: ItemType, i: number) {
    return (
      <MenuItem key={i} icon={item.icon} action={item.onClick}>
        {intl.formatMessage(item.text)}
      </MenuItem>
    )
  }

  function renderButtonOrDropdown(item: MenuItemType, i: number) {
    if (item.type === "group") {
      return (
        <Dropdown
          label={intl.formatMessage(item.text)}
          controlAction
          icon={item.icon}
          automationId="grouped-dropdown"
        >
          <MenuList>
            {item.menuItems.map((item, i) => renderItem(item, i))}
          </MenuList>
        </Dropdown>
      )
    } else {
      return (
        <Button
          key={i}
          label={intl.formatMessage(item.text)}
          icon={item.primary ? undefined : item.icon}
          onClick={item.onClick}
          secondary={!item.primary}
          primary={item.primary}
          automationId={item.automationId}
        />
      )
    }
  }

  return (
    <div className="ActionItems">
      <Media query={DESKTOP_QUERY}>
        {isDesktop => {
          /* If there is only one item we don't want it to be rendered as a
           * dropdown, regardless of screen width */
          if (!isDesktop && menuItems.length === 1) {
            return renderButtonOrDropdown(menuItems[0], 1)
            /* If there is more than one item, render the items into a dropdown
             * menu on mobile. */
          } else if (!isDesktop) {
            return (
              <Dropdown
                label={intl.formatMessage(strings.actionItems.actions)}
                controlAction
                automationId="actions-dropdown"
              >
                <MenuList>
                  {menuItems.map((item, i) => renderItemOrGroup(item, i))}
                </MenuList>
              </Dropdown>
            )
          }

          const [priorityActions, remainingActions] = _.partition(
            menuItems,
            item => item.priorityAction
          )

          /* If we are not on desktop, then we should render the items marked as */
          /* priorityActions as buttons (or their own dropdowns), and then the */
          /* rest of the items in a 'More' dropdown */
          return (
            <>
              {priorityActions.map((item, i) =>
                renderButtonOrDropdown(item, i)
              )}
              {remainingActions.length > 0 && (
                <Dropdown
                  label={intl.formatMessage(strings.actionItems.actions)}
                  controlAction
                  automationId="actions-dropdown"
                >
                  <MenuList>
                    {remainingActions.map((item, i) =>
                      renderItemOrGroup(item, i)
                    )}
                  </MenuList>
                </Dropdown>
              )}
            </>
          )
        }}
      </Media>
    </div>
  )
}

export const ActionItems = injectIntl(RawActionItems)
