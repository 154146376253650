import * as React from "react"
import { useEffect } from "react"
import { WithRouterProps } from "react-router"
import Loading from "../../elements/Loading/Loading"
import useGoalDossier from "../../../hooks/useGoalDossier"
import useCompanyGoals from "../../../hooks/useCompanyGoals"
import ScrollContainer from "../../widgets/ScrollContainer/ScrollContainer"
import useCurrentUser from "../../../hooks/useCurrentUser"
import GoalsTableHeader from "../../elements/GoalsTableHeader/GoalsTableHeader"
import GoalsTable from "../../widgets/GoalsTable/GoalsTable"
import GoalsEmptyState from "../../../components/widgets/GoalsEmptyState/GoalsEmptyState"
import strings from "../../../locale/strings"

type CompanySummariesListProps = React.FunctionComponent<{} & WithRouterProps>

const CompanySummariesList: CompanySummariesListProps = ({
  router,
  location
}) => {
  const currentUser = useCurrentUser()
  const { open: openGoalDossier } = useGoalDossier(router, location)
  const companyId = currentUser.company.id
  const { companyGoals, loading, fetch: fetchCompanyGoals } = useCompanyGoals(
    companyId
  )

  useEffect(() => {
    fetchCompanyGoals()
  }, [companyId, fetchCompanyGoals])

  return (
    <ScrollContainer onScrolledToBottom={() => {}}>
      {loading ? (
        <Loading />
      ) : companyGoals.length > 0 ? (
        <React.Fragment>
          <GoalsTableHeader goalType={"company"} />
          <GoalsTable
            titleName={currentUser.company.name}
            goals={companyGoals}
            displayOwners={true}
            goalProgress={Math.floor(
              (companyGoals.reduce(
                (progress, goal) => progress + goal.completion,
                0
              ) /
                companyGoals.length) *
                100
            )}
            openGoal={goalId => openGoalDossier(goalId)}
          />
        </React.Fragment>
      ) : (
        <GoalsEmptyState
          headingText={strings.companySummariesList.emptyStateHeadingText}
          bodyText={strings.companySummariesList.emptyStateBodyText}
          illustrationType={"neutral"}
        />
      )}
    </ScrollContainer>
  )
}

export default CompanySummariesList
