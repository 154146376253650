import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { setTimeout } from "timers"
// @ts-ignore
import Actions from "../../../actions"
import useCreateComment from "../../../hooks/useCreateComment"
import useDebounce from "../../../hooks/useDebounce"
import useDraft from "../../../hooks/useDraft"
import useSaveDraft from "../../../hooks/useSaveDraft"
import AvatarTextField from "../AvatarTextField/AvatarTextField"
import strings from "../../../locale/strings"

type GoalCommentTextField = React.FunctionComponent<{
  goalId: number
  parentCommentId?: number
  avatarURL: string
  value?: string
  placeholder?: string
  onChange?: (v: string) => void
  onSubmit?: (v: string) => void
  intl: InjectedIntl
}>

const GoalCommentTextField: GoalCommentTextField = ({
  goalId,
  parentCommentId,
  avatarURL,
  placeholder,
  onChange,
  onSubmit,
  intl
}) => {
  const { createComment } = useCreateComment("goal", `${goalId}`)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { draft, loading: loadingDraft } = useDraft(
    goalId,
    "goal",
    parentCommentId
  )
  const {
    saveDraft,
    loading: savingDraft,
    succeeded: draftSaved
  } = useSaveDraft(goalId, "goal", parentCommentId)
  const [detailText, setDetailText] = useState("")
  const [comment, setComment] = useState<string | undefined>(
    draft && draft.body
  )
  const debouncedComment = useDebounce(comment, 1000)
  const { formatMessage } = intl

  useEffect(() => {
    if (
      debouncedComment !== undefined &&
      draft &&
      debouncedComment !== draft.body
    ) {
      saveDraft(debouncedComment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedComment])

  useEffect(() => {
    if (draft && draft.body && !comment) {
      setComment(draft.body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft])

  useEffect(() => {
    let text = ""
    if (savingDraft) {
      text = formatMessage(strings.goals.goalComments.saving)
    } else if (comment === debouncedComment && draftSaved) {
      text = formatMessage(strings.goals.goalComments.saved)
    }

    setDetailText(text)

    if (text !== "") {
      const timeout = setTimeout(() => {
        setDetailText("")
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDraft, draftSaved, comment])

  // Since TextEditor is expecting a function that returns a Promise with the data, we have
  // to stick with the `loadUsersForMention` until we refactor the TextEditorComponent
  const fetchMentionSuggestions = ({ query }: { query: string }) => {
    return Actions.Goal.loadUsersForMention({ goalId, query })
  }

  const handleChange = useCallback(
    value => {
      setComment(value)
      onChange && onChange(value)
    },
    [onChange, setComment]
  )

  const handleSubmit = useCallback(() => {
    comment && createComment(comment, parentCommentId)
    comment && onSubmit && onSubmit(comment)
    setComment("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, comment, parentCommentId])

  return (
    (draft && (
      <AvatarTextField
        avatarURL={avatarURL}
        placeholder={placeholder}
        value={comment !== undefined ? comment : draft.body}
        detailText={detailText}
        onChange={handleChange}
        onSubmit={handleSubmit}
        loadUsersForMention={fetchMentionSuggestions}
      />
    )) ||
    null
  )
}

export default injectIntl(GoalCommentTextField)
