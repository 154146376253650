/* eslint react/jsx-key: 0 */
import React from "react"
import { IndexRedirect, Redirect, IndexRoute, Route } from "react-router"
// Hooks for onEnter
import Authenticate from "./components/pages/lib/Authenticate"
import ensureCultureAmpAuthed from "./components/pages/lib/ensureCultureAmpAuthed"
import redirectToChromePlugin from "./components/pages/lib/redirectToChromePlugin"
import { lazyLoader } from "./util/lazyLoading"
import Company from "./models/Company"
import User from "./models/User"
import { routes as GoalsAppRoutes } from "../src/components/widgets/GoalsApp/GoalsApp"
import { routes as TeamsAppRoutes } from "../src/components/widgets/TeamsApp/TeamsApp"
import { routes as SurveysResponsesRoutes } from "../src/components/widgets/SurveysResponsesRoutes/SurveysResponsesRoutes"
import ErrorPage404 from "./components/util/ErrorPage404/ErrorPage404"
import { HELP_REDIRECT_QUERY_KEY } from "./components/base/HelpCenter/HelpCenter"
import FeatureFlags from "./constants/featureFlags"
import { featureToggle } from "./components/base/FeatureToggle/FeatureToggle"

/* eslint-disable prettier/prettier */
// Admin pages
const AdminApp = lazyLoader(() => import(/* webpackChunkName: "AdminApp" */ "./admin/components/AdminApp"))
const AdminAllEvaluationsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminAllEvaluationsPage/AdminAllEvaluationsPage"))
const AdminCompletedSelfReflectionSurveyResponsePage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminCompletedSelfReflectionSurveyResponsePage/AdminCompletedSelfReflectionSurveyResponsePage"))
const AdminEvaluationOverviewPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminEvaluationOverviewPage/AdminEvaluationOverviewPage"))
const AdminEvaluationQuestionsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminEvaluationQuestionsPage/AdminEvaluationQuestionsPage"))
const AdminEvaluationRootPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminEvaluationRootPage/AdminEvaluationRootPage"))
const AdminEvaluationSummaryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminEvaluationSummaryPage/AdminEvaluationSummaryPage"))
const AdminFeedbackStatsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminFeedbackStatsPage/AdminFeedbackStatsPage"))
const AdminGoalsStatsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminGoalsStatsPage/AdminGoalsStatsPage"))
const AdminIncompleteSelfReflectionSurveyResponsePage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminIncompleteSelfReflectionSurveyResponsePage/AdminIncompleteSelfReflectionSurveyResponsePage"))
const AdminIndividualGoalsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminIndividualGoalsPage/AdminIndividualGoalsPage"))
const AdminIndividualManagerRequestHistoryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminIndividualManagerRequestHistoryPage/AdminIndividualManagerRequestHistoryPage"))
const AdminManagerRequestedFeedbackPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminManagerRequestedFeedbackPage/AdminManagerRequestedFeedbackPage"))
const AdminMRFSettingsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminMRFSettingsPage/AdminMRFSettingsPage"))
const AdminSelfReflectionSurveysPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminSelfReflectionSurveysPage/AdminSelfReflectionSurveysPage"))
const AdminSelfReflectionSurveySummaryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminSelfReflectionSurveySummaryPage/AdminSelfReflectionSurveySummaryPage"))
const AdminSkills = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminSkills/AdminSkills"))
const AdminSurveyFeedbackRequestsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/AdminSurveyFeedbackRequestsPage/AdminSurveyFeedbackRequestsPage"))
const Welcome = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/Welcome/Welcome"))
const AdminEeListingPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/components/pages/EeListingPage/EeListingPage"))

// Main pages
const BrowserPluginGiveFeedback = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/BrowserPluginGiveFeedback/BrowserPluginGiveFeedback"))
const CollaboratorEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/CollaboratorEvaluationPage/CollaboratorEvaluationPage"))
const CompletedSelfReflectionSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/CompletedSelfReflectionSurveyPage/CompletedSelfReflectionSurveyPage"))
const Development = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Development/Development"))
const Emails = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Emails/Emails"))
const EmailUnsubscribe = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EmailUnsubscribe/EmailUnsubscribe"))
const EvaluationOverviewPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EvaluationOverviewPage/EvaluationOverviewPage"))
const EvaluationQuestionsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EvaluationQuestionsPage/EvaluationQuestionsPage"))
const EvaluationRedirectPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EvaluationRedirectPage/EvaluationRedirectPage"))
const EvaluationRootPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EvaluationRootPage/EvaluationRootPage"))
const EvaluationSummaryPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/EvaluationSummaryPage/EvaluationSummaryPage"))
const FeedbackList = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/FeedbackList/FeedbackList"))
const FeedbackRequestsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/FeedbackRequestsPage/FeedbackRequestsPage"))
const Goals = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Goals/Goals"))
const IncompleteMRFSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/IncompleteMRFSurveyPage/IncompleteMRFSurveyPage"))
const IncompleteSelfReflectionSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/IncompleteSelfReflectionSurveyPage/IncompleteSelfReflectionSurveyPage"))
const Leaderboard = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Leaderboard/Leaderboard"))
const MySelfReflectionSurveyResponsesPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/MySelfReflectionSurveyResponsesPage/MySelfReflectionSurveyResponsesPage"))
const MySummary = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/MySummary/MySummary"))
const Notifications = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Notifications/Notifications"))
const PerformanceReviewCommentsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PerformanceReviewCommentsPage/PerformanceReviewCommentsPage"))
const PrinterFriendlyEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PrinterFriendlyEvaluationPage/PrinterFriendlyEvaluationPage"))
const PrinterFriendlyPeerReviewPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PrinterFriendlyPeerReviewPage/PrinterFriendlyPeerReviewPage"))
const PrinterFriendlySelfReflectionPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PrinterFriendlySelfReflectionPage/PrinterFriendlySelfReflectionPage"))
const PrinterFriendlySharedEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PrinterFriendlySharedEvaluationPage/PrinterFriendlySharedEvaluationPage"))
const Profile = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Profile/Profile"))
const PublicPraise = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PublicPraise/PublicPraise"))
const QuickComment = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/QuickComment/QuickComment"))
const Review = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Review/Review"))
const PeerFeedback = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PeerFeedback/PeerFeedback"))
const PeerFeedbackRequest = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/PeerFeedbackRequest/PeerFeedbackRequest"))
const Search = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/Search/Search"))
const SharedCalibrationViewPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/SharedCalibrationViewPage/SharedCalibrationViewPage"))
const SharedCalibrationViewsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/SharedCalibrationViewsPage/SharedCalibrationViewsPage"))
const SharedPerformanceReviewOverview = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/SharedPerformanceReviewOverview/SharedPerformanceReviewOverview"))
const TeamGoalsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamGoalsPage/TeamGoalsPage"))
const TeamMembers = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamMembers/TeamMembers"))
const TeamSelfReflectionSurveysPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamSelfReflectionSurveysPage/TeamSelfReflectionSurveysPage"))
const TeamSelfReflectionSurveySummaryPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamSelfReflectionSurveySummaryPage/TeamSelfReflectionSurveySummaryPage"))
const TeamSummary = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamSummary/TeamSummary"))
const TeamUsage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamUsage/TeamUsage"))
const TeamFeedbackRequest = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./components/pages/TeamFeedbackRequest/TeamFeedbackRequest"))

const AppRaw = lazyLoader(() => import(/* webpackChunkName: "App" */ "./components/App"))
// Converts the App component into a factory function. Allows us to easilly create different
// variants of the App wrapper.
// otherProps - usually just the props passed down from react-router, like location and router
// eslint-disable-next-line react/display-name
const App = props => (otherProps) => <AppRaw {...otherProps} {...props} />
/* eslint-enable prettier/prettier */

const cultureAmpAuthAndAuthorization = async (nextState, replace, callback) => {
  await ensureCultureAmpAuthed(nextState, replace, callback)
  return Authenticate()
}

const routes = [
  <Route path="unsubscribe" component={EmailUnsubscribe} />,

  <Route path="admin" component={AdminApp} onEnter={ensureCultureAmpAuthed}>
    <IndexRoute
      component={Welcome}
      onEnter={Authenticate({
        authorizedRoles: ["admin"],
        routeOnDeny: "admin/evaluation"
      })}
    />

    <Route
      onEnter={Authenticate({
        authorizedRoles: ["admin", "hris_admin"],
        routeOnDeny: "admin/evaluation"
      })}
    >
      <Route
        path="skills"
        onEnter={Authenticate({ authorizedRoles: ["admin"] })}
      >
        <IndexRoute component={AdminSkills} />
        <Route path=":categoryId/(:categoryName)" component={AdminSkills} />
      </Route>
    </Route>

    <Route
      onEnter={Authenticate({
        authorizedRoles: ["admin", "hr_business_partner"]
      })}
    >
      <IndexRedirect to="evaluation" />

      <Route path="evaluation">
        <IndexRoute
          component={featureToggle({
            flag: FeatureFlags.mrfCycles,
            // The new, Employee Evaluation page, with added Peer and Upward feedback
            On: AdminEeListingPage,
            // The old, Employee Evaluation page
            Off: AdminEvaluationOverviewPage
          })}
        />
        <Route path="all">
          <IndexRoute component={AdminAllEvaluationsPage} />

          <Route
            path=":cycleId(/:cycleName/)"
            component={AdminEvaluationRootPage}
          >
            <Route
              path="review/:reviewId"
              component={AdminEvaluationQuestionsPage}
            />
          </Route>
        </Route>
        <Route path="saved_view/:savedViewId">
          <IndexRoute component={AdminAllEvaluationsPage} />

          <Route
            path=":cycleId(/:cycleName/)"
            component={AdminEvaluationRootPage}
          >
            <Route
              path="review/:reviewId"
              component={AdminEvaluationQuestionsPage}
            />
          </Route>
        </Route>
        <Route
          path=":cycleId(/:cycleName/)"
          component={AdminEvaluationRootPage}
        >
          <Route
            path="review/:reviewId"
            component={AdminEvaluationQuestionsPage}
          />
          <IndexRoute component={AdminEvaluationSummaryPage} />
        </Route>
      </Route>

      <Route path="self_reflection">
        <IndexRoute component={AdminSelfReflectionSurveysPage} />

        <Route path=":cycleId/(:cycleName)">
          <IndexRoute component={AdminSelfReflectionSurveySummaryPage} />
        </Route>
      </Route>

      <Route
        path="completed_self_reflection"
        component={AdminCompletedSelfReflectionSurveyResponsePage}
      />

      <Route
        path="incomplete_self_reflection"
        component={AdminIncompleteSelfReflectionSurveyResponsePage}
      />

      <Route path="goals">
        <IndexRedirect to="individual" />
        <Route
          path="individual"
          onEnter={Authenticate({
            predicate: user => Company.isGoalsEnabled(user.company)
          })}
          component={AdminIndividualGoalsPage}
        />
        <Route
          path="stats"
          onEnter={Authenticate({
            predicate: user => Company.isGoalsEnabled(user.company)
          })}
          component={AdminGoalsStatsPage}
        />
      </Route>

      <Route path="team_feedback">
        <IndexRoute component={AdminManagerRequestedFeedbackPage} />
        <Route
          path="managers/:managerId"
          component={AdminIndividualManagerRequestHistoryPage}
        />
        <Route path="history" component={AdminManagerRequestedFeedbackPage} />
      </Route>

      <Route
        path="manager_request_feedback_settings"
        onEnter={Authenticate({ predicate: user => User.isAdmin(user) })}
        component={AdminMRFSettingsPage}
      />

      <Route
        path="request_feedback"
        component={AdminSurveyFeedbackRequestsPage}
      />

      <Route path="feedback/stats" component={AdminFeedbackStatsPage} />
    </Route>
  </Route>,

  <Route
    path="browser_plugin"
    component={App({ showMenu: false, pollNotifications: false })}
    onEnter={cultureAmpAuthAndAuthorization}
  >
    <IndexRoute component={BrowserPluginGiveFeedback} />
    <Route path="redirect" onEnter={redirectToChromePlugin} />
    <Route path="variants/:variant" component={BrowserPluginGiveFeedback} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_peer_review"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlyPeerReviewPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_self_reflection"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlySelfReflectionPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_evaluation"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlyEvaluationPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_shared_evaluation"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlySharedEvaluationPage} />
  </Route>,

  <Route path="/" component={App()} onEnter={ensureCultureAmpAuthed}>
    <Route path="emails" component={Emails} />

    <Redirect from="help" to="/" query={{ [HELP_REDIRECT_QUERY_KEY]: true }} />

    <Route onEnter={Authenticate()}>
      <IndexRoute component={Notifications} />
      {GoalsAppRoutes}
      {TeamsAppRoutes}
      <Route path="notifications" component={Notifications} />

      <Route path="profile" component={Profile} />
      <Route path="review" component={Review} />

      <Route
        path="manager_requested_feedback"
        component={IncompleteMRFSurveyPage}
      />

      <Route
        path="team_self_reflection"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
      >
        <IndexRoute component={TeamSelfReflectionSurveysPage} />
        <Route
          path=":cycleId/(:cycleName)"
          component={TeamSelfReflectionSurveySummaryPage}
        />
      </Route>

      <Route
        path="evaluation"
        onEnter={Authenticate({
          predicate: user => user.company.allow_performance_cycles
        })}
      >
        <Route path="saved_views">
          <IndexRoute component={SharedCalibrationViewsPage} />

          <Route path=":savedViewId">
            <IndexRoute component={SharedCalibrationViewPage} />

            <Route path=":cycleId(/:cycleName/)" component={EvaluationRootPage}>
              <Route
                path="review/:reviewId"
                component={EvaluationQuestionsPage}
              />
            </Route>
          </Route>
        </Route>
        <IndexRoute component={EvaluationOverviewPage} />
        <Route path=":cycleId(/:cycleName/)" component={EvaluationRootPage}>
          <Route path="redirect" component={EvaluationRedirectPage} />
          <Route
            path="review(/:reviewId)"
            component={EvaluationQuestionsPage}
          />
          <IndexRoute component={EvaluationSummaryPage} />
        </Route>
      </Route>

      <Route path="search" component={Search} />
      <Route path="myfeedback" component={FeedbackList} />
      <Route
        path="self_reflections_overview"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={MySelfReflectionSurveyResponsesPage}
      />
      <Route
        path="shared_evaluations_overview"
        onEnter={Authenticate({
          predicate: user => user.company.allow_performance_cycles
        })}
      >
        <IndexRoute component={SharedPerformanceReviewOverview} />
        <Route path="review/:reviewId">
          <IndexRoute component={PerformanceReviewCommentsPage} />
        </Route>
      </Route>
      <Route
        path="leaderboard"
        onEnter={Authenticate({
          predicate: user => user.company.allow_reviews
        })}
        component={Leaderboard}
      />
      <Route path="requests" component={FeedbackRequestsPage} />
      <Route path="development" component={Development} />
      <Route
        path="self_review"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={IncompleteSelfReflectionSurveyPage}
      />
      <Route
        path="completed_self_reflection"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={CompletedSelfReflectionSurveyPage}
      />
      <Route path="quick_comment" component={QuickComment} />
      <Route
        path="public_praise"
        onEnter={Authenticate({
          predicate: user => Company.publicPraiseEnable(user.company)
        })}
        component={PublicPraise}
      />

      <Route
        path="goals"
        onEnter={Authenticate({
          predicate: user => Company.isGoalsEnabled(user.company)
        })}
      >
        <Route path="team" component={TeamGoalsPage} />
        <Route path=":goalPageType" component={Goals} />
        <IndexRedirect to="personal" />
      </Route>

      <Route path="summary(/:section)" component={MySummary} />

      <Route
        path="team_search"
        component={TeamMembers}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route
        path="team_summary(/:section)"
        component={TeamSummary}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route path="team_usage" component={TeamUsage} />
      <Route
        path="team_feedback"
        component={PeerFeedback}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
            <Route
        path="team_feedback_request"
        component={PeerFeedbackRequest}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      {/* New Team Based Feedback (TBF) routes */}
      <Route
        path="team_feedback/:teamMemberId/:teamId"
        component={TeamFeedbackRequest}
        /*onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })} - TODO - add team lead authentication*/
      />

      {SurveysResponsesRoutes}
    </Route>
    <Route
      path="team_based_feedback/:teamId"
      component={TeamFeedbackRequest}
      /*onEnter={Authenticate({
        predicate: user => user.is_a_manager
      })} - TODO - add team lead authentication*/
    />

    <Route
      onEnter={Authenticate({
        predicate: user => user.company.allow_performance_cycles
      })}
      path="collaborator_evaluation/:reviewId"
      component={CollaboratorEvaluationPage}
    />
  </Route>,

  <Route path="*" component={ErrorPage404} />
]

export default routes
