import "./DeprecatedAvatar.less"
import PropTypes from "prop-types"
import React from "react"
import DEFAULT_IMAGE_URL from "../../../../src/static/images-2017/avatars/manager.png"

export default class DeprecatedAvatar extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    url: PropTypes.string,
    className: PropTypes.string,
    fullName: PropTypes.string
  }

  static defaultProps = {
    className: "",
    fullName: ""
  }

  render() {
    const { className, url, size } = this.props
    // This will used to render the initials on the FE
    // const initials = fullName
    //   ?.split(" ")
    //   .map(s => s.charAt(0))
    //   .join("")

    return (
      <img
        role="presentation"
        className={`Avatar ${className} Avatar--${size}`}
        src={url}
        onError={ev => {
          ev.target.src = DEFAULT_IMAGE_URL
          return false
        }}
      />
    )
  }
}
