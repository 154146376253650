import select from "select"
import Actions from "../../actions"

/**
 * Copies the content of a given DOM node to the clipboard.
 *
 * @param {HTMLElement} node - DOM node to copy
 * @param {MessageDescriptor=} successMessage - optional toast message to show on success
 * @param {boolean=} clearAfter - optional. If true, will clear the selection after copying. By
 *    default, the copied text is left selected.
 */
export function copyToClipboard({
  node,
  successMessage = null,
  clearAfter = false
}) {
  select(node)
  const succeeded = document.execCommand("copy")

  if (clearAfter) {
    window.getSelection().removeAllRanges()
  }

  if (succeeded) {
    if (successMessage) {
      Actions.UI.success(successMessage)
    }
  } else {
    throw new Error("Unable to copy text")
  }
}
