import { useContext, useEffect } from "react"
import getDraftDecoder from "../decoders/GetDraftDecoder/getDraftDecoder"
import { DraftsContext, DraftSourceType } from "../state/Drafts"
import useDataAPI from "./useDataAPI"

const useDraft = (
  sourceId: number,
  sourceType: DraftSourceType,
  recipientId?: number
) => {
  const { fire, loading, data } = useDataAPI("/drafts", "GET")
  const { state, dispatch } = useContext(DraftsContext)

  useEffect(() => {
    fire({
      parent_obj_type: sourceType,
      parent_obj_id: sourceId,
      recipient_id: recipientId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, sourceType, recipientId])

  useEffect(() => {
    if (data) {
      const draft = getDraftDecoder(data)

      dispatch({
        type: "FETCH_DRAFT",
        payload: {
          sourceType,
          sourceId,
          recipientId,
          draft
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const drafts = state.draftBySourceType[sourceType][sourceId]
  const draft = drafts ? drafts[recipientId || 0] : undefined

  return { loading, draft }
}

export default useDraft
