import "./MultiCommentContent.less"
import PropTypes from "prop-types"
import React from "react"
import Conversation from "../conversations/Conversation"

export default class MultiCommentContent extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  static propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRepliesNeeded: PropTypes.func.isRequired,
    onReply: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  }

  handleReply(section, body) {
    this.props.onReply(section, body)
  }

  renderSection = section => {
    const { onRepliesNeeded, readOnly } = this.props

    return (
      <Conversation
        key={section.id}
        {...section}
        onRepliesNeeded={onRepliesNeeded}
        onReply={this.handleReply.bind(this, section)}
        scrollToBottom={false}
        readOnly={readOnly}
      />
    )
  }

  render() {
    const { sections } = this.props
    return (
      <div className="MultiCommentContent">
        {sections.map(this.renderSection)}
      </div>
    )
  }
}
