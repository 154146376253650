import { Text } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
// @ts-ignore
import ProgressBar from "../ProgressBar/ProgressBar"
import strings from "../../../locale/strings"
import ProgressSlider from "../ProgressSlider/ProgressSlider"
import styles from "./GoalKeyResults.scss"

type GoalKeyResultsProps = {
  keyResults: {
    id: string
    title: string
    completion: number
  }[]
  onProgressChange: (id: string, progress: number) => void
  readOnly?: boolean
  intl: InjectedIntl
}

const GoalKeyResults = ({
  keyResults,
  onProgressChange,
  readOnly = false,
  intl
}: GoalKeyResultsProps) => {
  const { formatMessage } = intl
  return (
    <div className={styles.container}>
      <div className={styles.keyResultsLabel}>
        <Text tag="p" style="body-bold" inheritBaseline>
          {formatMessage(strings.goalsPage.goalsDossier.keyResults)}
        </Text>
      </div>
      {keyResults?.length > 0 ? (
        keyResults.map(keyResult => {
          return (
            <div className={styles.keyResult} key={keyResult.id}>
              <div className={styles.keyResultTitle}>
                <Text tag="div" style="small" inheritBaseline>
                  {keyResult.title}
                </Text>
              </div>
              <div className={styles.progress}>
                {readOnly ? (
                  <div data-automation-id="goal-key-result-progress-bar">
                    <ProgressBar value={keyResult.completion} />
                  </div>
                ) : (
                  <div data-automation-id="goal-key-result-progress-slider">
                    <ProgressSlider
                      progress={keyResult.completion * 100}
                      onProgressChange={progress =>
                        onProgressChange(keyResult.id, progress)
                      }
                      step={5}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })
      ) : (
        <Text tag="label" style="default-style">
          {formatMessage(strings.goalsPage.goalsDossier.keyResultsEmptyState)}
        </Text>
      )}
    </div>
  )
}

export default injectIntl(GoalKeyResults)
