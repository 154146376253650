import { JsonDecoder } from "ts.data.json"
import { DepartmentGoalPreview, DepartmentGoal } from "../../types/Goals"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  goalKeyResultDecoder,
  goalPriorityDecoder
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

export const departmentGoalDecoder: JsonDecoder.Decoder<DepartmentGoal> = JsonDecoder.object<
  DepartmentGoal
>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
    priority: goalPriorityDecoder,
    type: JsonDecoder.isExactly("department_goal").map(
      (): "department" => "department"
    ),
    owners: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalOwnerDecoder, "goalOwners")
    ),
    departmentId: JsonDecoder.number,
    departmentName: JsonDecoder.failover("", JsonDecoder.string),
    description: JsonDecoder.failover(undefined, JsonDecoder.string),
    status: JsonDecoder.string,
    keyResults: JsonDecoder.failover(
      [],
      JsonDecoder.array(goalKeyResultDecoder, "keyResults")
    ),
    visibility: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    ),
    outgoingAlignmentAssociations: JsonDecoder.failover(
      undefined,
      JsonDecoder.array(
        JsonDecoder.object<{
          id: number
          alignedGoalId: number
        }>(
          {
            id: JsonDecoder.number,
            alignedGoalId: JsonDecoder.number
          },
          "outgoing alignment association",
          {
            alignedGoalId: "incoming_alignment_goal_id"
          }
        ),
        "outgoing alignment associations "
      )
    )
  },
  "departmentGoal",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    owners: "goal_owners",
    departmentId: "department_id",
    departmentName: "department_name",
    keyResults: "key_results",
    outgoingAlignedGoals: "outgoing_aligned_goals",
    outgoingAlignmentAssociations: "outgoing_alignment_associations"
  }
)

export const departmentGoalPreviewDecoder: JsonDecoder.Decoder<DepartmentGoalPreview> = JsonDecoder.object<
  DepartmentGoalPreview
>(
  {
    owners: JsonDecoder.array(goalOwnerDecoder, "owners"),
    type: JsonDecoder.isExactly("department_goal").map(
      (): "department" => "department"
    ),
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map(dateString => new Date(dateString)),
    priority: goalPriorityDecoder,
    status: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    )
  },
  "departmentGoalPreview",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    owners: "goal_owners"
  }
)

type ResponseType = {
  goals: DepartmentGoalPreview[]
  overallProgress: number
}

export const getDepartmentGoalsResponseDecoder = JsonDecoder.object(
  {
    department_goals: JsonDecoder.array(
      JsonDecoder.object<ResponseType>(
        {
          goals: JsonDecoder.array(
            departmentGoalPreviewDecoder,
            "departmentGoals"
          ),
          overallProgress: JsonDecoder.failover(0, JsonDecoder.number)
        },
        "departmentGoal",
        {
          overallProgress: "overall_progress"
        }
      ),
      "getDepartmentGoalsResponse array"
    )
  },
  "getDepartmentGoalsResponse"
).map(({ department_goals }) =>
  department_goals[0] ? department_goals[0] : { goals: [], overallProgress: 0 }
)
