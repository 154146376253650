import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import EmptyState from "../EmptyState/EmptyState"
import strings from "../../../locale/strings"
import emptyPersonalGoalsImg from "../../../static/images-2017/drawings/emptystates/my-goals.png"

function PersonalGoalsEmptyState({ viewingOwnGoals, userName, email }) {
  const personalGoalMessages = strings.goals.empty.personalGoalMessages

  return (
    <EmptyState
      imgSrc={emptyPersonalGoalsImg}
      header={do {
        if (viewingOwnGoals) {
          ;<FormattedMessage {...personalGoalMessages.yourselfHeader} />
        } else {
          ;<FormattedMessage
            {...personalGoalMessages.header}
            values={{ name: userName }}
          />
        }
      }}
      body={
        <FormattedMessage
          {...personalGoalMessages.managerBody}
          values={{ name: userName }}
        />
      }
    />
  )
}

PersonalGoalsEmptyState.propTypes = {
  viewingOwnGoals: PropTypes.bool,
  userName: PropTypes.string,
  email: PropTypes.string
}

export default PersonalGoalsEmptyState
