// Need this implementation file separate from
// SessionActions to prevent
// circular dependency between SessionStore and
// SteadyfootAgent

import _ from "lodash"
import SessionActions from "./SessionActions"
import UIActions, { loadingTypes } from "./UIActions"
// NOTE: import SteadyfootAgent after SessionActions
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody
} from "./lib/apiActionHelpers"
import Settings from "../settings"
import strings from "../locale/strings"

const agent = SteadyfootAgent.defaultInstance

let googleAuth

/* SessionActions Action handlers not allowed there because
 * SteadyfootAgent depends on SessionActions
===============================*/

function authenticate(payload) {
  return new Promise((resolve, reject) => {
    UIActions.setLoadingState(true, { light: true, type: loadingTypes.AUTH })

    agent
      .post("/oauth/token")
      .send({ client_id: Settings.ZUGATA_CLIENT_ID, ...payload })
      .end((err, res) => {
        UIActions.setLoadingState(false, { type: loadingTypes.AUTH })

        if (err) {
          reject(err)
        } else {
          resolve(res.body)
        }
      }, true)
  })
}

SessionActions.login.listenAndPromise(
  (credentials, { silent = false } = {}) => {
    const authPromise = authenticate({
      grant_type: "password",
      username: credentials.user.email,
      password: credentials.user.password
    })

    if (silent) {
      return authPromise
    } else {
      return addToasts(
        { defaultError: strings.signin.genericLoginError },
        authPromise
      )
    }
  }
)

// Alias for "login", but with different failure semantics in the UI
SessionActions.autoLogin.preEmit = function(credentials) {
  SessionActions.login(credentials, { silent: true }).then(
    this.completed,
    this.failed
  )
}

SessionActions.exchangeAuthCode.preEmit = function(authCode) {
  authenticate({
    grant_type: "authorization_code",
    code: authCode,
    redirect_uri: Settings.BEACONSTAR_URL
  }).then(
    session => {
      SessionActions.login.completed(session)
      SessionActions.autoLogin.completed(session)
      this.completed(session)
    },
    err => {
      const body = err.response && err.response.body
      SessionActions.login.failed(body, err)
      SessionActions.autoLogin.failed(body, err)
      this.failed(body, err)
    }
  )
}

SessionActions.confirmAndLogin.listenAndPromise((
  confirmation_token // eslint-disable-line camelcase
) =>
  addLoadingState(
    [true, { light: true, type: loadingTypes.AUTH }],
    addToasts(
      { defaultError: strings.signin.genericConfirmAccountError },
      extractResponseBody(
        agent.post("/oauth/token").send({
          confirmation_token,
          grant_type: "password",
          client_id: Settings.ZUGATA_CLIENT_ID
        })
      )
    )
  )
)

SessionActions.startProviderLogin.listen(
  ({ providerName, settings = null }) => {
    switch (providerName) {
      case Settings.AUTH.PROVIDERS.GOOGLE:
        if (googleAuth) {
          googleAuth
            .grantOfflineAccess({
              access_type: "offline",
              redirect_uri: "postmessage"
            })
            .then(({ code }) => {
              SessionActions.Google.getAuthCode.completed(code)
              SessionActions.Google.exchangeAuthCode(code)
            })
        } else {
          // REVIEW
          UIActions.offline()
        }
        break

      case Settings.AUTH.PROVIDERS.SAML:
        SessionActions.Saml.startLogin(settings)
        break

      default:
        throw new Error(`Unrecognized auth provider name: ${providerName}`)
    }
  }
)

SessionActions.refresh.listenAndPromise(session =>
  addToasts(
    { defaultError: strings.signin.genericAuthenticationError },
    extractResponseBody(
      agent
        .post("/oauth/token")
        .send({
          grant_type: "refresh_token",
          refresh_token: session.refresh_token,
          client_id: Settings.ZUGATA_CLIENT_ID
        })
        .skipAuth(true)
    ).then(body => SessionActions.login.completed(body))
  )
)

SessionActions.logout.listenAndPromise(session => {
  return SessionActions.logout.completed()
})

// Google Actions

/**
 * Init Google API
 * @param  {object} gapi window.gapi from Google's init callback
 *
 * NOTE: can't be a promise due to a Google bug, so use events
 * TODO: move event name to Settings, but make it clear that
 *       Promises are to be preferred in general
 */
SessionActions.Google.init = function(gapi) {
  if (!gapi) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't find Google API")
    return
  }

  gapi.load("auth2", () => {
    googleAuth = gapi.auth2.init({
      client_id: Settings.GOOGLE_AUTH_CLIENT_ID,
      authuser: -1,
      scope: Settings.AUTH.GOOGLE_SCOPES.join(" ")
    })
  })
}

SessionActions.Google.exchangeAuthCode.preEmit = function(authCode) {
  UIActions.setLoadingState(true, { type: loadingTypes.AUTH, light: true })

  agent
    .post("/oauth/token")
    .send({
      grant_type: "password",
      authorization_code: authCode,
      client_id: Settings.ZUGATA_CLIENT_ID
    })
    .end((err, res) => {
      UIActions.setLoadingState(false, { type: loadingTypes.AUTH })

      if (err) {
        UIActions.error(
          _.get(res, "body.error") || strings.signin.googleAuthenticationError
        )
        SessionActions.Google.exchangeAuthCode.failed(res && res.body, err)
        // Handle AuthCode failure separately from form login failure
        // SessionActions.login.failed(res && res.body, err);
      } else {
        SessionActions.Google.exchangeAuthCode.completed(res.body)
        SessionActions.login.completed(res.body)
      }
    }, true)
}

SessionActions.Saml.startLogin.listen(({ redirect_url: redirectUrl }) => {
  window.location = redirectUrl
})
