import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import SurveyResponse from "../../../../models/SurveyResponse"
import { intersperseElement } from "../../../../util/react"
import InfiniteScroll from "../../InfiniteScroll/InfiniteScroll"
import ProfileSelfReflectionEmptyState from "../../self-reflections/ProfileSelfReflectionEmptyState"
import CompletedSelfReflectionSurveyResponse from "./CompletedSelfReflectionSurveyResponse"
import ProfileItemVisibilityIndicator from "../../ProfileItemVisibilityIndicator/ProfileItemVisibilityIndicator"
import "./ProfileSelfReflectionSurveyResponseList.less"

class ProfileSelfReflectionSurveyResponseList extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    htmlId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    surveyResponses: PropTypes.arrayOf(PropTypes.instanceOf(SurveyResponse)),
    hasMore: PropTypes.bool,
    onNextPageNeeded: PropTypes.func,
    emptyState: PropTypes.node
  }

  static defaultProps = {
    className: "",
    hasMore: false,
    onNextPageNeeded: _.noop
  }

  renderSurveyResponse = surveyResponse => {
    const { htmlId, user } = this.props

    return (
      <div key={`survey-response-${surveyResponse.id}`}>
        <div className="layout horizontal">
          <div className="ProfileSelfReflectionSurveyResponseList--cycle-name">
            {surveyResponse.survey_name}
          </div>

          <ProfileItemVisibilityIndicator
            itemType="self_reflection"
            subjectUser={user}
            getTooltipContainer={() => this.container}
          />
        </div>
        <CompletedSelfReflectionSurveyResponse
          htmlId={`${htmlId}--survey-response-${surveyResponse.id}`}
          surveyResponse={surveyResponse}
          enableCopyToClipboard={true}
          readOnly={true}
          hideDivider={true}
        />
      </div>
    )
  }

  render() {
    const {
      className,
      user,
      surveyResponses,
      hasMore,
      onNextPageNeeded,
      emptyState
    } = this.props

    return (
      <div
        ref={node => (this.container = node)}
        className={`ProfileSelfReflectionSurveyResponseList ${className}`}
      >
        {do {
          if (_.get(surveyResponses, "length") === 0) {
            emptyState || <ProfileSelfReflectionEmptyState user={user} />
          } else if (surveyResponses) {
            ;<InfiniteScroll
              items={surveyResponses}
              hasMore={hasMore}
              onNextPageNeeded={onNextPageNeeded}
            >
              {intersperseElement(
                surveyResponses.map(this.renderSurveyResponse),
                <hr className="ProfileSelfReflectionSurveyResponseList--divider" />
              )}
            </InfiniteScroll>
          }
        }}
      </div>
    )
  }
}

export default ProfileSelfReflectionSurveyResponseList
